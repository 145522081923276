import { FallbackProps } from 'react-error-boundary';
import { isDevMode } from './util/util_getbuildtime';


// Docs:
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
// https://www.npmjs.com/package/react-error-boundary


export function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <div className='alert alert-danger'>
      <div>Rendering error: {error.message}</div>
      {isDevMode() && <button onClick={resetErrorBoundary}>Try again</button>}
    </div>
  );
}
