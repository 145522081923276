import { useAppContext } from 'src/hooks/useAppContext';
import { log_info } from 'src/util/util_log';



interface ColumnFilterButtonProps {
  isPopupOpen: boolean;
  isFilterActive: boolean;
  openPopup: () => void;
  closePopup: () => void;
  columnName: string;
}

export function ColumnFilterButton({
  isPopupOpen,
  isFilterActive,
  openPopup,
  closePopup,
  columnName,
}: ColumnFilterButtonProps) {

  const { db, userDetails } = useAppContext();

  return (
    <i className={`bi ${isPopupOpen ? 'bi-filter-square-fill' : 'bi-filter-square'}`}
      style={{ color: isFilterActive ? 'red' : 'black' }}
      onClick={() => {
        if (!isPopupOpen) {
          log_info({ db, userDetails, logkey: 'filterpopup.open', desc: `Open filter popup [${columnName}]` });
          openPopup();
        } else {
          closePopup();
        }
      }}></i>
  );
}
