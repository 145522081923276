import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { formatNum } from 'src/util/util_formatnum';


interface C_CalculationSummaryProps {
  sheet: ExpenseSheetType;
}

export function C_CalculationSummary({
  sheet,
}: C_CalculationSummaryProps) {
  return (
    <>
      <h5>Summary</h5>

      <div className='tw-grid tw-grid-cols-4 tw-items-baseline expense-summary [&>div]:tw-px-2 [&>div]:tw-py-1'>
        <div><label htmlFor='summaryTransportation'>Transportation</label></div>
        <div className='tw-text-end'>+</div>
        <div><input className='form-control' id='summaryTransportation' readOnly value={formatNum(sheet.calc.totalTLTransportation)} /></div>
        <div>JPY</div>

        <div><label htmlFor='summaryOtherExpenses'>Other expenses</label></div>
        <div className='tw-text-end'>+</div>
        <div><input className='form-control' id='summaryOtherExpenses' readOnly value={formatNum(sheet.calc.totalOtherExpenses)} /></div>
        <div>JPY</div>

        <div><label htmlFor='summaryMealAllowance'>Meal allowance</label></div>
        <div className='tw-text-end'>+</div>
        <div><input className='form-control' id='summaryMealAllowance' readOnly value={formatNum(sheet.calc.totalMealAllowance)} /></div>
        <div>JPY</div>

        <div className='tw-col-span-4' style={{ borderTop: '1px solid #666' }}></div>

        <div><label htmlFor='summaryTotalExpenses'>Total expenses</label></div>
        <div className='tw-text-end'></div>
        <div><input className='form-control' id='summaryTotalExpenses' readOnly value={formatNum(sheet.calc.totalExpenses)} /></div>
        <div>JPY</div>

        <div><label htmlFor='summaryAdvancePayment'>Already paid</label></div>
        <div className='tw-text-end'>&#x2212;</div>
        <div><input className='form-control' id='summaryAdvancePayment' readOnly value={formatNum(sheet.calc.totalAdvancePaid)} /></div>
        <div>JPY</div>

        <div className='tw-col-span-4' style={{ borderTop: '1px solid #666' }}></div>

        <div><label htmlFor='summaryBalance'>Balance due</label></div>
        <div className='tw-text-end'></div>
        <div><input className='form-control' id='summaryBalance' readOnly value={formatNum(sheet.calc.balance)} /></div>
        <div>JPY</div>
      </div>
    </>
  );
}
