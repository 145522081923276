import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { useAppContext } from 'src/hooks/useAppContext';
import { DateInput } from 'src/pages/ExpenseSheet/DateInput';
import { TourRequestType } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { dateFormatJpShort, dateFormatJpShortWithTime, dateisoFormatJpShort } from 'src/util/dateformattools';
import { jst0_from_iso, local0_or_null_from_jst0 } from 'src/util/datetools';
import { userrole_isDev } from 'src/util/user_roles';
import { compare } from 'src/util/util_misc';


interface SalesInformationCrudProps {
  tourRequest: TourRequestType;
  userListSimple: UserSimpleType[];
}

export function SalesInformationCrud({ tourRequest, userListSimple }: SalesInformationCrudProps) {

  const { userDetails } = useAppContext();


  // const updateTourRequest = () => {
  //   console.error('saving si1 currently not supported')
  //   window.alert('saving si1 currently not supported')
  // }


  const [showDuplicateKintoneInfo, setShowDuplicateKintoneInfo] = useState(true);


  // *** all hooks above this line ***

  if (!tourRequest.salesInformation) {

    throw new Error('unreachable');


    // tourRequest.salesInformation = {
    //   usersInCharge: [],

    //   paymentMethod: '',

    //   quotedDate: '',
    //   quotedAmount: '',

    //   invoiceDate: '',
    //   invoicedAmount: '',

    //   depositDueBy: '',
    //   depositAmount: '',
    //   depositPaymentDone: '',

    //   balanceDueBy: '',
    //   balanceAmount: '',
    //   balancePaymentDone: '',

    //   note: '',
    // }


  }

  const salesinfo = tourRequest.salesInformation;
  const si1 = tourRequest.salesInformation;

  const hasDupeData =
    salesinfo.customerInformation
    || salesinfo.clientName
    || salesinfo.projectName
    || salesinfo.agencyOrPlatform
    || salesinfo.arrivalDate
    || salesinfo.departureDate;

  const READONLY = true;

  return (
    <div>
      <div className='maingrid sales-info1'>
        {userrole_isDev(userDetails.roles) && (
          <div className='col-all'>
            <ButtonTW disabled={READONLY} onClick={(e) => {
              // const si2 = tourRequest.salesInfo2

              // si2.note = si1.note
              // si2.usersInCharge = si1.usersInCharge.map((user) => user as UserSimpleTeamType) // TODO: hack

              // if (
              //   //si1.quotedDate &&
              //   si1.quotedAmount) {
              //   si2.quotations = [{
              //     dateQuotationSent: si1.quotedDate || si1.dateModified || null,
              //     quotedAmount: si1.quotedAmount as number,
              //     superseded: false,
              //     memorandum: '',
              //   }]
              // } else {
              //   if (si1.quotedDate) {
              //     const msg = 'CHECK THIS: quotedDate provided without quotedAmount'
              //     console.log(msg)
              //     window.alert(msg)
              //   }

              //   if (si1.invoicedAmount) {
              //     si2.quotations = [{
              //       dateQuotationSent: si1.invoiceDate || si1.quotedDate || si1.dateModified || null,
              //       quotedAmount: si1.invoicedAmount as number,
              //       superseded: false,
              //       memorandum: '',
              //     }]
              //   } else {
              //     const totalAmount =
              //       (typeof si1.depositAmount === 'number' && typeof si1.balanceAmount === 'number')
              //         ? si1.depositAmount + si1.balanceAmount
              //         : ''
              //     si2.quotations = [{
              //       dateQuotationSent: si1.quotedDate || si1.invoiceDate || si1.dateModified || null,
              //       quotedAmount: totalAmount as number,
              //       superseded: false,
              //       memorandum: '',
              //     }]
              //   }
              // }

              // si2.payments = []

              // if (si1.depositAmount) {
              //   const paymentType =
              //     (si1.depositAmount === si1.quotedAmount
              //       && si1.depositAmount === si1.invoicedAmount
              //       && !si1.balanceAmount)
              //       ? 'Full amount'
              //       : 'Deposit'

              //   si2.payments.push({
              //     dateInvoiceSent: si1.invoiceDate || si1.quotedDate || si1.dateModified || null,
              //     paymentType: paymentType,
              //     percent: null,
              //     paymentAmount: si1.depositAmount as number,
              //     dateDue: si1.depositDueBy || null,
              //     paymentMethod: si1.paymentMethod,
              //     isPaid: si1.depositPaymentDone,
              //     datePaymentReceived: null, // not in Kintone
              //     freeeStatus: null,
              //   })
              // }

              // if (si1.balanceAmount) {
              //   const paymentType =
              //     ((si1.balanceAmount === si1.quotedAmount || !si1.quotedAmount)
              //       && (si1.balanceAmount === si1.invoicedAmount || !si1.invoicedAmount)
              //       && !si1.depositAmount)
              //       ? 'Full amount'
              //       : 'Balance'

              //   si2.payments.push({
              //     dateInvoiceSent: si1.invoiceDate || si1.quotedDate || si1.dateModified || null,
              //     paymentType: paymentType,
              //     percent: null,
              //     paymentAmount: si1.balanceAmount as number,
              //     dateDue: si1.balanceDueBy || null,
              //     paymentMethod: si1.paymentMethod,
              //     isPaid: si1.balancePaymentDone,
              //     datePaymentReceived: null, // not in Kintone
              //     freeeStatus: null,
              //   })
              // }

              // if (si2.payments.length === 0 && si1.invoicedAmount) {
              //   si2.payments.push({
              //     dateInvoiceSent: si1.invoiceDate || si1.quotedDate || si1.dateModified || null,
              //     paymentType: 'Full amount',
              //     percent: null,
              //     paymentAmount: si1.invoicedAmount as number,
              //     dateDue: null,
              //     paymentMethod: si1.paymentMethod,
              //     isPaid: false,
              //     datePaymentReceived: null, // not in Kintone
              //     freeeStatus: null,
              //   })
              // }



              // updateTourRequest()

            }}>Migrate data</ButtonTW>
          </div>
        )}

        {hasDupeData && (
          <div className='col-all' style={{ fontSize: 'small' }}>
            <CheckboxSwitch id='switchShowDupe' label='Show Kintone duplicate data' checked={showDuplicateKintoneInfo} onChange={(e) => setShowDuplicateKintoneInfo(e.target.checked)} />
          </div>
        )}

        {hasDupeData && showDuplicateKintoneInfo && (
          <>
            <div className='col-all m-3' style={{ display: 'grid', fontSize: 'small' }}>
              <div className='col1'>Customer information</div>
              <div className='col2' style={salesinfo.customerInformation === tourRequest.travellerName ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {salesinfo.customerInformation} | {tourRequest.travellerName}
              </div>

              <div className='col1'>Client Name (add if not travel related customers)</div>
              <div className='col2' style={!salesinfo.clientName ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {salesinfo.clientName}
              </div>

              <div className='col1'>Project Name (add if not travel related customers)</div>
              <div className='col2' style={!salesinfo.projectName ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {salesinfo.projectName}
              </div>

              <div className='col1'>Agency/Platform</div>
              <div className='col2' style={salesinfo.agencyOrPlatform === tourRequest.agencyOrPlatform ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {salesinfo.agencyOrPlatform} | {tourRequest.agencyOrPlatform}
              </div>

              <div className='col1'>Arrival date</div>
              <div className='col2' style={compare(salesinfo.arrivalDate, jst0_from_iso(tourRequest.dateisoTourStart)) === 0 ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {dateFormatJpShort(salesinfo.arrivalDate)} | {dateisoFormatJpShort(tourRequest.dateisoTourStart)}
              </div>

              <div className='col1'>Departure date</div>
              <div className='col2' style={compare(salesinfo.departureDate, jst0_from_iso(tourRequest.dateisoTourEnd)) === 0 ? { backgroundColor: 'lightgreen' } : { backgroundColor: 'red', color: 'white' }}>
                {dateFormatJpShort(salesinfo.departureDate)} | {dateisoFormatJpShort(tourRequest.dateisoTourEnd)}
              </div>

              <div className='col1'>Date created</div>
              <div className='col2'>
                {dateFormatJpShortWithTime(salesinfo.dateCreated)}
              </div>

              <div className='col1'>Date modified</div>
              <div className='col2'>
                {dateFormatJpShortWithTime(salesinfo.dateModified)}
              </div>

            </div>
          </>
        )}

        <div className='col1 pt-2'>Person in charge</div>
        <div className='col2'>

          <Typeahead
            id='inputPersonInCharge'
            labelKey='name'
            multiple
            // @_ts-expect-error This issue says to case the onChange handler parameter, but TypeScript doesn't like it: https://github.com/ericgio/react-bootstrap-typeahead/issues/738
            // onChange={(array: UserSimpleType[]) => {
            //   salesinfo.usersInCharge = array
            //   updateTourRequest()
            // }}
            options={userListSimple}
            placeholder='Select one or several users'
            selected={salesinfo.usersInCharge}
            disabled={READONLY}
          />
        </div>

        <div className='col1 pt-2'>Payment method</div>
        <div className='col2'>
          <Form.Control type='text' disabled={READONLY} value={salesinfo.paymentMethod}
          // onChange={(e) => {
          //   salesinfo.paymentMethod = e.target.value
          //   updateTourRequest()
          // }}
          />
        </div>

        <div className='col1 pt-2'>Quoted date</div>
        <div>
          <DateInput disabled={READONLY} value_local0={local0_or_null_from_jst0(salesinfo.quotedDate)}
            onChange={(date_local0) => {
              // salesinfo.quotedDate = date_local0 ? jst0_from_local0(date_local0) : null
              // updateTourRequest()
            }}
          />
        </div>

        <div className='pt-2'>Quoted amount</div>
        <div>
          <Form.Control type='text' disabled={READONLY} value={salesinfo.quotedAmount}
          // onChange={(e) => {
          //   salesinfo.quotedAmount = strToNum(e.target.value)
          //   updateTourRequest()
          // }}
          />
        </div>

        {/* <div className='freee-checkbox'>
        Freee
        <Form.Check type='checkbox' inline id='chkFreee1' checked={salesinfo.freeeInput1} onChange={(e) => {
          salesinfo.freeeInput1 = e.target.checked
          updateTourRequest()
        }} />
      </div> */}

        <div className='col1 pt-2'>Invoice date</div>
        <div>
          <DateInput disabled={READONLY} value_local0={local0_or_null_from_jst0(salesinfo.invoiceDate)}
            onChange={(date_local0) => {
              // salesinfo.invoiceDate = date_local0 ? jst0_from_local0(date_local0) : null
              // updateTourRequest()
            }}
          />
        </div>

        <div className='pt-2'>Invoiced amount</div>
        <div>
          <Form.Control type='text' disabled={READONLY} value={salesinfo.invoicedAmount}
          // onChange={(e) => {
          //   salesinfo.invoicedAmount = strToNum(e.target.value)
          //   updateTourRequest()
          // }}
          />
        </div>

        {/* <div className='freee-checkbox'>
        Freee
        <Form.Check type='checkbox' inline id='chkFreee2' checked={salesinfo.freeeInput2} onChange={(e) => {
          salesinfo.freeeInput2 = e.target.checked
          updateTourRequest()
        }} />
      </div> */}

        <div className='col1 pt-2'>Deposit due by</div>
        <div>
          <DateInput disabled={READONLY} value_local0={local0_or_null_from_jst0(salesinfo.depositDueBy)}
            onChange={(date_local0) => {
              // salesinfo.depositDueBy = date_local0 ? jst0_from_local0(date_local0) : null
              // updateTourRequest()
            }}
          />
        </div>

        <div className='pt-2'>Deposit amount</div>
        <div>
          <Form.Control type='text' disabled={READONLY} value={salesinfo.depositAmount}
          // onChange={(e) => {
          //   salesinfo.depositAmount = strToNum(e.target.value)
          //   updateTourRequest()
          // }}
          />
        </div>

        {/* <div className='freee-checkbox'>
        Freee
        <Form.Check type='checkbox' inline id='chkFreee3' checked={salesinfo.freeeInput3} onChange={(e) => {
          salesinfo.freeeInput3 = e.target.checked
          updateTourRequest()
        }} />
      </div> */}

        <div></div>
        <div></div>

        <div>Deposit paid</div>
        <div>
          <Form.Check type='checkbox' disabled={READONLY} inline id='chkDepositPaid' checked={salesinfo.depositPaymentDone}
          // onChange={(e) => {
          //   salesinfo.depositPaymentDone = e.target.checked
          //   updateTourRequest()
          // }}
          />
        </div>

        <div className='col1 pt-2'>Balance due by</div>
        <div>
          <DateInput disabled={READONLY} value_local0={local0_or_null_from_jst0(salesinfo.balanceDueBy)}
            onChange={(date_local0) => {
              // salesinfo.balanceDueBy = date_local0 ? jst0_from_local0(date_local0) : null
              // updateTourRequest()
            }}
          />
        </div>

        <div className='pt-2'>Balance amount</div>
        <div>
          <Form.Control type='text' disabled={READONLY} value={salesinfo.balanceAmount}
          // onChange={(e) => {
          //   salesinfo.balanceAmount = strToNum(e.target.value)
          //   updateTourRequest()
          // }}
          />
        </div>

        {/* <div className='freee-checkbox'>
        Freee
        <Form.Check type='checkbox' inline id='chkFreee4' checked={salesinfo.freeeInput4}
        //  onChange={(e) => {
        //   salesinfo.freeeInput4 = e.target.checked
        //   updateTourRequest()
        // }}
           />
      </div>
 */}
        <div></div>
        <div></div>

        <div>Balance paid</div>
        <div>
          <Form.Check type='checkbox' disabled={READONLY} inline id='chkBalancePaid' checked={salesinfo.balancePaymentDone}
          // onChange={(e) => {
          //   salesinfo.balancePaymentDone = e.target.checked
          //   updateTourRequest()
          // }}
          />
        </div>

        <div className='col1 col1'>Note</div>
        <div className='col2'>
          <Form.Control as='textarea' disabled={READONLY} rows={3} value={salesinfo.note}
          // onChange={(e) => {
          //   salesinfo.note = e.target.value
          //   updateTourRequest()
          // }}
          />
        </div>



      </div>
    </div>
  );
}
