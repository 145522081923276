import { PayeeType } from 'src/types/types_payee';
import { UserSimpleUidType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { escapeRegex } from 'src/util/util_misc';


const mapPrefixExpansions = new Map([
  ['(株)', '株式会社'],
  ['㈱', '株式会社'],

  ['(有)', '有限会社'],
  ['㈲', '有限会社'],

  ['(同)', '合同会社'],
]);

const listPrefixes = [
  '株式会社',
  '有限会社',
  '合同会社',
  '一般社団法人',
  '事業協同組合',
  '宗教法人',
  '公益財団法人',
  '特定非営利活動法人',
];

const mapPrefixKana = new Map([
  ['株式会社', 'カブシキガイシャ'],
  ['有限会社', 'ユウゲンガイシャ'],
  ['合同会社', 'ゴウドウガイシャ'],
  ['一般社団法人', 'イッパンシャダンホウジン'],
  ['事業協同組合', 'ジギョウキョウドウクミアイ'],
  ['宗教法人', 'シュウキョウホウジン'],
  ['公益財団法人', 'コウエキザイダンホウジン'],
  ['特定非営利活動法人', 'トクテイヒエイリカツドウホウジン'],
]);

export function getAbbr(prefix: string) {
  if (prefix === '株式会社')
    return '㈱';
  if (prefix === '有限会社')
    return '㈲';
  if (prefix === '合同会社')
    return '(同)';
  if (prefix === '一般社団法人')
    return '㈳'; // officially (一社)
  if (prefix === '事業協同組合')
    return '';
  return prefix;
}

export function parsePrefix(fullname: string, payee: PayeeType) {

  // first, we expand abbreviated company types
  for (const abbr of mapPrefixExpansions.keys()) {
    fullname = fullname.replaceAll(abbr, mapPrefixExpansions.get(abbr)!);
  }

  // then, we split out the prefix/suffix from the main name
  const regexPrefix = new RegExp(`^(${listPrefixes.map(escapeRegex).join('|')})(.+)$`);
  const regexSuffix = new RegExp(`^(.+)(${listPrefixes.map(escapeRegex).join('|')})$`);

  const matchPrefix = fullname.match(regexPrefix);
  if (matchPrefix) {
    payee.nameJaPrefix = matchPrefix[1];
    payee.nameJaMain = matchPrefix[2];
    payee.nameJaSuffix = '';
    payee.nameKanaPrefix = mapPrefixKana.get(payee.nameJaPrefix)!;
    payee.nameKanaSuffix = '';
  } else {
    const matchSuffix = fullname.match(regexSuffix);
    if (matchSuffix) {
      payee.nameJaPrefix = '';
      payee.nameJaMain = matchSuffix[1];
      payee.nameJaSuffix = matchSuffix[2];
      payee.nameKanaPrefix = '';
      payee.nameKanaSuffix = mapPrefixKana.get(payee.nameJaSuffix)!;
    } else {
      payee.nameJaPrefix = '';
      payee.nameJaMain = fullname;
      payee.nameJaSuffix = '';
      payee.nameKanaPrefix = '';
      payee.nameKanaSuffix = '';
    }
  }

  trimPayee(payee);
}

export function trimPayee(payee: PayeeType) {
  payee.nameJaPrefix = payee.nameJaPrefix.trim();
  payee.nameJaMain = payee.nameJaMain.trim();
  payee.nameJaSuffix = payee.nameJaSuffix.trim();
}

export function getBlankPayee(userSimple: UserSimpleUidType) {
  const payee: PayeeType = {
    _isDeleted: false,
    id: null!,
    categories: [],
    isOverseasPayee: false,
    isFreelanceGuide: false,
    // company/individual
    //   - Ja
    nameJaPrefix: '',
    nameJaMain: '',
    nameJaSuffix: '',
    //   - Kana
    nameKanaPrefix: '',
    nameKanaMain: '',
    nameKanaSuffix: '',
    //   - En
    nameEnMain: '',
    // brand
    nameBrandJa: '',
    nameBrandKana: '',
    nameBrandEn: '',
    // person
    namePersonJa: '',
    namePersonKana: '',
    namePersonEn: '',
    // metadata
    dateCreated: serverTimestampAsDate(),
    userCreatorUid: userSimple.uid,
    userCreatorName: userSimple.name,
    userCreatorEmail: userSimple.email,
    dateModified: serverTimestampAsDate(),
    userModifiedUid: userSimple.uid,
    userModifiedName: userSimple.name,
    userModifiedEmail: userSimple.email,

    // local fields
    nameJaFull: '',
    nameKanaFull: '',
    nameEnFull: '',
    nameHiraganaFull: '',
    displayName: '',
  };

  return payee;
}

export function getPayeeMainName(s: string) {
  const payee = getBlankPayee({ uid: '', email: '', name: '' });
  parsePrefix(s, payee);
  return payee.nameJaMain;
}
