import { UserSimpleType } from 'src/types/types_user';
import { addDays, addMonthsUtc, getDateutc, getSpanDaysExact } from 'src/util/datetools';
import { GuideCalendarCellDataType } from '../useGuideCalendarCellData';



function round1(num: number) {
  return Math.round(num * 10) / 10;
}


interface YearlyGuideCalendarProps {
  guideCalendarCellData: GuideCalendarCellDataType;
  dateutcCalendarStart: Date;
  dateutcCalendarEnd: Date;
  numDaysInCalendar: number;
  iGuide: number | null;
  guideObj: UserSimpleType | null;
  closePopup: () => void;
  yearlyCalendarYear: number | null;
  setYearlyCalendarYear: (year: number | null) => void;
  viewAsCalendarEditor: boolean;
}

export function YearlyGuideCalendar({
  guideCalendarCellData,
  dateutcCalendarStart,
  dateutcCalendarEnd,
  numDaysInCalendar,
  iGuide,
  guideObj,
  closePopup,
  yearlyCalendarYear,
  setYearlyCalendarYear,
  viewAsCalendarEditor,
}: YearlyGuideCalendarProps) {

  if (iGuide === null || !guideObj)
    return null;

  const { lookup, allRectangles } = guideCalendarCellData;

  const lookupGuide = lookup.get(guideObj.id);
  if (!lookupGuide)
    // shouldn't happen, but in case user switches to view as guide for example
    return null;


  const year = yearlyCalendarYear || dateutcCalendarStart.getUTCFullYear();

  const twCellDay = 'tw-w-[2em] tw-h-[2em] tw-flex tw-justify-center tw-items-center';


  const yearDivs = [];


  for (let month = 1; month <= 12; month++) {
    const monthDivs = [];

    const dateutcFirstDay = getDateutc(year, month, 1);
    const firstDayDayOfWeek = dateutcFirstDay.getUTCDay(); // 0=Sunday,1=Monday...6=Saturday
    const dateutcLastDay = addDays(addMonthsUtc(dateutcFirstDay, 1), -1);
    const lastDayDayOfWeek = dateutcLastDay.getUTCDay(); // 0=Sunday,1=Monday...6=Saturday
    const lastDayDayOfMonth = dateutcLastDay.getUTCDate();

    let workHoursMin = 0;
    let workHoursMax = 0;

    // empty cells before 1st of month
    for (let i = 0; i < firstDayDayOfWeek; i++)
      monthDivs.push(<div key={`pre_${i}`} className={twCellDay}></div>);

    for (let day = 1; day <= lastDayDayOfMonth; day++) {
      const dateutc = addDays(dateutcFirstDay, day - 1);
      const dayIndex = getSpanDaysExact(dateutcCalendarStart, dateutc);
      if (dayIndex >= 0 && dayIndex < numDaysInCalendar) {
        const cellContents = lookupGuide.get(dayIndex);
        let backgroundColor: string | undefined = undefined;
        if (cellContents && cellContents.length > 0) {
          if (cellContents.length > 1) {
            backgroundColor = 'red';
            workHoursMin += Math.min(...cellContents.map((c) => Number(c.guidingHours)));
            workHoursMin += Math.max(...cellContents.map((c) => Number(c.guidingHours)));
          } else if (cellContents[0].cellType !== 'dayoff') {
            backgroundColor = cellContents[0].backgroundColor;
            workHoursMin += Number(cellContents[0].guidingHours);
            workHoursMax += Number(cellContents[0].guidingHours);
          }
        }
        monthDivs.push(
          <div key={`day_${day}`} className={twCellDay} style={{ backgroundColor }}>
            {day}
          </div>
        );
      } else {
        // not in scope
        monthDivs.push(
          <div key={`day_${day}`} className={twCellDay} style={{ color: '#ddd' }}>
            {day}
          </div>
        );
      }
    }

    // empty cells after last day of month
    for (let i = lastDayDayOfWeek + 1; i < 7; i++)
      monthDivs.push(<div key={`post_${i}`} className={twCellDay}></div>);


    let workDaysStr = '';
    if (viewAsCalendarEditor && workHoursMax > 0) {
      if (workHoursMin === workHoursMax) {
        workDaysStr = ` (${round1(workHoursMin / 8)} days)`;
      } else {
        workDaysStr = ` (${round1(workHoursMin / 8)}~${round1(workHoursMax / 8)} days)`;
      }
    }

    yearDivs.push(
      <div key={`month_${month}`}>
        <div style={{
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: '0.25em',
        }}>{year}/{month} {workDaysStr}</div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, 1fr)',
            border: '1px solid #888',
          }}>
          {monthDivs}
        </div>
      </div>
    );

  }


  return (
    <div style={{
      position: 'absolute',
      top: '1em',
      left: '10em',
      border: '0.125em solid #aaa',
      backgroundColor: 'white',
      padding: '0.5em',
      borderRadius: '1em',
      boxShadow: '0 0 0.5em 0 #00000060',
    }}>
      <div style={{
        fontSize: '1.5em',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 0.5em',
      }}>
        <div style={{ flexBasis: 0, flexGrow: 1 }}>
          {guideObj.name}
        </div>
        <div style={{ flexBasis: 0, flexGrow: 1, textAlign: 'center' }}>
          <i className='bi bi-chevron-left' style={{ fontSize: '0.9em', cursor: 'pointer' }} onClick={() => {
            setYearlyCalendarYear(year - 1);
          }}></i>
          {year}
          <i className='bi bi-chevron-right' style={{ fontSize: '0.9em', cursor: 'pointer' }} onClick={() => {
            setYearlyCalendarYear(year + 1);
          }}></i>
        </div>
        <div style={{ flexBasis: 0, flexGrow: 1, textAlign: 'right' }}>
          <i className='bi bi-x-square' style={{ cursor: 'pointer' }} onClick={() => {
            closePopup();
          }}></i>
        </div>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '0.25em',
      }}>
        {yearDivs}
      </div>
    </div>
  );
}
