import { Link } from 'react-router-dom';
import { getTailwindButtonClassName, VariantNameType } from './util_buttontw';



interface ButtonTWProps {
  to?: string;
  variant?: VariantNameType | 'link' | 'none';
  textSize?: 'sm' | 'md';
  onClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'submit' | undefined;
  id?: string;
}

export function ButtonTW({
  to,
  onClick,
  onMouseDown,
  variant = 'blue',
  textSize = 'sm',
  className,
  style,
  title,
  children,
  disabled,
  type,
  id,
}: ButtonTWProps) {



  let tailwind: string;
  if (variant === 'link') {
    tailwind = `
      tw-border-none
      tw-bg-transparent
      tw-m-0
      tw-p-0
      tw-text-blue-600
      tw-underline
      ${className || ''}
    `;
  } else if (variant === 'none') {
    tailwind = `
      tw-border-none
      tw-bg-transparent
      tw-m-0
      tw-p-0
      tw-text-black
      ${className || ''}
    `;
  } else {

    let variantUsed = variant;

    if (disabled) {
      if (variant.endsWith('_outline')) {
        variantUsed = 'disabled_outline';
      } else {
        variantUsed = 'disabled';
      }
    }

    tailwind = getTailwindButtonClassName(variantUsed, textSize) + ' ' + (className || '');
  }

  if (to) {
    return (
      <Link to={to}
        className={tailwind}
        style={{
          ...(style || {}),
          // lineHeight: '1.3125rem',
          // lineHeight: '1.5', // from bootstrap
          display: 'inline-block', // inline-block increases vertical height relative to inline, so we change tw-py-2 to tw-py-1. inline-block is better to accommodate inner icon height than inline.
        }}
        title={title}
        id={id}
      >{children}</Link>
    );
  }

  return (
    <button className={tailwind} onClick={onClick} onMouseDown={onMouseDown} title={title} style={style} disabled={disabled} type={type} id={id}>
      {children}
    </button>
  );

}
