import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { AgencyMonthlyStats } from './AgencyMonthlyStats';
import { useAgencyList } from './useAgencyList';



export function PageAgencyMonthlyStats() {

  const agencyList = useAgencyList();

  usePageTitle('Agency Monthly Stats');
  const loadingSpinner = getLoadingSpinnerOrNull([
    ['agency list', agencyList],
  ]);
  if (!agencyList)
    return loadingSpinner;

  return (
    <div className='container-fluid'>

      <h2 className='my-4'>Agency Monthly Stats</h2>

      <AgencyMonthlyStats agencyList={agencyList} />
    </div>
  );
}
