import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { GuideCalendarOfficeWorkType, GuideCalendarType } from 'src/types/types_tourrequest';
import { UserSimpleType } from 'src/types/types_user';
import { dateutcFormatMD } from 'src/util/dateformattools';
import { addDays } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { GuideCalendarSelectedCellType } from '../CalendarBlocks/R3_Guides';
import { GuideCalendarDaysoffButtons } from '../Components/GuideCalendarDaysoffButtons';
import { GuideCalendarNotesButtons } from '../Components/GuideCalendarNotesButtons';
import { GuideCalendarOfficeButtons } from '../Components/GuideCalendarOfficeButtons';
import { CellContentsType, RectangularBlockType } from '../useGuideCalendarCellData';
import { setDocGuidecal } from '../util_db_calendar';



interface GuideCalendarPopupProps {
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_GUIDECAL_EM: number;
  selectedCell: GuideCalendarSelectedCellType;
  guideName: string;
  dateutcCalendarStart: Date;
  isReadOnly: boolean;
  userSimple: UserSimpleType;
  showDeletionConfirmationPopup: string | null;
  setShowDeletionConfirmationPopup: (value: string | null) => void;
  setSelectedCell: (value: GuideCalendarSelectedCellType | null) => void;
  rowDays: Map<number, CellContentsType[]>;
  guideCalClipboard: RectangularBlockType | null;
  setGuideCalClipboard: (data: RectangularBlockType | null) => void;
}


export function GuideCalendarPopup({
  CELL_WIDTH_EM,
  CELL_HEIGHT_GUIDECAL_EM,
  selectedCell,
  guideName,
  dateutcCalendarStart,
  isReadOnly,
  userSimple,
  showDeletionConfirmationPopup,
  setShowDeletionConfirmationPopup,
  setSelectedCell,
  rowDays,
  guideCalClipboard,
  setGuideCalClipboard,
}: GuideCalendarPopupProps) {

  const { db, userDetails } = useAppContext();

  // selectedCell.colList contains at least 1 element.
  // if there are multiple elements, they are in order added, not in ascending order.
  const dayIndexFirstSelected = Math.min(...selectedCell.colList);
  const dayIndexLastSelected = Math.max(...selectedCell.colList);
  const dateutcSelectedFrom = addDays(dateutcCalendarStart, dayIndexFirstSelected);
  const dateutcSelectedTo = addDays(dateutcCalendarStart, dayIndexLastSelected);


  const rectangles = new Set<RectangularBlockType>();

  for (const dayIndex of selectedCell.colList) {
    const dayContents = rowDays.get(dayIndex);
    if (dayContents) {
      for (const item of dayContents) {
        if (item.rectangle) // sometimes item.rectangle is null (not sure why)
          rectangles.add(item.rectangle);
        else
          console.error('rectangle was null');
      }
    }
  }


  // output
  const datedRectanglesInPopup: JSX.Element[] = [];

  for (const rectangle of rectangles) {

    const boxContents = rectangle.cellContentJsx;

    const dateutcStart = addDays(dateutcCalendarStart, rectangle.dayIndex);
    let datesHeader = dateutcFormatMD(dateutcStart);
    if (rectangle.length > 1) {
      const dateutcEnd = addDays(dateutcCalendarStart, rectangle.dayIndex + rectangle.length - 1);
      datesHeader += '-' + dateutcFormatMD(dateutcEnd);
    }

    const additionalText: JSX.Element[] = [];
    let curStartDate: string | null = null;
    for (let i = 0; i < rectangle.cells.length; i++) {
      const cell = rectangle.cells[i];
      if (!cell.guidingAdditionalText)
        continue;
      if (i < rectangle.cells.length - 1 && cell.guidingAdditionalText === rectangle.cells[i + 1].guidingAdditionalText) {
        // memo is identical in next cell, skip this one to only show it once
        if (!curStartDate)
          curStartDate = cell.dateutc && dateutcFormatMD(cell.dateutc);
        continue;
      }
      additionalText.push(
        <div key={cell.dateiso} style={{
          backgroundColor: '#ffffff80',
          marginTop: '0.3em',
        }}>
          {curStartDate ? `${curStartDate}-` : ''}
          {cell.dateutc && dateutcFormatMD(cell.dateutc)}:{' '}
          {cell.guidingAdditionalText}
        </div>
      );
      curStartDate = null;
    }

    // for the div key, rectangle.identifier is not enough as if the guide is guiding on multiple non-contiguous days
    // on the same tour, there will be multiple rectangles with the same tour id.
    // so we also add the start date to make the key unique.
    const divKey = `col=${rectangle.dayIndex} w=${rectangle.length} ${rectangle.identifier}`;

    datedRectanglesInPopup.push(
      <div key={divKey} style={{
        backgroundColor: rectangle.backgroundColor,
        color: rectangle.textColor,
        padding: '0.3em',
        marginBottom: '0.3em',
      }}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 3em',
        }}>
          <div>
            <div style={{
              paddingBottom: '0.1em',
            }}><b>{datesHeader}</b></div>
            {boxContents}
          </div>
          <div>
            {rectangle.cellType === 'officework' && (
              <ButtonTW variant='transparent' style={{
                fontSize: '1.4em',
              }}
                className='tw-py-2'
                onClick={() => {
                  setGuideCalClipboard(rectangle);
                }}>
                <i className='bi bi-copy'></i>
              </ButtonTW>
            )}
          </div>
        </div>
        <div>
          {additionalText}
        </div>
      </div>
    );
  }


  const selectedCellsDaysOff: string[] = [];
  const selectedCellsOfficeDays: string[] = [];
  const selectedCellsCalendarNotes: string[] = [];

  for (const dayIndex of selectedCell.colList) {
    const dayContents = rowDays.get(dayIndex);
    if (dayContents) {
      for (const item of dayContents) {
        if (item.cellType === 'dayoff') {
          selectedCellsDaysOff.push(item.dateiso);
        }
        if (item.cellType === 'officework') {
          selectedCellsOfficeDays.push(item.dateiso);
        }
        if (item.cellType === 'calendarnote') {
          selectedCellsCalendarNotes.push(item.dateiso);
        }
      }
    }
  }



  return (
    <div className='guideCalendarPopup' style={{
      position: 'absolute',
      top: `${(selectedCell.row + 1.2) * CELL_HEIGHT_GUIDECAL_EM}em`,
      left: `${(selectedCell.colList[0] + 0.2) * CELL_WIDTH_EM}em`,
      width: '20em',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5em',
    }}
      onMouseDown={(e) => {
        e.stopPropagation(); // prevent the selection from moving when clicking *inside* the popup
      }}
      onMouseMove={(e) => {
        e.stopPropagation(); // prevent the selection from moving when clicking *inside* the popup
      }}
    >
      <div>
        <b>{guideName}</b><br />
        {dateutcFormatMD(dateutcSelectedFrom)}{selectedCell.colList.length > 1 ? ` - ${dateutcFormatMD(dateutcSelectedTo)}` : ''}
      </div>

      <div>
        {datedRectanglesInPopup}
      </div>

      {!isReadOnly && (
        <>
          {guideCalClipboard && (
            <>
              <ButtonTW variant='blue_outline' onClick={() => {
                for (let i = 0; i < guideCalClipboard.length; i++) {
                  const dayIndex = guideCalClipboard.dayIndex + i;
                  const dayItems = rowDays.get(dayIndex);
                  if (dayItems && dayItems.length > 0) {
                    // some other activity is already present, so we abort the paste
                    window.alert(`Cannot paste because another activity is already present on ${guideCalClipboard.cells[i].dateiso}`);
                    return;
                  }
                }

                const updateObj: Omit<GuideCalendarType, 'id' | 'daysOff' | 'calendarNotes'> = {
                  userModified: userSimple,
                  dateModified: serverTimestampAsDate(),
                  officeWork: {},
                };
                for (const item of guideCalClipboard.cells) {
                  const obj: GuideCalendarOfficeWorkType = {
                    _isDeleted: false,
                    dateiso: item.dateiso,
                    userModified: userSimple,
                    dateModified: serverTimestampAsDate(),
                    workContent: guideCalClipboard.identifier,
                    workHours: item.guidingHours,
                  };
                  // updateObj[`officeWork.${item.dateiso}`] = obj
                  updateObj.officeWork[item.dateiso] = obj;
                }

                setDocGuidecal(db, selectedCell.guideObj, updateObj, 'paste', 'tourcalendar.guide_action.paste', userDetails,
                  `Paste office work on ${Object.keys(updateObj.officeWork).map((dateiso) => `[${dateiso}]`).join('')}`
                );

                // close popup as it gets in the way to paste to other guides
                setSelectedCell(null);
              }}>
                Paste
              </ButtonTW>
              <ButtonTW variant='darkgray_outline' onClick={() => {
                setGuideCalClipboard(null);
              }}>
                Clear clipboard
              </ButtonTW>
            </>
          )}

          <GuideCalendarOfficeButtons
            userSimple={userSimple}
            dateutcCalendarStart={dateutcCalendarStart}
            selectedCell={selectedCell}
            selectedEventDates={selectedCellsOfficeDays}
            showDeletionConfirmationPopup={showDeletionConfirmationPopup}
            setShowDeletionConfirmationPopup={setShowDeletionConfirmationPopup}
            closePopup={() => setSelectedCell(null)}
          />

          <GuideCalendarDaysoffButtons
            userSimple={userSimple}
            dateutcCalendarStart={dateutcCalendarStart}
            selectedCell={selectedCell}
            selectedEventDates={selectedCellsDaysOff}
            showDeletionConfirmationPopup={showDeletionConfirmationPopup}
            setShowDeletionConfirmationPopup={setShowDeletionConfirmationPopup}
            closePopup={() => setSelectedCell(null)}
          />

          <GuideCalendarNotesButtons
            userSimple={userSimple}
            dateutcCalendarStart={dateutcCalendarStart}
            selectedCell={selectedCell}
            selectedEventDates={selectedCellsCalendarNotes}
            showDeletionConfirmationPopup={showDeletionConfirmationPopup}
            setShowDeletionConfirmationPopup={setShowDeletionConfirmationPopup}
            closePopup={() => setSelectedCell(null)}
          />
        </>
      )}

      <ButtonTW variant='darkgray_outline'
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedCell(null);
        }}>
        Close
      </ButtonTW>
    </div>
  );
}
