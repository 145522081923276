import { PlacesType, Tooltip } from 'react-tooltip';


interface InfoToolTipProps {
  tooltipId: string;
  tooltipHtml: string;
  tooltipPlace: PlacesType;
  label?: string;
}

export function InfoToolTip({
  tooltipId,
  tooltipHtml,
  tooltipPlace,
  label,
}: InfoToolTipProps) {

  return (
    <div>
      <a data-tooltip-id={tooltipId} data-tooltip-html={tooltipHtml} data-tooltip-place={tooltipPlace}><i className='bi bi-info-circle'></i>{label}</a>
      <Tooltip id={tooltipId} place={tooltipPlace} variant='dark' />
    </div>

  );
}
