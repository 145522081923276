import { collection, deleteField, doc, DocumentReference, DocumentSnapshot, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FaPerson } from 'react-icons/fa6';
import { HiMiniMoon } from 'react-icons/hi2';
import { MdBedroomParent } from 'react-icons/md';
import { TiWarningOutline } from 'react-icons/ti';
import { NavLink, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { AddButton } from 'src/components/Buttons/AddButton';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxButton } from 'src/components/Buttons/CheckboxButton';
import { CheckboxButtonBAR } from 'src/components/Buttons/CheckboxButtonBAR';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { SearchButton } from 'src/components/Buttons/SearchButton';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { EditableField } from 'src/components/EditableField/EditableField';
import { EditableFieldWithBorder } from 'src/components/EditableField/EditableFieldWithBorder';
import { getEditableFieldBorderStyle } from 'src/components/EditableField/util_borderstyle';
import { EditableFieldDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldDatepicker';
import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { ModalTW } from 'src/components/Modal/ModalTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { TopWhiteBarEditControls } from 'src/hooks/autosave/TopWhiteBarEditControls';
import { useUndoRedo } from 'src/hooks/autosave/useUndoRedo';
import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { TourRequestType } from 'src/types/types_tourrequest';
import { bookingStatuses, coll_tripquotations, coll_tripquotationshistory, HotelNightType, HotelRoomRawType, HotelRoomType, HotelTripQuotationRowRawType, HotelTripQuotationRowType, PassengerTripQuotationRowType, QuotationSnapshotType, Season, SelectedHotelPriceType, ServiceTripQuotationRowRawType, ServiceTripQuotationRowType, TripQuotationRawType, TripQuotationType, TripQuotationUpdateObjType } from 'src/types/types_tripquotations';
import { UserSimpleTeamType, UserSimpleUidType } from 'src/types/types_user';
import { dateFormatJpShort, dateFormatJpWithTime, dateisoFormatDayMonth, dateisoFormatDayOfWeek, dateisoFormatJp, dateisoFormatJpShort } from 'src/util/dateformattools';
import { addDaysIso, getSpanDaysExactIso, getTodayIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { userrole_isDev } from 'src/util/user_roles';
import { verifyNotDeleted } from 'src/util/util_db_misc';
import { convertServicePriceDates, convertTourRequestDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { log_db_read, log_db_write, log_info } from 'src/util/util_log';
import { arraySum, compare, convertToNumberOrNull } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { HotelOccupancyType, HotelPricesType, HotelRoomPricesType, ServiceMappingType, ServicePriceCatalogType, ServicePriceItemType } from '../../types/types_supplierprices';
import { useUserListSimple } from '../ExpenseSheet/util_getuserlist';
import { addMetadataModifiedHotelPricesTable, addMetadataModifiedServicePricesTable, roundUp10 } from '../Payees/SupplierPrices/util_serviceprices';
import { checkDupesAndSaveNewTourRequest, getTwoCharacterDate } from '../Requests/RequestsCrud/util_db_tourrequests';
import { getBlankTourRequest } from '../Requests/util_tourrequests';
import { HotelPriceBreakdownTable } from './HotelPriceBreakdownTable';
import { HotelSearch } from './HotelSearch';
import { QuotationPricingBlock } from './QuotationPricingBlock';
import { RoomSelector } from './RoomSelector';
import { ServiceSearch } from './ServiceSearch';
import { exportToExcel, getMealPlan, statusColors } from './tripQuotationExportUtils';
import { applyHotelRoomPrice, applyServicePriceToServiceRow, getHotelRoomList, getHotelRoomListWithPrice, getHotelRoomsObject, getPaxPerRoom, getSeasons } from './tripQuotationParsingUtils';
import { ButtonUpDown } from './UiComponents/ButtonUpDown';
import { CsvSourceTable } from './UiComponents/CsvSourceTable';
import { RowHighlighters } from './UiComponents/RowHighlighters';
import { SourceDataGrayBox } from './UiComponents/SourceDataGrayBox';
import { ItemNoteAlert, SupplierNoteAlert } from './UiComponents/SupplierNoteAlert';
import { highlighterColorMap } from './UiComponents/util_highlighters';

const AUDLEY_ID = 'vbJlvc6SlhCJCZeyv2OP';

const hotelsfield = (toplevel: 'hotels', rowid: string, fieldname: keyof HotelTripQuotationRowRawType) => {
  return `${toplevel}.${rowid}.${fieldname}`;
};

const servicesfield = (toplevel: 'services', rowid: string, fieldname: keyof ServiceTripQuotationRowRawType) => {
  return `${toplevel}.${rowid}.${fieldname}`;
};

const checkServiceRowsInChronologicalOrder = (serviceRows: ServiceTripQuotationRowType[]) => {
  let currentDate = '';
  for (let i = 0; i < serviceRows.length; i++) {
    if (!serviceRows[i].dateiso) {
      // ignore empty dates
      continue;
    }
    if (currentDate && serviceRows[i].dateiso < currentDate) {
      // not in order
      return false;
    }
    currentDate = serviceRows[i].dateiso;
  }
  return true;
};

export function PageQuotationEdit() {

  const { quotationId, quotationSnapshotId } = useParams();
  const [enableEditing, setEnableEditing] = useState(!quotationSnapshotId);
  const [saveStatus, setSaveStatus] = useState<string>();
  const [quotation, setQuotation] = useState<TripQuotationType>();
  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>();
  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>();
  const [filteredHotelPrices, setFilteredHotelPrices] = useState<HotelPricesType[]>([]);
  const [filteredServicePrices, setFilteredServicePrices] = useState<ServicePriceCatalogType[]>([]);
  const { db, setDbError, userDetails } = useAppContext();
  const [editedCell, setEditedCell] = useState<string | null>(null);
  const [showCsvSource, setShowCsvSource] = useState(false);
  const [shownPopup, setShownPopup] = useState<string | null>(null);
  const [showRowIds, setShowRowIds] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);

  const [snapshotNameInput, setSnapshotNameInput] = useState('');
  const [showSnapshotCreationModal, setShowSnapshotCreationModal] = useState(false);
  const [showHotelSearchModal, setShowHotelSearchModal] = useState(false);
  const [shouldSaveMapping, setShouldSaveMapping] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<HotelPricesType>();
  const [selectedRoom, setSelectedRoom] = useState<HotelRoomPricesType>();
  const [selectedOccupancy, setSelectedOccupancy] = useState<HotelOccupancyType>();
  const [hotelRowToMatch, setHotelRowToMatch] = useState<HotelTripQuotationRowType>();
  const [currentSeasons, setCurrentSeasons] = useState<Season[]>();
  const [hotelSearchQuery, setHotelSearchQuery] = useState<string>('');
  const [extendedInfoShownIds, setExtendedInfoShownIds] = useState<string[]>([]);

  const [showServiceSearchModal, setShowServiceSearchModal] = useState(false);
  const [serviceRowToMatch, setServiceRowToMatch] = useState<ServiceTripQuotationRowType>();
  const [selectedService, setSelectedService] = useState<ServicePriceCatalogType>();
  const [selectedServiceItem, setSelectedServiceItem] = useState<ServicePriceItemType>();
  const [serviceSearchQuery, setServiceSearchQuery] = useState<string>('');
  const [automatchOnDetails, setAutomatchOnDetails] = useState(false);

  const { getUndoRedoHistoryChanges } = useUndoRedo<TripQuotationType>('tripquotationshistory');
  const userListSimple = useUserListSimple();

  const userSimple = useMemo(() => {
    const userSimple: UserSimpleUidType = {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };
    return userSimple;
  }, [userDetails]);

  const snapshotNameAlreadyExists = useMemo(() => {
    if (quotation && snapshotNameInput) {
      return quotation.snapshotList.some((snapshot) => snapshot.snapshotName === snapshotNameInput);
    }
  }, [snapshotNameInput, quotation]);

  const snapshotVersionAlreadyExists = useMemo(() => {
    if (quotation) {
      return quotation.snapshotList.some(snapshot => snapshot.historyDocumentId === quotation.history.currentStepId);
    }
  }, [quotation]);

  const snapshotName = useMemo(() => {
    if (quotationSnapshotId && quotation) {
      const snapshot = quotation.snapshotList.find(snapshot => snapshot.historyDocumentId === quotationSnapshotId);
      return snapshot?.snapshotName;
    }
  }, [quotation, quotationSnapshotId]);

  useEffect(() => {
    if (!quotationId && !quotationSnapshotId) {
      setQuotation(undefined);
      return;
    }

    let isLoaded = false;
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const quotationRaw = { ...snapshot.data(), id: snapshot.id } as TripQuotationRawType;
      verifyNotDeleted(snapshot.exists(), quotationRaw, snapshot.id, setDbError, 'tripquotation');
      const serviceRows: ServiceTripQuotationRowType[] = Object.entries(quotationRaw.services).map(([key, value]) => {
        return { ...value, id: key };
      }).sort((a, b) => a.index - b.index);
      const indexes = JSON.stringify(serviceRows.map(row => (row as any).index)); // should be [0,1,2,3,4,5,6,7,8]
      const ordered = JSON.stringify(new Array(serviceRows.length).fill(0).map((_, i) => i)); // generate [0,1,2,3,4,5,6,7,8]
      if (indexes !== ordered) // check indexes in db are a sequence of consecutive integers starting from 0
        console.error('service indexes not ordered:', indexes);

      delete (quotationRaw as Partial<TripQuotationRawType>).services;
      const quotation = quotationRaw as unknown as TripQuotationType;
      quotation.serviceList = serviceRows;

      // Note: due to the large number of objects, we mutate the objects returned by Firestore instead of creating new ones with the spread operator.
      const hotelRows: HotelTripQuotationRowType[] = Object.entries(quotationRaw.hotels).map(([keyRow, rawRow]) => {
        const hotelRoomList: (HotelRoomType & { index: number })[] = Object.entries(rawRow.hotelRooms || {}).map(([keyRoom, rawRoom]) => {
          const hotelNightList: (HotelNightType & { index: number })[] = Object.entries(rawRoom.hotelNights).map(([keyNight, rawNight]) => {
            // RAW NIGHT: add id
            const night = rawNight as HotelNightType & { index: number };
            night.id = keyNight;
            return night;
          });
          hotelNightList.sort((a, b) => a.index - b.index);
          // RAW ROOM: add id, add hotelNightList, delete hotelNights
          const room = rawRoom as unknown as HotelRoomType & { index: number };
          room.id = keyRoom;
          room.hotelNightList = hotelNightList;
          delete (room as Partial<HotelRoomRawType>).hotelNights;
          return room;
        });
        hotelRoomList.sort((a, b) => a.index - b.index);
        // RAW ROW: add id, add hotelRoomList, delete hotelRooms
        const row = rawRow as unknown as HotelTripQuotationRowType & { index: number };
        row.id = keyRow;
        row.hotelRoomList = hotelRoomList;
        delete (row as Partial<HotelTripQuotationRowRawType>).hotelRooms;
        return row;
      }).sort((a, b) => a.index - b.index);
      delete (quotationRaw as Partial<TripQuotationRawType>).hotels;
      quotation.hotelList = hotelRows;

      if (quotationRaw.passengers) {

        const passengerRows: PassengerTripQuotationRowType[] = Object.entries(quotationRaw.passengers).map(([key, value]) => {
          return { ...value, id: key };
        }).sort((a, b) => a.index - b.index)
          .map(row => {
            const { index, ...remainder } = row;
            return remainder;
          });
        delete (quotationRaw as Partial<TripQuotationRawType>).passengers;
        quotation.passengerList = passengerRows;
      }

      const snapshots: QuotationSnapshotType[] = Object.entries(quotationRaw.snapshots ?? {}).map(([key, value]) => {
        return { ...value, id: key };
      }).sort((a, b) => compare(b.dateCreated, a.dateCreated));
      delete (quotationRaw as Partial<TripQuotationRawType>).snapshots;
      quotation.snapshotList = snapshots;

      if (!isLoaded) {
        if (quotationSnapshotId) {
          log_db_read({ db, userDetails, logkey: 'db_read.open_tripquotation_snapshot', desc: `Opened trip quotation snapshot ${quotationSnapshotId} ${quotation.generalInfo?.agencyItineraryRefWithVersion}` });
        } else {
          log_db_read({ db, userDetails, logkey: 'db_read.open_tripquotation', desc: `Opened trip quotation ${quotationId} ${quotation.generalInfo?.agencyItineraryRefWithVersion}` });
        }
        isLoaded = true;
      }

      setQuotation(quotation);
    };

    let documentRef: DocumentReference;
    let errorMsg: string;
    if (quotationId) {
      documentRef = doc(db, coll_tripquotations, quotationId);
      errorMsg = `Getting trip quotation ${quotationId}`;
    } else if (quotationSnapshotId) {
      documentRef = doc(db, coll_tripquotationshistory, quotationSnapshotId);
      errorMsg = `Getting trip quotation snapshot ${quotationSnapshotId}`;
    } else {
      throw Error('No id was not provided to retrieve the trip quotation');
    }
    const unsubscribe = onSnapshot(documentRef, processSnapshot, (err) => setDbError(errorMsg, err));

    return unsubscribe;
  }, [db, setDbError, userDetails, quotationId, quotationSnapshotId]);

  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {
        const hotelList: HotelPricesType[] = [];
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType;
          hotelList.push(hotelprice);
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex);
        setHotelPrices(hotelList);
        setFilteredHotelPrices(hotelList);
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    );
  }, [db, setDbError]);

  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {
        const serviceList: ServicePriceCatalogType[] = [];
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType;
          convertServicePriceDates(serviceprice);
          serviceList.push(serviceprice);
        }
        // serviceList.sort((a, b) => stringCompare(a.serviceName, b.serviceName))
        setServicePrices(serviceList);
        setFilteredServicePrices(serviceList);
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    );
  }, [db, setDbError]);

  const [requestsWithAudleyRef, setRequestsWithAudleyRef] = useState<TourRequestType[]>();
  useEffect(() => {
    if (!quotation) {
      // loading...
      return;
    }
    const audleyRef = quotation.generalInfo.agencyItineraryRef;
    if (!audleyRef) {
      console.log('No Audley ref found in quotation');
      setRequestsWithAudleyRef([]);
      return;
    }
    onSnapshot(
      query(collection(db, 'tourrequests'), where('audleyRef', '==', audleyRef)),
      (snapshot) => {
        const list: TourRequestType[] = [];
        for (const docu of snapshot.docs) {
          const request = { ...docu.data(), id: docu.id } as TourRequestType;
          convertTourRequestDates(request);
          list.push(request);
        }
        setRequestsWithAudleyRef(list);
      },
      (err) => setDbError(`Error getting requests: ${err}`)
    );
  }, [db, setDbError, quotation, quotation?.generalInfo?.agencyItineraryRef]);

  useEffect(() => {
    if (hotelPrices) {
      if (hotelSearchQuery) {
        const words = hotelSearchQuery.trim().split(' ');
        const res = hotelPrices.filter(hotelPrice => {
          let wordMatches = 0;
          for (const word of words) {
            if (hotelPrice.facilityName.toLowerCase().includes(word.toLowerCase())) {
              wordMatches++;
            }
          }
          if (wordMatches === words.length) return hotelPrice;
        });
        setFilteredHotelPrices(res);
      } else {
        setFilteredHotelPrices(hotelPrices);
      }
    }
  }, [hotelSearchQuery, hotelPrices]);

  useEffect(() => {
    if (servicePrices) {
      if (serviceSearchQuery) {
        const words = serviceSearchQuery.trim().split(' ');
        const res = servicePrices.filter(servicePrice => {
          let wordMatches = 0;
          for (const word of words) {
            if (servicePrice.serviceName.toLowerCase().includes(word.toLowerCase())) {
              wordMatches++;
            }
          }
          if (wordMatches === words.length) return servicePrice;
        });
        setFilteredServicePrices(res);
      } else {
        setFilteredServicePrices(servicePrices);
      }
    }
  }, [serviceSearchQuery, servicePrices]);


  // *** all hooks above this line ***

  let pageTitle = 'Quotation';
  if (quotation)
    pageTitle += ` ${quotation.requestCode || quotation.generalInfo.agencyItineraryRefWithVersion}`;
  usePageTitle(pageTitle);
  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotation', quotation],
    ['hotelPrices', hotelPrices],
    ['servicePrices', servicePrices],
    ['user list', userListSimple],
    ['requests', requestsWithAudleyRef],
  ]);
  if (!quotation || !hotelPrices || !servicePrices || !userListSimple || !requestsWithAudleyRef)
    return loadingSpinner;

  const isAudley = !!quotation.sourceCsv;
  const agentLabel = isAudley ? 'Audley' : 'Agent';

  const addMetadataModifiedQuotation = (updateObj: Partial<TripQuotationType>, userSimple: UserSimpleUidType) => {
    updateObj.dateModified = serverTimestampAsDate();
    updateObj.userModified = userSimple;
  };

  const autosaveNewStep = async (
    userAction: string,
    updateObj: Partial<TripQuotationType> | TripQuotationUpdateObjType,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {
    return autosaveAnyStep(userAction, updateObj, false, undefined, sUndoWall);
  };

  const autosaveAnyStep = async (
    userAction: string,
    updateObj: any,
    isUndoRedo: boolean,
    undoRedoTargetStep: number | undefined,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => {

    if (!enableEditing) {
      setDbError('Field change despite editing being disabled');
      throw new Error('Field change despite editing being disabled');
    }

    autosaveDocument(
      updateObj,
      userAction,
      isUndoRedo,
      undoRedoTargetStep,
      sUndoWall,
      quotation.id,
      quotation.history,
      userSimple,
      db,
      coll_tripquotations,
      (updateObj: Partial<TripQuotationType>) => addMetadataModifiedQuotation(updateObj, userSimple),
      setSaveStatus,
    )
      .catch((err) => setDbError(`Autosave [autosaveDocument] quotation id=${quotation.id} action=[${userAction}]`, err));
  };

  const autosaveUndoRedoStep = async (action: 'Undo' | 'Redo', targetStep: number) => {

    const undoRedoData = await getUndoRedoHistoryChanges(action, targetStep, quotation.history);
    if (!undoRedoData)
      // failed to retrieve history step from db
      return;

    const { updateObjHistory, targetStepObj } = undoRedoData;

    // fields that we remove in general:
    //   - id: we never save id as a field
    //   - _isDeleted: should always be false
    //   - history: specifically tweaked in a precise way by updateObjHistory
    //   - dateCreated, userCreated: immutable metadata
    //   - dateModified, userModified: metadata set upon save
    // general expense fields that we remove:
    //   - tbd

    // delete the field we specifically don't want:
    delete targetStepObj.id;
    delete targetStepObj._isDeleted;
    delete targetStepObj.history;
    // @ts-expect-error field parentDocumentId doesn't exist on type GeneralExpenseType
    delete targetStepObj.parentDocumentId;

    delete targetStepObj.dateCreated;
    delete targetStepObj.userCreated;
    delete targetStepObj.dateModified;
    delete targetStepObj.userModified;
    delete (targetStepObj as Partial<TripQuotationRawType>).snapshots;

    // add more immutable fields as needed:
    // here we should basically add all fields that are not editable through the CRUD UI

    const updateObj: Partial<TripQuotationType> = {
      ...targetStepObj,
      ...updateObjHistory,
    };

    autosaveAnyStep(
      action, // this isn't actually used
      updateObj,
      true,
      targetStep,
      'u', // undo/redo step is always undoable
    );
  };

  const servicesTotal = arraySum(quotation.serviceList.map(row => row.totalPrice ?? 0));
  const hotelsTotal = arraySum(quotation.hotelList.map(row => row.totalPrice ?? 0));



  const resetHotelSearch = () => {
    setShowHotelSearchModal(false);
    setSelectedHotel(undefined);
    setSelectedOccupancy(undefined);
    setSelectedRoom(undefined);
    setHotelRowToMatch(undefined);
    setCurrentSeasons(undefined);
    setHotelSearchQuery('');
    setFilteredHotelPrices(hotelPrices);
    setShouldSaveMapping(false);
  };
  const resetServiceSearch = () => {
    setShowServiceSearchModal(false);
    setSelectedService(undefined);
    setSelectedServiceItem(undefined);
    setServiceRowToMatch(undefined);
    setServiceSearchQuery('');
    setFilteredServicePrices(servicePrices);
    setShouldSaveMapping(false);
    setAutomatchOnDetails(false);
  };

  const createQuotationSnapshot = async () => {

    if (!quotationId) throw Error('Cannot create snapshot from other snapshot');

    log_info({ db, userDetails, logkey: 'tripquotations.create_snapshot', desc: `Create a snapshot for quotation: [${quotationId}]` });

    const updateObj: Record<string, any> = {
      [`snapshots.${nano_id()}`]: {
        dateCreated: serverTimestampAsDate(),
        userCreated: userSimple,
        historyDocumentId: quotation.history.currentStepId,
        snapshotName: snapshotNameInput || `Exported on ${dateFormatJpWithTime(new Date())}`,
      },
    };
    console.log(quotation.history.currentStepId);
    await updateDoc(doc(db, coll_tripquotations, quotationId), updateObj);
  };

  const submitRoomChoice = async () => {
    if (hotelRowToMatch && currentSeasons && selectedOccupancy && selectedRoom && selectedHotel) {
      log_info({ db, userDetails, logkey: 'tripquotations.apply_price_hotelroom', desc: `Apply hotel room price to hotel row: [${selectedHotel.facilityName}] [${selectedRoom.roomName}] [${selectedOccupancy.numOfPaxPerRoom}] savemapping=${shouldSaveMapping}`, errorData: { selectedHotel, selectedRoom, selectedOccupancy } });
      const numOfRooms = hotelRowToMatch.numOfRooms;
      const numOfPax = hotelRowToMatch.numOfPax;
      const numOfNights = hotelRowToMatch.numOfNights;

      if (!numOfRooms) {
        window.alert('Number of rooms is missing.');
        resetHotelSearch();
        return;
      }
      if (!numOfPax) {
        window.alert('Number of pax is missing.');
        resetHotelSearch();
        return;
      }
      if (!numOfNights) {
        window.alert('Number of nights is missing.');
        resetHotelSearch();
        return;
      }

      const hotelRoomList = applyHotelRoomPrice(hotelRowToMatch.hotelRoomList, null, selectedOccupancy);

      let totalPriceRaw = 0;
      for (const room of hotelRoomList) {
        for (const night of room.hotelNightList) {
          totalPriceRaw += night.totalPrice || 0;
        }
      }

      const totalPricePerPaxPerNight = roundUp10(totalPriceRaw / (numOfPax * numOfNights));
      const totalPrice = totalPricePerPaxPerNight * numOfPax * numOfNights;

      const selectedHotelPrice: SelectedHotelPriceType = {
        id: selectedHotel.id,
        hotelPlace: selectedHotel.city,
        hotelName: selectedHotel.facilityName,
        roomType: selectedRoom.roomName,
        numOfPaxPerRoom: selectedOccupancy.numOfPaxPerRoom,
        season: hotelRoomList[0].hotelNightList[0].season, // maxSeason
        pricePerPax: hotelRoomList[0].hotelNightList[0].pricePerPax,
      };

      const updateObj: Partial<TripQuotationType> = {
        [hotelsfield('hotels', hotelRowToMatch.id, 'selectedHotelPrice')]: selectedHotelPrice,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelPlace')]: selectedHotel.city,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelName')]: selectedHotel.facilityName,
        [hotelsfield('hotels', hotelRowToMatch.id, 'roomType')]: selectedRoom.roomName,
        [hotelsfield('hotels', hotelRowToMatch.id, 'pricePerPaxPerNight')]: totalPricePerPaxPerNight,
        [hotelsfield('hotels', hotelRowToMatch.id, 'totalPrice')]: totalPrice,
        [hotelsfield('hotels', hotelRowToMatch.id, 'hotelRooms')]: getHotelRoomsObject(hotelRoomList),
      };
      autosaveNewStep('Update hotel', updateObj, 'u');

      if (shouldSaveMapping) {
        const newHotelMappingKey = hotelRowToMatch.sourceHotelName;
        const newRoomMapping = hotelRowToMatch.sourceRoomType.replace(/^(\d+)x/i, '').trim();

        const updateObj: Partial<HotelPricesType> = {
          hotelNameMappings: [
            ...(selectedHotel.hotelNameMappings || []),
            newHotelMappingKey,
          ],
          roomList: selectedHotel.roomList.map(room => {
            if (room.roomName === selectedRoom.roomName) {
              const newRoom: HotelRoomPricesType = {
                ...room,
                roomNameMappings: [
                  ...(room.roomNameMappings || []),
                  newRoomMapping,
                ],
              };
              return newRoom;
            } else {
              return room;
            }
          }),
        };

        await autosaveDocument(
          updateObj,
          'Add hotel mapping',
          false,
          undefined,
          'UNDOWALL',
          selectedHotel.id,
          selectedHotel.history,
          userSimple,
          db,
          'hotelprices',
          (updateObj: any) => addMetadataModifiedHotelPricesTable(updateObj, userSimple),
          null,
        );
      }

      resetHotelSearch();
    }
  };

  const submitServiceChoice = async () => {
    if (serviceRowToMatch && selectedService && selectedServiceItem) {
      log_info({ db, userDetails, logkey: 'tripquotations.apply_price_service', desc: `Apply service price to service row: [${selectedService.serviceName}] [${selectedServiceItem.priceItemName}] savemapping=${shouldSaveMapping}`, errorData: { selectedService, selectedServiceItem } });

      const applied = applyServicePriceToServiceRow(selectedService, selectedServiceItem, serviceRowToMatch.numOfPax);
      if (!applied) return;

      const updateObj: Partial<TripQuotationType> = {
        [servicesfield('services', serviceRowToMatch.id, 'selectedServicePrice')]: applied.selectedServicePrice,
        [servicesfield('services', serviceRowToMatch.id, 'serviceName')]: applied.serviceName,
        [servicesfield('services', serviceRowToMatch.id, 'pricePerPax')]: applied.pricePerPax,
        [servicesfield('services', serviceRowToMatch.id, 'totalPrice')]: applied.totalPrice,
      };
      autosaveNewStep('Update service', updateObj, 'u');

      if (shouldSaveMapping) {
        const newMappingObj: ServiceMappingType = {
          serviceName: serviceRowToMatch.sourceServiceName.trim(), // audley service name
          details: automatchOnDetails ? serviceRowToMatch.serviceDetails.trim() : '', // audley service details
        };

        const updateObj: Partial<ServicePriceCatalogType> = {
          priceItems: selectedService.priceItems.map(priceItem => {
            if (priceItem.id === selectedServiceItem.id) {
              const newPriceItem: ServicePriceItemType = {
                ...priceItem,
                itemMappings: [
                  ...(priceItem.itemMappings || []),
                  newMappingObj,
                ],
              };
              return newPriceItem;
            } else {
              return priceItem;
            }
          }),
        };

        await autosaveDocument(
          updateObj,
          'Add service mapping',
          false,
          undefined,
          'UNDOWALL',
          selectedService.id,
          selectedService.history,
          userSimple,
          db,
          'serviceprices',
          (updateObj: any) => addMetadataModifiedServicePricesTable(updateObj, userSimple),
          null,
        );
      }
      resetServiceSearch();
    }
  };

  const seenDates = new Set<string>();
  const overlappingDates = new Set<string>();
  for (const [index, row] of quotation.hotelList.entries()) {
    if (index > 0 && quotation.hotelList[index - 1].checkinDateiso === row.checkinDateiso && quotation.hotelList[index - 1].checkoutDateiso === row.checkoutDateiso) {
      // same group with identical dates
      continue;
    }
    for (let dateiso = row.checkinDateiso; dateiso < row.checkoutDateiso; dateiso = addDaysIso(dateiso, 1)) {
      if (seenDates.has(dateiso)) {
        overlappingDates.add(dateiso);
      } else {
        seenDates.add(dateiso);
      }
    }
  }

  const tabNavigation = (tabKey: -1 | 1 | undefined, prevId: string, nextId: string) => {
    if (tabKey === -1) {
      setEditedCell(prevId || null);
    } else if (tabKey === 1) {
      setEditedCell(nextId || null);
    } else {
      setEditedCell(null);
    }
  };

  const nopriceServices = quotation.serviceList.filter(row => row.totalPrice === null).length;
  const nopriceHotels = quotation.hotelList.filter(row => row.totalPrice === null).length;
  const nopriceItems = nopriceServices + nopriceHotels;


  const getServiceBlueBubbleCell = (dateiso: string, discontinuousDates?: boolean) => {
    const dayOfWeek = dateisoFormatDayOfWeek(dateiso);
    return (
      <div className='tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-gap-0.5 tw-relative _tw-top-[-0.75em]'>
        {dateiso && (
          <div className={
            `tw-border tw-px-2 tw-border-solid tw-rounded
                     ${discontinuousDates ? 'tw-border-red-300 tw-bg-red-50' : 'tw-border-blue-300 tw-bg-blue-50'}
                       tw-grow tw-flex tw-flex-col tw-justify-center tw-text-sm`
          }>
            <div className='tw-whitespace-nowrap tw-text-center'>
              Day {1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, dateiso)}
            </div>
            <div className='tw-whitespace-nowrap tw-text-center'>
              <span className={`${dayOfWeek === 'Sat' || dayOfWeek === 'Sun' ? 'tw-text-red-500' : ''}`}>
                {dayOfWeek} {dateisoFormatDayMonth(dateiso)}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const addServiceRowHandler = (newRowIndex: number, dateiso: string) => {
    // newRowIndex = current_row_index + 1
    const newRow: ServiceTripQuotationRowRawType = {
      index: newRowIndex,
      csvdata: null,
      dateiso: dateiso,
      sourceServicePlace: '',
      serviceType: 'Package_Item',
      sourceServiceName: '',
      serviceName: '',
      sourceServiceDetails: '',
      serviceDetails: '',
      supplierNote: '',
      itemNote: '',
      pickUpTime: '',
      dropOffTime: '',
      passengers: '',
      bookingStatus: 'RQ',
      bookingRemarks: '',
      internalMemo: '',
      numOfPax: null,
      pricePerPax: null,
      totalPrice: null,
      selectedServicePrice: null,
    };

    const serviceId = nano_id();

    const updateObj: Record<string, any> = {
      [`services.${serviceId}`]: newRow,
    };

    // move all subsequent services down by 1
    for (let i = newRowIndex; i < quotation.serviceList.length; i++) {
      updateObj[servicesfield('services', quotation.serviceList[i].id, 'index')] = i + 1; // increment(1);
    }

    log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.add_service_row', desc: 'Insert service row' });

    autosaveNewStep('Insert new service row', updateObj, 'u');
  };

  const AddButtonServiceRow = ({ newRowIndex, dateiso }: { newRowIndex: number; dateiso: string }) => {
    return (
      <AddButton
        className='tw-bg-white'
        dataTooltipHtml={`Add a new service row on ${dateisoFormatJpShort(dateiso)}`}
        dataTooltipId='tooltip-page-quotation-edit'
        onClick={() => addServiceRowHandler(newRowIndex, dateiso)}
      />
    );
  };

  const getEmptyServiceRow = (newRowIndex: number, dateiso: string) => {
    return (
      <tr key={dateiso}>
        <td className='tw-border-t-2 !tw-bg-slate-100'>
          {getServiceBlueBubbleCell(dateiso)}
        </td>
        <td className='tw-border-t-2 !tw-bg-slate-100'>
          {dateisoFormatJp(dateiso)}
        </td>
        <td className='tw-border-t-2 !tw-bg-slate-100'></td>
        <td className='tw-border-t-2 !tw-bg-slate-100' colSpan={5}>
          <div className='tw-flex tw-items-center tw-h-full'>
            No services on this day
          </div>
        </td>
        <td className='tw-border-t-2 !tw-bg-slate-100'>
          <div className='tw-flex tw-items-center tw-h-full tw-justify-end'>
            <AddButtonServiceRow newRowIndex={newRowIndex} dateiso={dateiso} />
          </div>
        </td>
        <td className='!tw-bg-transparent'></td>
        <td className='tw-border-t-2 !tw-bg-slate-100'></td>
        <td className='tw-border-t-2 !tw-bg-slate-100'></td>
        <td className='!tw-bg-transparent'></td>
        <td className='tw-border-t-2 !tw-bg-slate-100'></td>
      </tr>
    );
  };


  const allServiceDates = [...new Set(quotation.serviceList.map(row => row.dateiso))].sort();
  let currentDate: string = 'start';
  const missingDatesByPrevDate = new Map<string, string[]>(); // prev date => list of immediate following dates that have zero service
  for (let dateiso = quotation.generalInfo.tripStartDateiso; dateiso <= quotation.generalInfo.tripEndDateiso; dateiso = addDaysIso(dateiso, 1)) {
    if (allServiceDates.includes(dateiso)) {
      currentDate = dateiso;
    } else {
      const list = missingDatesByPrevDate.get(currentDate) || [];
      list.push(dateiso);
      missingDatesByPrevDate.set(currentDate, list);
    }
  }
  const missingDatesByPrecedingRow = new Map<string, string[]>(); // preceding row id => list of dates that are missing
  for (const [date, dates] of missingDatesByPrevDate) {
    const precedingRowId = date === 'start' ? 'start' : quotation.serviceList.filter(row => row.dateiso === date).at(-1)!.id;
    missingDatesByPrecedingRow.set(precedingRowId, dates);
  }

  const missingDatesAtStart = missingDatesByPrecedingRow.get('start');

  const servicesAreInChronologicalOrder = checkServiceRowsInChronologicalOrder(quotation.serviceList);


  const priceMultiplier = 1
    * (1 + (quotation.pricingInfo?.eightyDaysMarkupActive && quotation.pricingInfo?.eightyDaysMarkup || 0) / 100)
    / (1 - (quotation.pricingInfo?.agentCommissionActive && quotation.pricingInfo?.agentCommission || 0) / 100);

  return (
    <div>
      <Tooltip id='tooltip-page-quotation-edit' place='bottom' variant='dark' />

      <TopWhiteBarEditControls
        whiteBarActive={!quotationSnapshotId}
        enableEditing={enableEditing}
        setEnableEditing={setEnableEditing}
        saveStatus={saveStatus}
        setSaveStatus={setSaveStatus}
        autosaveUndoRedoStep={autosaveUndoRedoStep}
        history={quotation.history}
        divFloatingTotals={null}
        userIsAllowedToEdit={true}
        middleSlot={(
          <span className='tw-mr-10 tw-font-bold tw-text-lg'>
            {snapshotName}
          </span>
        )}
      >
        <div className='tw-flex tw-gap-8 tw-items-center'>
          {enableEditing
            && (
              <>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <NavDropdown title='Snapshots' disabled={quotation.snapshotList.length <= 0}>
                      {quotation.snapshotList.map(
                        (snapshot) => (
                          <NavDropdown.Item key={snapshot.id} as={NavLink} target='_blank'
                            to={`/quotations/snapshot/${snapshot.historyDocumentId}`}>
                            {snapshot.snapshotName}
                          </NavDropdown.Item>
                        )
                      )}
                    </NavDropdown>
                  </li>
                </ul>
                <ButtonTW onClick={() => {
                  setShowSnapshotCreationModal(true);
                }}>Create snapshot</ButtonTW>
              </>
            )}


          {quotation.generalInfo.agencyItineraryRefWithVersion}
          {isAudley && (
            <>
              <CheckboxSwitch id='chkShowCsvSource' label='Show CSV' checked={showCsvSource} onChange={(e) => {
                const newState = e.target.checked;
                log_info({ db, userDetails, logkey: 'tripquotations.show_csv_source', desc: `Show CSV source ${newState ? 'enabled' : 'disabled'}` });
                setShowCsvSource(newState);
              }} />
              <ButtonTW onClick={() => {
                if (quotationSnapshotId) {
                  log_info({ db, userDetails, logkey: 'tripquotations.export_to_excel_snapshot', desc: `Export quotation snapshot to Excel [${quotation.generalInfo?.agencyItineraryRefWithVersion}] [${quotationSnapshotId}]`, errorData: { quotation } });
                } else {
                  log_info({ db, userDetails, logkey: 'tripquotations.export_to_excel', desc: `Export quotation to Excel [${quotation.generalInfo?.agencyItineraryRefWithVersion}] [${quotationId}]`, errorData: { quotation } });
                }
                exportToExcel(quotation);
                if (!quotationSnapshotId)
                  createQuotationSnapshot();
              }}>Export to Excel File</ButtonTW>
            </>
          )}
        </div>
      </TopWhiteBarEditControls>
      <ModalTW
        title='Create a snapshot'
        okLabel='Create'
        okDisabled={!snapshotNameInput || snapshotNameAlreadyExists}
        show={showSnapshotCreationModal}
        callbackClose={() => {
          setShowSnapshotCreationModal(false);
          setSnapshotNameInput('');
        }}
        onSubmit={async (e) => {
          e.preventDefault();
          await createQuotationSnapshot();
          setShowSnapshotCreationModal(false);
          setSnapshotNameInput('');
        }}
        body={(
          <div>
            <div>
              <p className='tw-m-0'>A snapshot is a version of the quotation you can save and refer to later.</p>
              <p className='tw-m-0'>Snapshots cannot be modified.</p>
              <p className='tw-m-0'>A snapshot is automatically created every time you make an export of the quotation.</p>
            </div>

            {snapshotVersionAlreadyExists && (
              <div className='tw-flex tw-gap-4 tw-items-center
            tw-border tw-border-solid tw-border-red-900/30 tw-bg-red-100/50 tw-p-4 tw-rounded tw-my-4 tw-text-red-950'>
                <TiWarningOutline className='tw-text-3xl' />
                <div>
                  A snapshot already exists for this version of the quotation.
                </div>
              </div>
            )}

            <div className='tw-mt-5 tw-mb-2'>
              Please enter a name for the snapshot
            </div>
            <div>
              <input type='text' placeholder='eg. Version with premium services'
                value={snapshotNameInput}
                onChange={(e) => setSnapshotNameInput(e.target.value)}
                className='tw-w-full'
              />
            </div>
            {snapshotNameAlreadyExists && (
              <span className='tw-text-red-600'>
                This name is already taken
              </span>
            )}
          </div>
        )}
      />
      <ModalTW
        title='Search hotels'
        okLabel='Apply selected price'
        okDisabled={!enableEditing}
        show={showHotelSearchModal}
        callbackClose={() => resetHotelSearch()}
        onSubmit={(e) => {
          e.preventDefault();
          submitRoomChoice();
        }}
        body={
          [0].map(() => {

            const listNumOfPaxPerRoom: number[] = [];
            if (hotelRowToMatch) {
              for (const room of hotelRowToMatch.hotelRoomList) {
                for (const night of room.hotelNightList) {
                  if (night.numOfPax && !listNumOfPaxPerRoom.includes(night.numOfPax)) {
                    listNumOfPaxPerRoom.push(night.numOfPax);
                  }
                }
              }
            }
            listNumOfPaxPerRoom.sort();

            return (
              <div key='0' className='tw-w-[50vw]'>
                {hotelRowToMatch && (
                  <div>
                    <div>
                      <div className='tw-p-1'>
                        <span className='tw-font-bold tw-mr-1'>
                          Source hotel:
                        </span>
                        {hotelRowToMatch.sourceHotelName}
                      </div>
                      <div className='tw-p-1'>
                        <span className='tw-font-bold tw-mr-1'>
                          Source room:
                        </span>
                        {hotelRowToMatch.sourceRoomType}
                      </div>
                      <div className='tw-p-1'>
                        <span className='tw-font-bold tw-mr-1'>
                          Pax per room:
                        </span>
                        {listNumOfPaxPerRoom.join(' or ')}
                      </div>
                      <div className='tw-p-1'>
                        <span className='tw-font-bold tw-mr-1'>
                          Period:
                        </span>
                        {dateisoFormatJpShort(hotelRowToMatch.checkinDateiso)} - {dateisoFormatJpShort(hotelRowToMatch.checkoutDateiso)}&nbsp;
                        ({currentSeasons?.join(' and ')} season)
                      </div>
                    </div>
                    {!selectedHotel ? (
                      <HotelSearch
                        hotelSearchQuery={hotelSearchQuery}
                        setHotelSearchQuery={setHotelSearchQuery}
                        hotels={filteredHotelPrices}
                        setSelectedHotel={setSelectedHotel}
                      />
                    ) : (
                      <div className='tw-w-[50vw]'>
                        <div className='tw-my-2 tw-font-bold tw-text'>
                          <span className='tw-mr-1'>
                            {selectedHotel.facilityName}
                          </span>
                          <DeleteButton onClick={() => {
                            setSelectedHotel(undefined);
                            setHotelSearchQuery('');
                            setFilteredHotelPrices(hotelPrices);
                          }} />
                        </div>
                        <div className='tw-h-[400px] tw-overflow-scroll'>
                          <RoomSelector
                            selectedRoom={selectedRoom}
                            selectedOccupancy={selectedOccupancy}
                            roomList={selectedHotel.roomList}
                            setSelectedOccupancy={setSelectedOccupancy}
                            setSelectedRoom={setSelectedRoom}
                            seasons={getSeasons(hotelRowToMatch.checkinDateiso, hotelRowToMatch.checkoutDateiso)}
                            listNumOfPaxPerRoom={listNumOfPaxPerRoom}
                          />
                        </div>
                        <div className='tw-mt-4'>
                          <label title='Next time you import a quotation request, these values will be automatically selected if the source is the same'>
                            <input type='checkbox' className='tw-mr-1' checked={shouldSaveMapping} onChange={(e) => setShouldSaveMapping(e.target.checked)} />
                            Use this selection for auto-matching
                          </label>
                        </div>
                        <div className={shouldSaveMapping ? '' : 'tw-opacity-0'}>
                          <table className='
                          [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400
                          [&>*>tr>*]:tw-py-1 [&>*>tr>*]:tw-px-2 tw-my-2'>
                            <thead>
                              <tr>
                                <th>Audley quotation</th>
                                <th>Hotel database</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{hotelRowToMatch.sourceHotelName}</td>
                                <td>{selectedHotel.facilityName}</td>
                              </tr>
                              <tr>
                                <td>{hotelRowToMatch.sourceRoomType}</td>
                                <td>{selectedRoom?.roomName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })
        } />
      <ModalTW
        title='Search services'
        okLabel='Apply selected price'
        okDisabled={!enableEditing}
        show={showServiceSearchModal}
        callbackClose={() => resetServiceSearch()}
        onSubmit={(e) => {
          e.preventDefault();
          submitServiceChoice();
        }}
        body={(
          <ServiceSearch
            services={filteredServicePrices}
            serviceRowToMatch={serviceRowToMatch}
            serviceSearchQuery={serviceSearchQuery}
            setServiceSearchQuery={setServiceSearchQuery}
            selectedService={selectedService}
            setSelectedService={setSelectedService}
            selectedServiceItem={selectedServiceItem}
            setSelectedServiceItem={setSelectedServiceItem}
            shouldSaveMapping={shouldSaveMapping}
            setShouldSaveMapping={setShouldSaveMapping}
            automatchOnDetails={automatchOnDetails}
            setAutomatchOnDetails={setAutomatchOnDetails}
          />
        )} />
      <div className='tw-p-5'>
        <div>
          <table className='
              [&>*>tr>*]:tw-border-b
              [&>*>tr>*]:tw-border-solid
              [&>*>tr>*]:tw-border-slate-200
              [&>*>tr>*]:tw-py-2
              [&>*>tr>*]:tw-px-2
              [&>*>tr>*]:tw-bg-white
              [&>*>tr>td]:tw-align-top
              [&>*>tr>th]:tw-align-bottom
              tw-mb-5'>
            <tbody>
              <tr>
                <td className='tw-font-bold'>Eighty Days PIC</td>
                <td>
                  <TypeaheadUserList
                    id='inputPersonInCharge'
                    multiple={true}
                    onChange={(array: UserSimpleTeamType[]) => {
                      const usersDesigners: UserSimpleUidType[] = [];
                      const usersDesignersUids: string[] = [];

                      for (const user of array) {
                        if (usersDesigners.some((u) => u.uid === user.id))
                          // don't allow duplicates
                          continue;
                        const newUser: UserSimpleUidType = {
                          uid: user.id,
                          email: user.email,
                          name: user.name,
                        };
                        usersDesigners.push(newUser);
                        usersDesignersUids.push(user.id);
                      }

                      const updateObj: TripQuotationUpdateObjType = {
                        usersDesigners,
                        usersDesignersUids,
                      };
                      autosaveNewStep(`Set travel designers to ‘${array.map((u) => u.name).join(', ')}’`, updateObj, 'u');
                    }}
                    userList={userListSimple}
                    selected={quotation.usersDesigners.map((user) => {
                      const dbUser = userListSimple.find((u) => u.id === user.uid);
                      const newUser: UserSimpleTeamType = {
                        id: user.uid,
                        email: user.email,
                        name: user.name,
                        teamName: dbUser?.teamName || 'Other',
                      };
                      return newUser;
                    })}
                    guidesFirst={false}
                    disabled={!enableEditing}
                  />
                </td>
              </tr>
              <tr>
                <td className='tw-font-bold'>Eighty Days Request Code</td>
                <td>
                  <div className='tw-flex tw-gap-2 tw-justify-between'>
                    {quotation.requestCode ? (
                      <RequestCodeLinkToAggregator
                        requestCode={quotation.requestCode}
                        linkId={'request_link'}
                        shownPopup={shownPopup}
                        setShownPopup={setShownPopup}
                      />
                    ) : (
                      requestsWithAudleyRef.length > 0 ? (
                        requestsWithAudleyRef.map(request => (
                          <div key={request.id}>
                            <ButtonTW onClick={() => {
                              const updateObj: TripQuotationUpdateObjType = {
                                requestId: request.id,
                                requestCode: request.requestCode,
                              };
                              log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.assign_request', desc: `Assign request ${request.requestCode} to quotation ${quotation.id}` });
                              autosaveNewStep(`Assign tour request ‘${request.requestCode}’`, updateObj, 'u');
                            }}>Assign {request.requestCode}</ButtonTW>
                          </div>
                        ))
                      ) : (
                        <ButtonTW
                          disabled={!enableEditing}
                          onClick={() => {

                            const newTourRequest = getBlankTourRequest(userSimple, 'Tour Request created from Audley quotation');
                            // @ts-expect-error delete id
                            delete newTourRequest.id;
                            newTourRequest.dateOriginallyReceived = getTodayIso();
                            newTourRequest.usersDesigners = [...quotation.usersDesigners];
                            newTourRequest.usersDesignersUids = [...quotation.usersDesignersUids];
                            newTourRequest.eightyDaysDepartment = 'Audley';
                            newTourRequest.customerType = 'FIT (Other)';
                            newTourRequest.agencyOrPlatform = 'Audley Travel';
                            newTourRequest.agencyOrPlatformId = AUDLEY_ID;
                            newTourRequest.numOfPax = quotation.passengerList.length;

                            // construct request code
                            const twoCharDate = getTwoCharacterDate(newTourRequest.dateOriginallyReceived);
                            if (!twoCharDate) {
                              alert('Invalid date');
                              return;
                            }

                            const audleyRef = quotation.generalInfo.agencyItineraryRef;
                            if (audleyRef.length !== 7) {
                              alert(`Invalid Audley number [${audleyRef}]`);
                              return;
                            }

                            let letterUser = userDetails.singleCharacterCode;
                            if (!letterUser)
                              letterUser = '9';

                            // const tentativeCode = `A${twoCharDate}${audleyNum}`
                            // const checkChar = generateCheckCharacter(tentativeCode);
                            // const finalCode = `A${twoCharDate}${checkChar}-${audleyNum.slice(0, -4)}-${audleyNum.slice(-4)}`
                            const finalCode = `A${twoCharDate}${letterUser}-${audleyRef.slice(0, -4)}-${audleyRef.slice(-4)}`;

                            console.log('finalCode', finalCode);

                            newTourRequest.requestCode = finalCode;
                            newTourRequest.audleyRefWithVersion = quotation.generalInfo.agencyItineraryRefWithVersion;
                            newTourRequest.audleyRef = audleyRef;

                            checkDupesAndSaveNewTourRequest(db, userDetails, newTourRequest)
                              .then(({ error, addedDocId }) => {
                                if (error) {
                                  window.alert(error);
                                } else {
                                  const updateObj: TripQuotationUpdateObjType = {
                                    requestCode: finalCode,
                                    requestId: addedDocId,
                                  };
                                  autosaveNewStep(`Assign tour request ‘${finalCode}’`, updateObj, 'UNDOWALL');
                                }
                              })
                              .catch((err) => setDbError('Adding new tour request', err));

                          }}>Create Request</ButtonTW>
                      )
                    )}

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {showCsvSource && (
          <div className='tw-flex tw-gap-4'>
            <table className='tw-text-xs tw-bg-stone-200 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-stone-400
                [&>*>tr>*]:tw-p-1 tw-m-1'>
              <tbody>
                {Object.entries(quotation.generalInfo.generalCsvData).map(([key, row]) => {
                  const rowNum = Number(key.match(/^row_(\d+)$/)?.[1]);
                  return [rowNum, row] as [number, string[]];
                })
                  .sort((a, b) => a[0] - b[0])
                  .filter(([rowNum, row]) => row[0] !== 'sep=' && !(row[0].trim() === '' && row.length === 1))
                  .map(([rowNum, row]) => (
                    <tr key={rowNum}>
                      {row.map((cell, i) => <td key={i}>{cell}</td>)}
                    </tr>
                  ))}
              </tbody>
            </table>

            <table className='tw-text-xs tw-bg-stone-200 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-stone-400
                [&>*>tr>*]:tw-p-1 tw-m-1'>
              <tbody>
                {quotation.passengerCsvData.map((row, index) => (
                  <tr key={index}>
                    {row.cells.map((cell, i) => <td key={i}>{cell}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className='tw-flex tw-flex-row tw-gap-5'>
          <div className='tw-w-1/4'>
            <h5 className='tw-font-bold tw-text-base tw-pl-2'>Trip Reference</h5>
            <table className='
                [&>*>tr>*]:tw-border-b
                [&>*>tr>*]:tw-border-solid
                [&>*>tr>*]:tw-border-slate-200
                [&>*>tr>*]:tw-py-2
                [&>*>tr>*]:tw-px-2
                [&>*>tr>*]:tw-bg-white
                [&>*>tr>td]:tw-align-top
                [&>*>tr>th]:tw-align-bottom'>
              <tbody>
                {isAudley && (
                  <>
                    <tr>
                      <td className='tw-font-bold'>{agentLabel} Reference</td>
                      <td>
                        <EditableFieldWithBorder
                          fieldname={'generalInfo.agencyItineraryRefWithVersion'}
                          validationType={''}
                          currentValue={quotation.generalInfo.agencyItineraryRefWithVersion || ''}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: TripQuotationUpdateObjType = {
                              'generalInfo.agencyItineraryRefWithVersion': dbvalue,
                            };
                            autosaveNewStep(`Change agency itinerary ref with version to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, '', 'generalInfo.agencyOwner');
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='tw-font-bold'>{agentLabel} Owner</td>
                      <td>
                        <EditableFieldWithBorder
                          fieldname={'generalInfo.agencyOwner'}
                          validationType={''}
                          currentValue={quotation.generalInfo.agencyOwner || ''}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: TripQuotationUpdateObjType = {
                              'generalInfo.agencyOwner': dbvalue,
                            };
                            autosaveNewStep(`Change agency owner to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, 'generalInfo.agencyItineraryRefWithVersion', 'generalInfo.businessUnit');
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className='tw-font-bold'>{agentLabel} Business Unit</td>
                      <td>
                        <EditableFieldWithBorder
                          fieldname={'generalInfo.businessUnit'}
                          validationType={''}
                          currentValue={quotation.generalInfo.agencyBusinessUnit || ''}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: TripQuotationUpdateObjType = {
                              'generalInfo.agencyBusinessUnit': dbvalue,
                            };
                            autosaveNewStep(`Change agency business unit to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, 'generalInfo.agencyOwner', 'generalInfo.paxName');
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className='!tw-bg-transparent'>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td className='tw-font-bold'>Lead Pax</td>
                  <td>
                    <EditableFieldWithBorder
                      fieldname={'generalInfo.paxName'}
                      validationType={''}
                      currentValue={quotation.generalInfo.paxName}
                      isClickableToEdit={enableEditing}
                      editedCell={editedCell}
                      setEditedCell={setEditedCell}
                      callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.paxName': dbvalue,
                        };
                        autosaveNewStep(`Change pax name to ‘${dbvalue}’`, updateObj, 'u');
                        tabNavigation(tabKey, 'generalInfo.businessUnit', 'generalInfo.numOfPax');
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='tw-w-1/4'>
            <h5 className='tw-font-bold tw-text-base tw-pl-2'>Trip dates</h5>
            <table className='
                [&>*>tr>*]:tw-border-b
                [&>*>tr>*]:tw-border-solid
                [&>*>tr>*]:tw-border-slate-200
                [&>*>tr>*]:tw-py-2
                [&>*>tr>*]:tw-px-2
                [&>*>tr>*]:tw-bg-white
                [&>*>tr>td]:tw-align-top
                [&>*>tr>th]:tw-align-bottom'>
              <tbody>
                <tr>
                  <td className='tw-font-bold'>Trip start date</td>
                  <td>
                    <EditableFieldDatepicker
                      showBorder={true}
                      currentValue_jst0={quotation.generalInfo.tripStartDateiso ? jst0_from_iso(quotation.generalInfo.tripStartDateiso) : null}
                      isClickableToEdit={enableEditing}
                      callbackCommitChange={(date_jst0) => {
                        const tripStartDateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.tripStartDateiso': tripStartDateiso,
                        };
                        if (tripStartDateiso && quotation.generalInfo.tripEndDateiso) {
                          const days = 1 + getSpanDaysExactIso(tripStartDateiso, quotation.generalInfo.tripEndDateiso);
                          updateObj['generalInfo.tripDurationDays'] = days;
                        }

                        autosaveNewStep(`Change start date to ‘${date_jst0 ? dateFormatJpShort(date_jst0) : ''}’`, updateObj, 'u');
                        setEditedCell(null);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'>Trip end date</td>
                  <td>
                    <EditableFieldDatepicker
                      showBorder={true}
                      currentValue_jst0={quotation.generalInfo.tripEndDateiso ? jst0_from_iso(quotation.generalInfo.tripEndDateiso) : null}
                      isClickableToEdit={enableEditing}
                      callbackCommitChange={(date_jst0) => {
                        const tripEndDateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                        const updateObj: TripQuotationUpdateObjType = {
                          'generalInfo.tripEndDateiso': tripEndDateiso,
                        };
                        if (quotation.generalInfo.tripStartDateiso && tripEndDateiso) {
                          const days = 1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, tripEndDateiso);
                          updateObj['generalInfo.tripDurationDays'] = days;
                        }

                        autosaveNewStep(`Change end date to ‘${date_jst0 ? dateFormatJpShort(date_jst0) : ''}’`, updateObj, 'u');
                        setEditedCell(null);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className='tw-font-bold'># of days</td>
                  <td>
                    <span className={(quotation.generalInfo.tripDurationDays || 0) <= 0 ? 'tw-text-red-500' : ''}>
                      {quotation.generalInfo.tripDurationDays}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='tw-w-1/4'>
            {quotation.passengerList && (
              <>
                <h5 className='tw-font-bold tw-text-base tw-pl-2'>Pax list</h5>
                <table className='
                    [&>*>tr>*]:tw-border-b
                    [&>*>tr>*]:tw-border-solid
                    [&>*>tr>*]:tw-border-slate-200
                    [&>*>tr>*]:tw-py-2
                    [&>*>tr>*]:tw-px-2
                    [&>*>tr>*]:tw-bg-white
                    [&>*>tr>td]:tw-align-top
                    [&>*>tr>th]:tw-align-bottom'>
                  <thead>
                    <tr>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Date of birth</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotation.passengerList.map((passenger) => (
                      <tr key={passenger.id}>
                        <td>{passenger.firstName}</td>
                        <td>{passenger.lastName}</td>
                        <td>{dateisoFormatJpShort(passenger.dateOfBirth)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table className='
                    [&>*>tr>*]:tw-border-b
                    [&>*>tr>*]:tw-border-solid
                    [&>*>tr>*]:tw-border-slate-200
                    [&>*>tr>*]:tw-py-2
                    [&>*>tr>*]:tw-px-2
                    [&>*>tr>*]:tw-bg-white
                    [&>*>tr>td]:tw-align-top
                    [&>*>tr>th]:tw-align-bottom
                    tw-w-72 tw-mt-4'>
                  <tbody>
                    <tr>
                      <td className='tw-font-bold'># of Pax</td>
                      <td>
                        <EditableFieldWithBorder
                          fieldname={'generalInfo.numOfPax'}
                          validationType={''}
                          currentValue={quotation.generalInfo.numOfPax || 0}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: TripQuotationUpdateObjType = {
                              'generalInfo.numOfPax': dbvalue,
                            };
                            autosaveNewStep(`Change number of pax to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, 'generalInfo.paxName', '');
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        <QuotationPricingBlock
          quotation={quotation}
          enableEditing={enableEditing}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          autosaveNewStep={autosaveNewStep}
          tabNavigation={tabNavigation}
          servicesTotal={servicesTotal}
          hotelsTotal={hotelsTotal}
          isAudley={isAudley}
        />
        <div>
          {nopriceItems > 0 && (
            <div className='tw-flex tw-gap-4 tw-items-center
            tw-border tw-border-solid tw-border-blue-900/30 tw-bg-blue-100/50 tw-p-4 tw-rounded tw-my-4 tw-text-blue-950'>
              <TiWarningOutline className='tw-text-3xl' />
              <div>
                There are {nopriceHotels ? `${nopriceHotels} hotel(s)` : ''}
                {nopriceHotels && nopriceServices ? ' and ' : ''}
                {nopriceServices ? `${nopriceServices} service(s) ` : ''}
                without a price. Before sending to client, fill in the missing prices.
              </div>
            </div>
          )}
        </div>
        {userrole_isDev(userDetails.roles) && (
          <div>
            <CheckboxSwitch id='chkShowRowIds' label='DEV: Show row ids' checked={showRowIds} onChange={(e) => {
              setShowRowIds(e.target.checked);
            }} />
          </div>
        )}
        {/*
ooooo   ooooo   .oooooo.   ooooooooooooo oooooooooooo ooooo             ooooo        ooooo  .oooooo..o ooooooooooooo 
`888'   `888'  d8P'  `Y8b  8'   888   `8 `888'     `8 `888'             `888'        `888' d8P'    `Y8 8'   888   `8 
 888     888  888      888      888       888          888               888          888  Y88bo.           888      
 888ooooo888  888      888      888       888oooo8     888               888          888   `"Y8888o.       888      
 888     888  888      888      888       888    "     888               888          888       `"Y88b      888      
 888     888  `88b    d88'      888       888       o  888       o       888       o  888  oo     .d8P      888      
o888o   o888o  `Y8bood8P'      o888o     o888ooooood8 o888ooooood8      o888ooooood8 o888o 8""88888P'      o888o     
        */}
        <div className='tw-mt-5'>
          <table className='
              tw-my-2
              [&>*>tr>*]:tw-border-solid
              [&>*>tr>*]:tw-border-slate-200
              [&>*>tr>*]:tw-py-2
              [&>*>tr>*]:tw-px-2
              [&>*>tr>*]:tw-bg-white
              [&>*>tr>td]:tw-align-top
              [&>*>tr>th]:tw-align-bottom
              tw-h-1' // tw-h-1 needed for div height 100% below. see https://stackoverflow.com/a/59030659/
          >
            <thead>
              <tr className='[&>th]:tw-border-b-2'>
                <th></th>
                <th>Check-in date</th>
                <th>Check-out date</th>
                <th title='Calculated from check-in and check-out dates'>Nights</th>
                <th>{/* expand chevron */}</th>
                <th>City</th>
                <th>Hotel name</th>
                <th>Room type</th>
                <th>Meals</th>
                <th># of Rooms</th>
                <th># of Pax</th>
                <th>Price per pax<br />per night</th>
                <th>BAR rate</th>
                <th>Total</th>
                <th>Actions</th>
                <th className='!tw-bg-transparent'></th>
                <th>Booking Status</th>
                <th>Booking Remarks</th>
                <th className='!tw-bg-transparent'></th>
                <th>80days internal memo</th>
              </tr>
            </thead>
            <tbody>
              {quotation.hotelList.map((row, index) => {
                const showExtended = extendedInfoShownIds.includes(row.id);

                const hideBreakdown = () => {
                  setExtendedInfoShownIds(extendedInfoShownIds.filter((x) => x !== row.id));
                };
                const showBreakdown = () => {
                  setExtendedInfoShownIds([...extendedInfoShownIds, row.id]);
                };

                let dateSpan: number;
                const sameDateRows: HotelTripQuotationRowType[] = [];
                if (index > 0 && row.checkinDateiso === quotation.hotelList[index - 1].checkinDateiso && row.checkoutDateiso === quotation.hotelList[index - 1].checkoutDateiso) {
                  dateSpan = 0;
                  sameDateRows.push(row);
                } else {
                  dateSpan = 0;
                  for (let i = index; i < quotation.hotelList.length; i++) {
                    if (row.checkinDateiso === quotation.hotelList[i].checkinDateiso && row.checkoutDateiso === quotation.hotelList[i].checkoutDateiso) {
                      dateSpan
                        += 1
                        + (extendedInfoShownIds.includes(quotation.hotelList[i].id) ? 1 : 0);
                      // + (showCsvSource ? 1 : 0);
                      sameDateRows.push(quotation.hotelList[i]);
                    } else {
                      break;
                    }
                  }
                  if (showCsvSource) dateSpan++;
                }

                const discontinuousDates = index > 0 && row.checkinDateiso !== quotation.hotelList[index - 1].checkoutDateiso;

                const updateRoomListPricePerPax = ({ pricePerPaxPerNight }: { pricePerPaxPerNight: number | null }) => {
                  const newHotelRooms = row.hotelRoomList.map(room => {
                    return {
                      ...room,
                      hotelNightList: room.hotelNightList.map(night => {
                        return {
                          ...night,
                          pricePerPax: pricePerPaxPerNight,
                          totalPrice: (night.numOfPax && pricePerPaxPerNight) ? night.numOfPax * pricePerPaxPerNight : null,
                        };
                      }),
                    };
                  });
                  return newHotelRooms;
                };

                const updateRoomListNumOfPax = (numOfPaxAllRooms: number | null) => {
                  const { numOfPaxPerRoom_min, excessPax } = getPaxPerRoom(numOfPaxAllRooms, row.numOfRooms);
                  const newHotelRooms = row.hotelRoomList.map((room, iRoom) => {
                    const numOfPaxPerRoom = (numOfPaxPerRoom_min && excessPax !== null) ? numOfPaxPerRoom_min + (iRoom < excessPax ? 1 : 0) : null;
                    return {
                      ...room,
                      hotelNightList: room.hotelNightList.map(night => {
                        return {
                          ...night,
                          numOfPax: numOfPaxPerRoom,
                          totalPrice: (numOfPaxPerRoom && night.pricePerPax) ? numOfPaxPerRoom * night.pricePerPax : null,
                        };
                      }),
                    };
                  });
                  return newHotelRooms;
                };

                const borderTop = (showCsvSource && dateSpan > 0) ? '' : 'tw-border-t-2';

                const blueBubbleCell = (
                  <div className='tw-flex tw-flex-col tw-h-full tw-justify-evenly tw-gap-0.5 tw-relative _tw-top-[-0.75em]'>
                    {[...Array(row.numOfNights || 0).keys()].map((i) => {
                      const dateiso = addDaysIso(row.checkinDateiso, i);
                      const dayOfWeek = dateisoFormatDayOfWeek(dateiso);
                      const isOverlapping = overlappingDates.has(dateiso);
                      const highlight = isOverlapping || (i === 0 && discontinuousDates); // could be discontinuous without overlapping, e.g. if there is a gap in the dates.
                      return (
                        <div key={i} className={
                          `tw-border tw-px-2 tw-border-solid tw-rounded
                                 ${highlight ? 'tw-border-red-300 tw-bg-red-50' : 'tw-border-blue-300 tw-bg-blue-50'}
                                   tw-grow tw-flex tw-flex-col tw-justify-center tw-text-sm`
                        }>
                          <div className='tw-whitespace-nowrap tw-text-center'>
                            Day {1 + getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, dateiso)}
                          </div>
                          <div className='tw-whitespace-nowrap tw-text-center'>
                            <span className={`${dayOfWeek === 'Sat' || dayOfWeek === 'Sun' ? 'tw-text-red-500' : ''}`}>
                              {dayOfWeek} {dateisoFormatDayMonth(dateiso)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );

                const tw_bgcolor = row.highlighterColor ? highlighterColorMap.get(row.highlighterColor)?.rowBackground : '';

                return (
                  <React.Fragment key={row.id}>
                    {showCsvSource && dateSpan > 0 && (
                      <tr>
                        <td rowSpan={dateSpan} className='tw-border-t-2 !tw-p-0'>
                          {blueBubbleCell}
                        </td>

                        <td colSpan={14} className='tw-border-t-2 !tw-p-0'>
                          <CsvSourceTable
                            sourceCsvHeaders={quotation.sourceCsvHeaders?.slice(0, 10)}
                            csvdatarows={sameDateRows.map(row => row.csvdata).filter(row => row !== null)}
                          />
                        </td>
                        <td className='!tw-bg-transparent'></td>
                        <td colSpan={2} className='tw-border-t-2'></td>
                        <td className='!tw-bg-transparent'></td>
                        <td className='tw-border-t-2'></td>
                      </tr>
                    )}
                    <tr>
                      {dateSpan > 0 && !showCsvSource && (
                        <td rowSpan={dateSpan} className='tw-border-t-2 !tw-p-0'>
                          {blueBubbleCell}
                        </td>
                      )}
                      <td className={`tw-min-w-[7.25em] ${dateSpan > 0 ? borderTop : ''} ${tw_bgcolor}`}
                        onMouseEnter={() => setHoveredRowId(row.id)}
                        onMouseLeave={() => setHoveredRowId(null)}>{/* COLUMN: CHECK-IN */}
                        {(dateSpan > 0 || hoveredRowId === row.id) && (
                          <EditableFieldDatepicker
                            showBorder={true}
                            currentValue_jst0={row.checkinDateiso ? jst0_from_iso(row.checkinDateiso) : null}
                            isClickableToEdit={enableEditing}
                            callbackCommitChange={(date_jst0) => {
                              const checkinDateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                              let numOfNights = checkinDateiso && row.checkoutDateiso ? getSpanDaysExactIso(checkinDateiso, row.checkoutDateiso) : null;
                              let checkoutDateiso = row.checkoutDateiso;
                              if (typeof numOfNights === 'number' && numOfNights <= 0) {
                                // checkin date was set after checkout date: move checkout date back
                                checkoutDateiso = addDaysIso(checkinDateiso, 1);
                                numOfNights = 1;
                              }
                              const updateObj: Record<string, any> = {};
                              for (const subrow of sameDateRows) {
                                const hotelRoomsList = getHotelRoomListWithPrice(subrow.numOfRooms, subrow.numOfPax, checkinDateiso, checkoutDateiso, subrow.pricePerPaxPerNight, null);
                                const hotelRoomsObj = getHotelRoomsObject(hotelRoomsList);
                                updateObj[hotelsfield('hotels', subrow.id, 'checkinDateiso')] = checkinDateiso;
                                updateObj[hotelsfield('hotels', subrow.id, 'checkoutDateiso')] = checkoutDateiso;
                                updateObj[hotelsfield('hotels', subrow.id, 'numOfNights')] = numOfNights;
                                updateObj[hotelsfield('hotels', subrow.id, 'hotelRooms')] = hotelRoomsObj;
                                if (numOfNights !== null && subrow.numOfPax !== null && subrow.pricePerPaxPerNight !== null) {
                                  updateObj[hotelsfield('hotels', subrow.id, 'totalPrice')] = numOfNights * subrow.numOfPax * subrow.pricePerPaxPerNight;
                                }
                              }
                              autosaveNewStep(`Change accommodation check-in to ‘${checkinDateiso}’`, updateObj, 'u');
                              setEditedCell(null);
                            }}
                          />
                        )}
                        {row.sourceCheckinDateiso && row.checkinDateiso !== row.sourceCheckinDateiso && dateSpan > 0 && (
                          <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different date: ${dateisoFormatJp(row.sourceCheckinDateiso)}`}>
                            <TiWarningOutline />
                          </span>
                        )}
                      </td>
                      <td className={`tw-min-w-[7.25em] ${dateSpan > 0 ? borderTop : ''} ${tw_bgcolor}`}
                        onMouseEnter={() => setHoveredRowId(row.id)}
                        onMouseLeave={() => setHoveredRowId(null)}>{/* COLUMN: CHECK-OUT */}
                        {(dateSpan > 0 || hoveredRowId === row.id) && (
                          <EditableFieldDatepicker
                            showBorder={true}
                            currentValue_jst0={row.checkoutDateiso ? jst0_from_iso(row.checkoutDateiso) : null}
                            isClickableToEdit={enableEditing}
                            callbackCommitChange={(date_jst0) => {
                              const checkoutDateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                              let numOfNights = row.checkinDateiso && checkoutDateiso ? getSpanDaysExactIso(row.checkinDateiso, checkoutDateiso) : null;
                              let checkinDateiso = row.checkinDateiso;
                              if (typeof numOfNights === 'number' && numOfNights <= 0) {
                                // checkout date was set before checkin date: move checkin date earlier
                                checkinDateiso = addDaysIso(checkoutDateiso, -1);
                                numOfNights = 1;
                              }
                              const updateObj: Record<string, any> = {};
                              for (const subrow of sameDateRows) {
                                const hotelRoomsList = getHotelRoomListWithPrice(subrow.numOfRooms, subrow.numOfPax, checkinDateiso, checkoutDateiso, subrow.pricePerPaxPerNight, null);
                                const hotelRoomsObj = getHotelRoomsObject(hotelRoomsList);
                                updateObj[hotelsfield('hotels', subrow.id, 'checkinDateiso')] = checkinDateiso;
                                updateObj[hotelsfield('hotels', subrow.id, 'checkoutDateiso')] = checkoutDateiso;
                                updateObj[hotelsfield('hotels', subrow.id, 'numOfNights')] = numOfNights;
                                updateObj[hotelsfield('hotels', subrow.id, 'hotelRooms')] = hotelRoomsObj;
                                if (numOfNights !== null && subrow.numOfPax !== null && subrow.pricePerPaxPerNight !== null) {
                                  updateObj[hotelsfield('hotels', subrow.id, 'totalPrice')] = numOfNights * subrow.numOfPax * subrow.pricePerPaxPerNight;
                                }
                              }
                              autosaveNewStep(`Change accommodation check-out to ‘${checkoutDateiso}’`, updateObj, 'u');
                              setEditedCell(null);
                            }}
                          />
                        )}
                        {row.sourceCheckoutDateiso && row.checkoutDateiso !== row.sourceCheckoutDateiso && dateSpan > 0 && (
                          <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different date: ${dateisoFormatJp(row.sourceCheckoutDateiso)}`}>
                            <TiWarningOutline />
                          </span>
                        )}
                      </td>
                      <td className={`${dateSpan > 0 ? borderTop : ''} ${tw_bgcolor}`}
                        onMouseEnter={() => setHoveredRowId(row.id)}
                        onMouseLeave={() => setHoveredRowId(null)}>{/* COLUMN: NUM OF NIGHTS */}
                        {(dateSpan > 0 || hoveredRowId === row.id) && (
                          <div>
                            {row.numOfNights && (
                              <div className='tw-whitespace-nowrap'>
                                {[...Array(row.numOfNights).keys()].map((i) => (
                                  <HiMiniMoon key={i} className='tw-text-lg' />
                                ))}
                              </div>
                            )}
                            <span className='tw-whitespace-nowrap'>
                              {row.numOfNights} {row.numOfNights === null ? '' : row.numOfNights === 1 ? 'night' : 'nights'}
                            </span>
                          </div>
                        )}
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: CHEVRON */}
                        {row.hotelRoomList.length > 0 && (
                          <div onClick={() => {
                            if (showExtended) {
                              log_info({ db, userDetails, logkey: 'tripquotations.btn_chevron.click_to_hide', desc: 'Click chevron to hide breakdown' });
                              hideBreakdown();
                            } else {
                              log_info({ db, userDetails, logkey: 'tripquotations.btn_chevron.click_to_show', desc: 'Click chevron to show breakdown' });
                              showBreakdown();
                            }
                          }}>
                            <i className={`bi ${showExtended ? 'bi-chevron-down' : 'bi-chevron-right'} tw-cursor-pointer`}></i>
                          </div>
                        )}
                      </td>
                      <td className={`!tw-p-0 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: CITY */}
                        <SourceDataGrayBox disabled={!enableEditing} onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'hotelPlace')]: row.sourceHotelPlace,
                          };
                          autosaveNewStep(`Change accommodation place to ‘${row.sourceHotelPlace}’`, updateObj, 'u');
                        }}>
                          {row.sourceHotelPlace}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableFieldWithBorder
                            fieldname={`hotelList_${row.id}_hotelPlace`}
                            validationType={''}
                            currentValue={row.hotelPlace}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'hotelPlace')]: dbvalue,
                              };
                              autosaveNewStep(`Change accommodation place to ‘${dbvalue}’`, updateObj, 'u');
                              tabNavigation(tabKey, '', `hotelList_${row.id}_hotelName`);
                            }}
                          />
                        </div>
                        <SupplierNoteAlert rowId={row.id} note={row.supplierNote} />
                        <ItemNoteAlert rowId={row.id} note={row.itemNote} />
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: HOTEL NAME */}
                        <SourceDataGrayBox disabled={!enableEditing} onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'hotelName')]: row.sourceHotelName,
                          };
                          autosaveNewStep(`Change accommodation facility name to ‘${row.sourceHotelName}’`, updateObj, 'u');
                        }}>
                          {row.sourceHotelName}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableFieldWithBorder
                            fieldname={`hotelList_${row.id}_hotelName`}
                            validationType={''}
                            currentValue={row.hotelName}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'hotelName')]: dbvalue,
                              };
                              autosaveNewStep(`Change accommodation facility name to ‘${dbvalue}’`, updateObj, 'u');
                              tabNavigation(tabKey, `hotelList_${row.id}_hotelPlace`, `hotelList_${row.id}_roomType`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: ROOM TYPE */}
                        <SourceDataGrayBox disabled={!enableEditing} onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [hotelsfield('hotels', row.id, 'roomType')]: row.sourceRoomType,
                          };
                          autosaveNewStep(`Change accommodation room type to ‘${row.sourceRoomType}’`, updateObj, 'u');
                        }}>
                          {row.sourceRoomType}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableFieldWithBorder
                            fieldname={`hotelList_${row.id}_roomType`}
                            validationType={''}
                            currentValue={row.roomType}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'roomType')]: dbvalue,
                              };
                              autosaveNewStep(`Change accommodation room type to ‘${dbvalue}’`, updateObj, 'u');
                              tabNavigation(tabKey, `hotelList_${row.id}_hotelName`, `hotelList_${row.id}_numOfRooms`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: MEALS */}
                        <div className='tw-flex tw-gap-1'>
                          <CheckboxButton
                            checked={row.breakfastIncluded}
                            disabled={!enableEditing}
                            onClick={() => {
                              if (!enableEditing)
                                return;
                              const newVal = !row.breakfastIncluded;
                              let updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'breakfastIncluded')]: newVal,
                              };
                              if (newVal === false) { // you can't have dinner without breakfast
                                updateObj = {
                                  ...updateObj,
                                  [hotelsfield('hotels', row.id, 'dinnerIncluded')]: false,
                                };
                              }
                              autosaveNewStep(`Change accommodation breakfast to ‘${newVal}’`, updateObj, 'u');
                              setEditedCell(null);
                            }}
                          >B</CheckboxButton>
                          <CheckboxButton
                            checked={row.dinnerIncluded}
                            disabled={!enableEditing}
                            onClick={() => {
                              if (!enableEditing)
                                return;
                              const newVal = !row.dinnerIncluded;
                              let updateObj: Partial<TripQuotationType> = {
                                [hotelsfield('hotels', row.id, 'dinnerIncluded')]: newVal,
                              };
                              if (newVal === true) { // you can't have dinner without breakfast
                                updateObj = {
                                  ...updateObj,
                                  [hotelsfield('hotels', row.id, 'breakfastIncluded')]: true,
                                };
                              }
                              autosaveNewStep(`Change accommodation dinner to ‘${newVal}’`, updateObj, 'u');
                              setEditedCell(null);
                            }}>D</CheckboxButton>
                        </div>
                        {isAudley && (
                          <div className='tw-text-center tw-whitespace-nowrap'>
                            {getMealPlan(row.breakfastIncluded, row.dinnerIncluded)}
                            {row.sourceMealCode && row.sourceMealCode !== getMealPlan(row.breakfastIncluded, row.dinnerIncluded) && (
                              <span className='tw-text-xl tw-mr-1 tw-text-red-500' title={`Audley csv has a different meal data: “${row.sourceMeals}” ${row.sourceMealCode}`}>
                                <TiWarningOutline />
                              </span>
                            )}
                          </div>
                        )}
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: NUM OF ROOMS */}
                        <EditableField
                          fieldname={`hotelList_${row.id}_numOfRooms`}
                          validationType={'number'}
                          currentValue={row.numOfRooms}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const newNumOfRooms = dbvalue;
                            const updateObj: Record<string, any> = {
                              [`hotels.${row.id}.numOfRooms`]: newNumOfRooms,
                              // [hotelsfield('hotels', row.id, 'hotelRooms')]: null,
                              // [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                            };

                            let newHotelRoomsList: HotelRoomType[] = [];
                            if (typeof newNumOfRooms === 'number' && newNumOfRooms > 0) {
                              newHotelRoomsList = getHotelRoomList(newNumOfRooms, row.numOfPax, row.checkinDateiso, row.checkoutDateiso);
                              newHotelRoomsList = applyHotelRoomPrice(newHotelRoomsList, row.pricePerPaxPerNight, null);
                            }
                            updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRoomsList);

                            autosaveNewStep(`Change numOfRooms to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, `hotelList_${row.id}_roomType`, `hotelList_${row.id}_numOfPax`);
                          }}
                          getDisplayValue={(value) => {
                            let content = <>{value}</>;
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<MdBedroomParent key={i} className='tw-text-[1.5em] tw-text-slate-700' />);
                              }
                              // return <><MdBedroomParent className='tw-text-[1.5em]' /> {`${value} ${value === 1 ? 'room' : 'rooms'}`}</>;
                              content = (
                                <>
                                  <div>{list}</div>
                                  <div>{`${value} ${value === 1 ? 'room' : 'rooms'}`}</div>
                                </>
                              );
                            }
                            return (
                              <div className={`tw-bg-white ${getEditableFieldBorderStyle(enableEditing)} tw-p-1 tw-h-14`}>
                                {content}
                              </div>
                            );
                          }}
                        />
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: NUM OF PAX */}
                        <EditableField
                          fieldname={`hotelList_${row.id}_numOfPax`}
                          validationType={'number'}
                          currentValue={row.numOfPax}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const numOfPaxAllRooms = convertToNumberOrNull(dbvalue) || null; // don't allow zero pax, convert zero to null
                            const newHotelRooms = updateRoomListNumOfPax(numOfPaxAllRooms);
                            const updateObj: Record<string, any> = {
                              [hotelsfield('hotels', row.id, 'numOfPax')]: numOfPaxAllRooms,
                              [hotelsfield('hotels', row.id, 'hotelRooms')]: getHotelRoomsObject(newHotelRooms),
                              // [hotelsfield('hotels', row.id, 'hotelPriceId')]: null,
                            };
                            if (numOfPaxAllRooms !== null) {
                              if (row.numOfNights) {
                                if (row.pricePerPaxPerNight !== null) {
                                  // numOfPax & numOfNights & pricePerPax --> totalPrice
                                  updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = numOfPaxAllRooms * row.pricePerPaxPerNight * row.numOfNights;
                                } else if (row.totalPrice !== null && numOfPaxAllRooms > 0) {
                                  // numOfPax & totalPrice --> pricePerPax
                                  updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = Math.round(row.totalPrice / (numOfPaxAllRooms * row.numOfNights));
                                }
                              }
                            } else {
                              // numOfPax was deleted
                              if (row.pricePerPaxPerNight !== null) {
                                // numOfPax deleted & pricePerPax present --> totalPrice deleted
                                updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                              } else {
                                // no pricePerPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }
                            autosaveNewStep(`Change accommodation number of pax to ‘${numOfPaxAllRooms}’`, updateObj, 'u');
                            tabNavigation(tabKey, `hotelList_${row.id}_numOfRooms`, `hotelList_${row.id}_pricePerPax`);
                          }}
                          getDisplayValue={(value) => {
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<FaPerson key={i} className='tw-text-[1.2em] tw-text-slate-700 tw-mx-[-0.15em]' />);
                              }
                              return (
                                <>
                                  <div className={`tw-bg-white ${getEditableFieldBorderStyle(enableEditing)} tw-p-1 tw-h-14`}>
                                    <div>{list}</div>
                                    <div>{value} pax</div>
                                  </div>
                                  {(row.numOfRooms ?? 0) > 1 && (row.numOfPax ?? 0) > 0 && <div>(in {row.numOfRooms} rooms)</div>}
                                </>
                              );
                            } else {
                              return (
                                <div className={`tw-bg-white ${getEditableFieldBorderStyle(enableEditing)} tw-p-1 tw-h-14`}>
                                  {value}
                                </div>
                              );
                            }
                          }}
                        />
                      </td>
                      <td className={`${borderTop} tw-min-w-40 ${tw_bgcolor}`}>{/* COLUMN: PRICE PER PAX PER NIGHT */}
                        <div className='tw-flex tw-gap-1'>
                          <div className='tw-grow'>
                            <EditableFieldWithBorder
                              fieldname={`hotelList_${row.id}_pricePerPaxPerNight`}
                              validationType={'number'}
                              currentValue={row.pricePerPaxPerNight}
                              isClickableToEdit={enableEditing}
                              editedCell={editedCell}
                              setEditedCell={setEditedCell}
                              callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                                const inputPricePerPaxPerNight = convertToNumberOrNull(dbvalue);
                                if (inputPricePerPaxPerNight !== row.pricePerPaxPerNight) { // if value didn't change, do nothing
                                  let pricePerPaxPerNight = inputPricePerPaxPerNight;
                                  const updateObj: Record<string, any> = {};
                                  if (pricePerPaxPerNight !== null) {
                                    pricePerPaxPerNight = roundUp10(pricePerPaxPerNight);
                                    if (row.numOfNights !== null) {
                                      if (row.numOfPax !== null) {
                                        // numOfPax & numOfNights & pricePerPaxPerNight --> totalPrice
                                        updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = row.numOfPax * row.numOfNights * pricePerPaxPerNight;
                                      } else if (row.totalPrice !== null && Number.isInteger(row.totalPrice / (pricePerPaxPerNight * row.numOfNights))) {
                                        // if pricePerPax present but numOfPax is not but can be calculated
                                        updateObj[hotelsfield('hotels', row.id, 'numOfPax')] = Math.round(row.totalPrice / (pricePerPaxPerNight * row.numOfNights));
                                      } else {
                                        // numOfPax is not present, and can't be calculated, so totalPrice must be set to null
                                        updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                                      }
                                    } else {
                                      // numOfNights is missing, set total price to null
                                      updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                                    }
                                  } else {
                                    // pricePerPax was deleted
                                    if (row.numOfPax !== null) {
                                      // pricePerPax deleted & numOfPax present --> totalPrice deleted
                                      updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = null;
                                    } else {
                                      // no numOfPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                                    }
                                  }

                                  const newHotelRooms = updateRoomListPricePerPax({ pricePerPaxPerNight });
                                  updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = pricePerPaxPerNight;
                                  updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);
                                  updateObj[hotelsfield('hotels', row.id, 'selectedHotelPrice')] = null;

                                  autosaveNewStep(`Change accommodation price per pax per night to ‘${pricePerPaxPerNight}’`, updateObj, 'u');
                                }
                                tabNavigation(tabKey, `hotelList_${row.id}_numOfPax`, `hotelList_${row.id}_totalPrice`);
                              }}
                            />
                          </div>
                          <SearchButton
                            className='tw-bg-white'
                            onClick={() => {
                              setShowHotelSearchModal(true);
                              setHotelRowToMatch(row);
                              if (row.selectedHotelPrice) {
                                const selectedHotelPrice = row.selectedHotelPrice;
                                const hotel = hotelPrices.find(hotel => hotel.id === selectedHotelPrice.id);
                                setSelectedHotel(hotel);
                                const roomType = hotel?.roomList?.find(room => room.roomName === selectedHotelPrice.roomType);
                                setSelectedRoom(roomType);
                                const occupancy = roomType?.occupancyList?.find(occ => occ.numOfPaxPerRoom === selectedHotelPrice.numOfPaxPerRoom);
                                setSelectedOccupancy(occupancy);
                              }
                              setCurrentSeasons(getSeasons(row.checkinDateiso, row.checkoutDateiso) ?? undefined);
                            }}
                          />
                        </div>
                        {row.pricePerPaxPerNight !== null && priceMultiplier > 1 && (
                          <div className='tw-whitespace-nowrap tw-text-sm'>
                            inc. com: {formatNum(Math.round(priceMultiplier * row.pricePerPaxPerNight))}
                          </div>
                        )}
                        {row.selectedHotelPrice && (
                          <div className='tw-mt-1'>
                            <span
                              id={`selectedHotelPrice_${row.id}`}
                              className='tw-p-0.5 tw-inline-block tw-cursor-default tw-text-sm
                                 tw-border tw-border-solid tw-border-blue-800/50 tw-rounded tw-bg-blue-100/50 hover:tw-bg-blue-100'>
                              <i className='bi bi-info-circle'></i>{' '}
                              From price database
                            </span>
                            <Tooltip anchorSelect={`#selectedHotelPrice_${row.id}`} clickable={false} place='bottom-start'>
                              <div className=''>
                                <div className='tw-whitespace-nowrap'>
                                  {row.selectedHotelPrice.hotelPlace}
                                </div>
                                <div className='tw-pl-0 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedHotelPrice.hotelName}
                                </div>
                                <div className='tw-pl-2 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedHotelPrice.roomType}
                                </div>
                                <div className='tw-pl-4 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedHotelPrice.numOfPaxPerRoom} pax/room
                                </div>
                                <div className='tw-pl-6 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedHotelPrice.season} season: ¥{formatNum(row.selectedHotelPrice.pricePerPax)}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: BAR RATE */}
                        <CheckboxButtonBAR
                          checked={row.isBarRate}
                          disabled={!enableEditing}
                          onClick={() => {
                            const newVal = !row.isBarRate;
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'isBarRate')]: newVal,
                            };
                            autosaveNewStep(`Change accommodation BAR Rate to ‘${newVal}’`, updateObj, 'u');
                            setEditedCell(null);
                          }} />
                      </td>
                      <td className={`${borderTop} tw-min-w-32 ${tw_bgcolor}`}>{/* COLUMN: TOTAL */}
                        <EditableFieldWithBorder
                          fieldname={`hotelList_${row.id}_totalPrice`}
                          validationType={'number'}
                          currentValue={row.totalPrice}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const inputTotalPrice = convertToNumberOrNull(dbvalue);
                            if (inputTotalPrice !== row.totalPrice) { // if value didn't change, do nothing
                              let newTotalPrice = inputTotalPrice; // could be different from inputTotalPrice due to rounding at pricePerPaxPerNight level
                              const updateObj: Record<string, any> = {};
                              let newPricePerPaxPerNight = undefined; // undefined -> no change;  null -> set it to null
                              let newNumOfPax = undefined;
                              if (newTotalPrice !== null) {
                                if (row.numOfNights !== null) {
                                  if (row.numOfPax !== null && row.numOfPax > 0) {
                                    // numOfPax & numOfNights & totalPrice --> pricePerPaxPerNight
                                    newPricePerPaxPerNight = roundUp10(newTotalPrice / (row.numOfPax * row.numOfNights));
                                    newTotalPrice = newPricePerPaxPerNight * row.numOfPax * row.numOfNights;
                                  } else if (row.pricePerPaxPerNight !== null && Number.isInteger(newTotalPrice / (row.pricePerPaxPerNight * row.numOfNights))) {
                                    // if pricePerPax present but numOfPax is not but can be calculated
                                    newNumOfPax = Math.round(newTotalPrice / (row.pricePerPaxPerNight * row.numOfNights));
                                  } else {
                                    // numOfPax is not present, and can't be calculated, so pricePerPax must be set to null
                                    newPricePerPaxPerNight = null;
                                  }
                                } else {
                                  // num of nights is missing, delete price per pax
                                  newPricePerPaxPerNight = null;
                                }
                              } else {
                                // totalPrice was deleted
                                if (row.numOfPax !== null) {
                                  // totalPrice deleted & numOfPax present --> newPricePerPaxPerNight deleted
                                  newPricePerPaxPerNight = null;
                                } else {
                                  // no numOfPax present -> don't touch newPricePerPaxPerNight (leave it if present) as it's ok to have a newPricePerPaxPerNight with no numOfPax and no totalPrice
                                }
                              }

                              updateObj[hotelsfield('hotels', row.id, 'totalPrice')] = newTotalPrice;
                              updateObj[hotelsfield('hotels', row.id, 'selectedHotelPrice')] = null;

                              if (newPricePerPaxPerNight !== undefined) {
                                updateObj[hotelsfield('hotels', row.id, 'pricePerPaxPerNight')] = newPricePerPaxPerNight;
                                const newHotelRooms = updateRoomListPricePerPax({ pricePerPaxPerNight: newPricePerPaxPerNight });
                                updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);
                              }

                              if (newNumOfPax !== undefined) {
                                updateObj[hotelsfield('hotels', row.id, 'numOfPax')] = newNumOfPax;
                                const newHotelRooms = updateRoomListNumOfPax(newNumOfPax);
                                updateObj[hotelsfield('hotels', row.id, 'hotelRooms')] = getHotelRoomsObject(newHotelRooms);
                              }

                              autosaveNewStep(`Change accommodation total price to ‘${newTotalPrice}’`, updateObj, 'u');
                            }
                            tabNavigation(tabKey, `hotelList_${row.id}_pricePerPax`, `hotelList_${row.id}_bookingRemarks`);
                          }}
                        />
                        {row.totalPrice !== null && (
                          <ButtonTW variant={showExtended ? 'blue' : 'blue_outline'}
                            className='tw-bg-white tw-whitespace-nowrap tw-text-xs !tw-p-1 tw-mt-1'
                            onClick={() => {
                              if (showExtended) {
                                log_info({ db, userDetails, logkey: 'tripquotations.btn_view_breakdown.click_to_hide', desc: 'Click button to hide breakdown' });
                                hideBreakdown();
                              } else {
                                log_info({ db, userDetails, logkey: 'tripquotations.btn_view_breakdown.click_to_show', desc: 'Click button to show breakdown' });
                                showBreakdown();
                              }
                            }}>
                            view breakdown
                          </ButtonTW>
                        )}
                        {row.totalPrice !== null && priceMultiplier > 1 && (
                          <div className='tw-whitespace-nowrap tw-text-sm'>
                            inc. com: {formatNum(Math.round(priceMultiplier * row.totalPrice))}
                          </div>
                        )}
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: ACTIONS */}
                        {enableEditing && (
                          <div className='tw-flex tw-gap-1'>

                            <ButtonUpDown
                              disableUp={index === 0}
                              disableDown={index >= quotation.hotelList.length - 1}
                              onClickUp={() => {
                                if (index === 0)
                                  return;
                                const updateObj: Record<string, any> = {
                                  [`hotels.${quotation.hotelList[index - 1].id}.index`]: index,
                                  [`hotels.${row.id}.index`]: index - 1,
                                };
                                console.log('updateObj', updateObj);
                                autosaveNewStep('Reorder accommodation rows', updateObj, 'u');
                              }}
                              onClickDown={() => {
                                if (index >= quotation.hotelList.length - 1)
                                  return;
                                const updateObj: Partial<TripQuotationRawType> = {
                                  [`hotels.${row.id}.index`]: index + 1,
                                  [`hotels.${quotation.hotelList[index + 1].id}.index`]: index,
                                };
                                autosaveNewStep('Reorder accommodation rows', updateObj, 'u');
                              }}
                            />

                            <DeleteButton
                              className='tw-bg-white'
                              onClick={() => {
                                const updateObj: Partial<TripQuotationRawType> = {
                                  [`hotels.${row.id}`]: deleteField(),
                                };
                                log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.delete_hotel_row', desc: 'Delete hotel row' });
                                autosaveNewStep('Delete accommodation row', updateObj, 'u');
                              }}
                            />

                          </div>
                        )}
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>
                        <select disabled={!enableEditing} className='tw-p-1 tw-rounded'
                          value={row.bookingStatus}
                          style={{ backgroundColor: statusColors[row.bookingStatus] + 'C0' }}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            console.log(newStatus);
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'bookingStatus')]: newStatus,
                            };
                            autosaveNewStep(`Change accommodation booking status to ‘${newStatus}’`, updateObj, 'u');
                            setEditedCell(null);
                          }}>
                          {bookingStatuses.map(status =>
                            <option key={status} value={status}>{status}</option>
                          )}
                        </select>
                      </td>
                      <td className={`tw-min-w-48 ${borderTop} ${tw_bgcolor}`}>
                        <EditableFieldWithBorder
                          fieldname={`hotelList_${row.id}_bookingRemarks`}
                          validationType={''}
                          currentValue={row.bookingRemarks}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'bookingRemarks')]: dbvalue,
                            };
                            autosaveNewStep(`Change hotel booking remarks to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, `hotelList_${row.id}_totalPrice`, `hotelList_${row.id}_internalMemo`);
                          }}
                          isTextArea={true}
                          textareaRows={2}
                        />
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`tw-min-w-48 ${borderTop} ${tw_bgcolor}`}>
                        {showRowIds && row.id}
                        <EditableFieldWithBorder
                          fieldname={`hotelList_${row.id}_internalMemo`}
                          validationType={''}
                          currentValue={row.internalMemo}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'internalMemo')]: dbvalue,
                            };
                            autosaveNewStep(`Change hotel internal memo to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, `hotelList_${row.id}_bookingRemarks`, '');
                          }}
                          isTextArea={true}
                          textareaRows={2}
                        />
                        <RowHighlighters
                          disabled={!enableEditing}
                          onClick={(color: string) => {
                            const newValue = color;
                            const updateObj: Partial<TripQuotationType> = {
                              [hotelsfield('hotels', row.id, 'highlighterColor')]: newValue,
                            };
                            autosaveNewStep(`Change hotel highlighterColor to ‘${newValue}’`, updateObj, 'u');
                          }}
                        />
                      </td>
                    </tr>
                    {showExtended && (
                      <tr>
                        <td colSpan={4}></td>
                        <td colSpan={10}>
                          <HotelPriceBreakdownTable
                            row={row}
                            hideBreakdown={() => {
                              log_info({ db, userDetails, logkey: 'tripquotations.breakdown.btn_cross.click_to_hide', desc: 'Click cross to hide breakdown' });
                              hideBreakdown();
                            }}
                          />
                        </td>
                        <td className='!tw-bg-transparent'></td>
                        <td colSpan={2}></td>
                        <td className='!tw-bg-transparent'></td>
                        <td></td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr className='[&>td]:tw-border-t-2'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{formatNum(hotelsTotal)}</td>
                <td>
                  {enableEditing && (
                    <AddButton onClick={() => {
                      let newCheckin = '', newCheckout = '';
                      let newNumOfNights: number | null = null;
                      const lastHotelCheckout = quotation.hotelList.at(-1)?.checkoutDateiso;
                      if (lastHotelCheckout) {
                        newCheckin = lastHotelCheckout;
                        newCheckout = addDaysIso(newCheckin, 1);
                        newNumOfNights = 1;
                      } else if (quotation.generalInfo.tripStartDateiso) {
                        // by default, when inserting first hotel row, use the first day of the trip
                        newCheckin = quotation.generalInfo.tripStartDateiso;
                        newCheckout = addDaysIso(newCheckin, 1);
                        newNumOfNights = 1;
                      }
                      const newHotelRow: HotelTripQuotationRowRawType = {
                        index: quotation.hotelList.length,
                        csvdata: null,
                        sourceCheckinDateiso: '',
                        sourceCheckoutDateiso: '',
                        sourceMeals: '',
                        sourceMealCode: '',
                        checkinDateiso: newCheckin,
                        checkoutDateiso: newCheckout,
                        numOfNights: newNumOfNights,
                        sourceHotelPlace: '',
                        hotelPlace: '',
                        serviceType: 'Accommodation',
                        roomType: '',
                        sourceRoomType: '',
                        supplierNote: '',
                        itemNote: '',
                        passengers: '',
                        bookingStatus: 'RQ',
                        bookingRemarks: '',
                        internalMemo: '',
                        hotelName: '',
                        sourceHotelName: '',
                        breakfastIncluded: false,
                        dinnerIncluded: false,
                        isBarRate: false,
                        numOfPax: null,
                        pricePerPaxPerNight: null,
                        totalPrice: null,
                        numOfRooms: null,
                        selectedHotelPrice: null,
                        hotelRooms: {},
                      };

                      const hotelId = nano_id();

                      const updateObj: Partial<TripQuotationRawType> = {
                        [`hotels.${hotelId}`]: newHotelRow,
                      };

                      log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.add_hotel_row', desc: 'Add hotel row' });

                      autosaveNewStep('Add accommodation row', updateObj, 'u');
                    }} />
                  )}
                </td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
                <td></td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/*
 .oooooo..o oooooooooooo ooooooooo.   oooooo     oooo ooooo   .oooooo.   oooooooooooo      ooooo        ooooo  .oooooo..o ooooooooooooo 
d8P'    `Y8 `888'     `8 `888   `Y88.  `888.     .8'  `888'  d8P'  `Y8b  `888'     `8      `888'        `888' d8P'    `Y8 8'   888   `8 
Y88bo.       888          888   .d88'   `888.   .8'    888  888           888               888          888  Y88bo.           888      
 `"Y8888o.   888oooo8     888ooo88P'     `888. .8'     888  888           888oooo8          888          888   `"Y8888o.       888      
     `"Y88b  888    "     888`88b.        `888.8'      888  888           888    "          888          888       `"Y88b      888      
oo     .d8P  888       o  888  `88b.       `888'       888  `88b    ooo   888       o       888       o  888  oo     .d8P      888      
8""88888P'  o888ooooood8 o888o  o888o       `8'       o888o  `Y8bood8P'  o888ooooood8      o888ooooood8 o888o 8""88888P'      o888o     
        */}
        <div className='tw-mt-5'>
          <table className='
              tw-my-2
              [&>*>tr>*]:tw-border-solid
              [&>*>tr>*]:tw-border-slate-200
              [&>*>tr>*]:tw-py-2
              [&>*>tr>*]:tw-px-2
              [&>*>tr>*]:tw-bg-white
              [&>*>tr>td]:tw-align-top
              [&>*>tr>th]:tw-align-bottom
              tw-h-1' // tw-h-1 needed for div height 100% below. see https://stackoverflow.com/a/59030659/
          >
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Service type</th>
                <th>Service name</th>
                <th>Details</th>
                <th># of Pax</th>
                <th>Price per person</th>
                <th>Total</th>
                <th>Actions</th>
                <th className='!tw-bg-transparent'></th>
                <th>Booking Status</th>
                <th>Booking Remarks</th>
                <th className='!tw-bg-transparent'></th>
                <th>80days internal memo</th>
              </tr>
            </thead>
            <tbody>
              {missingDatesAtStart && missingDatesAtStart.map(dateiso => getEmptyServiceRow(0, dateiso))}
              {quotation.serviceList.map((row, index) => {

                let dateSpan: number;
                const sameDateRows: ServiceTripQuotationRowType[] = [];
                if (index > 0 && row.dateiso === quotation.serviceList[index - 1].dateiso) {
                  dateSpan = 0;
                  sameDateRows.push(row);
                } else {
                  dateSpan = 0;
                  for (let i = index; i < quotation.serviceList.length; i++) {
                    if (row.dateiso === quotation.serviceList[i].dateiso) {
                      dateSpan += showCsvSource ? 2 : 1;
                      sameDateRows.push(quotation.serviceList[i]);
                    } else {
                      break;
                    }
                  }
                }

                const borderTop = showCsvSource ? '' : 'tw-border-t-2';
                const csvColumnCount = row.serviceType === 'Rail' ? 12 : 10;

                const blueBubbleCell = getServiceBlueBubbleCell(row.dateiso);

                const missingDates = missingDatesByPrecedingRow.get(row.id);

                const tw_bgcolor = row.highlighterColor ? highlighterColorMap.get(row.highlighterColor)?.rowBackground : '';

                return (
                  <React.Fragment key={row.id}>
                    {showCsvSource && (
                      <tr>
                        {dateSpan > 0 && (
                          <td rowSpan={dateSpan} className='tw-border-t-2'>
                            {blueBubbleCell}
                          </td>
                        )}
                        <td colSpan={8} className='tw-border-t-2'>
                          <CsvSourceTable
                            sourceCsvHeaders={quotation.sourceCsvHeaders?.slice(0, csvColumnCount)}
                            csvdatarows={row.csvdata ? [row.csvdata] : []}
                          />
                        </td>
                        <td className='!tw-bg-transparent'></td>
                        <td colSpan={2} className='tw-border-t-2'></td>
                        <td className='!tw-bg-transparent'></td>
                        <td className='tw-border-t-2'></td>
                      </tr>
                    )}
                    <tr>
                      {!showCsvSource && dateSpan > 0 && (
                        <td rowSpan={dateSpan} className={borderTop}>
                          {blueBubbleCell}
                        </td>
                      )}
                      <td className={`tw-min-w-[7.25em] ${dateSpan > 0 ? borderTop : ''} ${tw_bgcolor}`}
                        onMouseEnter={() => setHoveredRowId(row.id)}
                        onMouseLeave={() => setHoveredRowId(null)}>{/* COLUMN: DATE */}
                        {(dateSpan > 0 || hoveredRowId === row.id) && (
                          <EditableFieldDatepicker
                            showBorder={true}
                            currentValue_jst0={row.dateiso ? jst0_from_iso(row.dateiso) : null}
                            isClickableToEdit={enableEditing}
                            callbackCommitChange={(date_jst0) => {
                              const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                              if (dateiso === row.dateiso)
                                return;
                              const updateObj: Record<string, any> = {
                                [servicesfield('services', row.id, 'dateiso')]: dateiso,
                              };

                              // reorder rows based on new date
                              if (dateiso) {
                                let insertionIndex: number | null = null;
                                for (let i = 0; i < quotation.serviceList.length; i++) {
                                  if (quotation.serviceList[i].dateiso > dateiso) {
                                    insertionIndex = i > index ? i - 1 : i;
                                    break;
                                  }
                                }
                                if (insertionIndex === null) {
                                  insertionIndex = quotation.serviceList.length - 1;
                                }

                                if (insertionIndex < index) {
                                  // moving current item up / earlier
                                  for (let i = insertionIndex; i < index; i++) {
                                    updateObj[servicesfield('services', quotation.serviceList[i].id, 'index')] = i + 1;
                                    console.log(`moving ${i} -> ${i + 1}`);
                                  }
                                  updateObj[servicesfield('services', row.id, 'index')] = insertionIndex;
                                  console.log(`*moving ${index} -> ${insertionIndex}`);
                                } else if (insertionIndex > index) {
                                  // moving current item down / later
                                  for (let i = index + 1; i <= insertionIndex; i++) {
                                    updateObj[servicesfield('services', quotation.serviceList[i].id, 'index')] = i - 1;
                                    console.log(`moving ${i} -> ${i - 1}`);
                                  }
                                  updateObj[servicesfield('services', row.id, 'index')] = insertionIndex;
                                  console.log(`*moving ${index} -> ${insertionIndex}`);
                                }
                              }

                              autosaveNewStep(`Change service date to ‘${dateiso}’`, updateObj, 'u');
                              setEditedCell(null);
                            }}
                          />
                        )}
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: SERVICE TYPE */}
                        {!row.csvdata ? ( // editable if row did not come from csv
                          <select disabled={!enableEditing} value={row.serviceType} onChange={(e) => {
                            const newServiceType = e.target.value;
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'serviceType')]: newServiceType,
                            };
                            autosaveNewStep(`Change service type to ‘${newServiceType}’`, updateObj, 'u');
                            setEditedCell(null);
                          }}>
                            <option value='Package_Item'>Package_Item</option>
                            <option value='Transfer'>Transfer</option>
                            <option value='Activity'>Activity</option>
                            <option value='Rail'>Rail</option>
                          </select>
                        ) : (
                          row.serviceType.replaceAll('_', ' ')
                        )}
                        <SupplierNoteAlert rowId={row.id} note={row.supplierNote} />
                        <ItemNoteAlert rowId={row.id} note={row.itemNote} />
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: SERVICE NAME */}
                        <SourceDataGrayBox disabled={!enableEditing} onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [servicesfield('services', row.id, 'serviceName')]: row.sourceServiceName,
                          };
                          autosaveNewStep(`Change service name to ‘${row.sourceServiceName}’`, updateObj, 'u');
                        }}>
                          {row.sourceServiceName}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableFieldWithBorder
                            fieldname={`serviceList_${row.id}_serviceName`}
                            validationType={''}
                            currentValue={row.serviceName}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [servicesfield('services', row.id, 'serviceName')]: dbvalue,
                              };
                              autosaveNewStep(`Change service name to ‘${dbvalue}’`, updateObj, 'u');
                              tabNavigation(tabKey, '', `serviceList_${row.id}_serviceDetails`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-min-w-48 !tw-p-0 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: DETAILS */}
                        <SourceDataGrayBox disabled={!enableEditing} onArrowClick={() => {
                          const updateObj: Partial<TripQuotationType> = {
                            [servicesfield('services', row.id, 'serviceDetails')]: row.sourceServiceDetails,
                          };
                          autosaveNewStep(`Change service details to ‘${row.sourceServiceDetails}’`, updateObj, 'u');
                        }}>
                          {row.sourceServiceDetails}
                        </SourceDataGrayBox>
                        <div className='tw-p-2'>
                          <EditableFieldWithBorder
                            fieldname={`serviceList_${row.id}_serviceDetails`}
                            validationType={''}
                            currentValue={row.serviceDetails}
                            isClickableToEdit={enableEditing}
                            editedCell={editedCell}
                            setEditedCell={setEditedCell}
                            callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                              const updateObj: Partial<TripQuotationType> = {
                                [servicesfield('services', row.id, 'serviceDetails')]: dbvalue,
                              };
                              autosaveNewStep(`Change service details to ‘${dbvalue}’`, updateObj, 'u');
                              tabNavigation(tabKey, `serviceList_${row.id}_serviceName`, `serviceList_${row.id}_numOfPax`);
                            }}
                          />
                        </div>
                      </td>
                      <td className={`tw-whitespace-nowrap ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: NUM OF PAX */}
                        <EditableField
                          fieldname={`serviceList_${row.id}_numOfPax`}
                          validationType={'number'}
                          currentValue={row.numOfPax}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const numOfPax = convertToNumberOrNull(dbvalue) || null; // don't allow zero pax, convert zero to null
                            const updateObj: Record<string, number | null> = {
                              [servicesfield('services', row.id, 'numOfPax')]: numOfPax,
                            };
                            if (numOfPax !== null) {
                              if (row.pricePerPax !== null) {
                                // numOfPax & pricePerPax --> totalPrice
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = numOfPax * row.pricePerPax;
                              } else if (row.totalPrice !== null && numOfPax > 0) {
                                // numOfPax & totalPrice --> pricePerPax
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = Math.round(row.totalPrice / numOfPax);
                              }
                            } else {
                              // numOfPax was deleted
                              if (row.pricePerPax !== null) {
                                // numOfPax deleted & pricePerPax present --> totalPrice deleted
                                updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                              } else {
                                // no pricePerPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                              }
                            }
                            autosaveNewStep(`Change service number of pax to ‘${numOfPax}’`, updateObj, 'u');
                            tabNavigation(tabKey, `serviceList_${row.id}_serviceDetails`, `serviceList_${row.id}_pricePerPax`);
                          }}
                          getDisplayValue={(value) => {
                            let content = <>{value}</>;
                            if (typeof value === 'number') {
                              const list = [];
                              for (let i = 0; i < value; i++) {
                                list.push(<FaPerson key={i} className='tw-text-[1.2em] tw-text-slate-700 tw-mx-[-0.15em]' />);
                              }
                              content = (
                                <>
                                  <div>{list}</div>
                                  <div>{value} pax</div>
                                </>
                              );
                            }
                            return (
                              <div className={`tw-bg-white ${getEditableFieldBorderStyle(enableEditing)} tw-p-1 tw-h-14`}>
                                {content}
                              </div>
                            );
                          }}
                        />
                      </td>
                      <td className={`${borderTop} tw-min-w-40 ${tw_bgcolor}`}>{/* COLUMN: PRICE PER PAX */}
                        <div className='tw-flex tw-gap-1'>
                          <div className='tw-grow'>
                            <EditableFieldWithBorder
                              fieldname={`serviceList_${row.id}_pricePerPax`}
                              validationType={'number'}
                              currentValue={row.pricePerPax}
                              isClickableToEdit={enableEditing}
                              editedCell={editedCell}
                              setEditedCell={setEditedCell}
                              callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                                const pricePerPax = convertToNumberOrNull(dbvalue);
                                const updateObj: Record<string, number | null> = {
                                  [servicesfield('services', row.id, 'pricePerPax')]: pricePerPax,
                                };
                                if (pricePerPax !== null) {
                                  if (row.numOfPax !== null) {
                                    // numOfPax & pricePerPax --> totalPrice
                                    updateObj[servicesfield('services', row.id, 'totalPrice')] = row.numOfPax * pricePerPax;
                                  } else if (row.totalPrice !== null && Number.isInteger(row.totalPrice / pricePerPax)) {
                                    // if pricePerPax present but numOfPax is not but can be calculated
                                    updateObj[servicesfield('services', row.id, 'numOfPax')] = Math.round(row.totalPrice / pricePerPax);
                                  } else {
                                    // numOfPax is not present, and can't be calculated, so totalPrice must be set to null
                                    updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                                  }
                                } else {
                                  // pricePerPax was deleted
                                  if (row.numOfPax !== null) {
                                    // pricePerPax deleted & numOfPax present --> totalPrice deleted
                                    updateObj[servicesfield('services', row.id, 'totalPrice')] = null;
                                  } else {
                                    // no numOfPax present -> don't touch total (leave it if present) as it's ok to have a total with no numOfPax and no pricePerPax
                                  }
                                }
                                autosaveNewStep(`Change service price per pax to ‘${pricePerPax}’`, updateObj, 'u');
                                tabNavigation(tabKey, `serviceList_${row.id}_numOfPax`, `serviceList_${row.id}_totalPrice`);
                              }}
                            />
                          </div>
                          {['Activity', 'Package_Item'].includes(row.serviceType) && (
                            <SearchButton
                              className='tw-bg-white'
                              onClick={() => {
                                setShowServiceSearchModal(true);
                                setServiceRowToMatch(row);
                                if (row.selectedServicePrice) {
                                  const selectedServicePrice = row.selectedServicePrice;
                                  const service = servicePrices.find(service => service.id === selectedServicePrice.serviceId);
                                  setSelectedService(service);
                                  if (service) {
                                    const item = service.priceItems.find(item => item.id === selectedServicePrice.serviceItemId);
                                    setSelectedServiceItem(item);
                                  }
                                }
                              }}
                            />
                          )}
                        </div>
                        {row.selectedServicePrice && (
                          <div className='tw-mt-1'>
                            <span
                              id={`selectedServicePrice_${row.id}`}
                              className='tw-p-0.5 tw-inline-block tw-cursor-default tw-text-sm
                                 tw-border tw-border-solid tw-border-blue-800/50 tw-rounded tw-bg-blue-100/50 hover:tw-bg-blue-100'>
                              <i className='bi bi-info-circle'></i>{' '}
                              From price database
                            </span>
                            <Tooltip anchorSelect={`#selectedServicePrice_${row.id}`} clickable={false} place='bottom-start'>
                              <div className=''>
                                <div className='tw-whitespace-nowrap'>
                                  {row.selectedServicePrice.servicePlace}
                                </div>
                                <div className='tw-pl-0 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedServicePrice.serviceName}
                                </div>
                                {row.selectedServicePrice.serviceItemName && (
                                  <div className='tw-pl-2 tw-whitespace-nowrap'>
                                    <i className='bi bi-chevron-right tw-text-xs'></i>
                                    {row.selectedServicePrice.serviceItemName}
                                  </div>
                                )}
                                {/* <div className='tw-pl-4 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedServicePrice.seasonName}
                                </div>
                                <div className='tw-pl-5 tw-whitespace-nowrap'>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedServicePrice.ageName}
                                </div> */}
                                <div className={`${row.selectedServicePrice.serviceItemName ? 'tw-pl-4' : 'tw-pl-2'} tw-whitespace-nowrap`}>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  {row.selectedServicePrice.numOfPax} pax
                                </div>
                                <div className={`${row.selectedServicePrice.serviceItemName ? 'tw-pl-6' : 'tw-pl-4'} tw-whitespace-nowrap`}>
                                  <i className='bi bi-chevron-right tw-text-xs'></i>
                                  ¥{formatNum(row.selectedServicePrice.pricePerPax)}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        {row.pricePerPax !== null && priceMultiplier > 1 && (
                          <div className='tw-whitespace-nowrap tw-text-sm'>
                            inc. com: {formatNum(Math.round(priceMultiplier * row.pricePerPax))}
                          </div>
                        )}
                      </td>
                      <td className={`${borderTop} tw-min-w-32 ${tw_bgcolor}`}>{/* COLUMN: TOTAL PRICE */}
                        <EditableFieldWithBorder
                          fieldname={`serviceList_${row.id}_totalPrice`}
                          validationType={'number'}
                          currentValue={row.totalPrice}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const totalPrice = convertToNumberOrNull(dbvalue);
                            const updateObj: Record<string, number | null> = {
                              [servicesfield('services', row.id, 'totalPrice')]: totalPrice,
                            };
                            if (totalPrice !== null) {
                              if (row.numOfPax !== null && row.numOfPax > 0) {
                                // numOfPax & totalPrice --> pricePerPax
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = Math.round(totalPrice / row.numOfPax);
                              } else if (row.pricePerPax !== null && Number.isInteger(totalPrice / row.pricePerPax)) {
                                // if pricePerPax present but numOfPax is not but can be calculated
                                updateObj[servicesfield('services', row.id, 'numOfPax')] = Math.round(totalPrice / row.pricePerPax);
                              } else {
                                // numOfPax is not present, and can't be calculated, so pricePerPax must be set to null
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = null;
                              }
                            } else {
                              // totalPrice was deleted
                              if (row.numOfPax !== null) {
                                // totalPrice deleted & numOfPax present --> pricePerPax deleted
                                updateObj[servicesfield('services', row.id, 'pricePerPax')] = null;
                              } else {
                                // no numOfPax present -> don't touch pricePerPax (leave it if present) as it's ok to have a pricePerPax with no numOfPax and no totalPrice
                              }
                            }
                            autosaveNewStep(`Change service total price to ‘${totalPrice}’`, updateObj, 'u');
                            tabNavigation(tabKey, `serviceList_${row.id}_pricePerPax`, `serviceList_${row.id}_bookingRemarks`);
                          }}
                        />
                        {row.totalPrice !== null && priceMultiplier > 1 && (
                          <div className='tw-whitespace-nowrap tw-text-sm'>
                            inc. com: {formatNum(Math.round(priceMultiplier * row.totalPrice))}
                          </div>
                        )}
                      </td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: ACTIONS */}
                        {enableEditing && (
                          <div className='tw-flex tw-gap-1'>

                            {/* if array is already not in the right order, we always allow user to reorder rows. */}
                            {/* if array is currently in the right order, we don't allow the user to make it out of order. */}

                            <ButtonUpDown
                              disableUp={
                                !(index > 0
                                  && (!servicesAreInChronologicalOrder || !row.dateiso || !quotation.serviceList[index - 1].dateiso || quotation.serviceList[index - 1].dateiso === row.dateiso))
                              }
                              disableDown={
                                !(index < quotation.serviceList.length - 1
                                  && (!servicesAreInChronologicalOrder || !row.dateiso || !quotation.serviceList[index + 1].dateiso || quotation.serviceList[index + 1].dateiso === row.dateiso))
                              }
                              onClickUp={() => {
                                if (index === 0)
                                  return;
                                const updateObj: Record<string, any> = {
                                  [`services.${quotation.serviceList[index - 1].id}.index`]: index,
                                  [`services.${row.id}.index`]: index - 1,
                                };
                                // fix any broken index
                                // for (let i = 0; i < quotation.serviceList.length; i++) {
                                //   if (i !== index - 1 && i !== index) {
                                //     if (i !== quotation.serviceList[i].index) {
                                //       updateObj[`services.${quotation.serviceList[i].id}.index`] = i;
                                //     }
                                //   }
                                // }
                                console.log('updateObj', updateObj);
                                autosaveNewStep('Reorder service rows', updateObj, 'u');
                              }}
                              onClickDown={() => {
                                if (index >= quotation.serviceList.length - 1)
                                  return;
                                const updateObj: Partial<TripQuotationRawType> = {
                                  [`services.${row.id}.index`]: index + 1,
                                  [`services.${quotation.serviceList[index + 1].id}.index`]: index,
                                };
                                autosaveNewStep('Reorder service rows', updateObj, 'u');
                              }}
                            />

                            <DeleteButton
                              className='tw-bg-white'
                              onClick={() => {
                                const updateObj: Record<string, any> = {
                                  [`services.${row.id}`]: deleteField(),
                                };

                                // move all subsequent services up by 1
                                for (let i = index + 1; i < quotation.serviceList.length; i++) {
                                  updateObj[servicesfield('services', quotation.serviceList[i].id, 'index')] = i - 1; //  increment(-1);
                                }


                                log_db_write({ db, userDetails, logkey: 'db_write.tripquotations.delete_service_row', desc: 'Delete service row' });
                                autosaveNewStep('Delete service row', updateObj, 'u');
                              }}
                            />

                            <AddButtonServiceRow newRowIndex={index + 1} dateiso={row.dateiso} />
                          </div>
                        )}
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`${borderTop} ${tw_bgcolor}`}>{/* COLUMN: BOOKING STATUS */}
                        <select disabled={!enableEditing} className='tw-p-1 tw-rounded'
                          value={row.bookingStatus}
                          style={{ backgroundColor: statusColors[row.bookingStatus] + 'C0' }}
                          onChange={(e) => {
                            const newStatus = e.target.value;
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'bookingStatus')]: newStatus,
                            };
                            autosaveNewStep(`Change service booking status to ‘${newStatus}’`, updateObj, 'u');
                            setEditedCell(null);
                          }}>
                          {bookingStatuses.map(status =>
                            <option key={status} value={status}>{status}</option>
                          )}
                        </select>
                      </td>
                      <td className={`tw-min-w-48 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: REMARKS */}
                        <EditableFieldWithBorder
                          fieldname={`serviceList_${row.id}_bookingRemarks`}
                          validationType={''}
                          currentValue={row.bookingRemarks}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'bookingRemarks')]: dbvalue,
                            };
                            autosaveNewStep(`Change service booking remarks to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, `serviceList_${row.id}_totalPrice`, `serviceList_${row.id}_internalMemo`);
                          }}
                          isTextArea={true}
                          textareaRows={2}
                        />
                      </td>
                      <td className='!tw-bg-transparent'></td>
                      <td className={`tw-min-w-48 ${borderTop} ${tw_bgcolor}`}>{/* COLUMN: INTERNAL MEMO */}
                        {showRowIds && `id=${row.id} index=${(row as any).index}`}
                        <EditableFieldWithBorder
                          fieldname={`serviceList_${row.id}_internalMemo`}
                          validationType={''}
                          currentValue={row.internalMemo}
                          isClickableToEdit={enableEditing}
                          editedCell={editedCell}
                          setEditedCell={setEditedCell}
                          callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'internalMemo')]: dbvalue,
                            };
                            autosaveNewStep(`Change service internal memo to ‘${dbvalue}’`, updateObj, 'u');
                            tabNavigation(tabKey, `serviceList_${row.id}_bookingRemarks`, '');
                          }}
                          isTextArea={true}
                          textareaRows={2}
                        />
                        <RowHighlighters
                          disabled={!enableEditing}
                          onClick={(color: string) => {
                            const newValue = color;
                            const updateObj: Partial<TripQuotationType> = {
                              [servicesfield('services', row.id, 'highlighterColor')]: newValue,
                            };
                            autosaveNewStep(`Change service highlighterColor to ‘${newValue}’`, updateObj, 'u');
                          }}
                        />
                      </td>
                    </tr>
                    {missingDates && missingDates.map(dateiso => getEmptyServiceRow(index + 1, dateiso))}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr className='[&>td]:tw-border-t-2'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{formatNum(servicesTotal)}</td>
                <td>
                  {enableEditing && (
                    <AddButtonServiceRow
                      newRowIndex={quotation.serviceList.length}
                      dateiso={(() => {
                        const lastDate = quotation.serviceList.at(-1)?.dateiso;
                        if (lastDate) {
                          return addDaysIso(lastDate, 1);
                        }
                        return '';
                      })()}
                    />
                  )}
                </td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
                <td></td>
                <td className='!tw-bg-transparent'></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        {userrole_isDev(userDetails.roles) && (
          <div className='tw-my-3 tw-flex tw-gap-4'>
            DEV:
            <ButtonTW onClick={() => {
              const updateObj: Record<string, any> = {};
              for (let i = 0; i < quotation.serviceList.length; i++) {
                updateObj[servicesfield('services', quotation.serviceList[i].id, 'index')] = i;
              }
              updateDoc(doc(db, coll_tripquotations, quotation.id), updateObj);
            }}>Fix service row indexes</ButtonTW>
          </div>
        )}
      </div>
    </div>
  );
}
