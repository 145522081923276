import { Dispatch, Fragment, MouseEvent, ReactNode, SetStateAction } from 'react';
import { EditableField } from 'src/components/EditableField/EditableField';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { addMetadataModifiedExpenseSheet } from 'src/pages/ExpenseSheet/util_db_expensesheet';
import { getStatusLabel } from 'src/pages/ExpenseSheet/util_status';
import { ExpensePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet';
import { UserSimpleType } from 'src/types/types_user';
import { dateFormatJpShort, dateisoFormatJpShort } from 'src/util/dateformattools';
import { addDaysIso, addMonthsIso, getTodayIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { userrole_canMarkPaid } from 'src/util/user_roles';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { log_db_write } from 'src/util/util_log';
import { LinkToExpenseSheet } from './LinkToExpenseSheet';
import { ModalActionExpensePaymentType } from './ModalPopupMakeExpensePayment/ModalPopupMakeExpensePayment';
import { NumberBoxWithLabel } from './NumberBoxWithLabel';


interface ExpenseAccountingMonthlyGridProps {
  hoverCell: string | null;
  setHoverCell: (cellid: string | null) => void;
  setModalAction: Dispatch<SetStateAction<ModalActionExpensePaymentType | null>>;
  showPaidAdvances: boolean;
  setShowPaidAdvances: (show: boolean) => void;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  shownPopup: string | null;
  setShownPopup: (value: string | null) => void;
  editAccountingDates: boolean;
  simpleAccounting: boolean;
  donePayments: Map<string, ExpensePaymentType>;
  eom_balances: Map<string, Map<string, number>>;
  selectedMonths: string[];
  month: string;
  guide: UserSimpleType;
  month_guide_sheets: ExpenseSheetType[] | undefined;
  firstLevel2: boolean;
  prevMonth: string | null;
  nextMonth: string;
}

export function ExpenseAccountingMonthlyGrid({
  hoverCell, setHoverCell,
  setModalAction,
  showPaidAdvances, setShowPaidAdvances,
  editedCell, setEditedCell,
  shownPopup, setShownPopup,
  editAccountingDates,
  simpleAccounting,
  donePayments,
  eom_balances,
  selectedMonths,
  month, guide, month_guide_sheets, firstLevel2, prevMonth, nextMonth,
}: ExpenseAccountingMonthlyGridProps) {

  const { db, userDetails, setDbError } = useAppContext();

  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles);

  const autosaveNewStep = useAutosaveDocumentInList('expensesheets', addMetadataModifiedExpenseSheet);


  function cellOver(e: MouseEvent) {
    const target = e.target as HTMLDivElement;
    setHoverCell(target.id);
  }

  function cellOut() {
    setHoverCell(null);
  }



  const tablerows = [];

  const isMonthPending = month === 'PENDING';
  const isLastMonth = nextMonth === 'PENDING'; // last month = current month (not PENDING)
  const isMonthFinished = !isMonthPending && !isLastMonth; // month < dateFormat(getTodayJST(), 'yyyy-mm')

  let dt_salaryPayment: Date | null = null;
  let dt_salaryPayment_iso: string | null = null;
  let dt_nextMonthFirstDay = null;
  if (!isMonthPending) {
    const dateiso_month1st = `${month}-01`;
    const dateiso_nextmonth1st = addMonthsIso(dateiso_month1st, 1);
    dt_salaryPayment_iso = addDaysIso(dateiso_nextmonth1st, -1 + 15);
    dt_salaryPayment = jst0_from_iso(dt_salaryPayment_iso);
    dt_nextMonthFirstDay = jst0_from_iso(dateiso_nextmonth1st);
  }

  const highlightTotalExpense = ''; // hoverCell === `${month}_${guide.id}_total_balance` ? 'highlightPlus' : ''
  const highlightTotalPayment = ''; // hoverCell === `${month}_${guide.id}_total_balance` ? 'highlightMinus' : ''
  const highlightTotalBalance = hoverCell === `${month}_${guide.id}_curmonth_expense` ? 'highlightPlus' : '';
  const highlightPrevBalance = hoverCell === `${month}_${guide.id}_curmonth_balance` ? 'highlightPlus' : '';
  const highlightCurExpense = hoverCell === `${month}_${guide.id}_curmonth_balance` ? 'highlightPlus' : '';
  const highlightCurBalance = !isLastMonth
    ? (hoverCell === `${month}_${guide.id}_final_balance` ? 'highlightPlus' : '')
    : (hoverCell === `${nextMonth}_${guide.id}_prev_balance` ? 'highlightPlus' : '');
  const highlightFinalPayment = hoverCell === `${month}_${guide.id}_final_balance` ? 'highlightMinus' : '';
  const highlightFinalBalance = hoverCell === `${nextMonth}_${guide.id}_prev_balance` ? 'highlightPlus' : '';


  let firstSheet = true;

  let monthUserTotal_expense = 0, monthUserTotal_paid = 0, monthUserTotal_balance = 0;

  if (!month_guide_sheets) {
    tablerows.push(
      <div key={`${month}_${guide.id}_none`} className={`${(firstLevel2 && firstSheet) ? 'topOfLevel1' : firstSheet ? 'topOfLevel2' : ''} colspan-7`}>
        No expense sheets
      </div>
    );
    firstSheet = false;
  } else {
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_headers`}>
        <h3>
          Tour code
        </h3>
        <h3>
          Status
        </h3>
        <h3>
          Date submitted
        </h3>
        <h3 className='text-center'>
          Expense
        </h3>
        <h3 className='text-center'>
          Repaid
        </h3>
        <h3 className='text-center'>
          Balance
        </h3>
        <h3 className='text-center'>

        </h3>
      </Fragment>
    );
    month_guide_sheets.forEach((sheet) => {

      // check guide consistency
      if (sheet.userGuideUid !== guide.id)
        console.log(`inconsistent guide id: sheet.userGuideUid=${sheet.userGuideUid} guide.id=${guide.id}`);
      if (sheet.userGuideEmail !== guide.email)
        console.log(`inconsistent guide emails: sheet.userGuideEmail=${sheet.userGuideEmail} guide.email=${guide.email}`);

      // first, look into advance payments and whether they have been paid
      let totalPaid = 0;
      const advancePaymentRows: JSX.Element[] = [];


      const expenseCellId = `${month}_${guide.id}_${sheet.id}_expense`;
      const paymentCellId = `${month}_${guide.id}_${sheet.id}_payment`;
      const balanceCellId = `${month}_${guide.id}_${sheet.id}_balance`;
      const highlightSheetExpense = hoverCell === `${month}_${guide.id}_total_expense` ? 'highlightPlus'
        : hoverCell === balanceCellId ? 'highlightPlus' : '';
      const highlightSheetPayment = hoverCell === `${month}_${guide.id}_total_payment` ? 'highlightPlus'
        : hoverCell === balanceCellId ? 'highlightMinus' : '';
      const highlightSheetBalance = hoverCell === `${month}_${guide.id}_total_balance` ? 'highlightPlus' : '';
      const highlightSheetAdvancePayment = hoverCell === `${month}_${guide.id}_${sheet.id}_payment` ? 'highlightPlus' : '';

      let numAdvancePayments = 0;

      if (sheet.advancePayments.length) {
        sheet.advancePayments.forEach((ap) => {
          if (ap.isDeleted)
            return;

          // retrieve payment if any
          const advancePayment = donePayments.get(`advance: ${sheet.id} ${ap.id}`);

          const paid = advancePayment ? advancePayment.amount : 0;
          const payDate = advancePayment
            ? <span title={`Payment ID: ${advancePayment.id}`}>Paid on {dateFormatJpShort(advancePayment.paymentDate)}</span>
            : 'Not paid';
          const outstanding = (ap.amount ?? 0) - paid;
          const amountAssumedToBePaid = advancePayment ? paid : (ap.amount ?? 0);

          const hasProblem = advancePayment && (outstanding !== 0); // normally should never happen, so we strongly highligh it if it does.

          if (!advancePayment || !showPaidAdvances || true) {
            const rowStyle = `row-advance-payment ${advancePayment ? 'row-advance-payment-collapsible' : ''}`;

            const alreadyPaid = !!advancePayment;

            const onClick_MARK_PAID_ADV_PAYMENT = () => {
              const modal: ModalActionExpensePaymentType = {
                action: alreadyPaid ? 'show payment' : 'make payment',
                showModal: true,

                // general objects that describe the payment:
                paymentRecipient: guide,
                paymentFor: 'GUIDING_EXPENSE',
                paymentType: 'ADHOC_BANK_TRANSFER',

                paymentDbProps: {
                  isAdvance: true,
                  tourCode: sheet.tourCode,
                  sheetId: sheet.id,
                  advancePaymentId: ap.id,
                },
                paymentDisplayProps: {
                  desiredDate: ap.dateiso ? jst0_from_iso(ap.dateiso) : null,
                },

                ...(alreadyPaid ? ({
                  // actual payment details:
                  paymentSourceAccount: advancePayment.paymentSourceAccount,
                  amount: advancePayment.amount,
                  paymentDateIso: iso_from_jst0(advancePayment.paymentDate),
                  paymentId: advancePayment.id,
                }) : ({
                  // default values for the form fields that the user will input:
                  paymentSourceAccount: '',
                  amount: ap.amount,
                  paymentDateIso: getTodayIso(), // assume we pay today
                  paymentId: '',
                })),
              };
              setModalAction(modal);
            };

            advancePaymentRows.push(
              <Fragment key={`${month}_${guide.id}_${sheet.id}_${ap.id}`}>
                <div className={`${rowStyle}`}>{/*tour code*/}</div>
                <div className={`${rowStyle} col-advance-payment ${alreadyPaid ? '' : 'notpaid'}`}><div>Advance payment&nbsp;→</div></div>
                <div className={`${rowStyle} col-advance-payment ${alreadyPaid ? '' : 'notpaid'}`}><div>{payDate}</div></div>
                <div className={`${rowStyle} col-advance-payment numeric problem`}><div>{hasProblem ? formatNum(ap.amount) : ''}</div></div>
                <div className={`${rowStyle} col-advance-payment numeric ${alreadyPaid ? '' : 'notpaid'} ${highlightSheetAdvancePayment}`}><div>{formatNum((alreadyPaid ? paid : ap.amount))}</div></div>
                <div className={`${rowStyle} col-advance-payment ${alreadyPaid ? '' : 'notpaid'}`}><div></div></div>
                <div className={`${rowStyle} col-advance-payment cellButton`}><div>
                  {!isPaymentIssuer ? ''
                    : !alreadyPaid ? (
                      <button className='btn btn-outline-danger btn-sm' onClick={onClick_MARK_PAID_ADV_PAYMENT}>MARK PAID</button>
                    ) : (
                      <button className='btn btn-outline-secondary btn-sm' onClick={onClick_MARK_PAID_ADV_PAYMENT}>SHOW</button>
                    )}
                </div></div>
              </Fragment>
            );
          }

          //totalPaid += paid
          // we deduct the advance payment amount, even if it's hasn't actually been paid yet, because we don't want to pollute the totals and the salary amount
          totalPaid += amountAssumedToBePaid;
          numAdvancePayments += 1;
        });
      }

      const balance = sheet.calc.totalExpenses - totalPaid;

      const rowStyle = //firstSheet ? '' :
        'topOfSheet';
      tablerows.push(
        <Fragment key={`${month}_${guide.id}_${sheet.id}`}>
          <div className={`${rowStyle}`}>
            <LinkToExpenseSheet
              sheet={sheet}
              shownPopup={shownPopup}
              setShownPopup={setShownPopup}
            />
          </div>
          <div className={`${rowStyle}`}>{getStatusLabel(sheet.status)}</div>
          <div className={`${rowStyle}`}>
            <EditableField
              tableid={`table_${month}_${guide.id}`}
              rowid={`row_${month}_${guide.id}_${sheet.id}`}
              fieldname={'accounting_date_x'}
              validationType='date'
              currentValue={dateisoFormatJpShort(sheet.accountingDateiso)}
              isClickableToEdit={editAccountingDates}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(date_jst0) => {
                console.log(guide);
                const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                log_db_write({ db, userDetails, logkey: 'db_write.manual_accounting_date_override', desc: `Manual accounting date override: sheet.id=[${sheet.id}] guide=[${guide.name}] olddate=[${sheet.accountingDateiso}] newdate=[${dateiso}]` });
                const updateObj: Partial<ExpenseSheetType> = {
                  accountingDateiso: dateiso,
                  accountingDateSet: serverTimestampAsDate(),
                };
                autosaveNewStep(`Set accounting date to ${dateiso}`, sheet, updateObj, 'UNDOWALL');
                setEditedCell('');
              }}
              hasButtonForEditing={true}
            />
          </div>
          <div className={`${rowStyle} numeric ${highlightSheetExpense}`} id={expenseCellId} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(sheet.calc.totalExpenses)}</div>
          <div className={`${rowStyle} numeric ${highlightSheetPayment}`} id={paymentCellId} onMouseOver={cellOver} onMouseOut={cellOut}>
            {formatNum(totalPaid)}
            {numAdvancePayments > 0 && (
              <span className='triangle triangle-paidadvances' onClick={(e) => {
                setShowPaidAdvances(!showPaidAdvances);
              }}><i className='bi bi-caret-down'></i></span>
            )}
          </div>
          <div className={`${rowStyle} numeric ${highlightSheetBalance}`} id={balanceCellId} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(balance)}</div>
          <div className={`${rowStyle} py-1 text-center`}>
            {/* <button className='btn btn-outline-primary btn-sm'>PAID</button> */}
          </div>
        </Fragment>
      );

      tablerows.push(...advancePaymentRows);


      monthUserTotal_expense += sheet.calc.totalExpenses;
      monthUserTotal_paid += totalPaid; // sheet.calc.totalAdvancePaid can be outdated if we recently paid an advance payment.
      monthUserTotal_balance += balance; // sheet.calc.balance can also be outdated for the same reason.
      firstSheet = false;

    }); // each sheet

    // total for this month/this guide
    const rowStyle = `topOfSheet rowTotalGuide ${isLastMonth ? 'rowTotalGuideLM' : isMonthPending ? 'rowTotalGuidePENDING' : ''}`;
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_total`}>
        <div className={`${rowStyle} colspan-2`}>{month} total</div>
        <div className={`${rowStyle} text-center`}></div>
        <div className={`${rowStyle} numeric ${highlightTotalExpense}`} id={`${month}_${guide.id}_total_expense`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(monthUserTotal_expense)}</div>
        <div className={`${rowStyle} numeric ${highlightTotalPayment}`} id={`${month}_${guide.id}_total_payment`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(monthUserTotal_paid)}</div>
        <div className={`${rowStyle} numeric ${highlightTotalBalance}`} id={`${month}_${guide.id}_total_balance`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(monthUserTotal_balance)}</div>
        <div className={`${rowStyle} `}></div>
      </Fragment>
    );
  }

  const prevMonthBalance = prevMonth ? eom_balances.get(prevMonth)!.get(guide.id)! : 0;
  const curMonthBalance = prevMonthBalance + monthUserTotal_balance;

  const salaryPayment = donePayments.get(`salary: ${guide.id} ${month}`);
  const finalBalance = curMonthBalance - (salaryPayment ? salaryPayment.amount : 0);
  const paymentNeeded = finalBalance > 0 && !salaryPayment; // if already paid, even if still needed (should never happen anyway), too late now, will be carried to next month
  const finalBalanceStyle =
    !isMonthFinished ? ''
      : paymentNeeded ? 'payment-needed'
        : finalBalance > 0 ? 'balance-outstanding'
          : finalBalance < 0 ? 'overpaid'
            : 'all-good';

  const s_paymentAmount = salaryPayment ? <span title={`Payment ID: ${salaryPayment.id}`}>{formatNum(salaryPayment.amount)}</span> : !isMonthFinished ? 'TBD' : 'Not set';
  if (eom_balances.get(month)!.get(guide.id) !== finalBalance) {
    throw new Error(`inconsistent end of month balances month=${month} guide=${guide.id} ${guide.email}`);
  }

  let paymentButton: ReactNode = '';
  if (isPaymentIssuer && isMonthFinished && (paymentNeeded || salaryPayment)) {
    const alreadyPaid = !!salaryPayment;

    const onClick_SET_WITH_SALARY = () => {
      const modal: ModalActionExpensePaymentType = {
        action: alreadyPaid ? 'show payment' : 'make payment',
        showModal: true,

        // general objects that describe the payment:
        paymentRecipient: guide,
        paymentFor: 'GUIDING_EXPENSE',
        paymentType: 'WITH_SALARY',

        paymentDbProps: {
          isAdvance: false,
          salaryMonth: month,
        },

        ...(alreadyPaid ? ({
          // actual payment details:
          paymentSourceAccount: salaryPayment.paymentSourceAccount,
          amount: salaryPayment.amount,
          paymentDateIso: iso_from_jst0(salaryPayment.paymentDate),
          paymentId: salaryPayment.id,
        }) : ({
          // default values for the form fields that the user will input:
          paymentSourceAccount: '',
          amount: Math.max(curMonthBalance, 0),
          paymentDateIso: dt_salaryPayment_iso!, // dt_salaryPayment_iso is only ever null for PENDING month, but PENDING month does not show the SET button that calls this handler
          paymentId: '',
        })),
      };
      setModalAction(modal);

    };

    paymentButton = !alreadyPaid ? (
      <button className='btn btn-outline-danger btn-sm' onClick={onClick_SET_WITH_SALARY}>SET</button>
    ) : (
      <button className='btn btn-outline-secondary btn-sm' onClick={onClick_SET_WITH_SALARY}>SHOW</button>
    );
  }

  if (simpleAccounting) {
    const rowStyle = `topOfSheet rowGuideAccounting rowGuideAccountingSimple ${isLastMonth ? 'rowGuideAccountingLM' : isMonthPending ? 'rowGuideAccountingPENDING' : ''}`;
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_simple`}>
        <div className={`${rowStyle} colspan-6`}>
          <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
            <NumberBoxWithLabel label='Previous balance' value={prevMonthBalance} />
            <div>+</div>
            <NumberBoxWithLabel label='This month total' value={monthUserTotal_balance} />
            {isMonthFinished && (
              <>
                <div>&minus;</div>
                <NumberBoxWithLabel label='Payment to guide' value={s_paymentAmount} />
              </>
            )}
            <div>=</div>
            <NumberBoxWithLabel label='New balance' value={finalBalance} className={finalBalanceStyle} />
          </div>
        </div>
        <div className={`${rowStyle} text-center`}>
          {paymentButton}
        </div>
      </Fragment>
    );
  } else {
    const rowStyle = `topOfSheet rowGuideAccounting ${isLastMonth ? 'rowGuideAccountingLM' : isMonthPending ? 'rowGuideAccountingPENDING' : ''}`;
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_prevbalance`}>
        <div className={`${rowStyle} colspan-2`}>Previous month balance</div>
        <div className={`${rowStyle} text-center`}>{prevMonth || 'N/A'}</div>
        <div className={`${rowStyle} numeric leftBorder`}></div>
        <div className={`${rowStyle} numeric leftBorder`}></div>
        <div className={`${rowStyle} numeric leftBorder ${highlightPrevBalance}`} id={`${month}_${guide.id}_prev_balance`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(prevMonthBalance)}</div>
        <div className={`${rowStyle} leftBorder`}></div>
      </Fragment>
    );
    const rowStyle2 = `${rowStyle} topDash`;
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_curmonth`}>
        <div className={`${rowStyle2} colspan-2`}>Current month</div>
        <div className={`${rowStyle2} text-center`}>{month}</div>
        <div className={`${rowStyle2} numeric leftBorder ${highlightCurExpense}`} id={`${month}_${guide.id}_curmonth_expense`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(monthUserTotal_balance)}</div>
        <div className={`${rowStyle2} numeric leftBorder`}></div>
        <div className={`${rowStyle2} numeric leftBorder ${highlightCurBalance}`} id={`${month}_${guide.id}_curmonth_balance`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(curMonthBalance)}</div>
        <div className={`${rowStyle2} leftBorder`}></div>
      </Fragment>
    );
    tablerows.push(
      <Fragment key={`${month}_${guide.id}_salarypayment`}>
        <div className={`${rowStyle2} colspan-2`}>Payment of expenses with salary</div>
        <div className={`${rowStyle2} text-center`}>{isMonthPending ? 'N/A' : dateFormatJpShort(dt_salaryPayment)}</div>
        {!isMonthFinished ? (
          <>
            <div className={`${rowStyle2} numeric leftBorder text-center colspan-4`}>{isMonthPending ? '(unavailable for pending sheets)' : `(unavailable until ${dateFormatJpShort(dt_nextMonthFirstDay)})`}</div>
          </>
        ) : (
          <>
            <div className={`${rowStyle2} leftBorder numeric`}></div>
            <div className={`${rowStyle2} leftBorder numeric ${highlightFinalPayment}`}>{s_paymentAmount}</div>
            <div className={`${rowStyle2} leftBorder numeric ${highlightFinalBalance} ${finalBalanceStyle}`} id={`${month}_${guide.id}_final_balance`} onMouseOver={cellOver} onMouseOut={cellOut}>{formatNum(finalBalance)}</div>
            <div className={`${rowStyle2} leftBorder cellButton`}>
              {paymentButton}
            </div>
          </>
        )}
      </Fragment>
    );
  }

  const hideThisMonth = !selectedMonths.includes(month);

  return (
    <div key={`grid_${month}_${guide.id}`} className={`grid-one-guide-month ${firstLevel2 ? 'topOfLevel1' : 'topOfLevel2'}`}>
      {hideThisMonth ? null : tablerows}
    </div>
  );
}
