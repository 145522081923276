import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { EditableFieldWithBorder } from 'src/components/EditableField/EditableFieldWithBorder';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { UserDetailsType } from 'src/types/types_user';
import { UserRoleMainType, UserRoleOthersEnumType, displayRole, userRolesOtherList } from 'src/util/user_roles';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { saveProfilePhotoBase } from 'src/util/util_saveprofilephoto';

export function UserSettings() {

  const { db, storage, userDetails, _lang } = useAppContext();

  const [displayName, setDisplayName] = useState(userDetails.displayNameEn);
  const [editedCell, setEditedCell] = useState<string | null>(null);


  // *** all hooks above this line ***

  usePageTitle('User Settings');

  const roleList: (UserRoleMainType | UserRoleOthersEnumType)[] = [userDetails.roles?.mainRole ?? 'none'];
  for (const role_other of userRolesOtherList) {
    const val = userDetails.roles?.roleFlags?.[role_other];
    if (!val) continue;
    roleList.push(role_other);
  }

  return (
    <div className='tw-p-8'>

      <h3 className='tw-my-4'>{_lang('User settings', 'ユーザー設定')}</h3>

      <div className='tw-grid tw-grid-cols-[10em,20em] tw-gap-2'>
        <div className='tw-flex tw-items-end'>
          {_lang('Photo', '写真')}
        </div>
        <div>
          <img src={userDetails.photoFileDownloadURL} alt='mdo' width='96' height='96' className='tw-rounded-full' />
        </div>

        <div></div>
        <div>
          <a href='https://myaccount.google.com/' target='_blank' rel='noreferrer'>{_lang('Change', '変更')}</a>
          {' '}
          (<a href='https://support.google.com/mail/answer/35529?hl=en' target='_blank' rel='noreferrer'>{_lang('help', 'ヘルプ')}</a>)
          {' '}
          <button className='tw-border-none tw-bg-transparent tw-text-blue-600 tw-underline' onClick={(e) => {
            e.preventDefault();
            // we assume the photo URL has NOT changed (we reuse the photo URL from last time, as we don't have the fresh user object here)
            saveProfilePhotoBase(db, storage, userDetails.id, userDetails.email, userDetails.photoURL)
              .then(() => {
                alert(_lang(
                  'Profile picture has been refreshed. Please refresh the page in your browser.',
                  'プロフィールの写真がリフレッシュされまいた。ブラウザでこのページをリフレッシュしてください。'
                ));
              });
          }}>{_lang('Refresh', 'リフレッシュ')}</button>
        </div>

        <div>{_lang('E-mail', 'メールアドレス')}</div>
        <div>{userDetails.email}</div>

        <div>{_lang('Name', '氏名')}</div>
        <div>{userDetails.displayName}</div>

        <div>{_lang('Display name', '表示名')}</div>
        <div>
          <EditableFieldWithBorder
            fieldname='displayName'
            validationType=''
            currentValue={displayName}
            isClickableToEdit={true}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            callbackCommitChange={(value) => {
              if (!value)
                return;
              const updateObj: Partial<UserDetailsType> = {
                displayNameEn: value,
                dateModified: serverTimestampAsDate(),
              };
              updateDoc(doc(db, 'users', userDetails.id), updateObj)
                .then((result) => {
                  console.log('updateDoc result', result);
                  setDisplayName(value);
                });
              setEditedCell(null);
            }}
          />
          {displayName !== userDetails.displayNameEn && (
            <div className='tw-bg-red-100 tw-border tw-border-solid tw-border-red-700 tw-rounded tw-p-2 tw-mt-2 tw-text-red-700'>
              Press F5 to reload the app to apply the changes.
            </div>
          )}
        </div>

        {userDetails.roles?.mainRole && userDetails.roles.mainRole !== 'none' && (
          <>
            <div>User roles</div>
            <div>
              {roleList.map(displayRole).join(', ')}

            </div>
          </>
        )}

      </div>
    </div>
  );
}
