import { UserSimpleUidType } from './types_user.js';



export const logkeys = [

  'error.rendering',
  'error.setdberror',

  'tourcalendar.drag_resizer',
  'tourcalendar.show_only_tours',
  'tourcalendar.show_only_guides',
  'tourcalendar.show_both',
  'tourcalendar.center_on_today',
  'tourcalendar.scroll_to_date',
  'tourcalendar.scroll_to_tour',

  'filterpopup.directclick',
  'filterpopup.apply',
  'filterpopup.clear',
  'filterpopup.sortasc',
  'filterpopup.sortdesc',

  'search_tourrequest',
  'tourcalendar.select_year',
  'tourcalendar.set_start_date',
  'tourcalendar.set_end_date',
  'tourcalendar.toggle_edit_mode',
  'tourcalendar.toggle_auto_scroll_dates',
  'tourcalendar.toggle_show_workdays',

  'possible_bug.expense_sheet.inconsistent_status',
  'possible_bug.expense_sheet.field_with_dot',
  'possible_bug.expense_sheet.status_mismatch',

  'db_read.list_users',
  'db_read.settings.emergencyPhoneList',
  'db_read.list_agencies',
  'db_read.list_expense_sheets',
  'db_read.list_explorebookings',
  'db_read.list_tourrequests.where_isexploreseries',
  'db_read.list_payees',
  'db_read.list_tourrequests',

  'db_read.download_file',
  'db_read.open_expense_sheet',
  'db_read.open_general_expense',
  'db_read.list_invoices',
  'db_read.open_payee',
  'db_read.create_tour_request',
  'db_read.open_tour_request',
  'db_read.open_quotegrid_sheet',

  'db_write.general_expense.create',
  'db_write.general_expense.submit',
  'db_write.agency.update',
  'db_write.agency.delete',
  'db_write.manual_accounting_date_override',
  'db_write.expense_sheet.save',
  'db_write.general_expense.update',
  'db_write.invoice.edit',
  'db_write.invoice.add',
  'db_write.invoice.set_status',
  'db_write.invoice.inline_set_payee',
  'db_write.invoice.inline_set_field',
  'db_write.invoice.inline_upload_file',
  'db_write.payee.delete',
  'db_write.payee.save_new',
  'db_write.payee.save_existing',
  'db_write.tourrequest.save_new',
  'db_write.tourrequest.save_existing',
  'db_write.quotation_sheet.delete',
  'db_write.tourrequest.delete',

  'tourcalendar.set_hidden_in_calendar',
  'tourcalendar.drag_and_drop_day',

  'tourcalendar.toureditwindow.change_guide_hours',
  'tourcalendar.toureditwindow.delete_guide',
  'tourcalendar.toureditwindow.clear_all_guides',
  'tourcalendar.toureditwindow.set_guide',
  'tourcalendar.toureditwindow.set_memo',

  'tourcalendar.guide_action.days_off.set',
  'tourcalendar.guide_action.days_off.cancel',
  'tourcalendar.guide_action.calendar_note.set',
  'tourcalendar.guide_action.calendar_note.delete',
  'tourcalendar.guide_action.office_work.set',
  'tourcalendar.guide_action.office_work.cancel',
  'tourcalendar.guide_action.paste',
  'tourcalendar.zoom_horizontal_wider',
  'tourcalendar.zoom_horizontal_narrower',
  'tourcalendar.zoom_vertical_taller',
  'tourcalendar.zoom_vertical_shorter',
  'tourcalendar.zoom_text_larger',
  'tourcalendar.zoom_text_smaller',
  'tourcalendar.toggle_showall',
  'tourcalendar.toggle_changelog',
  'tourcalendar.set_guide_filter',

  'page_path',

  'db_write.update_permissions',
  'db_write.users.set_singleCharacterCode',
  'db_write.users.set_teamName',
  'db_write.users.set_departmentName',
  'db_write.users.set_manager',
  'db_write.users.set_guide_number',
  'db_write.users.set_guide_yearStart',
  'db_write.users.set_guide_paidTimeOff',
  'db_write.users.set_defaultEmergencyPhone',
  'db_write.users.set_freeeNumber',
  'db_write.users.set_freeeName',
  'error.query_not_supported.ineq_with_in',
  'error.query_not_supported.ineq_with_array_contains',
  'filterpopup.open',
  'db_write.agency.rename',
  'db_write.agency.edit.notes',
  'db_write.users.set_displayNameEn',
  'db_write.users.set_displayName',
  'tripquotations.breakdown.btn_cross.click_to_hide',
  'tripquotations.btn_chevron.click_to_hide',
  'tripquotations.btn_chevron.click_to_show',
  'tripquotations.btn_view_breakdown.click_to_hide',
  'tripquotations.btn_view_breakdown.click_to_show',
  'tripquotations.upload_csvs.submit',
  'tripquotations.upload_csvs.cancel',
  'tripquotations.apply_price_hotelroom',
  'tripquotations.apply_price_service',
  'tripquotations.create_snapshot',
  'db_read.open_tripquotation',
  'db_read.open_tripquotation_snapshot',
  'db_write.tripquotations.add_hotel_row',
  'db_write.tripquotations.add_service_row',
  'db_write.tripquotations.delete_hotel_row',
  'db_write.tripquotations.delete_service_row',
  'db_write.tripquotations.assign_request',
  'db_write.tripquotations.copy_gray_box_text',
  'tripquotations.export_to_excel',
  'tripquotations.export_to_excel_snapshot',
  'tripquotations.show_csv_source',
] as const;

export type LogKeyType = typeof logkeys[number] | '';


export type LevelType = 'error' | 'info' | 'possible_bug' | 'db_read' | 'db_write' | 'page_path';

export type LogMessageType = {
  id: string;
  desc: string;
  logkey: LogKeyType;
  level: LevelType;
  dateCreated: Date;
  user: UserSimpleUidType;
  userAs: UserSimpleUidType | null;
  error?: { errorCode: string; errorMessage: string; name: string };
  errorData?: string;
};

