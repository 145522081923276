import { addDoc, collection, Firestore, getCountFromServer, query, where } from 'firebase/firestore';
import { TourRequestType } from 'src/types/types_tourrequest';
import { UserDetailsType } from 'src/types/types_user';
import { utc0_from_iso } from 'src/util/datetools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { isDevMode } from 'src/util/util_getbuildtime';
import { log_db_write } from 'src/util/util_log';
import { getNextRequestNumber } from '../util_tourrequests';



export function addMetadataModifiedTourRequest(updateObj: Partial<TourRequestType>, userDetails: UserDetailsType) {

  // probably never happens
  if (isDevMode() && ('id' in updateObj)) throw new Error('id should not be in the object data');

  delete updateObj.id; // id should NOT be in the object data

  updateObj.dateModified = serverTimestampAsDate();
  updateObj.userModifiedUid = userDetails.id;
  updateObj.userModifiedEmail = userDetails.email;
  updateObj.userModifiedName = userDetails.displayNameEn;
}

export function getTwoCharacterDate(dateiso: string) {
  const date_utc = utc0_from_iso(dateiso);

  const fullYear = date_utc.getUTCFullYear();
  const month = date_utc.getUTCMonth() + 1;
  if (fullYear < 2020 || fullYear > 2040) {
    return null;
  }
  const yearChar = String(fullYear).slice(-1);
  let sMonth = `${month}`;
  if (month === 10) sMonth = 'X';
  else if (month === 11) sMonth = 'Y';
  else if (month === 12) sMonth = 'Z';

  return `${yearChar}${sMonth}`;
}

export async function checkDupesAndSaveNewTourRequest(
  db: Firestore,
  userDetails: UserDetailsType,
  dbObj: TourRequestType,
): Promise<{ error?: string; addedDocId?: string }> {

  // @ts-expect-error delete id
  delete dbObj.id;

  // check requestCode not used just before adding, to ensure no duplicates
  const snap_dupe_requestCode = await getCountFromServer(query(collection(db, 'tourrequests'), where('requestCode', '==', dbObj.requestCode)));
  if (snap_dupe_requestCode.data().count > 0) {
    return { error: `Request code ${dbObj.requestCode} is already used.` };
  }

  // get request number based on current max in database
  dbObj.requestNumber = await getNextRequestNumber(db);

  const addedDoc = await addDoc(collection(db, 'tourrequests'), dbObj);

  console.log(`Created new request with id ${addedDoc.id}`);
  log_db_write({ db, userDetails, logkey: 'db_write.tourrequest.save_new', desc: `Saved NEW tour request to db [${dbObj.requestCode}] [${addedDoc.id}]` });

  return { addedDocId: addedDoc.id };
}
