

interface IconButtonProps {
  icon: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  dataTooltipId?: string;
  dataTooltipHtml?: string;
  disabled?: boolean;
}

export function IconButton({
  icon,
  onClick,
  className,
  dataTooltipId,
  dataTooltipHtml,
  disabled,
}: IconButtonProps) {
  return (
    <button
      disabled={disabled}
      className={`btn btn-outline-primary btn-sm ${className}`}
      onClick={onClick}
      data-tooltip-id={dataTooltipId}
      data-tooltip-html={dataTooltipHtml}
      data-tooltip-place='top'
    ><i className={`bi ${icon}`}></i></button>
  );
}
