import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dateinput.css';


interface DateRangeInputProps {
  startDate: Date | null;
  endDate: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
  onBlur?: () => void;
  disabled?: boolean;
  className?: string;
}

export function DateRangeInput({
  startDate,
  endDate,
  onChange,
  onBlur,
  disabled,
  className,
}: DateRangeInputProps) {

  // react-datepicker assumes dates are 00:00 in user's browser's local time

  return (
    // @t_s-expect-error ReactDatePicker local package cannot be used as a JSX component
    <ReactDatePicker
      dateFormat='yyyy/MM/dd'
      //selected={startDate}
      onChange={onChange}
      //peekNextMonth
      //showMonthDropdown
      //showYearDropdown
      //dropdownMode="select"
      selectsRange
      startDate={startDate ?? undefined}
      endDate={endDate ?? undefined}
      className={'datePickerRange ' // to get extra width
        + (className ?? '')}
      onBlur={onBlur}
      disabled={disabled}
      minDate={new Date(2020, 0, 1)}
      maxDate={new Date(2039, 11, 31)}
    //inline
    />
  );
}
