import { stringify } from 'csv-stringify/browser/esm';
import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { getUserComparer } from 'src/pages/Admin/util_userlist';
import { useUserDetailsList } from 'src/pages/ExpenseSheet/util_getuserlist';
import { CommuteMonthlyType } from 'src/types/types_commute';
import { addMonthsUtc, dateutcFormatStandardMonth } from 'src/util/datetools';
import { userrole_canEditAnyInvoice, userrole_canMarkPaid } from 'src/util/user_roles';
import { convertCommuteMonthlyDates } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { calculateTotals, getDateutcList } from '../util_commutingexpenses';
import './monthlysummaryallemployees.css';



type DuplicateCommuteType = {
  month: string;
  userId: string;
  name: string;
  commuteId1: string;
  commuteId2: string;
};

type OneCellNumbersType = {
  commute: number;
  commute_capped: number;
  otherTransportation: number;
  nonTransportation: number;
  emergencyPhone: number;
  all: number;
  approved: string | null;
};


export function MonthlySummaryAllEmployees() {


  const { db, setDbError, userDetails } = useAppContext();

  const isPaymentIssuer = userrole_canMarkPaid(userDetails.roles);
  // if (!isPaymentIssuer)
  //   throw new Error('Invalid user role')

  const isSeniorInputter = userrole_canEditAnyInvoice(userDetails.roles);
  if (!isSeniorInputter)
    throw new Error('Invalid user role');

  const [hoveredCell, setHoveredCell] = useState<string | null>(null);

  const userList = useUserDetailsList(true);

  const [commuteData, setCommuteData] = useState<Map<string, Map<string, CommuteMonthlyType>>>(); // month => userId => CommuteMonthly
  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      const commuteData = new Map();
      const duplicates: DuplicateCommuteType[] = [];
      for (const doc of snapshot.docs) {
        const commuteMonthly = { ...doc.data(), id: doc.id } as CommuteMonthlyType;
        convertCommuteMonthlyDates(commuteMonthly);
        const sMonth = commuteMonthly.month;
        if (sMonth === 'DEFAULT')
          continue;
        if (!sMonth.match(/^\d{4}-\d{2}$/)) {
          throw new Error(`invalid month: ${sMonth} on commutemonthly ${commuteMonthly.id}`);
        }
        const userId = commuteMonthly.employee.id;
        if (!commuteData.has(sMonth)) {
          commuteData.set(sMonth, new Map());
        }
        const monthData = commuteData.get(sMonth);
        if (monthData.has(userId)) {
          duplicates.push({
            month: sMonth,
            userId: userId,
            name: commuteMonthly.employee.name,
            commuteId1: monthData.get(userId).id,
            commuteId2: commuteMonthly.id,
          });
        }
        monthData.set(userId, commuteMonthly);
      }

      if (duplicates.length > 0) {
        console.log('duplicates', duplicates);
        const errorMessage = `Duplicate CommuteMonthly: ${duplicates.length} duplicates`;
        setDbError(errorMessage);
        throw new Error(errorMessage);
      }

      setCommuteData(commuteData);
    };

    let q = query(collection(db, 'commutemonthlies'));
    if (!isPaymentIssuer) {
      const uidlist = [userDetails.id];
      if (userDetails.directreports) {
        uidlist.push(...Object.keys(userDetails.directreports));
      }
      q = query(q, where('employee.id', 'in', uidlist));
    }
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting commutemonthlies list', err));

    return unsubscribe;

  }, [db, setDbError, userDetails, isPaymentIssuer]);


  const downloadLinkRef = useRef<HTMLAnchorElement>(null);


  // *** all hooks above this line ***

  const title = isPaymentIssuer
    ? 'Summary of all employees commuting expenses'
    : 'Summary of all direct reports commuting expenses';
  usePageTitle(title);

  const loadingSpinner = getLoadingSpinnerOrNull([
    ['user list', userList],
    ['commute data', commuteData],
  ]);
  if (!userList || !commuteData)
    return loadingSpinner;

  let availableUsers;
  if (isPaymentIssuer) {
    availableUsers = userList;
  } else {
    availableUsers = userList.filter((user) => user.id === userDetails.id || (userDetails.directreports && userDetails.directreports[user.id]));
  }

  availableUsers.sort(getUserComparer({ guidesFirst: false }));
  const teams = [...new Set(availableUsers.map((user) => user.teamName))];
  const usersByTeam = new Map(teams.map((team) => [team, availableUsers.filter((user) => user.teamName === team)]));


  const months = Array.from(commuteData.keys()).sort();
  const nextMonths = new Map(months.map((sMonth) => {
    const sNextMonth = dateutcFormatStandardMonth(addMonthsUtc(new Date(`${sMonth}-01`), 1));
    return [sMonth, sNextMonth];
  }));

  const totals = new Map<string, Map<string, OneCellNumbersType | null>>();

  for (const user of availableUsers) {

    const userMap = new Map<string, OneCellNumbersType | null>();
    totals.set(user.id, userMap);

    for (const sMonth of months) {

      const monthData = commuteData.get(sMonth)!;
      const commuteMonthly = monthData.get(user.id);
      if (!commuteMonthly) {
        userMap.set(sMonth, null);
        continue;
      }

      const dateutcList = getDateutcList(sMonth);
      const totals = calculateTotals(dateutcList, commuteMonthly);
      const thisUserMonth: OneCellNumbersType = {
        commute: totals.monthlyTotal_commute,
        commute_capped: totals.monthlyTotal_commute_capped,
        otherTransportation: totals.monthlyTotal_nonCommuteTransportation,
        nonTransportation: totals.monthlyTotal_nonCommuteOther,
        emergencyPhone: totals.monthlyTotal_emergencyPhone,
        all: totals.monthlyTotal_all,
        approved: commuteMonthly.status === 'APPROVED' ? commuteMonthly.userApproved.name : null,
      };

      userMap.set(sMonth, thisUserMonth);

      const doubleCheck = thisUserMonth.commute_capped + thisUserMonth.otherTransportation + thisUserMonth.nonTransportation + thisUserMonth.emergencyPhone;
      if (doubleCheck !== totals.monthlyTotal_all) {
        throw new Error('inconsistent totals');
      }

    }
  }


  return (
    <div className='container-fluid'>
      <h2 className='my-4'>{title}</h2>

      <a ref={downloadLinkRef} href='' download='filename.csv' style={{ display: 'none' }}></a>

      <div className='tableMonthlySummaryAllEmployees'>
        <table className='table tableMonthlySummaryAllEmployees' style={{ width: 'auto' }}>
          <thead>
            <tr>
              <th>
                Incurred →
              </th>
              {months.map((sMonth, iMonth) => {
                return (
                  <th key={sMonth} style={{ textAlign: 'center' }} className={iMonth % 2 === 0 ? 'even' : 'odd'}>
                    {sMonth}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th style={{ color: '#999' }}>
                Paid →
              </th>
              {months.map((sMonth, iMonth) => {
                return (
                  <th key={sMonth} style={{ textAlign: 'center', color: '#999' }} className={iMonth % 2 === 0 ? 'even' : 'odd'}>
                    {nextMonths.get(sMonth)}-15
                  </th>
                );
              })}
            </tr>
            {isPaymentIssuer && (
              <tr>
                <th>
                  CSV for Freee
                </th>
                {months.map((sMonth, iMonth) => {
                  return (
                    <th key={sMonth} className={iMonth % 2 === 0 ? 'even' : 'odd'}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '0em', flexDirection: 'column' }}>
                        <div>
                          <ButtonTW variant='bsOrange' onClick={() => {

                            const columns = {
                              freeeNumber: '従業員番号',
                              freeeName: '従業員名（編集しても反映されません）',
                              timing: '支給タイミング（給与（毎月）/給与（1回）/賞与）',
                              allowanceName: '手当名',
                              allowanceAmount: '手当金額',
                              recomputeHourlyWage: '1時間あたりの割増賃金を再計算する（個別の勤務時間設定を行なっている社員のみ対象になります）（する/空欄）',
                              recomputeExemption: '1時間あたりの勤怠控除を再計算する（個別の勤務時間設定を行なっている社員のみ対象になります）（する/空欄）',
                            };

                            const rows = [];
                            const errors: string[] = [];

                            for (const user of userList) {
                              const userMonthTotals = totals.get(user.id)!.get(sMonth);
                              if (!userMonthTotals)
                                continue;

                              if (!user.freeeNumber)
                                errors.push(`${user.displayNameEn}: Freee number missing`);
                              if (!user.freeeName)
                                errors.push(`${user.displayNameEn}: Freee name missing`);

                              if (userMonthTotals.commute_capped) {
                                const row = {
                                  freeeNumber: user.freeeNumber,
                                  freeeName: user.freeeName,
                                  timing: '給与（毎月）',
                                  allowanceName: '通勤交通費',
                                  allowanceAmount: userMonthTotals.commute_capped,
                                  recomputeHourlyWage: '',
                                  recomputeExemption: '',
                                };
                                rows.push(row);
                              }

                              if (userMonthTotals.otherTransportation) {

                                const row = {
                                  freeeNumber: user.freeeNumber,
                                  freeeName: user.freeeName,
                                  timing: '給与（毎月）',
                                  allowanceName: 'その他旅費交通費',
                                  allowanceAmount: userMonthTotals.otherTransportation,
                                  recomputeHourlyWage: '',
                                  recomputeExemption: '',
                                };
                                rows.push(row);
                              }

                              if (userMonthTotals.nonTransportation) {
                                // should always be zero
                                const row = {
                                  freeeNumber: user.freeeNumber,
                                  freeeName: user.freeeName,
                                  timing: '給与（毎月）',
                                  allowanceName: '???',
                                  allowanceAmount: userMonthTotals.nonTransportation,
                                  recomputeHourlyWage: '',
                                  recomputeExemption: '',
                                };
                                rows.push(row);
                                throw new Error('non zero non-transportation expense');
                              }

                              if (userMonthTotals.emergencyPhone) {
                                const row = {
                                  freeeNumber: user.freeeNumber,
                                  freeeName: user.freeeName,
                                  timing: '給与（毎月）',
                                  allowanceName: 'Emergency Phone',
                                  allowanceAmount: userMonthTotals.emergencyPhone,
                                  recomputeHourlyWage: '',
                                  recomputeExemption: '',
                                };
                                rows.push(row);
                              }

                            } // each user

                            if (errors.length > 0) {
                              window.alert(`Warning: the following errors were detected:\n\n${errors.join('\n')}`);
                            }

                            stringify(
                              rows,
                              {
                                header: true,
                                columns: columns,
                                // cast: {
                                //   // https://stackoverflow.com/questions/45302027/node-csv-stringify-format-timestamp-column
                                //   date: (value: any) => dateFormatCsvDate(value),
                                //   boolean: (value: any) => value ? 'TRUE' : 'FALSE',
                                // },
                              },
                              (err: any, output: any) => {
                                if (err) throw err;
                                // console.log('output', output)

                                // https://stackoverflow.com/a/44661948/
                                const element = downloadLinkRef.current!;
                                // include the UTF-8 BOM as it is requred for Excel to treat the csv as UTF-8
                                const file = new Blob(['\ufeff', output], { type: 'text/csv' });
                                element.href = URL.createObjectURL(file);
                                element.download = `Portal_output_従業員手当一括インポート用テンプレート_incurred_${sMonth}_paid_${nextMonths.get(sMonth)}.csv`;
                                // document.body.appendChild(element); // Required for this to work in FireFox
                                element.click();

                              });

                          }}>
                            CSV
                            <i className='bi bi-download'></i>
                          </ButtonTW>
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            <tr>
              <td></td>
              {months.map((sMonth, iMonth) => {
                return (
                  <td key={sMonth} className={iMonth % 2 === 0 ? 'even' : 'odd'}></td>
                );
              })}
            </tr>
            {teams.map((team) => {

              if (!team || team === 'Other')
                return;

              return (
                <React.Fragment key={team}>
                  <tr className='commuteSummaryTeamNameRow'>
                    <td colSpan={1 + months.length}>{team}</td>
                  </tr>
                  {usersByTeam.get(team)!.map((user) => {

                    return (
                      <tr key={user.id}>
                        <td>{user.displayNameEn}</td>
                        {months.map((sMonth, iMonth) => {
                          const cellContents = [];
                          const userMonthTotals = totals.get(user.id)!.get(sMonth);
                          if (userMonthTotals) {
                            if (userMonthTotals.approved) {
                              cellContents.push(
                                <div key='approved' style={{
                                  border: '1px solid green',
                                  padding: '0.2em',
                                  borderRadius: '0.2em',
                                  backgroundColor: '#e0ffe0',
                                }}>✅ Approved by {userMonthTotals.approved}</div>
                              );
                            }
                            if (userMonthTotals.commute) {
                              if (userMonthTotals.commute_capped !== userMonthTotals.commute) {
                                cellContents.push(<div key='commute'>🚃 <s>{formatNum(userMonthTotals.commute)}</s> {formatNum(userMonthTotals.commute_capped)}</div>);
                              } else {
                                cellContents.push(<div key='commute'>🚃 {formatNum(userMonthTotals.commute_capped)}</div>);
                              }
                            }
                            if (userMonthTotals.otherTransportation) {
                              cellContents.push(<div key='otherTransportation'>🚄 {formatNum(userMonthTotals.otherTransportation)}</div>);
                            }
                            if (userMonthTotals.nonTransportation) {
                              // should always be zero
                              cellContents.push(<div key='nonTransportation'>❓ {formatNum(userMonthTotals.nonTransportation)}</div>);
                            }
                            if (userMonthTotals.emergencyPhone) {
                              cellContents.push(<div key='emergencyPhone'>📱 {formatNum(userMonthTotals.emergencyPhone)}</div>);
                            }
                          }
                          return (
                            <td key={sMonth} className={iMonth % 2 === 0 ? 'even' : 'odd'} onMouseEnter={() => setHoveredCell(`${user.id}_${sMonth}`)} onMouseLeave={() => setHoveredCell(null)}>
                              {cellContents}
                              <div style={{ visibility: (hoveredCell === `${user.id}_${sMonth}`) ? undefined : 'hidden' }}>
                                <Link to={`/commute/?employeeId=${user.id}&month=${sMonth}`} style={{ textDecoration: 'none' }}>
                                  <i className='bi bi-table'></i> Open<br /> monthly sheet
                                </Link>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

    </div>
  );
}
