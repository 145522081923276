import React, { CSSProperties } from 'react';



interface InputTWProps {
  type: 'text';
  style?: CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
}

export function InputTW({
  type,
  style,
  onChange,
  onKeyDown,
  value,
}: InputTWProps) {


  return (
    <input
      className='
      tw-px-3 tw-py-1.5 tw-w-full
      tw-border tw-border-solid tw-border-slate-300 tw-rounded-md
      focus:tw-outline-none
      focus:tw-ring focus:tw-ring-blue-200
      focus:tw-border focus:tw-border-solid focus:tw-border-blue-500
      '
      type={type}
      style={style}
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
    />
  );
}
