import { deleteField } from 'firebase/firestore';
import { QuotegridLineItemTypeUnion, QuotegridOverallType } from 'src/types/types_quotegrid';
import { arraySum } from 'src/util/util_misc';


export const NEW_ROW_ID = '*** NEW ROW ID ***';

export function calculateSameRowValues(
  item: QuotegridLineItemTypeUnion, // current value, before modification
  field: string,
  dbvalue: any,
  updateObj: Record<string, any>,
  itemModified: Record<string, any>,
) {

  // this function calculates values within the same row as the edited cell.
  // e.g. calculate totalPrice from unitPrice * unitCount, etc.

  // this is only called when editing an existing lineItem.
  // (when creating a new lineItem, updateObj structure is different so the below code won't work.
  // anyway the below code only does anything if at least 2 fields are populated with numbers,
  // so it will never do anything on a newly created lineItem as it would only have 1 field populated.)


  const setField = (field: string, value: number | null) => {
    // we update updateObj, to write to the db:
    updateObj[`${item.id}.${field}`] = value;
    updateObj[`${item.id}.${field}_formula`] = deleteField();
    // we update itemModified, so that locally we can calculate the quotegrid total cost:
    itemModified[field] = value;
    delete itemModified[`${field}_formula`];
  };

  if (item.itemType === 'transportation') {
    if (field === 'transportation_unitPrice' && item.transportation_unitCount !== null) {
      setField('totalPrice', dbvalue * item.transportation_unitCount);
    }
    if (field === 'transportation_unitCount' && item.transportation_unitPrice !== null) {
      setField('totalPrice', item.transportation_unitPrice * dbvalue);
    }
    if (field === 'totalPrice' && item.transportation_unitCount) {
      setField('transportation_unitPrice', dbvalue / item.transportation_unitCount);
    }
  }
  if (item.itemType === 'accommodation') {
    if (field === 'room_pricePerPerson' && item.room_numberOfPaxPerRoom !== null) {
      setField('totalPrice', dbvalue * item.room_numberOfPaxPerRoom);
    }
    if (field === 'room_numberOfPaxPerRoom' && item.room_pricePerPerson !== null) {
      setField('totalPrice', item.room_pricePerPerson * dbvalue);
    }
    if (field === 'totalPrice' && item.room_numberOfPaxPerRoom) {
      setField('room_pricePerPerson', dbvalue / item.room_numberOfPaxPerRoom);
    }
  }
  if (item.itemType === 'tickets') {

    let priceAdults = 0;
    if (item.ticket_pricePerAdult !== null && item.ticket_numberOfAdults !== null) {
      priceAdults = item.ticket_pricePerAdult * item.ticket_numberOfAdults;
    }

    let priceChildren = 0;
    if (item.ticket_pricePerChild !== null && item.ticket_numberOfChildren !== null) {
      priceChildren = item.ticket_pricePerChild * item.ticket_numberOfChildren;
    }

    if (field === 'ticket_pricePerAdult' && item.ticket_numberOfAdults !== null) {
      const priceAdults = dbvalue * item.ticket_numberOfAdults;
      setField('totalPrice', priceAdults + priceChildren);
    }
    if (field === 'ticket_numberOfAdults' && item.ticket_pricePerAdult !== null) {
      const priceAdults = item.ticket_pricePerAdult * dbvalue;
      setField('totalPrice', priceAdults + priceChildren);
    }
    if (field === 'ticket_pricePerChild' && item.ticket_numberOfChildren !== null) {
      const priceChildren = dbvalue * item.ticket_numberOfChildren;
      setField('totalPrice', priceAdults + priceChildren);
    }
    if (field === 'ticket_numberOfChildren' && item.ticket_pricePerChild !== null) {
      const priceChildren = item.ticket_pricePerChild * dbvalue;
      setField('totalPrice', priceAdults + priceChildren);
    }
    if (field === 'totalPrice') {
      if (item.ticket_numberOfAdults && item.ticket_numberOfChildren) {
        // can't calculate
        setField('ticket_pricePerAdult', null);
        setField('ticket_pricePerChild', null);
      }
      if (item.ticket_numberOfAdults && !item.ticket_numberOfChildren) {
        setField('ticket_pricePerAdult', dbvalue / item.ticket_numberOfAdults);
      }
      if (!item.ticket_numberOfAdults && item.ticket_numberOfChildren) {
        setField('ticket_pricePerChild', dbvalue / item.ticket_numberOfChildren);
      }
    }
  }

  if (item.itemType === 'guide') {
    const priceAccommodation = item.guide_accommodation || 0;
    const priceTransportation = item.guide_transportation || 0;
    const priceMeals = item.guide_meals || 0;
    const numHours = item.guide_numberOfHours || 0;
    const pricePerHour = item.guide_pricePerHour || 0;
    const inputNum = dbvalue || 0;

    if (field === 'guide_accommodation') {
      setField('totalPrice', inputNum + priceTransportation + priceMeals + numHours * pricePerHour);
    }
    if (field === 'guide_transportation') {
      setField('totalPrice', priceAccommodation + inputNum + priceMeals + numHours * pricePerHour);
    }
    if (field === 'guide_meals') {
      setField('totalPrice', priceAccommodation + priceTransportation + inputNum + numHours * pricePerHour);
    }
    if (field === 'guide_numberOfHours') {
      setField('totalPrice', priceAccommodation + priceTransportation + priceMeals + inputNum * pricePerHour);
    }
    if (field === 'guide_pricePerHour') {
      setField('totalPrice', priceAccommodation + priceTransportation + priceMeals + numHours * inputNum);
    }
    if (field === 'totalPrice' && item.guide_numberOfHours) {
      setField('guide_pricePerHour', (inputNum - (priceAccommodation + priceTransportation + priceMeals)) / item.guide_numberOfHours);
    }
  }

}

export function calculateQuotegridOverallNumbers(overallClone: QuotegridOverallType, lineItemsClone: { totalPrice: number | null }[], updateObj: Record<string, any>) {
  const markupPercent = overallClone.ourMarkupPercent ?? 0;
  const commissionPercent = overallClone.agentCommissionPercent ?? 0;
  if (commissionPercent >= 100) throw new Error(`Commission must be less than 100%, got ${commissionPercent}`);

  const markupFraction = markupPercent / 100;
  const commissionFraction = commissionPercent / 100;


  const total = arraySum(lineItemsClone.map((item) => Number(item.totalPrice ?? 0)));

  let ourMarkupAmount;
  if (overallClone.ourMarkupIsOnGross) {
    ourMarkupAmount = Math.round(total * markupFraction / (1 - markupFraction));
  } else {
    ourMarkupAmount = Math.round(total * markupFraction);
  }


  const withMarkup = total + ourMarkupAmount;

  //                X            X * comPc
  // comAmnt =  --------- - X  = ---------
  //            1 - comPc        1 - comPc

  let agentCommissionAmount;
  if (overallClone.agentCommissionIsOnGross) {
    agentCommissionAmount = Math.round(withMarkup * commissionFraction / (1 - commissionFraction));
  } else {
    agentCommissionAmount = Math.round(withMarkup * commissionFraction);
  }

  const withCommission = withMarkup + agentCommissionAmount;


  updateObj['overall.totalPrice'] = total;

  updateObj['overall.ourMarkupAmount'] = ourMarkupAmount;
  updateObj['overall.totalPriceIncMarkup'] = withMarkup;

  updateObj['overall.agentCommissionAmount'] = agentCommissionAmount;
  updateObj['overall.totalPriceIncCommission'] = withCommission;
}
