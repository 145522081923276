import { LoadingSpinner } from './LoadingSpinner';



export function getLoadingSpinnerOrNull(list: [string, any][]) {
  // this can't be defined as a react component because even when we return null,
  // the caller will get a non-null component with no rendered content instead of
  // null, so it cannot determine whether it should move to render itself or not.

  // list must be list of 2-item arrays:
  // [
  //   ['invoices', listInvoices],
  //   ['supplier', payeeObj],
  //   ...
  // ]


  // console.log('LoadingSpinnerOrNull', list)

  const notYetLoaded = [];
  for (const [name, value] of list) {
    if (!value)
      notYetLoaded.push(name);
  }

  // console.log('notYetLoaded.length', notYetLoaded.length)

  if (notYetLoaded.length === 0)
    return null;

  return (
    <LoadingSpinner list={notYetLoaded} />
  );

}
