

export function H_ExpenseRules() {

  return (
    <>

      <h5 className='mt-4'>Expense rules</h5>

      <div className='rules-table'>

        <div style={{ gridArea: 'head' }} className='rules-title'>Expense Rules</div>

        <div style={{ gridArea: 'meals' }}>Meal allowance</div>
        <div style={{ gridArea: 'break' }}>Breakfast</div>
        <div style={{ gridArea: 'break_amnt' }}>No decided amount (up to 1,000 yen)</div>
        <div style={{ gridArea: 'break_amnt_1' }}>Give the receipt to the office when adding your breakfast in the expense sheet</div>
        <div style={{ gridArea: 'break_appl' }}>This will be applied when:</div>
        <div style={{ gridArea: 'break_appl_1' }}>your accommodation doesn’t have breakfast included</div>
        <div style={{ gridArea: 'break_appl_2' }}>the departure time is earlier than breakfast time at your accommodation</div>
        <div style={{ gridArea: 'break_appl_3' }}>you are not staying at a hotel and your tour starts at 7am or eaerlier</div>
        <div style={{ gridArea: 'break_nota' }}>This won’t be applied when:</div>
        <div style={{ gridArea: 'break_nota_1' }}>your hotel is booked with breakfast included</div>
        <div style={{ gridArea: 'lunch' }}>Lunch</div>
        <div style={{ gridArea: 'lunch_amnt' }}>1,500 yen per day</div>
        <div style={{ gridArea: 'lunch_appl' }}>This will be applied when:</div>
        <div style={{ gridArea: 'lunch_appl_1' }}>your tour goes over lunch time</div>
        <div style={{ gridArea: 'lunch_nota' }}>This won’t be applied when:</div>
        <div style={{ gridArea: 'lunch_nota_1' }}>the tour starts in the afternoon</div>
        <div style={{ gridArea: 'lunch_nota_2' }}>the tour ends before lunch</div>
        <div style={{ gridArea: 'lunch_nota_3' }}>the lunch is included in the tour or accommodation covered by the company</div>
        <div style={{ gridArea: 'lunch_nota_4' }}>you are forced to use a meal option that significantly exceeds the allowance, for example the dinner at Takayama during the “Simply Japan” tour (submit the receipt as expense instead)</div>
        <div style={{ gridArea: 'dinne' }}>Dinner</div>
        <div style={{ gridArea: 'dinne_amnt' }}>2,000 yen per night</div>
        <div style={{ gridArea: 'dinne_appl' }}>This will be applied when:</div>
        <div style={{ gridArea: 'dinne_appl_1' }}>your tour requires you to stay in a hotel</div>
        <div style={{ gridArea: 'dinne_appl_2' }}>your tour ends at 9PM or later</div>
        <div style={{ gridArea: 'dinne_nota' }}>This won’t be applied when:</div>
        <div style={{ gridArea: 'dinne_nota_1' }}>the dinner is included in the tour or accommodation covered by the company</div>
        <div style={{ gridArea: 'dinne_nota_2' }}>you are forced to use a meal option that significantly exceeds the allowance, for example the dinner at Takayama during the “Simply Japan” tour (submit the receipt as expense instead)</div>
        <div style={{ gridArea: 'other' }}>Other Expenses</div>
        <div style={{ gridArea: 'daily' }}>Daily budget for tours</div>
        <div style={{ gridArea: 'daily_amnt' }}>The amount will be decided depending on the tour</div>
        <div style={{ gridArea: 'daily_amnt_1' }}>Explore allows 3,000 yen per day</div>
        <div style={{ gridArea: 'daily_appl' }}>This will be applied when:</div>
        <div style={{ gridArea: 'daily_appl_1' }}>customers had an emergency situation where you can’t wait for the company operation team’s permission</div>
        <div style={{ gridArea: 'daily_appl_2' }}>company operation team agrees on its spending</div>
        <div style={{ gridArea: 'accom' }}>Accommodation</div>
        <div style={{ gridArea: 'accom_appl' }}>If you are to book your own accommodation for your tour:</div>
        <div style={{ gridArea: 'accom_appl_1' }}>the budget for accommodation is 8,000 yen for low season and 10,000 yen for peak season (Japanese holiday season such Golden week and Obon, March, April, October and November)</div>
        <div style={{ gridArea: 'perso' }}>Personal expenses</div>
        <div style={{ gridArea: 'perso_appl' }}>Not allowed except:</div>
        <div style={{ gridArea: 'perso_appl_1' }}>Laundry expense for tours that last 3 nights or more. Laundry needs to be done in coin laundries or one in accommoation</div>
        <div style={{ gridArea: 'trans' }}>Transport</div>
        <div style={{ gridArea: 'trans_1' }}>Use public transport unless there is an exceptional circumstance</div>
        <div style={{ gridArea: 'trans_2' }}>Use economy class/ordinary class for express trains, Shinkansen or flights</div>
      </div>

    </>
  );
}
