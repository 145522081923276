import { FileDownloadUploadButtons } from 'src/components/FileDownloadUpload/FileDownloadUploadButtons';
import { GeneralExpenseRowType } from 'src/types/types_generalexpense';
import { FileStoragePathAndDownloadUrlType } from 'src/types/types_invoices';


interface GeneralExpenseReceiptsProps {
  isReadOnly: boolean;
  isExpandable: boolean;
  row: GeneralExpenseRowType;
  tableid: string;
  callbackUploadSuccess: ((uploadedFilePaths: FileStoragePathAndDownloadUrlType[]) => void) | null;
  handleDeleteFile: ((file: FileStoragePathAndDownloadUrlType) => void) | null;
  generalExpenseIdForLog: string;
}

export function GeneralExpenseReceipts({
  isReadOnly,
  isExpandable,
  row,
  tableid,
  callbackUploadSuccess,
  handleDeleteFile,
  generalExpenseIdForLog,
}: GeneralExpenseReceiptsProps) {


  return (
    <FileDownloadUploadButtons
      isReadOnly={isReadOnly}
      isExpandable={isExpandable}
      listFiles={row?.receipts}
      uploadButtonId={`${tableid}_${row?.id}`}
      storageBaseFolder={'receipts'}
      callbackUploadSuccess={callbackUploadSuccess}
      loginfo={`general expense ${generalExpenseIdForLog}`}
      handleDeleteFile={handleDeleteFile}
    />
  );
}
