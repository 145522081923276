import { IconButton } from './IconButton';


interface AddButtonProps {
  onClick: () => void;
  className?: string;
  dataTooltipId?: string;
  dataTooltipHtml?: string;
  disabled?: boolean;
}

export function AddButton({
  onClick,
  className,
  dataTooltipId,
  dataTooltipHtml,
  disabled,
}: AddButtonProps) {
  return (
    <IconButton
      icon='bi-plus-circle'
      onClick={onClick}
      className={className}
      dataTooltipId={dataTooltipId}
      dataTooltipHtml={dataTooltipHtml}
      disabled={disabled}
    />
  );
}
