import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { isTestDb } from 'src/config/config-select';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeType } from 'src/types/types_payee';
import { UserSimpleTeamType } from 'src/types/types_user';



export const calendarGuideTeamList = ['Eighty Days Tour Leaders', 'Eighty Days Other', 'Freelance Guides'] as const;
export type CalendarGuideTeamType = typeof calendarGuideTeamList[number];

export type UserSimpleCalendarGuideType = {
  id: string;
  email: string;
  name: string;
  teamName: CalendarGuideTeamType;
};


type FreelanceGuideType = {
  // id, email, name, teamName are required for TypeaheadUserList
  id: string;
  email: string;
  name: string;
  teamName: 'Freelance Guides';
  payeeId: string;
};

/**
 * @returns List of guides (not sorted), including:
 *     - 80days guides
 *     - 80days other employees, inc. office employees
 *     - Freelance guides
 */
export function useGuideList(
  userListSimple: UserSimpleTeamType[] | undefined,
  viewAsTravelDesignerOrBetter: boolean,
  viewAsExecutiveGuideOrBetter: boolean,
): UserSimpleCalendarGuideType[] | undefined {

  const { db, setDbError, userDetails } = useAppContext();


  const [freelanceGuides, setFreelanceGuides] = useState<FreelanceGuideType[]>();
  useEffect(() => {
    if (!viewAsTravelDesignerOrBetter) {
      setFreelanceGuides([]);
      return;
    }

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const list: FreelanceGuideType[] = [];
      for (const doc of snapshot.docs) {
        const payee = { ...doc.data(), id: doc.id } as PayeeType;
        const freelanceGuide: FreelanceGuideType = {
          id: `payee:${doc.id}`,
          email: `payee:${doc.id}`,
          name: payee.nameEnMain,
          teamName: 'Freelance Guides',
          payeeId: doc.id,
        };
        list.push(freelanceGuide);
      }
      setFreelanceGuides(list);
    };

    const q = query(collection(db, 'payees'), where('_isDeleted', '==', false), where('isFreelanceGuide', '==', true));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting freelance guides list', err));

    return unsubscribe;

  }, [db, setDbError, viewAsTravelDesignerOrBetter]);


  const guideList = useMemo<UserSimpleCalendarGuideType[] | undefined>(() => {

    if (!viewAsExecutiveGuideOrBetter) {
      const userGuide: UserSimpleCalendarGuideType = {
        id: userDetails.id,
        email: userDetails.email,
        name: userDetails.displayNameEn,
        teamName: 'Eighty Days Tour Leaders',
      };
      return [userGuide];
    }


    if (!userListSimple || !freelanceGuides)
      // still loading
      return undefined;


    const userListGuides = userListSimple
      .filter((user) => {
        if (viewAsTravelDesignerOrBetter) {
          return user.teamName
            && user.teamName !== 'Former employees'
            && user.teamName !== 'External accountant'
            && (isTestDb() || user.teamName !== 'Other');
        } else {
          // executive guide
          return user.teamName === 'Guide';
        }
      })
      .map((user) => {
        const userGuide: UserSimpleCalendarGuideType = {
          ...user,
          teamName: user.teamName === 'Guide' ? 'Eighty Days Tour Leaders' : 'Eighty Days Other',
        };
        return userGuide;
      });

    const guideList = [...userListGuides, ...freelanceGuides];

    return guideList;
  }, [freelanceGuides, userListSimple, viewAsTravelDesignerOrBetter, viewAsExecutiveGuideOrBetter, userDetails]);

  return guideList;
}
