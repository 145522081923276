import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { BookingStatusType, TripQuotationType } from 'src/types/types_tripquotations';
import { dateFormatCompact, dateFormatExcelExport } from 'src/util/dateformattools';
import { utc0_from_iso } from 'src/util/datetools';
import { eightyDaysLogo } from './eightyDaysLogo';

const lightGrey = '#eeeeee';
const darkGrey = '#9e9e9e';
const barRateLightRed = '#ffdada';
const headerLightBlue = '#dbeff5';
const lightBlue = '#b5e0ec';
const statusYellow = '#ffe683';
const statusBlue = '#7bb7e6';
const statusGreen = '#6fcf62';

export const statusColors: Record<BookingStatusType, string> = {
  'RQ': statusYellow,
  'RB': statusBlue,
  'CF': statusGreen,
};

const dateFormat = '[$-409]d-mmm-yyyy';

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
/**
 * Converts a number into letter
 */
const ntl = (num: number) => alphabet[num - 1];

const addHeader = (worksheet: ExcelJS.Worksheet, columns: (string | null)[], rowNumber: number, startCell: number = 0) => {
  columns.forEach((colValue, index) => {
    const cell = worksheet.getCell(rowNumber, 1 + index + startCell);
    if (colValue) {
      cell.value = colValue;
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: headerLightBlue.slice(1) },
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    }
  });
};

const lightFill: ExcelJS.Fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: lightBlue.slice(1) },
};

const titleFont: Partial<ExcelJS.Font> = {
  name: 'Calibri',
  size: 14,
};
const defaultFont: Partial<ExcelJS.Font> = {
  name: 'Calibri',
  size: 11,
};
const boldFont: Partial<ExcelJS.Font> = {
  name: 'Calibri',
  size: 11,
  bold: true,
};

const lightBorderStyle: Partial<ExcelJS.Border> = { style: 'thin', color: { argb: lightGrey.slice(1) } };
const darkBorderStyle: Partial<ExcelJS.Border> = { style: 'thin', color: { argb: darkGrey.slice(1) } };

const tableCellBorder: Partial<ExcelJS.Borders> = { top: darkBorderStyle, left: lightBorderStyle, bottom: darkBorderStyle, right: lightBorderStyle };

const numberFormat = '#,##0';

const titleCellStyle: Partial<ExcelJS.Style> = {
  font: titleFont,
  fill: lightFill,
  alignment: { vertical: 'middle', horizontal: 'center' },
};

export const getMealPlan = (breakfastIncluded: boolean, dinnerIncluded: boolean) => {
  if (breakfastIncluded && dinnerIncluded) {
    return 'HBD';
  } else if (breakfastIncluded) {
    return 'ABF';
  } else if (dinnerIncluded) {
    // should never happen
    return '??';
  } else {
    return '-';
  }
};

export const exportToExcel = (quotation: TripQuotationType) => {

  const { generalInfo, serviceList, hotelList, passengerList } = quotation;

  const workbook = new ExcelJS.Workbook();
  workbook.creator = '80Days';
  workbook.created = new Date();

  const worksheet = workbook.addWorksheet('Quotation', { views: [{ showGridLines: false }] });

  const mergeHorizontal = (row: number, colFrom: number, colTo: number) => {
    worksheet.mergeCells(row, colFrom, row, colTo);
  };


  const columnsAccommodations = ['Check-in date', 'Check-out date', 'Nights', 'Name', 'Room type', 'No. of Rooms', 'Meals', 'Pax', 'Price per pax per night', 'Total', null, 'Status', 'Remarks'];
  const colhotel_1_checkin = 1;
  const colhotel_2_checkout = 2;
  const colhotel_3_numnights = 3;
  const colhotel_4_hotelname = 4;
  const colhotel_5_details = 5;
  const colhotel_7_meals = 7;
  const col_8_numpax = 8;
  const col_9_priceperpax = 9;
  const col_10_total = 10;
  const col_12_status = 12;

  const columnsServices = ['Date', 'Service Type', 'Service Name', null, 'Details', null, null, 'Pax', 'Price per pax', 'Total', null, 'Status', 'Remarks'];
  const colserv_1_date = 1;
  const colserv_2_type = 2;
  const colserv_3_servicename = 3;
  const colserv_4_servicename_end = 4;
  const colserv_5_details = 5;
  const colserv_7_details_end = 7;

  const bigRowHeight = 29; // based on Windows version of Excel using Calibri font size 11pt with 2 lines of text

  const colWidths = [20, 20, 8, 32, 32, 8, 8, 8, 12, 12, 8, 8, 100];
  colWidths.forEach((width, index) => worksheet.getColumn(1 + index).width = width);

  const {
    agencyItineraryRefWithVersion,
    agencyOwner,
    agencyBusinessUnit,
    agencyPrimaryContact,
    paxName,
    numOfPax,
    tripStartDateiso,
    tripEndDateiso,
    tripDurationDays,
  } = generalInfo;

  let travelDates = '';
  if (tripStartDateiso) {
    const formatedStartDate = dateFormatExcelExport(tripStartDateiso);
    const formatedEndDate = tripEndDateiso ? dateFormatExcelExport(tripEndDateiso) : '???';
    travelDates = `${formatedStartDate} to ${formatedEndDate}`;
  }

  const numOfPaxCell = 'E2';
  const row1 = worksheet.addRow(['Audley Reference', agencyItineraryRefWithVersion, null, 'Client Name', paxName]);
  const row2 = worksheet.addRow(['Audley Owner', agencyOwner, null, '# of pax', numOfPax]);
  const row3 = worksheet.addRow(['Business Unit', agencyBusinessUnit, null, 'Travel Dates', travelDates]);
  const row4 = worksheet.addRow(['Primary Contact', agencyPrimaryContact, null, '# of days', tripDurationDays]);
  [row1, row2, row3, row4].forEach(row => {
    row.getCell(1).fill = lightFill;
    row.getCell(4).fill = lightFill;
  });
  row2.getCell(5).alignment = { horizontal: 'left' };
  row4.getCell(5).alignment = { horizontal: 'left' };
  worksheet.addRow(null); // row5
  const row6 = worksheet.addRow(['Eighty Days PIC', quotation.usersDesigners[0]?.name]);
  const row7 = worksheet.addRow(['Eighty Days Tour Code', quotation.requestCode]);
  [row6, row7].forEach(row => {
    row.getCell(1).fill = lightFill;
  });
  worksheet.addRow(null); // row8

  if (passengerList) {

    const firstChunk = passengerList.slice(0, 7);
    const secondChunk = passengerList.slice(7, 14);

    firstChunk.forEach((passenger, index) => {
      const rowNumber = index + 1;
      const leftCell = worksheet.getCell(rowNumber, 6);
      if (rowNumber === 1) leftCell.value = 'Pax list';
      leftCell.fill = lightFill;

      const rightCell = worksheet.getCell(rowNumber, 7);
      const birthDate = passenger.dateOfBirth ? ` (${passenger.dateOfBirth})` : '';
      rightCell.value = `${passenger.firstName} ${passenger.lastName}${birthDate}`;
    });
    secondChunk.forEach((passenger, index) => {
      const cell = worksheet.getCell(index + 1, 11);
      const birthDate = passenger.dateOfBirth ? ` (${passenger.dateOfBirth})` : '';
      cell.value = `${passenger.firstName} ${passenger.lastName}${birthDate}`;
    });
    if (passengerList.length > 14) {
      // if not all names can be shown, explicitly tell the user with '...'
      worksheet.getCell(7, 11).value = '...';
    }
  }

  const hotelTitleRowNumber = worksheet.lastRow!.number + 1;
  mergeHorizontal(hotelTitleRowNumber, colhotel_1_checkin, col_10_total);
  const hotelTitleCell = worksheet.getCell(hotelTitleRowNumber, 1);
  hotelTitleCell.value = 'Accommodations';
  hotelTitleCell.style = titleCellStyle;
  worksheet.getRow(hotelTitleRowNumber).height = bigRowHeight;

  const hotelHeaderRowNumber = hotelTitleRowNumber + 1;
  addHeader(worksheet, columnsAccommodations, hotelHeaderRowNumber);
  worksheet.getRow(hotelHeaderRowNumber).height = bigRowHeight;

  const hotelsFirstRowNumber = hotelHeaderRowNumber + 1;

  type FromTo = { from: number; to: number };
  const mergeData: {
    checkinDate: FromTo[];
    checkoutDate: FromTo[];
    hotelName: FromTo[];
  } = {
    checkinDate: [],
    checkoutDate: [],
    hotelName: [],
  };

  hotelList.forEach((hotelRow, i) => {
    let remarks = hotelRow.bookingRemarks;
    if (hotelRow.isBarRate) {
      remarks = 'BAR RATE ' + remarks;
    }
    const row = worksheet.addRow([
      hotelRow.checkinDateiso ? utc0_from_iso(hotelRow.checkinDateiso) : null,
      hotelRow.checkoutDateiso ? utc0_from_iso(hotelRow.checkoutDateiso) : null,
      hotelRow.numOfNights,
      hotelRow.hotelName,
      hotelRow.roomType,
      hotelRow.numOfRooms,
      getMealPlan(hotelRow.breakfastIncluded, hotelRow.dinnerIncluded),
      hotelRow.numOfPax,
      hotelRow.pricePerPaxPerNight,
      hotelRow.totalPrice,
      null,
      hotelRow.bookingStatus,
      remarks,
    ]);

    if (i === 0 || hotelRow.checkinDateiso !== hotelList[i - 1].checkinDateiso) {
      // check-in date changed: fully new row: don't merge any cells across different check-in dates
      mergeData.checkinDate.push({ from: i, to: i });
      mergeData.checkoutDate.push({ from: i, to: i });
      mergeData.hotelName.push({ from: i, to: i });
    } else if (hotelRow.checkoutDateiso !== hotelList[i - 1].checkoutDateiso) {
      // common checkin date only
      mergeData.checkinDate.at(-1)!.to = i;
      mergeData.checkoutDate.push({ from: i, to: i });
      mergeData.hotelName.push({ from: i, to: i });
    } else if (hotelRow.hotelName !== hotelList[i - 1].hotelName) {
      // common checkin/checkout/numNights, different hotel name
      mergeData.checkinDate.at(-1)!.to = i;
      mergeData.checkoutDate.at(-1)!.to = i;
      mergeData.hotelName.push({ from: i, to: i });
    } else {
      // common checkin/checkout/numNights/hotelName
      mergeData.checkinDate.at(-1)!.to = i;
      mergeData.checkoutDate.at(-1)!.to = i;
      mergeData.hotelName.at(-1)!.to = i;
    }

    const rowNumNights = hotelsFirstRowNumber + mergeData.checkoutDate.at(-1)!.from;

    // default styles for all cells
    columnsAccommodations.forEach((colname, i) => {
      if (colname) {
        row.getCell(1 + i).border = tableCellBorder;
        row.getCell(1 + i).font = defaultFont;
        row.getCell(1 + i).alignment = { vertical: 'middle', wrapText: true };
      }
    });

    // date format
    row.getCell(colhotel_1_checkin).numFmt = dateFormat;
    row.getCell(colhotel_2_checkout).numFmt = dateFormat;

    // number format
    [colhotel_3_numnights, col_8_numpax, col_9_priceperpax, col_10_total].forEach(colNumber => {
      row.getCell(colNumber).numFmt = numberFormat;
    });

    // center alignment
    [colhotel_1_checkin, colhotel_2_checkout, colhotel_3_numnights, colhotel_7_meals, col_12_status].forEach(colNumber => {
      row.getCell(colNumber).alignment = { horizontal: 'center', vertical: 'middle' };
    });

    // hotel row total formula
    let outputRowTotalFormula = false;
    if (hotelRow.numOfNights && hotelRow.numOfPax && hotelRow.pricePerPaxPerNight && hotelRow.totalPrice) {
      if (hotelRow.numOfNights * hotelRow.numOfPax * hotelRow.pricePerPaxPerNight === hotelRow.totalPrice) {
        outputRowTotalFormula = true;
      } else {
        // should not happen
        console.log('Unexpected values when computing hotel row total (1)');
      }
    } else if ((!hotelRow.numOfNights || !hotelRow.numOfPax || !hotelRow.pricePerPaxPerNight) && !hotelRow.totalPrice) {
      // either numOfNights, numOfPax, pricePerPaxPerNight is zero, and total (product of the three) is zero:
      // we can provide the formula
      outputRowTotalFormula = true;
    } else {
      //should not happen
      console.log('Unexpected values when computing hotel row total (2)');
    }

    if (outputRowTotalFormula) {
      const totalCell = row.getCell(col_10_total);
      const totalFormula =
        `${ntl(colhotel_3_numnights)}${rowNumNights}`
        + `*${ntl(col_8_numpax)}${row.number}`
        + `*${ntl(col_9_priceperpax)}${row.number}`;
      totalCell.value = { formula: totalFormula, result: hotelRow.totalPrice || 0 };
    }


    // booking status backgound color
    row.getCell(col_12_status).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: statusColors[hotelRow.bookingStatus].slice(1) },
    };

    // bar rate background color
    if (hotelRow.isBarRate) {
      const fillStyle = {
        type: 'pattern' as const,
        pattern: 'solid' as const,
        fgColor: { argb: barRateLightRed.slice(1) },
      };
      row.getCell(col_9_priceperpax).fill = fillStyle;
      row.getCell(col_10_total).fill = fillStyle;
    }

    row.height = bigRowHeight;
  });

  const hotelsTotal = hotelList.reduce((acc, row) => acc + (row.totalPrice || 0), 0);

  const hotelTotalRowNumber = hotelsFirstRowNumber + hotelList.length;
  const hotelsTotalCell = worksheet.getCell(hotelTotalRowNumber, col_10_total);
  const hotelsTotalFormula = `SUM(${ntl(col_10_total)}${hotelsFirstRowNumber}:${ntl(col_10_total)}${hotelsFirstRowNumber + hotelList.length - 1})`;
  hotelsTotalCell.value = { formula: hotelsTotalFormula, result: hotelsTotal };
  hotelsTotalCell.numFmt = numberFormat;
  hotelsTotalCell.border = tableCellBorder;
  hotelsTotalCell.alignment = { vertical: 'middle' };
  const hotelTotalRow = worksheet.getRow(hotelTotalRowNumber);
  hotelTotalRow.height = bigRowHeight;

  worksheet.addRow(null);


  // --- ↑ HOTELS ↑ --- ↓ SERVICES ↓ ---


  const servicesTitleRowNumber = worksheet.lastRow!.number + 1;
  mergeHorizontal(servicesTitleRowNumber, colserv_1_date, col_10_total);
  const servicesTitleCell = worksheet.getCell(servicesTitleRowNumber, 1);
  servicesTitleCell.value = 'Services';
  servicesTitleCell.style = titleCellStyle;
  worksheet.getRow(servicesTitleRowNumber).height = bigRowHeight;

  const serviceHeaderRowNumber = servicesTitleRowNumber + 1;
  mergeHorizontal(serviceHeaderRowNumber, colserv_3_servicename, colserv_4_servicename_end);
  mergeHorizontal(serviceHeaderRowNumber, colserv_5_details, colserv_7_details_end);
  addHeader(worksheet, columnsServices, serviceHeaderRowNumber);
  worksheet.getRow(serviceHeaderRowNumber).height = bigRowHeight;

  const servicesFirstRowNumber = serviceHeaderRowNumber + 1;

  serviceList.forEach((serviceRow) => {
    const row = worksheet.addRow([
      serviceRow.dateiso ? utc0_from_iso(serviceRow.dateiso) : null,
      serviceRow.serviceType,
      serviceRow.serviceName,
      serviceRow.serviceName,
      serviceRow.serviceDetails,
      serviceRow.serviceDetails,
      serviceRow.serviceDetails,
      serviceRow.numOfPax,
      serviceRow.pricePerPax,
      serviceRow.totalPrice,
      null,
      serviceRow.bookingStatus,
      serviceRow.bookingRemarks,
    ]);

    mergeHorizontal(row.number, colserv_3_servicename, colserv_4_servicename_end);
    mergeHorizontal(row.number, colserv_5_details, colserv_7_details_end);

    // default styles for all cells
    columnsServices.forEach((colname, i) => {
      if (colname) {
        row.getCell(1 + i).border = tableCellBorder;
        row.getCell(1 + i).font = defaultFont;
        row.getCell(1 + i).alignment = { vertical: 'middle', wrapText: true };
      }
    });

    // date format
    row.getCell(colserv_1_date).numFmt = dateFormat;

    // number format
    [col_8_numpax, col_9_priceperpax, col_10_total].forEach(colNumber => {
      row.getCell(colNumber).numFmt = numberFormat;
    });

    // center alignment
    [colserv_1_date, col_12_status].forEach(colNumber => {
      row.getCell(colNumber).alignment = { horizontal: 'center', vertical: 'middle' };
    });

    // service row total formula
    let outputRowTotalFormula = false;
    if (serviceRow.numOfPax && serviceRow.pricePerPax && serviceRow.totalPrice) {
      if (serviceRow.numOfPax * serviceRow.pricePerPax === serviceRow.totalPrice) {
        outputRowTotalFormula = true;
      } else {
        // should not happen
        console.log('Unexpected values when computing service row total (1)');
      }
    } else if ((!serviceRow.numOfPax || !serviceRow.pricePerPax) && !serviceRow.totalPrice) {
      // either numOfPax, pricePerPax is zero, and total (product of the two) is zero:
      // we can provide the formula
      outputRowTotalFormula = true;
    } else {
      //should not happen
      console.log('Unexpected values when computing service row total (2)');
    }

    if (outputRowTotalFormula) {
      const totalCell = row.getCell(col_10_total);
      const totalFormula = `${ntl(col_8_numpax)}${row.number}*${ntl(col_9_priceperpax)}${row.number}`;
      totalCell.value = { formula: totalFormula, result: serviceRow.totalPrice || 0 };
    }

    // booking status backgound color
    row.getCell(col_12_status).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: statusColors[serviceRow.bookingStatus].slice(1) },
    };

    row.height = bigRowHeight;
  });

  const servicesTotal = serviceList.reduce((acc, row) => {
    if (row.numOfPax && row.pricePerPax)
      acc += row.numOfPax * row.pricePerPax;
    return acc;
  }, 0);

  const serviceTotalRow = servicesFirstRowNumber + serviceList.length;
  const servicesTotalCell = worksheet.getCell(serviceTotalRow, col_10_total);
  const servicesTotalFormula = `SUM(${ntl(col_10_total)}${servicesFirstRowNumber}:${ntl(col_10_total)}${servicesFirstRowNumber + serviceList.length - 1})`;
  servicesTotalCell.value = { formula: servicesTotalFormula, result: servicesTotal };
  servicesTotalCell.numFmt = numberFormat;
  servicesTotalCell.border = tableCellBorder;
  servicesTotalCell.alignment = { vertical: 'middle' };
  const serviceRow = worksheet.getRow(serviceTotalRow);
  serviceRow.height = bigRowHeight;

  worksheet.addRow(null);


  // Merge hotel cells
  const mergeHotelRows = (fromIndex: number, toIndex: number, col: number) => {
    if (toIndex - fromIndex >= 1) {
      // at least 2 identical rows
      worksheet.mergeCells(hotelsFirstRowNumber + fromIndex, col, hotelsFirstRowNumber + toIndex, col);
    }
  };
  for (const mergeItem of mergeData.checkinDate) {
    mergeHotelRows(mergeItem.from, mergeItem.to, colhotel_1_checkin);
  }
  for (const mergeItem of mergeData.checkoutDate) {
    mergeHotelRows(mergeItem.from, mergeItem.to, colhotel_2_checkout);
    mergeHotelRows(mergeItem.from, mergeItem.to, colhotel_3_numnights);
  }
  for (const mergeItem of mergeData.hotelName) {
    mergeHotelRows(mergeItem.from, mergeItem.to, colhotel_4_hotelname);
  }

  // Merge service cells
  const mergeServiceRows = (fromIndex: number, toIndex: number, col: number) => {
    if (toIndex - fromIndex >= 1) {
      // at least 2 identical rows
      worksheet.mergeCells(servicesFirstRowNumber + fromIndex, col, servicesFirstRowNumber + toIndex, col);
    }
  };
  let firstIndexToMerge = 0;
  for (let i = 1; i <= serviceList.length; i++) {
    // we need to know all the rows to merge at once as we can't merge rows that are already merged
    if (i === serviceList.length || serviceList[i].dateiso !== serviceList[i - 1].dateiso) {
      mergeServiceRows(firstIndexToMerge, i - 1, colserv_1_date);
      firstIndexToMerge = i;
    }
  }


  // Total block

  const col_label = 8;
  const col_value = 10;

  const totalsRow = worksheet.lastRow!.number + 1;

  mergeHorizontal(totalsRow + 0, col_label, col_value);
  for (let i = 1; i < 7; i++) {
    mergeHorizontal(totalsRow + i, col_label, col_label + 1);
  }

  worksheet.getCell(totalsRow + 0, col_label).value = 'Total';
  worksheet.getCell(totalsRow + 0, col_label).style = titleCellStyle;
  worksheet.getRow(totalsRow + 0).height = bigRowHeight;

  addHeader(worksheet, ['Category', 'Price'], totalsRow + 1, col_label);

  const cellTotalBlockHotels = worksheet.getCell(totalsRow + 2, col_value);
  worksheet.getCell(totalsRow + 2, col_label).value = 'Accommodations';
  worksheet.getCell(totalsRow + 2, col_value).value = { formula: `${hotelsTotalCell.address}`, result: hotelsTotal };

  const cellTotalBlockServices = worksheet.getCell(totalsRow + 3, col_value);
  worksheet.getCell(totalsRow + 3, col_label).value = 'Services';
  worksheet.getCell(totalsRow + 3, col_value).value = { formula: `${servicesTotalCell.address}`, result: servicesTotal };

  const cellGrandTotal = worksheet.getCell(totalsRow + 4, col_value);
  worksheet.getCell(totalsRow + 4, col_label).value = 'Total';
  worksheet.getCell(totalsRow + 4, col_value).value = { formula: `${cellTotalBlockHotels.address}+${cellTotalBlockServices.address}`, result: hotelsTotal + servicesTotal };
  worksheet.getCell(totalsRow + 4, col_value).font = boldFont;

  worksheet.addRow(null);

  worksheet.getCell(totalsRow + 6, col_label).value = {
    formula: `"Per person ("&${numOfPaxCell}&" pax)"`,
    result: `Per person (${numOfPax} pax)`,
  };
  const pricePerPerson = numOfPax ? (servicesTotal + hotelsTotal) / numOfPax : 0;
  worksheet.getCell(totalsRow + 6, col_value).value = {
    formula: `${cellGrandTotal.address}/${numOfPaxCell}`,
    result: pricePerPerson,
  };

  // set border and number format on total block
  [2, 3, 4, 6].forEach(i => {
    worksheet.getCell(totalsRow + i, col_label).border = tableCellBorder;
    worksheet.getCell(totalsRow + i, col_value).border = tableCellBorder;
    worksheet.getCell(totalsRow + i, col_value).numFmt = numberFormat;
  });


  const logo = workbook.addImage({
    base64: eightyDaysLogo,
    extension: 'png',
  });

  if (passengerList) {
    if (passengerList.length <= 7) {

      worksheet.addImage(logo, {
        tl: { col: 10, row: 0 },
        ext: { width: 68, height: 90 },
      });
    }

  } else {
    worksheet.addImage(logo, {
      tl: { col: 7, row: 0 },
      ext: { width: 68, height: 90 },
    });
  }

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/xlsx' });
    const filename =
      'Quotation-'
      + `${quotation.generalInfo.agencyItineraryRefWithVersion.replaceAll('/', '_')}`
      + `-${dateFormatCompact(new Date())}`
      + '.xlsx';
    saveAs(blob, filename);
  });
};
