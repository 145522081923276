import { addDoc, collection, doc, getDocs, query, updateDoc } from 'firebase/firestore';
import Papa from 'papaparse';
import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { getSpanDaysExact } from 'src/util/datetools';
import { parseDateCsv, parseDateTimeCsv } from 'src/util/datetools_dayjs';
import { userrole_isDev } from 'src/util/user_roles';
import { mapUsers, useUserListKintone } from './util_kintone';
import { stripFinalLf } from './util_misc';

const mapKintoneFirestore = new Map([
  ['Agency/Platform', 'agencyOrPlatform'],
  ['Traveller Name', 'travellerName'],
  ['Country', 'country'],
  ['State/region', 'stateOrRegion'],
  ['Customer Type', 'customerType'],
  ['Arrival date', 'tourStart'],
  ['Departure date', 'tourEnd'],
  ['No. of Travellers', 'numOfPax'],
  ['Created datetime', 'dateCreated'],
  ['Updated datetime', 'dateModified'],
  ['Status[Confirmed]', 'statusConfirmed'],
  ['Status[Received a deposit]', 'statusReceivedDeposit'],
  ['Status[Received a total trip fee]', 'statusReceivedTotalTripFee'],
  ['Record number', 'kintoneRecordNumber'],
  ['Categories', 'eightyDaysDepartment'],
  ['Special Occasion', 'specialOccasion'],
  ['Guide Preference', 'guidePreference'],
  ['Guide Language', 'guideLanguage'],
  ['Dietary Requirements', 'dietaryRequirements'],
  ["Customer's Interests", 'customersInterests'],
  ['Comments', 'requestComments'],
  ['Comment', 'updatedComment'],
]);

const numericFields = ['No. of Travellers', 'Record number'];
const boolFields = ['Status[Confirmed]', 'Status[Received a deposit]', 'Status[Received a total trip fee]'];
const dateFields = ['Arrival date', 'Departure date'];
const dateTimeFields = ['Created datetime', 'Updated datetime'];

const travellerFields = ['Name', 'Age', 'Passport Information[Received]'];



export function KintoneTourRequestImporter() {

  const { db, setDbError, userDetails } = useAppContext();

  if (!userrole_isDev(userDetails.roles))
    throw new Error('user must be dev');

  const [err, setErr] = useState();
  const [success, setSuccess] = useState([]);


  const userList = useUserListKintone();

  // *** all hooks above this line ***

  usePageTitle('Kintone Request Importer');
  if (!userList)
    return <LoadingSpinner />;


  function processFile(e) {
    const saveToDb = e.target.saveToDb.checked;
    console.log({ saveToDb });

    console.log(e.target.fileInput.files);
    if (!(e.target.fileInput.files && e.target.fileInput.files.length > 0))
      return;

    const file = e.target.fileInput.files[0];

    const reader = new FileReader();
    reader.onerror = (e) => setErr('error in FileReader');
    reader.onload = function (e) {
      let contents = e.target.result;
      console.log(`length ${contents.length}`);

      // strip final new line as papaparse doesn't like it
      contents = stripFinalLf(contents);


      Papa.parse(contents, {
        header: true,
        dynamicTyping: true,
        complete: function (results) {

          console.log(results);

          if (results.errors.length > 0) {
            let msg = '';
            results.errors.forEach((err) => {
              msg += err + '\n';
            });
            setErr(msg);
            return;
          }


          const tourrequests = [];
          let tourrequest = null;

          const addTraveller = (tourrequest, row) => {
            tourrequest.travellers.push({
              name: row['Name'],
              age: row['Age'],
              passportInfoReceived: !!row['Passport Information[Received]'],
            });
          };

          function handleUser(row, colName, destFieldName) {

            const colNameDisplayName = `${colName}(Display Name)`;
            const kintoneUser = `${row[colName]} _ ${row[colNameDisplayName]}`;

            const userEmail = mapUsers.get(kintoneUser);
            if (!userEmail)
              throw new Error(`missing in mapping: ${kintoneUser}`);

            const userObj = userList.get(userEmail);
            if (!userObj)
              throw new Error(`user not found: ${userEmail}`);

            tourrequest[`user${destFieldName}Uid`] = userObj.id;
            tourrequest[`user${destFieldName}Email`] = userObj.email;
            tourrequest[`user${destFieldName}Name`] = userObj.displayNameEn;

            delete row[colName];
            delete row[colNameDisplayName];
          }


          results.data.forEach((_row, index) => {

            const row = { ..._row };
            //console.log(row)

            if (row['New Record Flag'] === '*') {
              tourrequest = {
                _isDeleted: false,
                accommodationStars: [],
                accommodationPreference: [],
                travellers: [],
              };

              delete row['New Record Flag'];

              // handle user ids
              handleUser(row, 'Created by', 'Created');
              handleUser(row, 'Updated by', 'Modified');


              for (const field of mapKintoneFirestore.keys()) {
                const newField = mapKintoneFirestore.get(field);

                let value = row[field];

                if (value === undefined)
                  throw new Error('undefined field'); // should not happen

                if (dateFields.includes(field))
                  value = parseDateCsv(value);

                if (dateTimeFields.includes(field))
                  value = parseDateTimeCsv(value);

                if (boolFields.includes(field))
                  value = !!value; // null and 1 -> false and true

                if (value === null) {
                  if (numericFields.includes(field)
                    || boolFields.includes(field)
                    || dateFields.includes(field)
                    || dateTimeFields.includes(field)
                  ) {
                    console.log(`NULL: ${newField}`);
                  } else {
                    // this is a string field, replace with empty string
                    value = '';
                  }
                }

                tourrequest[newField] = value;
                delete row[field];
              }

              // check length of the stay
              const calculatedLength = getSpanDaysExact(tourrequest.tourStart, tourrequest.tourEnd) + 1;
              if (row['Length of the Stay'] !== calculatedLength) {
                console.log(`WRONG LENGTH OF STAY ${row['Length of the Stay']} != ${calculatedLength}`);
              }
              delete row['Length of the Stay'];


              delete row['New Record Flat'];
              delete row['List of Agencies/Platforms'];
              tourrequest.requestNumber = row['ID'];
              tourrequest.requestCode = ''; // need to determine separately
              delete row['ID'];

              // note: some tours have 'passport received' ticked but no name/age specified
              if (tourrequest.requestNumber === 428)
                console.log({ rowName: row['Name'], rowAge: row['Age'], rowPassport: row['Passport Information[Received]'] });

              if (row['Name'] !== null || row['Age'] !== null || row['Passport Information[Received]'] !== null) {
                addTraveller(tourrequest, row);
              }
              delete row['Name'];
              delete row['Age'];
              delete row['Passport Information[Received]'];


              for (const field in row) {
                if (field.startsWith('Accommodation Type')) {
                  const match = field.match(/^Accommodation Type\[([0-9]) star\]/);
                  if (!match) throw new Error('invalid accommodation type');
                  const num = Number(match[1]);
                  if (row[field] === 1) {
                    tourrequest.accommodationStars.push(`${num} star`);
                  } else if (row[field] === null) {
                    // ok, not selected
                  } else {
                    throw new Error(`invalid accommodation type: [${row[field]}]`);
                  }
                } else if (field.startsWith('Accommodation Preference')) {
                  const match = field.match(/^Accommodation Preference\[([A-Za-z ]+)\]$.*$/i);
                  if (!match) throw new Error(`invalid accommodation preference >${field}<`);
                  const pref = match[1];
                  if (row[field] === 1) {
                    tourrequest.accommodationPreference.push(pref);
                  } else if (row[field] === null) {
                    // ok, not selected
                  } else {
                    throw new Error('invalid accommodation');
                  }
                } else {
                  console.log(`UNKNOWN FIELD ${field}`);
                }


              }
              tourrequests.push(tourrequest);

            } else {
              for (const field in row) {
                if (field === 'New Record Flag') {
                  // skip
                } else if (travellerFields.includes(field)) {
                  // handle these below
                } else {
                  // ensure data is consistent
                  if (results.data[index - 1][field] !== row[field]) {
                    console.log(`mismatch data ${field}: ${results.data[index - 1][field]} != ${row[field]}`);
                    throw new Error(`mismatch data ${field}`);
                  }
                }
              }
              addTraveller(tourrequest, row);
            }


          }); // each row

          console.log(`Parsing complete. ${tourrequests.length} tour requests`);

          //console.log({ req0: tourrequests[0] });
          console.log(tourrequests);

          if (!saveToDb)
            return;

          // store to database
          tourrequests.forEach((req) => {
            console.log(`Adding doc ${req.requestNumber}`);
            addDoc(collection(db, 'tourrequests'), req)
              .then((addedDoc) => {
                console.log('tour request added to db');
                setSuccess((suc) => [...suc, addedDoc]);
              })
              .catch((err) => {
                console.log(err);
                setErr(`addDoc error ${err}`);
              });
          });



        }, // `complete` handler
      });



    };
    reader.readAsText(file, 'UTF-8');



    //e.target.form.reset()

  }


  return (
    <div className='container my-5'>
      <h5 className='my-4'>Importer</h5>

      <div className='alert'>{err}</div>
      <div className='alert'>Success.length={success.length}</div>

      <form onSubmit={(e) => {
        e.preventDefault();
        processFile(e);
      }}>
        <CheckboxSwitch id='saveToDb' label='Save to DB' />
        <input type='file' id='fileInput' />
        <ButtonTW type='submit'>Parse</ButtonTW>
      </form>



      <div className='mt-4'>
        <h6>Temporary adhoc tools</h6>
        <ButtonTW onClick={(e) => {
          // adhoc tool to quick fix database data.
          // proper way is to fix the kintone importer and re-import.
          if (confirm('are you sure') && false) {
            getDocs(query(collection(db, 'tourrequests')))
              .then((result) => {
                console.log('got list');
                result.docs.forEach((onedoc) => {
                  console.log('updating doc');
                  updateDoc(doc(db, 'tourrequests', onedoc.id), { _isDeleted: false, status: '' })
                    .then(() => {
                      console.log('done');
                    });
                });
              })
              .catch((err) => setDbError('Getting tour requests', err));
          }

        }}>fix status</ButtonTW>
      </div>


    </div>
  );
}
