import { Tooltip } from 'react-tooltip';



interface SupplierNoteAlertProps {
  rowId: string;
  note: string;
};

export function SupplierNoteAlert({
  rowId,
  note,
}: SupplierNoteAlertProps) {

  if (!note)
    return null;

  const id = `anchor_supplierNote_${rowId}`;

  return (
    <>
      <div id={id} className='tw-border tw-border-solid tw-border-orange-800/50 tw-bg-orange-200 tw-rounded tw-p-1 tw-text-sm tw-text-orange-800 tw-min-w-32'>
        <i className='bi bi-info-circle'></i>{' '}
        Supplier note
      </div>
      <Tooltip anchorSelect={`#${id}`} clickable={false} place='bottom-start' className='tw-max-w-sm'>
        {note}
      </Tooltip>
    </>
  );
}

export function ItemNoteAlert({
  rowId,
  note,
}: SupplierNoteAlertProps) {

  if (!note)
    return null;

  const id = `anchor_itemNote_${rowId}`;

  return (
    <>
      <div id={id} className='tw-border tw-border-solid tw-border-yellow-800/50 tw-bg-yellow-200 tw-rounded tw-p-1 tw-text-sm tw-text-yellow-800 tw-min-w-32'>
        <i className='bi bi-info-circle'></i>{' '}
        Item note
      </div>
      <Tooltip anchorSelect={`#${id}`} clickable={false} place='bottom-start' className='tw-max-w-sm'>
        {note}
      </Tooltip>
    </>
  );
}

