import { RefObject, useEffect } from 'react';


export function useOutsideAlerter(inputRef: RefObject<HTMLElement>, onClickOutside: () => void) {
  // This hook allows detecting when the user clicks outside of a given element
  // https://stackoverflow.com/a/42234988/

  useEffect(() => {

    // Alert if clicked on outside of element
    function handleClickOutside(event: MouseEvent) {
      // console.log('handleClickOutside', inputRef.current, event.target)
      if (!inputRef.current) {
        // console.log('no inputRef')
        return;
      }
      if (!inputRef.current.contains(event.target as Node)) {
        // console.log('clicked outside')
        onClickOutside();
      } else {
        // console.log('clicked inside')
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [inputRef, onClickOutside]);

}
