import { DocumentSnapshot, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { AnnouncementType, WebappMinVersionType } from 'src/types/objectTypes';
import { dateFormatJpWithTime } from '../util/dateformattools';
import { convertWebppMinVersionDates } from '../util/util_firestoredates';
import { getBuildTime } from '../util/util_getbuildtime';
import { useAppContext } from './useAppContext';



export function useAnnouncements(): [AnnouncementType | null, string | null] {

  const { db, setDbError, userDetails } = useAppContext();

  const [announcement, setAnnouncement] = useState<AnnouncementType | null>(null);
  useEffect(() => {
    if (!userDetails)
      // don't have permission to view announcement
      return;

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const announcement = snapshot.data() as AnnouncementType;
      setAnnouncement(announcement);
    };

    const unsubscribe = onSnapshot(doc(db, 'settings', 'announcement'), processSnapshot, (err) => setDbError('Getting settings announcement', err));
    return unsubscribe;
  }, [db, setDbError, userDetails]);


  const [upgradeMessage, setUpgradeMessage] = useState<string | null>(null);
  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {

      const minVerInfo = snapshot.data() as WebappMinVersionType;
      convertWebppMinVersionDates(minVerInfo);

      // console.log('minVerInfo', minVerInfo)

      const {
        hardMinBuildTime,
        hardMinVersion,
        warnMinBuildTime,
        warnMinVersion,
      } = minVerInfo;


      // carry out version checks here

      const currentBuildTime = getBuildTime();
      const currentVersion = import.meta.env.VITE_APP_VERSION;
      const isDev = currentBuildTime === 'dev';
      // in dev mode (vite serve) we cannot get the build time

      // console.log('currentBuildTime', currentBuildTime)
      // console.log('currentVersion', currentVersion)

      const msgBase = 'Web app is out of date. Please save your work, then press Ctrl+F5 or refresh the page to update.';

      if (currentVersion < hardMinVersion) {
        setDbError(`${msgBase} [curver=${currentVersion};reqver=${hardMinVersion}]`, null, true);
        return;
      }

      if (!isDev && currentBuildTime < hardMinBuildTime) {
        setDbError(`${msgBase} [curbuild=${dateFormatJpWithTime(currentBuildTime)};reqbuild=${dateFormatJpWithTime(hardMinBuildTime)}]`, null, true);
        return;
      }

      if (currentVersion < warnMinVersion) {
        setUpgradeMessage(`${msgBase} [curver=${currentVersion};reqver=${warnMinVersion}]`);
        return;
      }

      if (!isDev && currentBuildTime < warnMinBuildTime) {
        setUpgradeMessage(`${msgBase} [curbuild=${dateFormatJpWithTime(currentBuildTime)};reqbuild=${dateFormatJpWithTime(warnMinBuildTime)}]`);
        return;
      }

      //console.log('up to date')
      setUpgradeMessage(null);
    };


    const unsubscribe = onSnapshot(doc(db, 'settings', 'webappMinVersion'), processSnapshot, (err) => setDbError('Getting settings webappMinVersion', err));
    return unsubscribe;
  }, [db, setDbError]);


  return [announcement, upgradeMessage];
}
