import { useAppContext } from 'src/hooks/useAppContext';
import { HighlightFieldBasic } from 'src/pages/Requests/RequestsList/TableParts/HighlightFieldBasic';
import { RequestCodeAggregator } from './RequestCodeAggregator';


interface RequestCodeLinkToAggregatorProps {
  requestCode: string;
  linkId: string;
  shownPopup: string | null | undefined;
  setShownPopup: (value: string | null) => void;
  highlightResult?: string;
  linkColorInherit?: boolean;
  alternateLabel?: string; // link label is normally the Request Code, but can be overridden with this prop
}

export function RequestCodeLinkToAggregator({
  requestCode,
  linkId,
  shownPopup,
  setShownPopup,
  highlightResult,
  linkColorInherit,
  alternateLabel,
}: RequestCodeLinkToAggregatorProps) {

  const { userDetails, perm, cacheRequestCodes } = useAppContext();

  if (!requestCode)
    return null;

  if (!perm('tourrequests_read'))
    return requestCode; // no link, just text

  const found = cacheRequestCodes.has(requestCode);

  if (!found) {
    // console.log(`WARNING: request code ${requestCode} not found in cache`)
  }

  return (
    <>
      <a href='#' style={{
        whiteSpace: 'nowrap',
        color: !found ? 'darkred' : linkColorInherit ? 'inherit' : undefined,
      }} onClick={(e) => {
        e.preventDefault();
        if (shownPopup === linkId)
          setShownPopup(null);
        else
          setShownPopup(linkId);
      }}>
        <i className='bi bi-link-45deg'></i>
        {alternateLabel ?? (
          <HighlightFieldBasic fieldValue={requestCode} highlightedValue={highlightResult} />
        )}
      </a>
      <RequestCodeAggregator
        requestCode={requestCode}
        show={shownPopup === linkId}
        callbackClose={() => setShownPopup(null)}
      />
    </>
  );
}
