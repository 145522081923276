


interface RedTriangleProps {
  cssRight?: string;
}

export function RedTriangle({
  cssRight,
}: RedTriangleProps) {
  return (
    <>
      <div style={{
        position: 'absolute',
        top: '0',
        right: cssRight ?? '0',
        borderTop: '0.35em solid white',
        borderRight: '0.35em solid white',
        borderBottom: '0.35em solid transparent',
        borderLeft: '0.35em solid transparent',
      }}></div>
      <div style={{
        position: 'absolute',
        top: '0',
        right: cssRight ?? '0',
        borderTop: '0.3em solid red',
        borderRight: '0.3em solid red',
        borderBottom: '0.3em solid transparent',
        borderLeft: '0.3em solid transparent',
      }}></div>
    </>
  );
}
