

export function getStatusLabel(s: string): string {
  if (s === 'TD_DRAFT')
    return 'Travel Designer Input';
  if (s === 'TL_DRAFT')
    return 'Guide Input';
  if (s === 'SUBMITTED')
    return 'Submitted';
  if (s === 'APPROVED')
    return 'Approved';
  throw new Error('invalid status');
}
