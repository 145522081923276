


interface CheckboxButtonBARProps {
  checked: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

export function CheckboxButtonBAR({
  checked,
  onClick,
  disabled,
}: CheckboxButtonBARProps) {


  return (
    <button className={`tw-border tw-border-solid tw-rounded tw-ps-0.5 tw-py-0.5 tw-whitespace-nowrap ${!disabled ? 'tw-cursor-pointer' : '!tw-cursor-default'}
      ${checked
        ? 'tw-bg-red-200/70 tw-border-red-700 tw-text-red-900'
        : 'tw-bg-slate-200 tw-border-slate-400 tw-text-slate-500'}
        tw-flex tw-items-center
        tw-w-[3.7em]
        `}
      onClick={!disabled ? onClick : undefined}>
      {checked ? <i className='bi bi-check'></i> : <i className='bi bi-x'></i>}
      {checked ? (
        <div className='tw-text-center tw-w-full'>BAR</div>
      ) : (
        <div className='tw-flex tw-flex-col tw-text-xs tw-leading-3 tw-items-center tw-grow'>
          <div>NOT</div>
          <div>BAR</div>
        </div>
      )}
    </button>
  );
}
