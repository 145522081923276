import { ClipboardEvent } from 'react';
import { QuotegridLineItemType } from 'src/types/types_quotegrid';
import { formatNum } from 'src/util/util_formatnum';
import { getFieldValue } from 'src/util/util_getFieldValue';
import { EditableField } from './EditableField';


export type FunctionSaveFieldToDbType = (
  baseField: 'overall' | 'days' | 'lineItems',
  itemId: string, // 'overall' for baseField='overall'; 'lineItems.abcdefgh' for line item; 'days.day_7' for day.
  rowObj: QuotegridLineItemType, // null except when creating a new row
  fieldname: string,
  dbvalue: any,
  formula: string | undefined
) => { newRowId: string | null } | undefined;

export type FunctionTabKeyPress = (tabKey: -1 | 1 | undefined, currentCellId: string, newRowId: string | null) => void;

interface EditableFieldQuotegridProps {
  baseField: 'overall' | 'days' | 'lineItems';
  rowObj: any;
  tableid: string;
  fieldname: string;
  validationType: '' | 'formula';
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  tabKeyPress: FunctionTabKeyPress;
  saveFieldToDb: FunctionSaveFieldToDbType;
  callbackOnChange?: (value: string) => void;
  isTextArea?: boolean;
  placeholderText?: string;
  callbackOnPaste?: (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  getDisplayValue?: (value: any) => any;
  hasButtonForEditing?: boolean;
}

export function EditableFieldQuotegrid({
  baseField,
  rowObj,
  tableid,
  fieldname,
  validationType,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  tabKeyPress,
  saveFieldToDb,
  callbackOnChange, // called on each key press. used for showing station name suggestions etc.
  isTextArea,
  placeholderText,
  callbackOnPaste,
  getDisplayValue,
  hasButtonForEditing,
}: EditableFieldQuotegridProps) {

  const rowid = rowObj.id;

  const currentValue: any = getFieldValue(rowObj, fieldname) ?? ''; //   " ?? '' " was added for dayInfo.memorandumTop as it is initially undefined

  let currentValueStr: string;

  // convert non-string types (number, date) to string for displaying to user
  if (validationType === 'formula') {
    if (currentValue !== '' && typeof currentValue !== 'number') {
      console.error(`Expected a number, got [${currentValue}] of type ${typeof currentValue}`);
    }
    const formula = getFieldValue(rowObj, `${fieldname}_formula`);
    if (formula) {
      const computedValue = currentValue;
      getDisplayValue = () => formatNum(computedValue);
      currentValueStr = formula;
    } else {
      currentValueStr = formatNum(currentValue);
    }

  } else {
    if (typeof currentValue !== 'string') {
      console.error(`Expected a string, got [${currentValue}] of type ${typeof currentValue}`);
    }
    currentValueStr = currentValue;
  }

  const callbackCommitChange = (dbvalue: any, tabKey: -1 | 1 | undefined, formula: string | undefined) => {

    let newRowId = null;

    const saveResult = saveFieldToDb(baseField, rowObj.id, rowObj, fieldname, dbvalue, formula);
    if (saveResult && saveResult.newRowId)
      // row id changes if customSaveCallback saved a new row to db which didn't yet have an id
      newRowId = saveResult.newRowId;

    const currentCellId = `${tableid}_${rowid}_${fieldname}`; // still has 'new' if new row
    tabKeyPress(tabKey, currentCellId, newRowId);

  };

  return (
    <EditableField
      tableid={tableid}
      rowid={rowid}
      fieldname={fieldname}
      validationType={validationType}
      currentValue={currentValueStr}
      isClickableToEdit={isClickableToEdit}
      editedCell={editedCell}
      setEditedCell={setEditedCell}
      callbackCommitChange={callbackCommitChange}
      callbackOnChange={callbackOnChange} // on each key press
      isTextArea={isTextArea}
      placeholderText={placeholderText}
      callbackOnPaste={callbackOnPaste}
      getDisplayValue={getDisplayValue}
      hasButtonForEditing={hasButtonForEditing}
    />
  );
}
