


export function getDayListLabel(selectedDayIndexesU: number[], dayOneIndex: number, numDaysInTour: number) {


  if (selectedDayIndexesU.length === 0)
    return '';

  const dayNumArray = [...selectedDayIndexesU].sort((a, b) => a - b).map((i) => i - dayOneIndex + 1); // 1-based, from tour start date

  const result: string[] = [];
  let curMin = dayNumArray[0];
  let curMax = dayNumArray[0];
  for (let i = 1; i <= dayNumArray.length; i++) { // we let i go beyond array bounds so that the last range can be printed
    const dayNum = dayNumArray[i];
    if (dayNum === curMax + 1) {
      curMax = dayNum;
      continue;
    }
    // dayNum is not consecutive. need to print previous range to result
    if (curMin === curMax) {
      result.push(dayNumToString(curMin, numDaysInTour));
    } else {
      result.push(`${dayNumToString(curMin, numDaysInTour)}-${dayNumToString(curMax, numDaysInTour)}`);
    }
    curMin = dayNum;
    curMax = dayNum;

  }

  return result.join(', ');

}

export function dayNumToString(dayNum: number, numDaysInTour: number) {
  if (dayNum < 1) {
    return 'S-' + (1 - dayNum);
  } else if (dayNum <= numDaysInTour) {
    return dayNum.toString();
  } else {
    return 'E+' + (dayNum - numDaysInTour);
  }
}

export function parseDayListLabel(label: string, numDaysInTour: number) {

  const parts = label.toUpperCase().split(',').map((s: string) => s.trim());

  const result: number[] = [];

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    const match_rangedash = part.match(/^(.*[^S])-(.+)$/);
    if (match_rangedash) {
      // range of days 'a-b' (from a to b)
      const dayFrom = parseDayNumString(match_rangedash[1], numDaysInTour);
      if (dayFrom === null)
        return null;
      const dayTo = parseDayNumString(match_rangedash[2], numDaysInTour);
      if (dayTo === null)
        return null;
      if (dayFrom >= dayTo)
        return null;
      if (result.length > 0 && result.at(-1)! >= dayFrom)
        return null;
      for (let i = dayFrom; i <= dayTo; i++) {
        result.push(i);
      }
    } else {
      // single day
      const dayNum = parseDayNumString(part, numDaysInTour);
      if (dayNum === null)
        return null;
      if (result.length > 0 && result.at(-1)! >= dayNum)
        return null;
      result.push(dayNum);
    }
  }

  return result;
}

function parseDayNumString(dayNumString: string, numDaysInTour: number) {
  const match_beforestart = dayNumString.match(/^S-(\d+)$/);
  if (match_beforestart) {
    // a day before the tour start date
    const num = parseInt(match_beforestart[1]);
    if (num === 0)
      return null;
    const dayNum = 1 - num;
    return dayNum;
  }
  const match_afterend = dayNumString.match(/^E\+(\d+)$/);
  if (match_afterend) {
    // a day after the tour end date
    const num = parseInt(match_afterend[1]);
    if (num === 0)
      return null;
    const dayNum = numDaysInTour + num;
    return dayNum;
  }
  const match_num = dayNumString.match(/^(\d+)$/);
  if (match_num) {
    // a day within the tour start-end range
    const dayNum = parseInt(dayNumString);
    if (dayNum < 1 || dayNum > numDaysInTour)
      return null;
    return dayNum;
  }
  return null;
}

