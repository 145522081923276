import { QuerySnapshot, collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeType } from 'src/types/types_payee';
import { katakanaToHiragana } from 'src/util/kana_conversion';
import { userrole_canListPayees } from 'src/util/user_roles';
import { convertPayeeDates } from 'src/util/util_firestoredates';
import { getFullDisplayNameEn, getFullDisplayNameJa, getFullDisplayNameKana, getShortDisplayNameEn, getShortDisplayNameJa } from './util_payees';



export function usePayeeList() {

  const { db, setDbError, userDetails } = useAppContext();

  const [payeeList, setPayeeList] = useState<PayeeType[]>();

  useEffect(() => {
    // if user is readonly, they don't have permissions to access payee list
    if (!userrole_canListPayees(userDetails.roles))
      return;

    const processSnapshot = function (snapshot: QuerySnapshot) {
      const payees: PayeeType[] = [];
      for (const doc of snapshot.docs) {
        const payee = { ...doc.data(), id: doc.id } as PayeeType;
        payee.nameJaFull = getFullDisplayNameJa(payee);
        payee.nameKanaFull = getFullDisplayNameKana(payee);
        payee.nameEnFull = getFullDisplayNameEn(payee);
        payee.nameHiraganaFull = katakanaToHiragana(payee.nameKanaFull);
        payee.displayName = `${getShortDisplayNameJa(payee)} \u00a0 \u00a0 [${getShortDisplayNameEn(payee)}]`;
        convertPayeeDates(payee);
        payees.push(payee);
      }
      setPayeeList(payees);
    };

    const q = query(collection(db, 'payees'), where('_isDeleted', '==', false));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting payee list', err));

    return unsubscribe;
  }, [db, setDbError, userDetails]);

  return payeeList;
}
