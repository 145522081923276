import { isMidnightUTC, iso_from_utc0 } from './datetools';


// company holiday = 30 Dec - 3 Jan inclusive (5 days)

const bankHolidays = new Set([
  '2023-01-01',
  '2023-01-02',
  '2023-01-03',
  '2023-01-09',
  '2023-02-11',
  '2023-02-23',
  '2023-03-21',
  '2023-04-29',
  '2023-05-03',
  '2023-05-04',
  '2023-05-05',
  '2023-07-17',
  '2023-08-11',
  '2023-09-18',
  '2023-09-23',
  '2023-10-09',
  '2023-11-03',
  '2023-11-23',
  '2023-12-30',
  '2023-12-31',

  '2024-01-01',
  '2024-01-02',
  '2024-01-03',
  '2024-01-08',
  '2024-02-11',
  '2024-02-12',
  '2024-02-23',
  '2024-03-20',
  '2024-04-29',
  '2024-05-03',
  '2024-05-04',
  '2024-05-05',
  '2024-05-06',
  '2024-07-15',
  '2024-08-11',
  '2024-08-12',
  '2024-09-16',
  '2024-09-22',
  '2024-09-23',
  '2024-10-14',
  '2024-11-03',
  '2024-11-04',
  '2024-11-23',
  '2024-12-30',
  '2024-12-31',

  '2025-01-01',
  '2025-01-02',
  '2025-01-03',
  '2025-01-13',
  '2025-02-11',
  '2025-02-23',
  '2025-02-24',
  '2025-03-20',
  '2025-04-29',
  '2025-05-03',
  '2025-05-04',
  '2025-05-05',
  '2025-05-06',
  '2025-07-21',
  '2025-08-11',
  '2025-09-15',
  '2025-09-23',
  '2025-10-13',
  '2025-11-03',
  '2025-11-23',
  '2025-11-24',
  '2025-12-30',
  '2025-12-31',


]);

export function isBankHoliday(dateutc: Date) {
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC');
  const dateStr = iso_from_utc0(dateutc);
  return bankHolidays.has(dateStr);
}

export function isWeekend(dateutc: Date) {
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC');
  const dayOfWeek = dateutc.getUTCDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function isHoliday(dateutc: Date) {
  if (!isMidnightUTC(dateutc))
    throw new Error('date is not midnight UTC');
  return isBankHoliday(dateutc) || isWeekend(dateutc);
}
