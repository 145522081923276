import { QueryDocumentSnapshot, QueryFieldFilterConstraint, QueryOrderByConstraint, QuerySnapshot, Unsubscribe, collection, getCountFromServer, limit, onSnapshot, orderBy, query, startAfter, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { getQueryConstraintsBasedOnDateFilter, tourRequestClientSideFiltering } from 'src/components/ColumnFilters/util_filter_dates';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestType } from 'src/types/types_tourrequest';
import { formatConstraint } from 'src/util/util_db_misc';
import { convertTourRequestDates } from 'src/util/util_firestoredates';
import { log_db_read, log_info } from 'src/util/util_log';
import { StatusKeyType } from '../PageTourRequests';
import { OneRequestTableType, RequestListColumnFilterAppliedData } from '../types_tourrequest_state';


function isIneq(op: string): boolean {
  return op === '>'
    || op === '<'
    || op === '>='
    || op === '<=';
  // 'in' query is basically an OR union of multiple '==' queries, so doesn't count as an inequality
}

export type WhereClauseType = [
  string, // field name
  '==' | '>' | '<' | '>=' | '<=' | 'array-contains',
  string | boolean | number // value
] | [
  string, // field name
  'array-contains-any' | 'in',
  string[] | number[]
];

interface UseQueryRequestListProps {
  isRequestListPage: boolean;
  statusKey: StatusKeyType;
  sortByStatus: boolean;
  columnFilterAppliedData: RequestListColumnFilterAppliedData;

  showExploreSeries: boolean;
  disabledPagination: { tourStart: boolean; tourEnd: boolean; anyField: boolean };
}

export function useQueryRequestList({
  isRequestListPage,
  statusKey,
  sortByStatus,
  columnFilterAppliedData,

  showExploreSeries,
  disabledPagination, // this is true if and only if we are doing client side filtering
}: UseQueryRequestListProps): OneRequestTableType {

  const { db, setDbError, userDetails } = useAppContext();


  const {
    appliedFilterRequestCode,
    appliedFilterTourStart,
    appliedFilterTourEnd,
    appliedFilterPaymentDate,
    appliedFilterPaymentAmount,
    appliedFilterPaxName,
    appliedFilterTeamCategory,
    appliedFilterCustomerType,
    appliedFilterAgency,
    appliedFilterCountry,
    paramDesigner,
  } = columnFilterAppliedData;

  const stillLoading =
    appliedFilterRequestCode === undefined
    || appliedFilterTourStart === undefined
    || appliedFilterTourEnd === undefined
    || appliedFilterPaymentDate === undefined
    || appliedFilterPaymentAmount === undefined
    || appliedFilterPaxName === undefined
    || appliedFilterTeamCategory === undefined
    || appliedFilterCustomerType === undefined
    || appliedFilterAgency === undefined
    || appliedFilterCountry === undefined
    || paramDesigner === undefined;


  const autoExpandByDefault = statusKey === 'all' || statusKey === 'confirmed_active' || statusKey === 'ongoing';

  const [requestList, setRequestList] = useState<TourRequestType[]>();
  const [currentPageLastDoc, setCurrentPageLastDoc] = useState<QueryDocumentSnapshot | null>(null);
  const [rowCount, setRowCount] = useState<number>();
  const [previousPagesLastDocs, setPreviousPagesLastDocs] = useState<QueryDocumentSnapshot[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberPerPage, setNumberPerPage] = useState(50);
  const [isExpanded, setIsExpanded] = useState(autoExpandByDefault);
  const [queryErrorMessage, setQueryErrorMessage] = useState<string | null>(null);

  // const current_key = paramDesigner || 'all_designers'
  // `hasBeenLoadedOnce` string value is so that the downloaded requests are kept in state,
  // and the onSnapshot is kept alive, even when section is collapsed by user, to avoir fetching them again
  // when user just collapses/expands the section.
  // the problem is we want to only keep it alive if the query remains identical
  //  -> this string should contain not only the designer, but all other query constraints too
  // we will fix this later, for now we disable.
  // const [hasBeenLoadedOnce, setHasBeenLoadedOnce] = useState(autoExpandByDefault ? current_key : '')

  // if (isExpanded && hasBeenLoadedOnce !== current_key) {
  //   setHasBeenLoadedOnce(current_key)
  // }

  // ACTIVE can be true even if isExpended is false
  // const ACTIVE =
  //   isRequestListPage && hasBeenLoadedOnce === current_key && (
  //     (!sortByStatus && statusKey === 'all')
  //     || (sortByStatus && statusKey !== 'all')
  //   )


  const ACTIVE =
    !stillLoading
    && isRequestListPage
    // && isExpanded // we make ACTIVE true even if not expanded, because we want to get the row count
    && (
      (!sortByStatus && statusKey === 'all')
      || (sortByStatus && statusKey !== 'all')
    );



  useEffect(() => {

    let useEffectCancelled = false;


    if (!ACTIVE) {

      // setIsLoading(false)

      return;
    }

    setQueryErrorMessage(null);

    const processSnapshot = function (snapshot: QuerySnapshot) {
      let tourrequests: TourRequestType[] = [];
      for (const doc of snapshot.docs) {
        const tourrequest = { ...doc.data(), id: doc.id } as TourRequestType;
        convertTourRequestDates(tourrequest);
        tourrequests.push(tourrequest);
      }

      if (disabledPagination.tourStart) {
        tourrequests = tourRequestClientSideFiltering(tourrequests, appliedFilterTourStart!, 'dateisoTourStart');
      }

      if (disabledPagination.tourEnd) {
        tourrequests = tourRequestClientSideFiltering(tourrequests, appliedFilterTourEnd!, 'dateisoTourEnd');
      }

      // Client side filtering not needed for payment dates as we use a simple filter that doesn't allow selecting ranges

      setRequestList(tourrequests);

      let newCurrentLastPageDoc;
      if (!disabledPagination.anyField && snapshot.docs.length === numberPerPage) {
        newCurrentLastPageDoc = snapshot.docs.at(-1)!;
      } else {
        // this page isn't full, so there is certainly no next page
        newCurrentLastPageDoc = null;
      }
      setCurrentPageLastDoc(newCurrentLastPageDoc);

      setIsLoading(false);

      if (!disabledPagination.anyField) {
        getCountFromServer(q_full)
          .then((snapshot) => {
            if (useEffectCancelled) return;
            setRowCount(snapshot.data().count);
          })
          .catch((err) => setDbError('Getting tour request row count', err));
      } else {
        setRowCount(tourrequests.length); // no pagination, so we have the entire result set here and the row count is just the length of the result set
      }
    };

    const queryConstraints: WhereClauseType[] = [];
    let orderConstraint: QueryOrderByConstraint;
    const paginationConstraints = [];

    if (statusKey === 'confirmed_active' || statusKey === 'ongoing' || statusKey === 'confirmed_archived') {
      orderConstraint = orderBy('dateisoTourStart', 'asc');
    } else if (statusKey === 'lost_and_cancelled' || statusKey === 'all') { // for status lost, the tour might not have a start date specified, so it makes more sense to sort by creating date
      orderConstraint = orderBy('dateCreated', 'desc');
    } else {
      const _typecheck: never = statusKey;
      throw new Error('unreachable');
    }

    if (!disabledPagination.anyField) {
      if (previousPagesLastDocs.length > 0) {
        paginationConstraints.push(startAfter(previousPagesLastDocs.at(-1)));
      }
      if (numberPerPage) {
        paginationConstraints.push(limit(numberPerPage));
      }
    }

    if (!showExploreSeries) {
      queryConstraints.push(['isExploreSeries', '==', false]);
    }

    if (appliedFilterRequestCode) {
      //desc = `FILTER_REQUESTCODE checkboxes ${requestCodeFilter}`

      const listRequestCodes: string[] = [...appliedFilterRequestCode.keys()];
      queryConstraints.push(['requestCode', 'in', listRequestCodes]);
    }
    if (appliedFilterTourStart) {
      const { queryConstraints: constraints, clientSideFiltering } = getQueryConstraintsBasedOnDateFilter(appliedFilterTourStart, 'dateisoTourStart');
      if (clientSideFiltering !== disabledPagination.tourStart) throw new Error('unreachable - client side filtering tour start');
      queryConstraints.push(...constraints);
    }
    if (appliedFilterTourEnd) {
      const { queryConstraints: constraints, clientSideFiltering } = getQueryConstraintsBasedOnDateFilter(appliedFilterTourEnd, 'dateisoTourEnd');
      if (clientSideFiltering !== disabledPagination.tourEnd) throw new Error('unreachable - client side filtering tour end');
      queryConstraints.push(...constraints);
    }
    if (appliedFilterPaymentDate) {
      // only support the case of a list of individual dates
      // don't support ranges, entire months, entire years, etc.
      queryConstraints.push(['paymentDatesCache', 'array-contains-any', [...appliedFilterPaymentDate.keys()]]);
    }
    if (appliedFilterPaymentAmount) {
      queryConstraints.push(['paymentAmountsCache', 'array-contains-any', [...appliedFilterPaymentAmount.keys()].map((sAmount) => Number(sAmount))]);
    }

    if (appliedFilterTeamCategory) {
      queryConstraints.push(['eightyDaysDepartment', 'in', [...appliedFilterTeamCategory.keys()]]);
    }
    if (appliedFilterAgency) {
      queryConstraints.push(['agencyOrPlatformId', 'in', [...appliedFilterAgency.keys()]]);
    }
    if (appliedFilterCountry) {
      queryConstraints.push(['country', 'in', [...appliedFilterCountry.keys()]]);
    }
    if (appliedFilterPaxName) {
      queryConstraints.push(['travellerName', 'in', [...appliedFilterPaxName.keys()]]);
    }
    if (appliedFilterCustomerType) {
      queryConstraints.push(['customerType', 'in', [...appliedFilterCustomerType.keys()]]);
    }

    if (paramDesigner) {
      queryConstraints.push(['usersDesignersUids', 'array-contains', paramDesigner]);
    }

    if (statusKey === 'all') {
      // no filtering
    } else if (statusKey === 'confirmed_active') {
      queryConstraints.push(['statusConfirmed_active', '==', true]);
    } else if (statusKey === 'ongoing') {
      queryConstraints.push(['statusOngoing', '==', true]);
    } else if (statusKey === 'confirmed_archived') {
      queryConstraints.push(['statusConfirmed_archived', '==', true]);
    } else if (statusKey === 'lost_and_cancelled') {
      queryConstraints.push(['statusCancelled_or_lost', '==', true]);
    } else {
      const _typecheck: never = statusKey;
      throw new Error('unreachable');
    }

    let firestoreQueryConstraints: QueryFieldFilterConstraint[];

    const hasIneq = queryConstraints.find((q) => isIneq(q[1]));

    console.log('hasIneq', hasIneq);
    if (hasIneq) {

      // as the query has an inequality, we must rebuild it from scratch to use the master index

      const fieldList = [
        // 'statusConfirmed_active',
        // 'statusOngoing',
        // 'statusConfirmed_archived',
        // 'statusCancelled_or_lost',
        'agencyOrPlatformId',
        'country',
        'customerType',
        'eightyDaysDepartment',
        'requestCode',
        'travellerName',
        'dateisoTourStart',
        'dateisoTourEnd',
      ];

      const newConstraints: WhereClauseType[] = [];

      if (statusKey === 'all') {
        // no filtering by status
      } else if (statusKey === 'confirmed_active') {
        newConstraints.push(['statusConfirmed_active', '==', true]);
      } else if (statusKey === 'ongoing') {
        newConstraints.push(['statusOngoing', '==', true]);
      } else if (statusKey === 'confirmed_archived') {
        newConstraints.push(['statusConfirmed_archived', '==', true]);
      } else if (statusKey === 'lost_and_cancelled') {
        newConstraints.push(['statusCancelled_or_lost', '==', true]);
      } else {
        const _typecheck: never = statusKey;
        throw new Error('unreachable');
      }

      // we don't handle inequality + 'array-contains' constraints, as they would require additional indexes, because array-contains can't be replaced with a >= + <= combination
      const constraintsArrayContains = queryConstraints.filter((q) => q[1] === 'array-contains' || q[1] === 'array-contains-any');
      if (constraintsArrayContains.length > 0) {
        const errormessage = `Query is not currently supported because it combines an inequality on field [${hasIneq[0]}] and an "array-contains" clause on field [${constraintsArrayContains.map((x) => x[0]).join(', ')}]`;
        log_info({ db, userDetails, logkey: 'error.query_not_supported.ineq_with_array_contains', desc: errormessage });
        setQueryErrorMessage(errormessage);
        setRowCount(undefined);
        return;
      }

      // sanity check: make sure there are no constraints on fields that are not in master index
      // console.log('queryConstraints', queryConstraints)
      const unknownConstraints = queryConstraints.filter((x) => !fieldList.includes(x[0]) && x[0] !== 'statusConfirmed_active' && x[0] !== 'statusOngoing' && x[0] !== 'statusConfirmed_archived' && x[0] !== 'statusCancelled_or_lost');
      if (unknownConstraints.length > 0) {
        const errormessage = `Query is not currently supported because it contains constraints on fields that are not in the master index: [${unknownConstraints.map((x) => x[0]).join(', ')}]`;
        throw new Error(errormessage); // should never happen
        // log_info({ db, userDetails, logkey: 'error.query_not_supported.ineq_with_unknown_fields', desc: errormessage })
        // setQueryErrorMessage(errormessage)
        // setRowCount(undefined)
        // return
      }


      for (const fieldName of fieldList) {
        const existingConstraints = queryConstraints.filter((q) => q[0] === fieldName);

        if (existingConstraints.length === 0) {
          // no constraint on this field, we add a dummy constraint in order to use the master index
          // string field is always >=''  (boolean field would use >=false)
          newConstraints.push([fieldName, '>=', '']);
          continue;
        }

        if (existingConstraints.some((x) => isIneq(x[1]))) {
          // already an inequality constraint

          // sanity checks
          if (existingConstraints.length !== 1 && existingConstraints.length !== 2)
            throw new Error('must be 1 or 2 inequalities');
          if (existingConstraints.some((x) => !isIneq(x[1])))
            throw new Error('cannot handle mix of equality and inequality constraints on same field');

          // push the inequality constraints as-is
          newConstraints.push(...existingConstraints);
          continue;
        }

        // equality constraints: 1 per field
        if (existingConstraints.length > 1) {
          throw new Error('multiple constraints on same field');
        }
        const existingConstraint = existingConstraints[0];

        if (existingConstraint[1] === '==') {
          newConstraints.push([existingConstraint[0], '>=', existingConstraint[2]]);
          newConstraints.push([existingConstraint[0], '<=', existingConstraint[2]]);
        } else if (existingConstraint[1] === 'in') {
          const list = existingConstraint[2] as string[];
          if (list.length === 1) {
            newConstraints.push([existingConstraint[0], '>=', list[0]]);
            newConstraints.push([existingConstraint[0], '<=', list[0]]);
          } else {
            const errormessage = `Query is not currently supported because it combines an inequality on field [${hasIneq[0]}] and an "in" clause on field [${fieldName}] with multiple possible values[${list.join(', ')}]`;
            // throw new Error(errormessage)
            log_info({ db, userDetails, logkey: 'error.query_not_supported.ineq_with_in', desc: errormessage });
            setQueryErrorMessage(errormessage);
            setRowCount(undefined);
            return;
            // TODO: this could be handled by separating the OR clauses into separate queries on client side
          }
        } else {
          // unreachable?
          throw new Error('query type not implemented (op is not "==" or "in")');
        }
      }

      firestoreQueryConstraints = newConstraints.map(([field, op, value]) => where(field, op, value));

    } else {

      firestoreQueryConstraints = queryConstraints.map(([field, op, value]) => where(field, op, value));
    }

    console.log('query constraints', [...firestoreQueryConstraints, orderConstraint].map(formatConstraint));

    const q_full = query(collection(db, 'tourrequests'), ...firestoreQueryConstraints, orderConstraint); // query without limit(), to get the full row count
    const q_paginated = query(collection(db, 'tourrequests'), ...firestoreQueryConstraints, orderConstraint, ...paginationConstraints);
    // q.explain({analyze:true})

    let unsubscribe: Unsubscribe;
    if (isExpanded) {

      log_db_read({ db, userDetails, logkey: 'db_read.list_tourrequests', desc: 'List requests' });

      setIsLoading(true);

      unsubscribe = onSnapshot(q_paginated, (snapshot: QuerySnapshot) => processSnapshot(snapshot), (err) => setDbError('Getting request list', err));

    } else {

      if (disabledPagination.anyField) {
        setRowCount(undefined); // row count unknown as it requires client side filtering
      } else {
        getCountFromServer(q_full)
          .then((snapshot) => {
            if (useEffectCancelled) return;
            setRowCount(snapshot.data().count);
          })
          .catch((err) => setDbError('Getting tour request row count', err));
      }

    }

    return () => {
      useEffectCancelled = true;
      if (unsubscribe) {
        unsubscribe();
      }
    };


  }, [db, setDbError, setCurrentPageLastDoc, setRequestList, setIsLoading, previousPagesLastDocs, numberPerPage, userDetails,
    appliedFilterRequestCode, appliedFilterAgency, appliedFilterCountry, appliedFilterPaxName,
    appliedFilterCustomerType, appliedFilterTourStart, appliedFilterTourEnd, appliedFilterPaymentDate,
    appliedFilterPaymentAmount, appliedFilterTeamCategory, showExploreSeries, disabledPagination,
    paramDesigner, ACTIVE, statusKey, isExpanded]);


  return {
    statusKey,
    requestList,                                        // set inside processSnapshot
    currentPageLastDoc,                                 // set inside processSnapshot. null if currently on last page.
    rowCount,                                           // set inside processSnapshot (via callback) if isExpanded, or in main hook body (via callback) if !isExpanded. undefined if row count unknown.
    queryErrorMessage,
    previousPagesLastDocs, setPreviousPagesLastDocs,    // set when clicking prev_page/next_page. [] when going to first page. length = currentPageNum - 1
    isLoading, setIsLoading,
    numberPerPage, setNumberPerPage,
    isExpanded, setIsExpanded,
  };

}
