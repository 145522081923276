import { doc, updateDoc } from 'firebase/firestore';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { useAppContext } from 'src/hooks/useAppContext';
import { coll_tripquotations, TripQuotationType } from 'src/types/types_tripquotations';



interface DeleteQuotationButtonProps {
  quotation: TripQuotationType;
}

export function DeleteQuotationButton({
  quotation,
}: DeleteQuotationButtonProps) {

  const { db, setDbError, userDetails } = useAppContext();


  return (
    <DeleteButton
      onClick={() => {
        if (quotation.userCreated.uid !== userDetails.id) {
          window.alert('Only the creator can delete the quotation');
          return;
        }
        if (!window.confirm('Delete quotation?'))
          return;

        const updateObj: Partial<TripQuotationType> = {
          _isDeleted: true,
        };
        updateDoc(doc(db, coll_tripquotations, quotation.id), updateObj);
      }}
    />

  );
}
