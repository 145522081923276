import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { ButtonSm } from 'src/components/Buttons/ButtonSm';
import { useAppContext } from 'src/hooks/useAppContext';
import { UserDetailsType, UserPermissionType } from 'src/types/types_user';
import { UserRoleMainType, UserRoleOthersType, UserRolesType, getPermissionObjectFromRoles, userRolesMainList, userrole_isAdmin } from 'src/util/user_roles';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { log_db_write } from 'src/util/util_log';



interface UserListEditPermissionsProps {
  user: UserDetailsType;
}

export function UserListEditPermissions({
  user,
}: UserListEditPermissionsProps) {


  const { db, userDetails, setDbError } = useAppContext();

  // state will remain null until user selects a value, so that if role is change in Firestore by another user, it gets properly
  // reflected in the UI here
  const [roleMain, setRoleMain] = useState<UserRoleMainType | null>(null);
  const [roleExpenseAccountingChecked, setRoleExpenseAccountingChecked] = useState<boolean | null>(null);
  const [roleCalendarEditorChecked, setRoleCalendarEditorChecked] = useState<boolean | null>(null);
  const [roleCalendarViewAllGuidesChecked, setRoleCalendarViewAllGuidesChecked] = useState<boolean | null>(null);
  const [roleQuotationsEditChecked, setRoleQuotationsEditChecked] = useState<boolean | null>(null);

  const current_roleMain = roleMain ?? user.roles?.mainRole ?? 'none';
  const current_roleExpenseAccounting = roleExpenseAccountingChecked ?? user.roles?.roleFlags?.role_expense_accounting ?? false;
  const current_roleCalendarEditor = roleCalendarEditorChecked ?? user.roles?.roleFlags?.role_calendar_editor ?? false;
  const current_roleCalendarViewAllGuides = roleCalendarViewAllGuidesChecked ?? user.roles?.roleFlags?.role_calendar_view_all_guides ?? false;
  const current_roleQuotationsEdit = roleQuotationsEditChecked ?? user.roles?.roleFlags?.role_quotations_edit ?? false;

  const isModified_roleMain = current_roleMain !== user.roles?.mainRole;
  const isModified_roleExpenseAccounting = current_roleExpenseAccounting !== user.roles?.roleFlags?.role_expense_accounting;
  const isModified_roleCalendarEditor = current_roleCalendarEditor !== user.roles?.roleFlags?.role_calendar_editor;
  const isModified_roleCalendarViewAllGuides = current_roleCalendarViewAllGuides !== user.roles?.roleFlags?.role_calendar_view_all_guides;
  const isModified_roleQuotationsEdit = current_roleQuotationsEdit !== user.roles?.roleFlags?.role_quotations_edit;

  const isModified_roleFlags = isModified_roleExpenseAccounting || isModified_roleCalendarEditor || isModified_roleCalendarViewAllGuides || isModified_roleQuotationsEdit;
  const isModified = isModified_roleMain || isModified_roleFlags;

  return (
    <div className='tw-flex'>
      <div className='_left_block '>
        <div className='_main_role tw-flex tw-gap-2'>
          <label htmlFor={`mainRole_dropdown_${user.id}`}>Role:</label>
          <div>
            {userrole_isAdmin(user.roles) ? (
              <input type='text' id={`mainRole_dropdown_${user.id}`} value={user.roles.mainRole} disabled={true} readOnly={true} style={{ width: '7em', padding: 0 }} />
            ) : (
              <select id={`mainRole_dropdown_${user.id}`} value={current_roleMain} onChange={(e) => {
                setRoleMain(e.currentTarget.value as UserRoleMainType);
              }}>
                {[...userRolesMainList].reverse().filter((r) => r !== 'dev' && r !== 'admin').map((role) => <option key={role}>{role}</option>)}
              </select>
            )}
          </div>
        </div>
        <div className='_role_expense_accounting tw-mt-2'>
          <label htmlFor={`chkRoleExpenseAccounting_${user.id}`}>
            <input type='checkbox' id={`chkRoleExpenseAccounting_${user.id}`}
              checked={current_roleExpenseAccounting}
              onChange={(e) => {
                setRoleExpenseAccountingChecked(e.currentTarget.checked);
              }} />{' '}
            Expense accounting
            <i className='bi bi-info-circle tw-ml-1' title='Can view all guides’ expense accounting'></i>
          </label>
        </div>
        <div className='_role_calendar_editor'>
          <label htmlFor={`chkRoleCalendarEditor_${user.id}`}>
            <input type='checkbox' id={`chkRoleCalendarEditor_${user.id}`}
              checked={current_roleCalendarEditor}
              onChange={(e) => {
                setRoleCalendarEditorChecked(e.currentTarget.checked);
              }} />{' '}
            Calendar editor
            <i className='bi bi-info-circle tw-ml-1' title='Can edit calendar'></i>
          </label>
        </div>
        <div className='_role_view_all_guides'>
          <label htmlFor={`chkRoleCalendarViewAllGuides_${user.id}`}>
            <input type='checkbox' id={`chkRoleCalendarViewAllGuides_${user.id}`}
              checked={current_roleCalendarViewAllGuides}
              onChange={(e) => {
                setRoleCalendarViewAllGuidesChecked(e.currentTarget.checked);
              }} />{' '}
            View all tour leaders on calendar (for senior tour leader)
            <i className='bi bi-info-circle tw-ml-1' title='Travel designers can already view all tour leaders on the calender. This permission is for non travel designers (e.g. senior tour leaders) so that they can view all other tour leaders’ calendars'></i>
          </label>
        </div>
        <div className='_role_quotations_edit'>
          <label htmlFor={`chkRoleQuotationsEdit_${user.id}`}>
            <input type='checkbox' id={`chkRoleQuotationsEdit_${user.id}`}
              checked={current_roleQuotationsEdit}
              onChange={(e) => {
                setRoleQuotationsEditChecked(e.currentTarget.checked);
              }} />{' '}
            Edit quotations and supplier prices
          </label>
        </div>
      </div>
      <div>
        <ButtonSm orange={isModified} className='tw-ms-1' onClick={() => {

          if (!userRolesMainList.includes(current_roleMain))
            throw new Error(`invalid roleMain: ${current_roleMain}`);

          let desc = '';
          if (isModified_roleMain) desc += `[mainRole:${user.roles?.mainRole}→${current_roleMain}]`;
          if (isModified_roleExpenseAccounting) desc += `[role_expense_accounting:${user.roles?.roleFlags?.role_expense_accounting}→${current_roleExpenseAccounting}]`;
          if (isModified_roleCalendarEditor) desc += `[role_calendar_editor:${user.roles?.roleFlags?.role_calendar_editor}→${current_roleCalendarEditor}]`;
          if (isModified_roleCalendarViewAllGuides) desc += `[role_calendar_view_all_guides:${user.roles?.roleFlags?.role_calendar_view_all_guides}→${current_roleCalendarViewAllGuides}]`;

          const role_others_obj: UserRoleOthersType = {
            role_expense_accounting: current_roleExpenseAccounting,
            role_calendar_editor: current_roleCalendarEditor,
            role_calendar_view_all_guides: current_roleCalendarViewAllGuides,
            role_quotations_edit: current_roleQuotationsEdit,
          };

          const roles: UserRolesType = {
            mainRole: current_roleMain,
            roleFlags: role_others_obj,
          };

          const permObj0 = getPermissionObjectFromRoles(roles);
          const permObj: Omit<UserPermissionType, 'id'> = {
            ...permObj0,
            email: user.email,
            dateModified: serverTimestampAsDate(),
          };

          setDoc(doc(db, 'permissions', user.id), permObj); // use setDoc to clean up any obsolete permission fields
          updateDoc(doc(db, 'users', user.id), {
            roles,
            dateModified: serverTimestampAsDate(),
          })
            .then(() => {
              setRoleMain(null);
              setRoleExpenseAccountingChecked(null);
              setRoleCalendarEditorChecked(null);
            });
          log_db_write({ db, userDetails, logkey: 'db_write.update_permissions', desc: `Updated permissions on ${user.email}: ${desc}` });
        }}>Save</ButtonSm>
      </div>
    </div>
  );
}
