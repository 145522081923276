


interface CsvSourceTableProps {
  sourceCsvHeaders: string[] | null;
  csvdatarows: string[][];
}

export function CsvSourceTable({
  sourceCsvHeaders,
  csvdatarows,
}: CsvSourceTableProps) {

  if (csvdatarows.length === 0)
    return null;

  return (
    <table className='tw-text-xs tw-bg-stone-200 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-stone-400
    [&>*>tr>*]:tw-p-1 tw-m-1'>
      <thead>
        <tr>
          {sourceCsvHeaders?.map((header, i) => <th key={i}>{header}</th>)}
        </tr>
      </thead>
      <tbody>
        {csvdatarows.map((row, i) => (
          <tr key={i}>
            {row?.map((cell, i) => <td key={i}>{cell}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
