


interface ScrollableTableProps {
  headers: string[];
  headerClassNames: string[];
  columnWidths: string[];
  data: { id: string; cells: string[]; onClick?: () => void }[];
  tableHeight: string;
}

export function ScrollableTable({
  headers,
  headerClassNames,
  columnWidths,
  data,
  tableHeight,
}: ScrollableTableProps) {

  const headerRowHeight = '2.5rem';

  return (
    <div className='__container tw-relative tw-border-r tw-border-t tw-border-l-0 tw-border-b-0 tw-border-solid tw-border-slate-300 tw-w-fit'>
      <div className='tw-bg-slate-200' style={{ height: headerRowHeight }}></div>
      <div className='__scrollable tw-overflow-y-auto' style={{ maxHeight: tableHeight }}>
        <table className={
          `[&>*>tr>td]:tw-border-l [&>*>tr>td]:tw-border-b [&>*>tr>td]:tw-border-solid [&>*>tr>td]:tw-border-slate-300 tw-bg-white
           [&>*>tr>td]:tw-py-2 [&>*>tr>td]:tw-px-2
           tw-border-separate tw-border-spacing-0` // `tw-border-separate tw-border-spacing-0` is necessary for vertical borders on headers and main table cells to be aligned
        }>
          {columnWidths && (
            <colgroup>
              {columnWidths.map((width, i) => (
                <col key={i} style={{ width }} />
              ))}
            </colgroup>
          )}
          <thead>
            <tr>
              {headers.map((header, i) => (
                <th key={i} className='tw-h-0 tw-leading-[0] tw-p-0'>
                  <div className={`
                      tw-absolute tw-top-0 tw-leading-normal tw-p-2
                      tw-border-l tw-border-y-0 tw-border-r-0 tw-border-solid tw-border-slate-300
                      ${headerClassNames[i]}`}
                    style={{ height: headerRowHeight, width: columnWidths[i] }}>
                    {header}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, i) => (
              <tr
                key={row.id}
                className='tw-cursor-pointer [&>td]:hover:tw-bg-blue-400/20'
                onClick={row.onClick}>
                {row.cells.map((cell, j) => (
                  <td key={j} className='tw-cursor-pointer'>
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
