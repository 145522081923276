

interface WhoInputsThisProps {
  show: boolean;
  role: string;
}

export function WhoInputsThis({ show, role }: WhoInputsThisProps) {

  if (!show)
    return <></>;

  return (
    <div className='who-inputs-this'>input by {role}</div>
  );
}
