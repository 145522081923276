import { UserDetailsType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { isDevMode } from 'src/util/util_getbuildtime';


export function addMetadataModifiedExpenseSheet(updateObj: any, userDetails: UserDetailsType) {

  // probably never happens
  if (isDevMode() && ('id' in updateObj)) throw new Error('id should not be in the object data');

  delete updateObj.id; // id should NOT be in the object data
  updateObj.dateModified = serverTimestampAsDate();
  updateObj.userModifiedUid = userDetails.id;
  updateObj.userModifiedEmail = userDetails.email;
  updateObj.userModifiedName = userDetails.displayNameEn;

  return updateObj;
}
