import { getTodayUTC, iso_from_utc0 } from './datetools';



export function getMonthList(startYear: number, startMonth: number) {
  const currentMonth = iso_from_utc0(getTodayUTC()).substring(0, 7);

  const list = [];
  let year = startYear;
  let month = startMonth;
  for (; ;) {
    const sMonth = `${year}-${month.toString().padStart(2, '0')}`;
    list.push(sMonth);
    if (sMonth >= currentMonth)
      break;
    month++;
    if (month > 12) {
      year++;
      month = 1;
    }
  }
  // list from most recent to oldest
  list.reverse();
  return list;
}
