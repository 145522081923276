import { ReactNode } from 'react';
import { QuotegridUploadedFilesType } from 'src/types/types_quotegrid';



const getUploadedFileByName = (name: string, uploadedFiles: QuotegridUploadedFilesType) => {
  if (!uploadedFiles)
    return;
  const keys = Object.keys(uploadedFiles);
  for (const key of keys) {
    const item = uploadedFiles[key];
    if (item.name === name)
      return item;
  }
};

export const renderImageTags = (strValue: string, uploadedFiles: QuotegridUploadedFilesType) => {
  const memoParts: ReactNode[] = [];
  const re = /<img src="([^>"]+)"( +width=([0-9]+))?>/g;
  let match = null;
  const lines = strValue.split('\n');
  lines.forEach((line, iLine) => {
    let currentIndex = 0;
    let k = 0;
    while ((match = re.exec(line)) !== null) {
      memoParts.push(line.substring(currentIndex, match.index));
      const matchLength = match[0].length;
      const name = match[1];
      const sWidth = match[3]; // string or undefined
      const uploadedFile = getUploadedFileByName(name, uploadedFiles);
      if (uploadedFile) {
        if (uploadedFile.downloadURL) {
          memoParts.push(<img key={`img_${iLine}_${k}`} src={uploadedFile.downloadURL} width={sWidth} />);
        } else {
          memoParts.push('[IMG]');
        }
      } else {
        memoParts.push('[image not found]');
      }
      k++;
      currentIndex = match.index + matchLength;
    }
    memoParts.push(line.substring(currentIndex));
    memoParts.push(<br key={`br_${iLine}`} />);
  });

  return memoParts;
};
