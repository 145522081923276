import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { ExpensePaymentType } from 'src/types/types_expensesheet';
import { dateFormatIso } from 'src/util/dateformattools';


interface PaymentsTableProps {
  donePayments: Map<string, ExpensePaymentType>;
}

export function PaymentsTable({
  donePayments,
}: PaymentsTableProps) {

  const [showBottomPaymentsTable, setShowBottomPaymentsTable] = useState(false);


  const paymentsTableRows: JSX.Element[] = [];
  [...donePayments].forEach(([key, payment]) => {
    paymentsTableRows.push(
      <tr key={payment.id}>
        <td>{payment.id}</td>
        <td>{key}</td>
        <td>{payment.isAdvance ? 'true' : 'false'}</td>
        <td>{payment.paymentSourceAccount}</td>
        <td>{payment.amount}</td>
        <td>{dateFormatIso(payment.paymentDate)}</td>
        <td>{payment.status}</td>
        <td>{payment.userPaymentToEmail}</td>
        <td>{payment.tourCode}</td>
        <td>{payment.sheetId}</td>
        <td>{payment.advancePaymentId}</td>
        <td>{payment.salaryMonth}</td>
        <td>{payment.dateCreated && (
          // dateCreated will be null immediately after the payment is created. see https://stackoverflow.com/a/57275922/
          dateFormatIso(payment.dateCreated)
        )}</td>
        <td>{payment.dateModified && (
          // dateModified will be null immediately after the payment is created.
          dateFormatIso(payment.dateModified)
        )}</td>
        <td>{payment.userCreatorEmail}</td>
      </tr>
    );
  });



  return (
    <>
      <h5 className='mt-5'>Payments: <ButtonTW variant='link' onClick={(e) => {
        setShowBottomPaymentsTable(!showBottomPaymentsTable);
      }}>{showBottomPaymentsTable ? 'Hide' : 'Show'}</ButtonTW></h5>
      {showBottomPaymentsTable && (
        <table className='table'>
          <thead>
            <tr>
              <th>id</th>
              <th>lookup key</th>
              <th>isAdvance</th>
              <th>paymentSourceAccount</th>
              <th>amount</th>
              <th>paymentDate</th>
              <th>status</th>
              <th>userPaymentToEmail</th>
              <th>tourCode</th>
              <th>sheetId</th>
              <th>advancePaymentId</th>
              <th>salaryMonth</th>
              <th>dateCreated</th>
              <th>dateModified</th>
              <th>userCreatorEmail</th>
            </tr>
          </thead>
          <tbody>
            {paymentsTableRows}
          </tbody>
        </table>
      )}
    </>
  );
}
