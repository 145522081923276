

export function getBuildTime() {

  // string to be replaced during build process:
  const currentVersionDefine = '__DEFINE_BUILD_TIMESTAMP__'; // string is not replaced in dev mode, stays as is
  const currentVersionEnvvar = import.meta.env.VITE_LG_BUILD_TIME; // undefined in dev mode

  // console.log(`currentVersionDefine: [${currentVersionDefine}]`)
  // console.log(`currentVersionEnvvar: [${currentVersionEnvvar}]`)

  if (currentVersionDefine.startsWith('__DEFINE_')) {
    // vite serve: don't check version
    // console.log('build date not replaced')
    return 'dev';
  }

  // env var has priority
  if (currentVersionEnvvar.endsWith('_FHWCB8129')) {
    const curVerIso = currentVersionEnvvar.substring(0, currentVersionEnvvar.length - 10);
    console.log('curVerIso', curVerIso);
    const curVer = new Date(curVerIso);
    console.log('curVer', curVer);
    return curVer;
  }

  // if (currentVersionDefine.startsWith("'") && currentVersionDefine.endsWith("'")) {
  //   const curVerIso = currentVersionDefine.substring(1, currentVersionDefine.length - 1)
  if (currentVersionDefine.endsWith('_WZNCH6P08')) {
    const curVerIso = currentVersionDefine.substring(0, currentVersionDefine.length - 10);
    console.log('curVerIso', curVerIso);
    const curVer = new Date(curVerIso);
    console.log('curVer', curVer);
    return curVer;
  }

  throw new Error(`Could not parse build timestamp: ${currentVersionDefine}`);

}

export function getBuildTimeStr() {
  const buildTime = getBuildTime();
  if (buildTime === 'dev')
    return 'dev';
  return buildTime.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
}

export function isDevMode() {
  // means we are running vite serve
  return getBuildTime() === 'dev';
}
