

// code: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// api: https://labs.goo.ne.jp/api/jp/hiragana-translation/

// alternative API (provides furigana): https://developer.yahoo.co.jp/webapi/jlp/furigana/v2/furigana.html

// Example POST method implementation:
async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //mode: 'cors', // no-cors, *cors, same-origin
    //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    //redirect: 'follow', // manual, *follow, error
    //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function getKana(sentence: string) {
  const result = await postData('https://labs.goo.ne.jp/api/hiragana', {
    app_id: '641d0965e4212ce215c73778ce230365c7476690fb1116b3790da3cc4f73377c',
    sentence,
    output_type: 'katakana',
  });
  //   .then((data) => {
  //   console.log(data); // JSON data parsed by `data.json()` call
  // });

  const { converted } = result;

  // sometimes the converted kana contain double spaces
  // (when original string had one ideographic space maybe?)
  // so we clean up the double spaces here
  const cleanKana = converted.replaceAll('  ', ' ');

  return cleanKana;
}
