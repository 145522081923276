import { WhereClauseType } from 'src/pages/Requests/RequestsList/useQueryRequestList';
import { ColumnFilterDateisoType, ThreeLevelDateTree, TreeListStateType } from 'src/types/types_columnfilters';
import { TourRequestType } from 'src/types/types_tourrequest';
import { addDaysIso, addMonthsIso, addYearsIso, dateIso, dateparts_from_iso, maxDateiso, minDateiso, monthIso } from 'src/util/datetools';
import { max, min } from 'src/util/util_misc';



export function constructDefaultTreeListState(filterCacheDates: ThreeLevelDateTree) {
  const treeListState: TreeListStateType = {
    ternarySelected: 0,
    ternarySelectedPaid: 0,
    emptyValues: 0,
  };

  for (const [year, months] of filterCacheDates) {
    treeListState[year.toString()] = 0;
    for (const [month, days] of months) {
      const monthiso = monthIso(year, month);
      treeListState[monthiso] = 0;
      for (const day of days) {
        const dateiso = dateIso(year, month, day);
        treeListState[dateiso] = 0;
      }
    }
  }

  return treeListState;
}



export function getDateFilterFromParam(
  selectedDate: string | null,
  filterCacheDates: ThreeLevelDateTree | undefined,
): ColumnFilterDateisoType | null | undefined {

  if (!selectedDate) return null;
  if (!filterCacheDates) return undefined; // still loading

  {
    const match = selectedDate.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (match) {
      return {
        filterMode: 'equals',
        filterEquals: selectedDate,
      };
    }
  }

  {
    const match = selectedDate.match(/^(\d{4}-\d{2}-\d{2})~$/);
    if (match) {
      return {
        filterMode: 'range',
        filterLowerBound: match[1],
      };
    }
  }

  {
    const match = selectedDate.match(/^~(\d{4}-\d{2}-\d{2})$/);
    if (match) {
      return {
        filterMode: 'range',
        filterUpperBound: match[1],
      };
    }
  }

  {
    const match = selectedDate.match(/^(\d{4}-\d{2}-\d{2})~(\d{4}-\d{2}-\d{2})$/);
    if (match) {
      return {
        filterMode: 'range',
        filterLowerBound: match[1],
        filterUpperBound: match[2],
      };
    }
  }

  if (!selectedDate.match(/^\[.+\]$/))
    throw new Error(`Invalid date filter parameter: ${selectedDate}`);

  const treeListState = constructDefaultTreeListState(filterCacheDates);

  const list = selectedDate.slice(1, -1).split(',');


  if (list.includes('all')) {
    // ALL SELECTED
    treeListState.emptyValues = 1;
    treeListState.ternarySelected = 1;
    treeListState.ternarySelectedPaid = 1;
    for (const [year, months] of filterCacheDates) {
      treeListState[year.toString()] = 1;
      for (const [month, days] of months) {
        treeListState[monthIso(year, month)] = 1;
        for (const day of days) {
          treeListState[dateIso(year, month, day)] = 1;
        }
      }
    }
  } else {

    treeListState.emptyValues = list.includes('notpaid') ? 1 : 0;

    if (list.includes('paid')) {
      // FULL DECADE
      treeListState.ternarySelectedPaid = 1;
      for (const [year, months] of filterCacheDates) {
        treeListState[year.toString()] = 1;
        for (const [month, days] of months) {
          treeListState[monthIso(year, month)] = 1;
          for (const day of days) {
            treeListState[dateIso(year, month, day)] = 1;
          }
        }
      }

    } else {
      for (const [year, months] of filterCacheDates) {
        if (list.includes(year.toString())) {
          // FULL YEAR
          treeListState[year.toString()] = 1;
          for (const [month, days] of months) {
            treeListState[monthIso(year, month)] = 1;
            for (const day of days) {
              treeListState[dateIso(year, month, day)] = 1;
            }
          }
        } else {
          // PARTIAL YEAR
          for (const [month, days] of months) {
            const monthiso = monthIso(year, month);
            if (list.includes(monthiso)) {
              // FULL MONTH
              treeListState[monthiso] = 1;
              for (const day of days) {
                treeListState[dateIso(year, month, day)] = 1;
              }
            } else {
              // PARTIAL MONTH
              for (const day of days) {
                const dateiso = dateIso(year, month, day);
                if (list.includes(dateiso)) {
                  treeListState[dateiso] = 1;
                }
              } // each day
              treeListState[monthIso(year, month)] = refreshMonthTernaryState(treeListState, year, month, days);
            }
          } // each month
          treeListState[year.toString()] = refreshYearTernaryState(treeListState, year, months);
        }
      } // each year

    } // not 'all paid'

    refreshOverallTernaryState(treeListState, filterCacheDates);

  } // not 'all'

  return {
    filterMode: 'checkboxes',
    treeListState,
  };
}


export function refreshMonthTernaryState(treeListState: TreeListStateType, year: number, month: number, days: number[]) {
  let allOne = true, allZero = true;
  for (const day of days) {
    const dayState = treeListState[dateIso(year, month, day)];
    if (dayState !== 1) allOne = false;
    if (dayState !== 0) allZero = false;
  }
  return allOne ? 1 : allZero ? 0 : 0.5;
}


export function refreshYearTernaryState(treeListState: TreeListStateType, year: number, months: Map<number, number[]>) {

  let allOne = true, allZero = true;
  for (const month of months.keys()) {
    const monthState = treeListState[monthIso(year, month)];
    if (monthState !== 1) allOne = false;
    if (monthState !== 0) allZero = false;
  }
  return allOne ? 1 : allZero ? 0 : 0.5;
}

export function refreshOverallTernaryState(treeListState: TreeListStateType, allDates: ThreeLevelDateTree) {
  let allOne = true, allZero = true;
  for (const year of allDates.keys()) {
    const yearState = treeListState[year.toString()];
    if (yearState !== 1) allOne = false;
    if (yearState !== 0) allZero = false;
  }

  treeListState.ternarySelectedPaid = allOne ? 1 : allZero ? 0 : 0.5;

  if (treeListState.emptyValues !== 1) allOne = false;
  if (treeListState.emptyValues !== 0) allZero = false;

  treeListState.ternarySelected = allOne ? 1 : allZero ? 0 : 0.5;
}


export function getMinMaxDates(
  selectedDate: string,
  appliedFilter: ColumnFilterDateisoType,
  descBase: string,
): {
  desc: string;
  dateisoMin: string | undefined;
  dateisoMaxExc: string | undefined;
} {

  let dateisoMin: string | undefined;
  let dateisoMaxExc: string | undefined;
  let desc: string;

  if (appliedFilter.filterMode === 'equals') {
    desc = `${descBase} equals ${selectedDate}`;
    dateisoMin = appliedFilter.filterEquals;
    dateisoMaxExc = addDaysIso(dateisoMin, 1);
  } else if (appliedFilter.filterMode === 'range') {
    desc = `${descBase} range ${selectedDate}`;
    dateisoMin = appliedFilter.filterLowerBound;
    dateisoMaxExc = appliedFilter.filterUpperBound ? addDaysIso(appliedFilter.filterUpperBound, 1) : undefined;
  } else if (appliedFilter.filterMode === 'checkboxes') {
    desc = `${descBase} checkboxes ${selectedDate}`;
    dateisoMin = '2100-01-01';
    dateisoMaxExc = '1900-01-01';

    const keys = Object.keys(appliedFilter.treeListState);
    const years = keys.filter((key) => key.length === 4);
    const months = keys.filter((key) => key.length === 7);
    const days = keys.filter((key) => key.length === 10);

    for (const sYear of years) {
      if (appliedFilter.treeListState[sYear] === 0) {
        continue;
      }
      if (appliedFilter.treeListState[sYear] === 1) {
        const dateisoYearStart = `${sYear}-01-01`;
        const dateisoYearEndExc = addYearsIso(dateisoYearStart, 1);
        dateisoMin = minDateiso(dateisoMin, dateisoYearStart);
        dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoYearEndExc);
      }
    } // each year

    for (const sMonth of months) {
      if (appliedFilter.treeListState[sMonth] === 0) {
        continue;
      }
      if (appliedFilter.treeListState[sMonth] === 1) {
        const dateisoMonthStart = `${sMonth}-01`;
        const dateisoMonthEndExc = addMonthsIso(dateisoMonthStart, 1);
        dateisoMin = minDateiso(dateisoMin, dateisoMonthStart);
        dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoMonthEndExc);
      }
    } // each month

    for (const sDay of days) {
      if (appliedFilter.treeListState[sDay] === 0) {
        continue;
      }
      const dateisoDayStart = sDay;
      const dateisoDayEndExc = addDaysIso(dateisoDayStart, 1);
      dateisoMin = minDateiso(dateisoMin, dateisoDayStart);
      dateisoMaxExc = maxDateiso(dateisoMaxExc, dateisoDayEndExc);
    } // each day

  } else {
    throw new Error('invalid filter mode');
  }

  return { desc, dateisoMin, dateisoMaxExc };
}


export function getQueryConstraintsBasedOnDateFilter(appliedFilter: ColumnFilterDateisoType, fieldName: string): {
  queryConstraints: WhereClauseType[];
  clientSideFiltering: boolean;
} {

  if (appliedFilter.filterMode === 'equals') {
    return {
      queryConstraints: [[fieldName, '==', appliedFilter.filterEquals]],
      clientSideFiltering: false,
    };
  }

  if (appliedFilter.filterMode === 'range') {
    const queryConstraints: WhereClauseType[] = [];
    if (appliedFilter.filterLowerBound)
      queryConstraints.push([fieldName, '>=', appliedFilter.filterLowerBound]);
    if (appliedFilter.filterUpperBound)
      queryConstraints.push([fieldName, '<=', appliedFilter.filterUpperBound]);
    return {
      queryConstraints,
      clientSideFiltering: false,
    };
  }

  if (appliedFilter.filterMode === 'checkboxes') {

    const keys = Object.keys(appliedFilter.treeListState);
    const years = keys.filter((key) => key.length === 4);
    const months = keys.filter((key) => key.length === 7);
    const days = keys.filter((key) => key.length === 10);

    let minDate = null;
    let maxDate = null;
    let onlySpecificDates = true;
    let mergedMultipleRanges = false;
    const specificDates: string[] = [];
    for (const sYear of years) {
      if (appliedFilter.treeListState[sYear] === 0)
        continue;
      if (appliedFilter.treeListState[sYear] === 1) {
        onlySpecificDates = false;
        const thisMinDate = `${sYear}-01-01`;
        const thisMaxDate = `${sYear}-12-31`;
        if (minDate === null || maxDate === null) {
          minDate = thisMinDate;
          maxDate = thisMaxDate;
        } else {
          minDate = min(minDate, thisMinDate);
          maxDate = max(maxDate, thisMaxDate);
          mergedMultipleRanges = true;
        }
        continue;
      }
    }
    for (const sMonth of months) {
      if (appliedFilter.treeListState[sMonth] === 0)
        continue;
      if (appliedFilter.treeListState[sMonth] === 1) {
        onlySpecificDates = false;
        const thisMinDate = `${sMonth}-01`;
        const thisMaxDate = addDaysIso(addMonthsIso(thisMinDate, 1), -1);
        if (minDate === null || maxDate === null) {
          minDate = thisMinDate;
          maxDate = thisMaxDate;
        } else {
          minDate = min(minDate, thisMinDate);
          maxDate = max(maxDate, thisMaxDate);
          mergedMultipleRanges = true;
        }
        continue;
      }
    }
    for (const sDay of days) {
      if (appliedFilter.treeListState[sDay] === 0)
        continue;
      if (minDate === null || maxDate === null) {
        minDate = sDay;
        maxDate = sDay;
      } else {
        minDate = min(minDate, sDay);
        maxDate = max(maxDate, sDay);
        mergedMultipleRanges = true;
      }
      specificDates.push(sDay);
    }

    if (onlySpecificDates) {
      return {
        queryConstraints: [[fieldName, 'in', specificDates]],
        clientSideFiltering: false,
      };
    } else {
      // if onlySpecificDates is false, minDate and maxDate have necessarily been set
      return {
        queryConstraints: [
          [fieldName, '>=', minDate!],
          [fieldName, '<=', maxDate!],
        ],
        clientSideFiltering: mergedMultipleRanges,
      };
    }
  }

  throw new Error('unknown filter mode');
}

export function tourRequestClientSideFiltering(tourrequests: TourRequestType[], appliedFilter: ColumnFilterDateisoType, fieldName: 'dateisoTourStart' | 'dateisoTourEnd') {
  console.log('CLIENT SIDE FILTERING: tour start/end');
  const filteringFunction = getFilteringFunction(appliedFilter);
  return tourrequests.filter((tourrequest) => {
    return filteringFunction(tourrequest[fieldName]);
  });
}

export function tourRequestClientSideFilteringPaymentDates(tourrequests: TourRequestType[], appliedFilter: ColumnFilterDateisoType) {
  console.log('CLIENT SIDE FILTERING: payment dates');
  const filteringFunction = getFilteringFunction(appliedFilter);
  return tourrequests.filter((tourrequest) => {
    if (!tourrequest.paymentDatesCache || tourrequest.paymentDatesCache.length === 0)
      return false;
    let atLeastOneMatch = false;
    for (const paymentDateiso of tourrequest.paymentDatesCache) {
      if (filteringFunction(paymentDateiso))
        atLeastOneMatch = true;
    }
    return atLeastOneMatch;
  });
}

export function getFilteringFunction(appliedFilter: ColumnFilterDateisoType): ((dateiso: string) => boolean) {
  if (appliedFilter.filterMode === 'equals') {
    return (dateiso) => {
      return dateiso === appliedFilter.filterEquals;
    };
  } else if (appliedFilter.filterMode === 'range') {
    return (dateiso) => {
      if (appliedFilter.filterLowerBound && dateiso < appliedFilter.filterLowerBound)
        return false;
      if (appliedFilter.filterUpperBound && dateiso > appliedFilter.filterUpperBound)
        return false;
      return true;
    };
  } else if (appliedFilter.filterMode === 'checkboxes') {
    return (dateiso) => {
      const result = appliedFilter.treeListState[dateiso]; // 0 or 1

      // double check consistency?
      const [year, month, day] = dateparts_from_iso(dateiso);
      const yearFilter = appliedFilter.treeListState[year.toString()];
      if (yearFilter !== 0.5 && yearFilter !== result)
        throw new Error('inconsistent year filter');
      const monthFilter = appliedFilter.treeListState[monthIso(year, month)];
      if (monthFilter !== 0.5 && monthFilter !== result)
        throw new Error('inconsistent month filter');

      return result === 1;
    };
  } else {
    throw new Error('unknown filter mode');
  }

}

