

export function Unauthorized() {
  return (
    <div className='container m-4 bg-warning-subtle col-sm-5 p-4 border border-warning-subtle rounded border-2 rounded-3'>
      <h3 className='mb-3'><i className='bi-x-octagon'></i> Access disabled</h3>
      <div>Please ask an administrator to enable access to this page.</div>
    </div>
  );
}

