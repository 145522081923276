import { serverTimestamp } from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { ClipboardEvent } from 'react';
import { uploadFilesToFirebase } from 'src/components/FileDownloadUpload/util_fileupload';
import { QuotegridDayType, QuotegridLineItemType, QuotegridType, QuotegridUpdatableItemType } from 'src/types/types_quotegrid';
import { UserSimpleUidType } from 'src/types/types_user';



function insertTextAtCursor(el: HTMLInputElement | HTMLTextAreaElement, text: string) {
  const val = el.value;
  const endIndex = el.selectionEnd!;
  const newString = val.slice(0, endIndex) + text + val.slice(endIndex);
  el.value = newString;
  el.selectionStart = el.selectionEnd = endIndex + text.length;
  return newString;
}

function getFilenameAndExtension(filename: string) {
  const lastDot = filename.lastIndexOf('.');
  if (lastDot === -1 || lastDot === 0 || lastDot === filename.length - 1 || filename.slice(lastDot).length > 5)

    return { filename, extension: '' };
  return {
    filename: filename.slice(0, lastDot),
    extension: filename.slice(lastDot),
  };
}


type ImageDimensionsType = { width: number; height: number };

function getImageDimensionsAsync(src: string): Promise<ImageDimensionsType> {
  // https://stackoverflow.com/a/46399452/
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = src;
  });
}

export type PrepareSaveFieldToDbType = (
  baseField: 'overall' | 'days' | 'lineItems',
  itemId: string, // null for baseField=overall
  item: QuotegridUpdatableItemType,
  field: string,
  value: any,
  formula: string | undefined
) => { updateObj: Record<string, any>; newRowId: string | null } | undefined;

export type UpdateQuotegridType = (updateObj: any, userAction: string, isUndoRedo?: boolean, undoRedoTargetStep?: number) => void;

type FileAndCustomNameType = { file: File; customname: string };

export function handleQuotegridPaste(
  e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  baseField: 'lineItems' | 'days',
  item: QuotegridLineItemType | QuotegridDayType,
  field: string,
  userSimple: UserSimpleUidType,
  quotegrid: QuotegridType,
  storage: FirebaseStorage,
  prepareSaveFieldToDb: PrepareSaveFieldToDbType,
  updateQuotegrid: UpdateQuotegridType,
) {
  const storageBaseFolder = 'quotegrids';
  const storageFolder = `${storageBaseFolder}/${userSimple.uid}`;

  console.log('event', e);

  console.log('handlePaste');
  console.log('e.clipboardData', e.clipboardData);
  console.log('e.clipboardData.files', e.clipboardData.files);
  console.log('e.clipboardData.files.length', e.clipboardData.files.length);
  console.log('e.clipboardData.items', e.clipboardData.items);
  console.log('e.clipboardData.items.length', e.clipboardData.items.length);
  // document.getElementById('files1').files = e.clipboardData.files;
  // console.log(document.getElementById('files1').files);
  // return;

  const filesAndCustomNames: FileAndCustomNameType[] = [];
  for (let i = 0; i < e.clipboardData.items.length; i++) {
    const item = e.clipboardData.items[i];
    console.log('Item type', item.type);

    // if (item.type.indexOf('image' === -1)) {
    //   console.log('Discarding non-image paste data');
    //   continue
    // }

    const file = item.getAsFile();
    if (!file) {
      console.log('Discarding non-file paste data');
      continue;
    }

    const { filename, extension } = getFilenameAndExtension(file.name);

    console.log('file name', file.name);
    const customfilename = window.prompt('Input name for this file', filename)
      || filename;

    filesAndCustomNames.push({ file, customname: `${customfilename}${extension}` });
  }

  // get image dimensions
  const promisesGetDimensions = filesAndCustomNames.map(({ file }) => {
    const objUrl = URL.createObjectURL(file);
    console.log('objUrl', objUrl);
    return getImageDimensionsAsync(objUrl);
  });

  Promise.all(promisesGetDimensions)
    .then((dimensions) => {

      console.log('dimensions', dimensions);

      // upload files to Firebase
      const files = filesAndCustomNames.map((item) => item.file);
      uploadFilesToFirebase(storage, storageFolder, files)
        .then((uploadedFilePathsAndURLs) => {
          console.log('uploadedFilePathsAndURLs', uploadedFilePathsAndURLs);

          // analyze existing files to get max file number and existing names
          const existingNames: string[] = [];
          let maxFileNum = 0;
          console.log('quotegrid.uploadedFiles', quotegrid.uploadedFiles);
          if (quotegrid.uploadedFiles) {
            for (const uploadedFileKey of Object.keys(quotegrid.uploadedFiles)) {
              const match = uploadedFileKey.match(/^file_(\d+)$/)!;
              const thisFileNum = Number(match[1]);
              maxFileNum = Math.max(maxFileNum, thisFileNum);
              const uploadedFile = quotegrid.uploadedFiles[uploadedFileKey];
              existingNames.push(uploadedFile.name);
            }
          }


          let html = '';
          const updateObj: Record<string, any> = {};

          uploadedFilePathsAndURLs.forEach(({ storagePath, downloadURL }, i) => {
            const customNameBase = filesAndCustomNames[i].customname;
            const { width, height } = dimensions[i];

            // check name is not already used
            const { filename, extension } = getFilenameAndExtension(customNameBase);
            let k = 1;
            let customName = customNameBase;
            while (existingNames.includes(customName)) {
              customName = `${filename} (${k})${extension}`;
              k++;
            }

            maxFileNum++;
            updateObj[`uploadedFiles.file_${maxFileNum}`] = {
              name: customName,
              storagePath,
              downloadURL,
              dateUploaded: serverTimestamp(),
              userUploaded: userSimple,
              width,
              height,
              devicePixelRatio: window.devicePixelRatio,
            };

            const pxWidth = Math.round(width / window.devicePixelRatio);

            existingNames.push(customName);
            html += `<img src="${customName}" width=${pxWidth}>`;
          });

          // note: e.currentTarget (which doesn't need a cast) is null. (not sure why)
          // we must use e.target (which requires a cast)
          const newString = insertTextAtCursor(e.target as HTMLInputElement | HTMLTextAreaElement, html);

          const prepResult = prepareSaveFieldToDb(baseField, item.id, item, field, newString, undefined);
          const updateObjFull = {
            ...(prepResult?.updateObj ?? {}),
            ...updateObj,
          };

          updateQuotegrid(updateObjFull, 'Paste image', false);

        });
    });
}
