import { Firestore, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { TourRequestType } from 'src/types/types_tourrequest';
import { UserDetailsType, UserSimpleUidType } from 'src/types/types_user';
import { nano_id } from 'src/util/util_nano_id';



export async function createNewQuotegrid(
  db: Firestore,
  userDetails: UserDetailsType,
  tourRequest: TourRequestType,
  tourrequestId: string,
  quotegridSheetName: string
) {
  const step1id = nano_id();

  // validation
  if (!tourrequestId)
    return 'tourrequestId is required';
  if (!tourRequest)
    return 'tourRequest is required';
  if (!tourRequest.dateisoTourStart)
    return 'tourRequest.dateisoTourStart is required';
  if (!tourRequest.dateisoTourEnd)
    return 'tourRequest.dateisoTourEnd is required';
  if (!tourRequest.numOfPax)
    return 'tourRequest.numOfPax is required';

  const userSimple: UserSimpleUidType = {
    uid: userDetails.id,
    email: userDetails.email,
    name: userDetails.displayNameEn,
  };

  const result = await addDoc(collection(db, 'quotationsheets'), {
    _isDeleted: false,
    // tour request info
    requestInfo: {
      tourrequestId: tourrequestId,
      requestCode: tourRequest.requestCode,
      travellerName: tourRequest.travellerName,
      dateisoTourStart: tourRequest.dateisoTourStart,
      dateisoTourEnd: tourRequest.dateisoTourEnd,
      numOfPax: tourRequest.numOfPax,
    },
    // quotegrid info
    quotegridSheetName,
    lineItems: {},
    days: {},
    overall: {},
    // metadata
    metadataCreated: {
      dateCreated: serverTimestamp(),
      userCreated: userSimple,
    },
    metadataModified: {
      dateModified: serverTimestamp(),
      userModified: userSimple,
    },
    history: getNewHistoryInfoObj(userSimple, 'Newly created'),
  });

  console.log('result', result);
  const quotegridsheetId = result.id;

  return 'success';
}
