import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { FilterButton } from 'src/components/Buttons/FilterButton';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { useAutosaveDocumentInList } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { addMetadataModifiedExpenseSheet } from 'src/pages/ExpenseSheet/util_db_expensesheet';
import { getStatusLabel } from 'src/pages/ExpenseSheet/util_status';
import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';



export type ExpenseListModalActionType = {
  action: 'duplicate sheet';
  sheetId: string;
} | {
  action: 'create new sheet';
};

interface ExpenseListTableProps {
  sheetList: ExpenseSheetType[];
  isTravelDesignerTable: boolean;
  setModalAction: ((action: ExpenseListModalActionType) => void) | null;
}

export function ExpenseListTable({
  sheetList,
  isTravelDesignerTable,
  setModalAction,
}: ExpenseListTableProps) {

  const { db } = useAppContext();

  const [shownPopup, setShownPopup] = useState<string | null>(null);

  const [filterGuide, setFilterGuide] = useState<string | null>(null);
  const [filterDesigner, setFilterDesigner] = useState<string | null>(null);

  const sheetListFiltered =
    sheetList.filter((sheet) =>
      (!filterGuide || sheet.userGuideUid === filterGuide)
      && (!filterDesigner || sheet.usersDesigners.find((user) => user.uid === filterDesigner))
    );

  const autosaveNewStep = useAutosaveDocumentInList('expensesheets', addMetadataModifiedExpenseSheet);

  return (
    <table className='table draftlist'>
      <thead>
        <tr>
          <th></th>
          <th>Tour code</th>
          <th>Total amount</th>
          <th>Status</th>
          <th>Travel Designer</th>
          <th style={{ whiteSpace: 'nowrap' }}>
            Guide
            {' '}
            {/* {filterGuide && (
              <i className='bi bi-filter-circle-fill' style={{ cursor: 'pointer' }} onClick={(e) => {
                setFilterGuide(null)
              }}></i>
            )} */}
          </th>
          <th style={{ whiteSpace: 'nowrap' }}><i className='bi-caret-down-square'></i>&nbsp;Created</th>
          <th>Last modified</th>
          <th>Last modified by</th>
          {isTravelDesignerTable && (
            <th className='text-center'></th>
          )}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sheetListFiltered.map((sheet) => {

          let css = '';
          if (isTravelDesignerTable) {
            if (sheet.status === 'TL_DRAFT') css = 'inactive';
            if (sheet.status === 'APPROVED') css = 'success';
          } else {
            if (sheet.status === 'TD_DRAFT') css = 'inactive';
            if (sheet.status === 'SUBMITTED') css = 'inactive';
            if (sheet.status === 'APPROVED') css = 'success';
          }

          return (
            <tr key={sheet.id} className={css}>
              <td>
                <ButtonTW variant='blue_outline' to={`sheet/${sheet.id}`}>Open</ButtonTW>
              </td>
              <td>
                <div>
                  <RequestCodeLinkToAggregator
                    requestCode={sheet.tourCode}
                    linkId={sheet.id}
                    shownPopup={shownPopup}
                    setShownPopup={setShownPopup}
                  />
                </div>
                {sheet.sheetTitle && (
                  <div>{sheet.sheetTitle}</div>
                )}
              </td>
              <td>
                {formatNum(sheet.calc.totalExpenses)}
              </td>
              <td>
                {getStatusLabel(sheet.status)}
              </td>
              <td>
                {sheet.usersDesigners.map((user) => {
                  return (
                    <div key={user.uid}>
                      {user.name}
                      {' '}
                      <FilterButton filterActive={filterDesigner === user.uid} setFilterValue={setFilterDesigner} currentValue={user.uid} title='Filter by Travel Designer' />
                    </div>
                  );
                })}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {sheet.userGuideName}
                {' '}
                <FilterButton filterActive={filterGuide === sheet.userGuideUid} setFilterValue={setFilterGuide} currentValue={sheet.userGuideUid} title='Filter by Guide' />
              </td>
              <td>
                {dateFormatUserFriendly(sheet.dateCreated, true)}
              </td>
              <td>
                {dateFormatUserFriendly(sheet.dateModified, true)}
              </td>
              <td>
                {sheet.userModifiedName}
              </td>
              {isTravelDesignerTable && setModalAction && (
                <>
                  <td className='text-center'>
                    <ButtonTW variant='blue_outline' onClick={(e) => {
                      setModalAction({
                        action: 'duplicate sheet',
                        sheetId: sheet.id,
                      });
                    }}>Duplicate</ButtonTW>
                    {' '}
                    <ButtonTW variant='blue_outline' onClick={(e) => {
                      e.preventDefault();
                      if (window.confirm('Delete guiding expense sheet?')) {
                        const updateObj: Partial<ExpenseSheetType> = {
                          _isDeleted: true,
                          status: 'DELETED',
                          dateModified: serverTimestampAsDate(),
                        };
                        autosaveNewStep('DELETE', sheet, updateObj, 'UNDOWALL');
                      }
                    }}>Delete</ButtonTW>
                  </td>
                </>
              )}
              <td>

              </td>
            </tr>
          );
        })}

      </tbody>
    </table>
  );
}
