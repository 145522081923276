import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { MouseEvent } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { TourRequestTypeWithDates } from 'src/types/types_tourrequest';
import { dateutcFormatMMMD } from 'src/util/dateformattools';
import { addDays, getSpanDaysExact, iso_from_utc0, utc0_from_iso } from 'src/util/datetools';
import { isBankHoliday, isWeekend } from 'src/util/util_holidays';
import { ArrayJoin } from 'src/util/util_misc_jsx';
import { EditWindowParamsType } from '../PageTourCalendar';
import { GuideCalendarCellDataType, guideDayHasConflict } from '../useGuideCalendarCellData';
import { dayNumToString } from '../util_daylistparsing';
import { moveTourrequestCalendayDay } from '../util_db_calendar';
import { getSelectionBordersCss, getTourDayIndexes, gridCellMouseDown } from '../util_tourcalendar';



interface TourSidePanelProps {
  shownEditWindow: EditWindowParamsType;
  setShownEditWindow: (value: EditWindowParamsType) => void;
  tourrequest: TourRequestTypeWithDates;
  isReadOnly: boolean;
  dateutcCalendarStart: Date;
  guideCalendarCellData: GuideCalendarCellDataType;
}


export function TourSidePanel({
  shownEditWindow,
  setShownEditWindow,
  tourrequest,
  isReadOnly,
  dateutcCalendarStart,
  guideCalendarCellData,
}: TourSidePanelProps) {

  const { db, setDbError, userDetails } = useAppContext();


  const dateutcTourStart = utc0_from_iso(tourrequest.dateisoTourStart);
  const dateutcTourEnd = utc0_from_iso(tourrequest.dateisoTourEnd);
  const dayindexTourStart = getSpanDaysExact(dateutcCalendarStart, dateutcTourStart); // 0-based
  const numDaysInTour = getSpanDaysExact(dateutcTourStart, dateutcTourEnd) + 1;


  const dayIndexes = getTourDayIndexes(tourrequest, dateutcCalendarStart);

  // sample drag and drop code taken from:
  // https://codesandbox.io/p/sandbox/k260nyxq9v?file=%2Findex.js

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    console.log('dropped', result);
    if (!result.destination) {
      return;
    }

    const match_dest = result.destination.droppableId.match(/^droppable_(\d+)$/);
    if (!match_dest)
      return;

    const dayIndex_dest = Number(match_dest[1]);

    const match_src = result.source.droppableId.match(/^droppable_(\d+)$/);
    if (!match_src)
      return;

    const dayIndex_src = Number(match_src[1]);

    if (dayIndex_dest === dayIndex_src)
      return;


    const dateutc_src = addDays(dateutcCalendarStart, dayIndex_src);
    const dateiso_src = iso_from_utc0(dateutc_src);

    const dateutc_dest = addDays(dateutcCalendarStart, dayIndex_dest);
    const dateiso_dest = iso_from_utc0(dateutc_dest);

    if (!tourrequest.calendarDays)
      return;

    if (tourrequest.calendarDays[dateiso_dest]) {
      if ((tourrequest.calendarDays[dateiso_dest].guides ?? []).length > 0
        || tourrequest.calendarDays[dateiso_dest].additionalText) {
        if (!window.confirm('Destination day is not empty. Overwrite?')) {
          return;
        }
      }
    }

    if (!tourrequest.calendarDays[dateiso_src])
      // nothing to copy
      return;

    moveTourrequestCalendayDay(db, userDetails, tourrequest, dateiso_src, dateiso_dest)
      .then(() => {
        console.log('update success');

        setShownEditWindow({
          ...shownEditWindow,
          selectedDayIndexesU: [dayIndex_dest],
        });

      })
      .catch((err) => setDbError(err));

  }; // onDragEnd

  return (
    <div className='sidePanel' style={{
      backgroundColor: 'white',
      padding: '1em',
      border: '1px solid #aaa',
      borderRadius: '0.5em',
      boxShadow: '0 0 0.5em #aaa',
    }}>
      <h4>{tourrequest.requestCode}</h4>
      <h5>{tourrequest.travellerName}</h5>
      <div>

        <DragDropContext onDragEnd={onDragEnd}>

          <table className='tourDaysList'>
            <thead className='[&>tr>th]:tw-bg-slate-200'>
              <tr>
                <th>Day</th>
                <th>Date</th>
                <th>Tour Leader</th>
                <th>Memo</th>
                {!isReadOnly && (
                  <th>Drag</th>
                )}
              </tr>
            </thead>
            <tbody>
              {dayIndexes.map((dayIndex) => { // dayIndex: 0-based, from calendar start
                const isSelectedDay = shownEditWindow.selectedDayIndexesU.includes(dayIndex);
                const dateutc = addDays(dateutcCalendarStart, dayIndex);
                const dateiso = iso_from_utc0(dateutc);
                const dateStr = dateutcFormatMMMD(dateutc);
                const dayNum = dayIndex - dayindexTourStart + 1;

                const selectionBordersCss = getSelectionBordersCss(shownEditWindow.selectedDayIndexesU, dayIndex, 'vertical');

                const guides = tourrequest.calendarDays?.[dateiso]?.guides || [];

                return (
                  <tr key={dayIndex}
                    className={
                      (isSelectedDay ? 'selectedDay ' : '')
                      + (dayNum < 1 || dayNum > numDaysInTour ? '[&>td]:tw-bg-amber-100 ' : '')
                    }
                    style={selectionBordersCss}
                  >
                    <td className='tw-cursor-pointer tw-whitespace-nowrap' onMouseDown={(e: MouseEvent) => {
                      e.preventDefault();
                      gridCellMouseDown(e, shownEditWindow, setShownEditWindow, dayIndex, dateutcCalendarStart, tourrequest);
                    }}>
                      Day {dayNumToString(dayNum, numDaysInTour)}
                    </td>
                    <td className='tw-cursor-pointer tw-whitespace-nowrap'
                      style={{ color: isWeekend(dateutc) ? 'crimson' : isBankHoliday(dateutc) ? 'dodgerblue' : undefined }}
                      onMouseDown={(e: MouseEvent) => {
                        e.preventDefault();
                        gridCellMouseDown(e, shownEditWindow, setShownEditWindow, dayIndex, dateutcCalendarStart, tourrequest);
                      }}>
                      {dateStr}
                    </td>
                    <td>
                      {ArrayJoin(guides.map((guide) => {
                        let guidename = guide.name;
                        if (guide.hours) {
                          guidename += ` (${guide.hours}hr`;
                          if (guide.am_pm_only) {
                            guidename += ` ${guide.am_pm_only.substring(0, 2).toUpperCase()}`;
                          }
                          guidename += ')';
                        }

                        const dayContents = guideCalendarCellData.lookup.get(guide.id)?.get(dayIndex);
                        const hasConflict = guideDayHasConflict(dayContents);
                        if (hasConflict === 'CONFLICT')
                          return <span key={guide.id} className='tw-bg-red-500 tw-text-white'>{guidename}</span>;
                        else if (hasConflict === 'MULTIPLE')
                          return <span key={guide.id} className='tw-bg-red-200 tw-text-black'>{guidename}</span>;
                        else
                          return guidename;
                      }), ', ')}
                    </td>
                    <td>
                      {tourrequest.calendarDays?.[dateiso]?.additionalText}
                    </td>
                    {!isReadOnly && (
                      <td style={{ padding: 0 }}>


                        <Droppable droppableId={`droppable_${dayIndex}`}>
                          {(provided, snapshot) => (
                            <>
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  backgroundColor: snapshot.isDraggingOver ? 'orange' : 'transparent',
                                  padding: '0 1em',
                                  textAlign: 'center',
                                }}
                              >

                                {isSelectedDay
                                  && shownEditWindow.selectedDayIndexesU.length === 1
                                  && tourrequest.calendarDays?.[dateiso]
                                  && (
                                    <>
                                      <Draggable draggableId={`draggable_${dayIndex}`} index={0}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              userSelect: 'none',
                                              // snapshot.isDragging,
                                              ...provided.draggableProps.style,
                                              //  border: '3px solid blue'
                                            }}
                                          >
                                            <i className='bi bi-arrows-move' style={{ cursor: 'grab' }}></i>
                                          </div>
                                        )}
                                      </Draggable>
                                    </>
                                  )}
                                {provided.placeholder}
                              </div>

                            </>
                          )}
                        </Droppable>

                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

        </DragDropContext>

      </div>
    </div>
  );
}
