import { EditableField } from 'src/components/EditableField/EditableField';
import { FunctionTabKeyPress } from 'src/components/EditableField/EditableFieldQuotegrid';
import { QuotegridLineItemTypeUnion, QuotegridType } from 'src/types/types_quotegrid';
import { UserSimpleUidType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum, formatNumRound } from 'src/util/util_formatnum';
import { calculateQuotegridOverallNumbers } from '../util_calculateQuotegridTotals';
import { UpdateQuotegridType } from '../util_handlepaste';


const tw_hightlight_cell = 'tw-bg-blue-100 tw-border tw-border-solid tw-border-blue-500 tw-rounded-md tw-p-2';
const tw_neutral_cell = 'tw-border tw-border-solid tw-border-transparent tw-rounded-md tw-p-2';


interface TotalsTableProps {
  quotegrid: QuotegridType;
  enableEditing: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  tabKeyPress: FunctionTabKeyPress;
  userSimple: UserSimpleUidType;
  lineItemsAll: QuotegridLineItemTypeUnion[];
  updateQuotegrid: UpdateQuotegridType;
}

export function TotalsTable({
  quotegrid,
  enableEditing,
  editedCell,
  setEditedCell,
  tabKeyPress,
  userSimple,
  lineItemsAll,
  updateQuotegrid,
}: TotalsTableProps) {


  const ourMarkupPercent = quotegrid.overall.ourMarkupPercent ?? 0;

  let current_our_markup = '';
  let current_our_com = '';
  if (quotegrid.overall.ourMarkupIsOnGross) {
    current_our_markup = formatNumRound(ourMarkupPercent / (1 - ourMarkupPercent / 100.0), 2);
    current_our_com = formatNum(ourMarkupPercent);
  } else {
    current_our_markup = formatNum(ourMarkupPercent);
    current_our_com = formatNumRound(ourMarkupPercent / (1 + ourMarkupPercent / 100.0), 2);
  }


  const agentCommissionPercent = quotegrid.overall.agentCommissionPercent ?? 0;

  let current_agent_markup = '';
  let current_agent_com = '';
  if (quotegrid.overall.agentCommissionIsOnGross) {
    current_agent_markup = formatNumRound(agentCommissionPercent / (1 - agentCommissionPercent / 100.0), 2);
    current_agent_com = formatNum(agentCommissionPercent);
  } else {
    current_agent_markup = formatNum(agentCommissionPercent);
    current_agent_com = formatNumRound(agentCommissionPercent / (1 + agentCommissionPercent / 100.0), 2);
  }


  return (
    <table className='table quotegridTotalTable'>
      <thead>
        <tr>
          <th></th>
          <th>Rate (as<br />markup)</th>
          <th>Rate (as<br />commission)</th>
          <th>Per group</th>
          <th>Per pax ({quotegrid.requestInfo.numOfPax})</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total price inc. tax</td>
          <td></td>
          <td></td>
          <td className='numeric'>{formatNum(quotegrid.overall.totalPrice)}</td>
          <td className='numeric'>{formatNum(Math.round((quotegrid.overall.totalPrice || 0) / quotegrid.requestInfo.numOfPax))}</td>
        </tr>
        <tr>
          <td>Eighty Days fee</td>

          <td>
            {/* // 100 inc 10 COM -> 90 + 10/90 MU -> MU = COM / (100-COM) */}
            <div className={!quotegrid.overall.ourMarkupIsOnGross ? tw_hightlight_cell : tw_neutral_cell}>
              <EditableField
                tableid='table_quotegrid_overall'
                rowid='overall'
                fieldname='our_markup'
                validationType='percent'
                currentValue={current_our_markup}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any, tabKey: -1 | 1 | undefined, formula: string | undefined) => {
                  const fieldname = 'ourMarkupPercent';

                  if (dbvalue < 0)
                    return;

                  if (dbvalue !== Number(current_our_markup)) {

                    const updateObj = {
                      'overall.ourMarkupPercent': dbvalue,
                      'overall.ourMarkupIsOnGross': false,
                      'overall.dateModified': serverTimestampAsDate(),
                      'overall.userModified': userSimple,
                    };

                    const overallModified = {
                      ...quotegrid.overall,
                      ourMarkupPercent: dbvalue,
                      ourMarkupIsOnGross: false,
                    };

                    calculateQuotegridOverallNumbers(overallModified, lineItemsAll, updateObj);

                    updateQuotegrid(updateObj, `Type ‘${dbvalue}’ in ‘Eighty Days Markup’`, false);
                  }

                  const currentCellId = `${'table_quotegrid_overall'}_${'overall'}_${fieldname}`; // still has 'new' if new row
                  tabKeyPress(tabKey, currentCellId, null);

                }}
                hasButtonForEditing={true}
              />
            </div>
          </td>
          <td>
            {/* // 100 + 10 = 110. MU = 10, COM = 10/110 = MU/(1+MU) */}
            <div className={quotegrid.overall.ourMarkupIsOnGross ? tw_hightlight_cell : tw_neutral_cell}>
              <EditableField
                tableid='table_quotegrid_overall'
                rowid='overall'
                fieldname='our_commission'
                validationType='percent'
                currentValue={current_our_com}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any, tabKey: -1 | 1 | undefined, formula: string | undefined) => {
                  const fieldname = 'ourMarkupPercent';

                  if (dbvalue < 0 || dbvalue >= 100.0)
                    return;

                  if (dbvalue !== Number(current_our_com)) {

                    const updateObj = {
                      'overall.ourMarkupPercent': dbvalue,
                      'overall.ourMarkupIsOnGross': true,
                      'overall.dateModified': serverTimestampAsDate(),
                      'overall.userModified': userSimple,
                    };

                    const overallModified = {
                      ...quotegrid.overall,
                      ourMarkupPercent: dbvalue,
                      ourMarkupIsOnGross: true,
                    };

                    calculateQuotegridOverallNumbers(overallModified, lineItemsAll, updateObj);

                    updateQuotegrid(updateObj, `Type ‘${dbvalue}’ in ‘Eighty Days Commission`, false);
                  }

                  const currentCellId = `${'table_quotegrid_overall'}_${'overall'}_${fieldname}`; // still has 'new' if new row
                  tabKeyPress(tabKey, currentCellId, null);

                }}
                hasButtonForEditing={true}
              />
            </div>

          </td>
          <td className='numeric'>{formatNum(quotegrid.overall.ourMarkupAmount)}</td>
          <td className='numeric'>{formatNum(Math.round((quotegrid.overall.ourMarkupAmount || 0) / quotegrid.requestInfo.numOfPax))}</td>
        </tr>
        <tr>
          <td>Price inc. Eighty Days fee</td>
          <td></td>
          <td></td>
          <td className='numeric'>{formatNum(quotegrid.overall.totalPriceIncMarkup)}</td>
          <td className='numeric'>{formatNum(Math.round((quotegrid.overall.totalPriceIncMarkup || 0) / quotegrid.requestInfo.numOfPax))}</td>
        </tr>
        <tr>
          <td>Agent fee</td>
          <td>

            <div className={!quotegrid.overall.agentCommissionIsOnGross ? tw_hightlight_cell : tw_neutral_cell}>
              <EditableField
                tableid='table_quotegrid_overall'
                rowid='overall'
                fieldname='agent_markup'
                validationType='percent'
                currentValue={current_agent_markup}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any, tabKey: -1 | 1 | undefined, formula: string | undefined) => {
                  const fieldname = 'agentCommissionPercent';

                  if (dbvalue < 0)
                    return;

                  if (dbvalue !== Number(current_agent_markup)) {

                    const updateObj = {
                      'overall.agentCommissionPercent': dbvalue,
                      'overall.agentCommissionIsOnGross': false,
                      'overall.dateModified': serverTimestampAsDate(),
                      'overall.userModified': userSimple,
                    };

                    const overallModified = {
                      ...quotegrid.overall,
                      agentCommissionPercent: dbvalue,
                      agentCommissionIsOnGross: false,
                    };

                    calculateQuotegridOverallNumbers(overallModified, lineItemsAll, updateObj);

                    updateQuotegrid(updateObj, `Type ‘${dbvalue}’ in ‘Agent Markup’`, false);
                  }

                  const currentCellId = `${'table_quotegrid_overall'}_${'overall'}_${fieldname}`; // still has 'new' if new row
                  tabKeyPress(tabKey, currentCellId, null);

                }}
                hasButtonForEditing={true}
              />
            </div>

          </td>
          <td>
            <div className={quotegrid.overall.agentCommissionIsOnGross ? tw_hightlight_cell : tw_neutral_cell}>
              <EditableField
                tableid='table_quotegrid_overall'
                rowid='overall'
                fieldname='agent_commission'
                validationType='percent'
                currentValue={current_agent_com}
                isClickableToEdit={enableEditing}
                editedCell={editedCell}
                setEditedCell={setEditedCell}
                callbackCommitChange={(dbvalue: any, tabKey: -1 | 1 | undefined, formula: string | undefined) => {
                  const fieldname = 'agentCommissionPercent';

                  if (dbvalue < 0 || dbvalue >= 100.0)
                    return;

                  if (dbvalue !== Number(current_agent_com)) {

                    const updateObj = {
                      'overall.agentCommissionPercent': dbvalue,
                      'overall.agentCommissionIsOnGross': true,
                      'overall.dateModified': serverTimestampAsDate(),
                      'overall.userModified': userSimple,
                    };

                    const overallModified = {
                      ...quotegrid.overall,
                      agentCommissionPercent: dbvalue,
                      agentCommissionIsOnGross: true,
                    };

                    calculateQuotegridOverallNumbers(overallModified, lineItemsAll, updateObj);

                    updateQuotegrid(updateObj, `Type ‘${dbvalue}’ in ‘Agent Commission`, false);
                  }

                  const currentCellId = `${'table_quotegrid_overall'}_${'overall'}_${fieldname}`; // still has 'new' if new row
                  tabKeyPress(tabKey, currentCellId, null);

                }}
                hasButtonForEditing={true}
              />
            </div>
          </td>
          <td className='numeric'>{formatNum(quotegrid.overall.agentCommissionAmount)}</td>
          <td className='numeric'>{formatNum(Math.round((quotegrid.overall.agentCommissionAmount || 0) / quotegrid.requestInfo.numOfPax))}</td>
        </tr>
        <tr>
          <td>Price inc. Agent fee</td>
          <td></td>
          <td></td>
          <td className='numeric'>{formatNum(quotegrid.overall.totalPriceIncCommission)}</td>
          <td className='numeric'>{formatNum(Math.round((quotegrid.overall.totalPriceIncCommission || 0) / quotegrid.requestInfo.numOfPax))}</td>
        </tr>
      </tbody>
    </table>
  );

}
