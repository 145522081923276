

export const requestClassificationListNames = [
  'eightyDaysTeams',
  'customerTypes',
  'guidePreferences',
  'accommodationStars',
  'accommodationPreferences',
];


export function sanitizeId(text: string) {
  return text.replaceAll(/\(|\)/g, '').replaceAll(' ', '_');
}

