import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { EditableField } from 'src/components/EditableField/EditableField';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { useAppContext } from 'src/hooks/useAppContext';
import { ExpenseGuideTransportation, ExpenseMealAllowance, ExpenseOtherExpense, ExpenseSheetType } from 'src/types/types_expensesheet';
import { TourRequestType } from 'src/types/types_tourrequest';
import { UserSimpleUidType } from 'src/types/types_user';
import { dateisoFormatJp, dateisoFormatJpShort } from 'src/util/dateformattools';
import { addDaysIso, iso_from_local0, local0_from_iso } from 'src/util/datetools';
import { DateRangeInput } from '../DateRangeInput';
import { AutosaveSheetType } from '../ExpenseSheet';


const guideHoursChoices: [number | string, string][] = [
  [8, '8hr'],
  [4, '4hr'],
  ['other', 'Other'],
];

interface B_TourInformationProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  autosaveNewStep: AutosaveSheetType;
  tourDays: number[];
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  userListSimple: UserSimpleUidType[];
}

export function B_TourInformation({
  sheet,
  isReadOnly,
  autosaveNewStep,
  tourDays,
  editedCell,
  setEditedCell,
  userListSimple,
}: B_TourInformationProps) {

  const { db, setDbError } = useAppContext();

  // tmpTourStartDate holds the start date temporarily when datepicker is waiting for user to select the end date.
  // it is null once the end date has been selected.
  const [datelocalTmpTourStartDate, setDatelocalTmpTourStartDate] = useState<Date | null>(null);

  const tourNumDays = sheet.calc.tourNumDays;

  return (
    <>

      <h5 className=''>Tour information</h5>

      <div style={{
        display: 'grid',
        gridTemplateColumns: '10em auto 1fr',
        gap: '0.5em',
      }}>

        {/* {!isReadOnly ? ( */}
        <>
          <RequestCodeSelector
            tripCode={sheet.tourCode}
            paxName={sheet.paxName}
            setRequestData={({ requestCode, paxName, tourrequestId }) => {

              const saveRequestCode = async () => {
                const updateObj: Partial<ExpenseSheetType> = {
                  tourCode: requestCode,
                  paxName: paxName,
                };

                if (tourrequestId) {
                  const docu = await getDoc(doc(db, 'tourrequests', tourrequestId));

                  const tourrequest = { ...docu.data(), id: docu.id } as TourRequestType;
                  if (tourrequest.numOfPax) {
                    const newNumPax = tourrequest.numOfPax.toString();
                    if (!sheet.numberOfPax
                      || sheet.numberOfPax === newNumPax
                      || window.confirm(`Do you want to overwrite the number of pax?\n${sheet.numberOfPax} → ${newNumPax}`)
                    ) {
                      updateObj.numberOfPax = newNumPax;
                    }
                  }
                  if (tourrequest.dateisoTourStart && tourrequest.dateisoTourEnd) {
                    if ((!sheet.dateisoTourStart && !sheet.dateisoTourEnd)
                      || window.confirm('Do you want to overwrite the tour dates?\n'
                        + `Start: ${dateisoFormatJpShort(sheet.dateisoTourStart)} → ${dateisoFormatJpShort(tourrequest.dateisoTourStart)}\n`
                        + `End: ${dateisoFormatJpShort(sheet.dateisoTourEnd)} → ${dateisoFormatJpShort(tourrequest.dateisoTourEnd)}`)
                    ) {
                      updateObj.dateisoTourStart = tourrequest.dateisoTourStart;
                      updateObj.dateisoTourEnd = tourrequest.dateisoTourEnd;
                    }
                  }

                }

                autosaveNewStep('Set Request Code', updateObj, 'u');
              };

              // call async function
              saveRequestCode()
                .catch((err) => setDbError(`Saving request code on expense sheet id=[${sheet.id}] requestId=${tourrequestId} requestCode=${requestCode}`, err));

            }}
            classNameDivLabel='pt-2'
            classNameDivInput=''
            disabled={isReadOnly}
          />
        </>


        <div className='col1 pt-0'><label htmlFor='sheetTitle'>Expense sheet title (optional)</label></div>
        <div className=''>
          <div style={{
            backgroundColor: isReadOnly ? 'rgb(233, 236, 239)' : 'rgba(0,0,0,0.05)',
            borderRadius: '0.375em',
            padding: '0.25em',
            minHeight: '2em',
          }}>
            <EditableField
              tableid='B_tourinfo'
              rowid='sheetTitle'
              fieldname='sheetTitle'
              validationType=''
              currentValue={sheet.sheetTitle || ''}
              isClickableToEdit={!isReadOnly}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(value: any, tabKey?: -1 | 1, formula?: string) => {
                const updateObj: Partial<ExpenseSheetType> = {
                  sheetTitle: value,
                };
                autosaveNewStep('Set sheet title', updateObj, 'u');
                setEditedCell(null);
              }}
              hasButtonForEditing={false}
            />
          </div>
          <div>(e.g. “Haneda pickup”, etc.)</div>
        </div>

        <div className='col1 pt-2'><label htmlFor='numberOfPax'>Number of pax</label></div>
        <div className=''>
          <div style={{
            backgroundColor: isReadOnly ? 'rgb(233, 236, 239)' : 'rgba(0,0,0,0.05)',
            borderRadius: '0.375em',
            padding: '0.25em',
            minHeight: '2em',
          }}>
            <EditableField
              tableid='B_tourinfo'
              rowid='numberOfPax'
              fieldname='numberOfPax'
              validationType='number'
              currentValue={sheet.numberOfPax ?? ''}
              isClickableToEdit={!isReadOnly}
              editedCell={editedCell}
              setEditedCell={setEditedCell}
              callbackCommitChange={(value: any, tabKey?: -1 | 1, formula?: string) => {
                const updateObj: Partial<ExpenseSheetType> = {
                  numberOfPax: value,
                };
                autosaveNewStep('Set num of pax', updateObj, 'u');
                setEditedCell(null);
              }}
              hasButtonForEditing={false}
            />
          </div>
        </div>


        <div className='col1 pt-2'><label>Tour dates</label></div>
        <div className=''>
          {isReadOnly ? (
            <input type='text' className='form-control'
              value={(sheet.dateisoTourStart && sheet.dateisoTourEnd)
                ? (`${dateisoFormatJp(sheet.dateisoTourStart)} - ${dateisoFormatJp(sheet.dateisoTourEnd)}`)
                : 'missing'}
              readOnly={isReadOnly} style={{ width: '13em', display: 'inline-block' }} />
          ) : (
            <DateRangeInput
              startDate={datelocalTmpTourStartDate !== null
                ? datelocalTmpTourStartDate
                : (sheet.dateisoTourStart ? local0_from_iso(sheet.dateisoTourStart) : null)}
              endDate={datelocalTmpTourStartDate !== null
                ? null
                : (sheet.dateisoTourEnd ? local0_from_iso(sheet.dateisoTourEnd) : null)}
              onBlur={() => {
                setDatelocalTmpTourStartDate(null);
              }}
              onChange={(dates) => {

                // The input control will return [startdate, null] when user just clicked once.
                // We do NOT want to persist this incomplete state to DB.
                // But we need to persist it to local state in order to pass to the startDate parameter.

                if (dates[0] && !dates[1]) {
                  setDatelocalTmpTourStartDate(dates[0]);
                  return;
                }

                setDatelocalTmpTourStartDate(null);

                const [datelocalTourStart, datelocalTourEnd] = dates;

                const updateObj: Partial<ExpenseSheetType> = {
                  dateisoTourStart: datelocalTourStart ? iso_from_local0(datelocalTourStart) : '',
                  dateisoTourEnd: datelocalTourEnd ? iso_from_local0(datelocalTourEnd) : '',
                };

                // TODO: change arrays to objects so that we can surgically update just the date field instead of resending the entire array

                const guideTransportationNew = sheet.guideTransportation.map((item) => {
                  if (item.dayNumber) {
                    const itemNew: ExpenseGuideTransportation = {
                      ...item,
                      dateiso: sheet.dateisoTourStart ? addDaysIso(sheet.dateisoTourStart, item.dayNumber - 1) : '',
                    };
                    return itemNew;
                  } else {
                    return item;
                  }
                });

                const otherExpensesNew = sheet.otherExpenses.map((item) => {
                  if (item.dayNumber) {
                    const itemNew: ExpenseOtherExpense = {
                      ...item,
                      dateiso: sheet.dateisoTourStart ? addDaysIso(sheet.dateisoTourStart, item.dayNumber - 1) : '',
                    };
                    return itemNew;
                  } else {
                    return item;
                  }
                });

                updateObj.guideTransportation = guideTransportationNew;
                updateObj.otherExpenses = otherExpensesNew;

                autosaveNewStep('Set tour dates', updateObj, 'u');

              }} />
          )}
          {' '}<span className='ms-3'>({tourNumDays} {tourNumDays === 1 ? 'day' : 'days'})</span>
        </div>


        <div className='col1 pt-2'>Guiding days</div>
        <div className='' style={{ gridColumn: '2/4' }}>
          <table className='table inputtingTable' style={{ width: `${2 + 7 + 8 + 3 + 10.5 + 16 + 2}em` }}>
            <colgroup>
              <col style={{ width: '2em' }} />
              <col style={{ width: '7em' }} />
              <col style={{ width: '8em' }} />
              <col style={{ width: '3em' }} />
              <col style={{ width: '10.5em' }} />
              <col style={{ width: '16em' }} />
              <col style={{ width: '2em' }} />
            </colgroup>
            <thead>
              <tr className={`row-header ${!isReadOnly ? 'no-bottom-border' : ''}`}>
                <th className='pb-0'></th>
                <th className='pb-0'>Day №</th>
                <th className='pb-0'>Date</th>
                <th className='pb-0' colSpan={2}>Guiding</th>
                <th className='pb-0'>Memo</th>
                <th className='pb-0'></th>
              </tr>
              {!isReadOnly && (
                <tr className='row-header'>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className='text-center'>
                    <input type='checkbox' className='form-check-input' checked={sheet.calc.numGuidingDays === tourNumDays} onChange={(e) => {
                      const mealAllowanceNew: ExpenseMealAllowance[] = sheet.mealAllowance.map((item) => {
                        const newItem: ExpenseMealAllowance = {
                          ...item,
                          guiding: e.target.checked,
                        };
                        return newItem;
                      });

                      const updateObj: Partial<ExpenseSheetType> = {
                        mealAllowance: mealAllowanceNew,
                      };

                      autosaveNewStep(e.target.checked ? 'Set guiding on all days' : 'Unset guiding on all days', updateObj, 'u');
                    }} />
                  </th>
                  <th>
                    <div style={{
                      display: 'flex',
                      gap: '0.25em',
                    }}>
                      {guideHoursChoices.map(([value, label]) => {
                        return (
                          <ButtonTW
                            key={value}
                            variant='darkgray_outline'
                            className='py-0'
                            onClick={() => {
                              const mealAllowanceNew = sheet.mealAllowance.map((item) => {
                                if (item.guiding) {
                                  const newItem: ExpenseMealAllowance = {
                                    ...item,
                                    guidingHours: value,
                                  };
                                  return newItem;
                                } else {
                                  return item;
                                }
                              });

                              const updateObj: Partial<ExpenseSheetType> = {
                                mealAllowance: mealAllowanceNew,
                              };

                              autosaveNewStep(`Set all days guiding hours to ${label}`, updateObj, 'u');
                            }}
                          >
                            {label}
                          </ButtonTW>
                        );
                      })}
                    </div>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              )}
            </thead>
            <tbody>
              {tourDays.map((i, index) => {

                return (
                  <tr key={i}>
                    <td>
                    </td>
                    <td>
                      {i + 1}
                    </td>
                    <td>
                      {dateisoFormatJpShort(addDaysIso(sheet.dateisoTourStart, i))}
                    </td>
                    <td className='text-center'>
                      {/* readonly attribute doesn't work on checkboxes. we can use disabled instead, but it dims the appearance. here we just prevent updating the state in JS */}
                      <input type='checkbox' className='form-check-input' checked={sheet.mealAllowance[i].guiding} disabled={isReadOnly} onChange={(e) => {
                        if (isReadOnly) return;

                        const mealAllowanceNew = sheet.mealAllowance.map((item, index) => {
                          if (index === i) {
                            const newItem: ExpenseMealAllowance = {
                              ...item,
                              guiding: e.target.checked,
                            };
                            return newItem;
                          } else {
                            return item;
                          }
                        });

                        const updateObj: Partial<ExpenseSheetType> = {
                          mealAllowance: mealAllowanceNew,
                        };

                        autosaveNewStep(`${e.target.checked ? 'Set' : 'Unset'} guiding on day ${1 + i}`, updateObj, 'u');
                      }} />
                    </td>
                    <td>
                      {sheet.mealAllowance[i].guiding && (
                        <div style={{
                          display: 'flex',
                          gap: '0.25em',
                        }}>
                          {guideHoursChoices.map(([value, label]) => {
                            return (
                              <ButtonTW
                                key={value}
                                variant={sheet.mealAllowance[i].guidingHours === value ? 'bsGreen' : 'darkgray_outline'}
                                className='py-0'
                                disabled={isReadOnly}
                                onClick={() => {
                                  const mealAllowanceNew = sheet.mealAllowance.map((item, index) => {
                                    if (index === i) {
                                      const newItem: ExpenseMealAllowance = {
                                        ...item,
                                        guidingHours: value,
                                      };
                                      return newItem;
                                    } else {
                                      return item;
                                    }
                                  });

                                  const updateObj: Partial<ExpenseSheetType> = {
                                    mealAllowance: mealAllowanceNew,
                                  };

                                  autosaveNewStep(`Set guiding hours on day ${1 + i} to ${label}`, updateObj, 'u');
                                }}
                              >
                                {label}
                              </ButtonTW>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td>
                      <EditableField
                        tableid='guidingDays'
                        rowid={`${i}`}
                        fieldname='guidingDayMemorandum'
                        validationType=''
                        currentValue={sheet.mealAllowance[i].guidingDayMemorandum}
                        isClickableToEdit={!isReadOnly}
                        editedCell={editedCell}
                        setEditedCell={setEditedCell}
                        callbackCommitChange={(dbvalue) => {
                          const mealAllowanceNew = sheet.mealAllowance.map((item, index) => {
                            if (index === i) {
                              const newItem: ExpenseMealAllowance = {
                                ...item,
                                guidingDayMemorandum: dbvalue,
                              };
                              return newItem;
                            } else {
                              return item;
                            }
                          });
                          setEditedCell('');

                          const updateObj: Partial<ExpenseSheetType> = {
                            mealAllowance: mealAllowanceNew,
                          };

                          autosaveNewStep(`Set memo on guiding day ${1 + i}`, updateObj, 'u');
                        }}
                      />
                    </td>
                    <td>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>


      </div>

    </>
  );
}
