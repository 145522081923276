import { Firestore, Query, and, collection, or, query, where } from 'firebase/firestore';
import { ColumnFilterDateisoType } from 'src/types/types_columnfilters';
import { getMinMaxDates } from './util_filter_dates';



export function getQueryWhenFilteringByPaymentDate(
  db: Firestore,
  selectedPaymentDate: string,
  appliedFilterPaymentDate: ColumnFilterDateisoType,
  descBase: string,
): [string, Query] {

  const { desc, dateisoMin, dateisoMaxExc } = getMinMaxDates(selectedPaymentDate, appliedFilterPaymentDate, descBase);

  let qInvoices;

  if (appliedFilterPaymentDate.filterMode === 'checkboxes'
    && appliedFilterPaymentDate.treeListState.emptyValues === 1
    && appliedFilterPaymentDate.treeListState.ternarySelectedPaid === 0) {
    // only notpaid
    qInvoices = query(collection(db, 'invoices'), where('_isDeleted', '==', false), where('statusPaidOrScheduled', '==', false));
  } else {

    if (appliedFilterPaymentDate.filterMode === 'checkboxes'
      && appliedFilterPaymentDate.treeListState.emptyValues === 1) {

      // notpaid plus some paid
      const constraints = [
        where('_isDeleted', '==', false),
        where('statusPaidOrScheduled', '==', true),
      ];
      if (dateisoMin)
        constraints.push(where('paymentDateiso', '>=', dateisoMin));
      if (dateisoMaxExc)
        constraints.push(where('paymentDateiso', '<', dateisoMaxExc));

      qInvoices = query(collection(db, 'invoices'),
        or(
          and(where('_isDeleted', '==', false), where('statusPaidOrScheduled', '==', false)),
          and(...constraints)
        )
      );


    } else {


      qInvoices = query(collection(db, 'invoices'), where('_isDeleted', '==', false), where('statusPaidOrScheduled', '==', true));
      if (dateisoMin)
        qInvoices = query(qInvoices, where('paymentDateiso', '>=', dateisoMin));
      if (dateisoMaxExc)
        qInvoices = query(qInvoices, where('paymentDateiso', '<', dateisoMaxExc));
    }
  }

  return [desc, qInvoices];
}

