import { TourRequestType } from 'src/types/types_tourrequest';



export type TourColorType = {
  paleBg: string;
  paleText: string;
  mainBg: string;
  mainText: string;
};

export function getTourrequestColors(tourrequest: TourRequestType): TourColorType {

  if (tourrequest.statusCancelled) {
    return {
      paleBg: '#666666',
      paleText: 'white',
      mainBg: '#666666',
      mainText: 'white',
    };
  }

  const color_code = tourrequest.calendarColorMain;

  if (color_code) {

    const colors = getTourrequestColorsFromColorCode(color_code);
    if (colors) {
      return colors;
    }

    // throw new Error(`Unknown color code: ${color_code}`)

    // unknown color on db object: show white as default to show there's a potential problem
    return {
      paleBg: '#dddddd',
      paleText: 'black',
      mainBg: 'white',
      mainText: 'black',
    };

  } else {

    if (tourrequest.agencyOrPlatform === 'Remote Lands') {
      return {
        paleBg: '#de6363',
        paleText: 'white',
        mainBg: '#c00000',
        mainText: 'white',
      };
    } else if (tourrequest.customerType.includes('FIT')) {
      return {
        paleBg: '#b3e2bf',
        paleText: 'black',
        mainBg: '#92d04f',
        mainText: 'black',
      };
    } else if (tourrequest.customerType.includes('Group') || tourrequest.customerType.includes('MICE')) {
      return {
        paleBg: '#8dd9ff',
        paleText: 'black',
        mainBg: '#3ebeff',
        mainText: 'black',
      };
    } else {
      // default: this will be used for 'Project' requests, inspections, etc.
      return {
        paleBg: '#d4e0eb',
        paleText: 'black',
        mainBg: '#bed2e3',
        mainText: 'black',
      };
    }

  }

}

export function getTourrequestColorsFromColorCode(color_code: string) {

  if (color_code === 'calcol_fit') {
    return {
      paleBg: '#b3e2bf',
      paleText: 'black',
      mainBg: '#92d04f',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_simply') {
    return {
      paleBg: '#ffff9b',
      paleText: 'black',
      mainBg: '#ffff00',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_walk') {
    return {
      paleBg: '#d8b8f5',
      paleText: 'black',
      mainBg: '#b585df',
      mainText: 'white',
    };
  }

  if (color_code === 'calcol_explore_highlights') {
    return {
      paleBg: '#ffcce6',
      paleText: 'black',
      mainBg: '#ff99cc',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_taste') {
    return {
      paleBg: '#99b3e6',
      paleText: 'black',
      mainBg: '#0470bf',
      mainText: 'white',
    };
  }

  if (color_code === 'calcol_explore_shogun') {
    return {
      paleBg: '#c7d9eb',
      paleText: 'black',
      mainBg: '#a0c4e7',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_snowfestival') {
    return {
      paleBg: '#d6f0f3',
      paleText: 'black',
      mainBg: '#b3e3e7',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_cycle') {
    return {
      paleBg: '#ffdb87',
      paleText: 'black',
      mainBg: '#ffcd58',
      mainText: 'black',
    };
  }

  if (color_code === 'calcol_explore_disco') {
    return {
      paleBg: '#e3ff87',
      paleText: 'black',
      mainBg: '#bcff58',
      mainText: 'black',
    };
  }

  return undefined;
}

export function getTourrequestColorsFromExploreCode(code: string) {
  if (code === 'XJF')
    return getTourrequestColorsFromColorCode('calcol_explore_taste');
  if (code === 'CJA')
    return getTourrequestColorsFromColorCode('calcol_explore_cycle');
  if (code === 'JPH')
    return getTourrequestColorsFromColorCode('calcol_explore_highlights');
  if (code === 'JIF')
    return getTourrequestColorsFromColorCode('calcol_explore_snowfestival');
  if (code === 'JS')
    return getTourrequestColorsFromColorCode('calcol_explore_shogun');
  if (code === 'QJS')
    return getTourrequestColorsFromColorCode('calcol_explore_simply');
  if (code === 'WJP')
    return getTourrequestColorsFromColorCode('calcol_explore_walk');
  if (code === 'DJA')
    return getTourrequestColorsFromColorCode('calcol_explore_disco');

  return undefined;
}
