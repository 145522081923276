import { Link } from 'react-router-dom';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { H_ExpenseRules } from 'src/pages/ExpenseSheet/Sections/H_ExpenseRules';


export function ExpenseRulesPage() {

  usePageTitle('Expense rules');

  return (
    <div className='container'>
      <div className='my-3'>
        <Link to='/guide/expenses/'>&lt;&lt;Back to expense sheet list</Link>
      </div>

      <H_ExpenseRules />

    </div>
  );
}
