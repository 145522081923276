import { httpsCallable } from 'firebase/functions';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { useAppContext } from 'src/hooks/useAppContext';


export function RoundUserDropdown() {

  const { db, auth, userDetails, _lang, setLang, cloudFunctions } = useAppContext();
  const isJa = _lang('en', 'ja') === 'ja';

  const [isClearingAliasUid, setIsClearingAliasUid] = useState<number | undefined>();

  return (
    <>
      <div className='dropdown text-end'>
        <button className='btn btn-secondary d-blockx link-body-emphasisx text-decoration-none dropdown-togglex' data-bs-toggle='dropdown' aria-expanded='false'
          style={{ backgroundColor: 'transparent', border: 'none', padding: '0.2rem 0.75rem' }}>
          <img src={userDetails.photoFileDownloadURL || userDetails.photoURL} alt='' width='40' height='40' style={{ width: '3rem', height: '3rem' }} className='rounded-circle' />
          {isClearingAliasUid && (
            <div className='tw-absolute'>
              <BarLoader color={isClearingAliasUid === 1 ? 'lightblue' : 'lightgreen'} width={40} />
            </div>
          )}
        </button>
        <ul className='dropdown-menu dropdown-menu-end text-small'>{/* dropdown-menu-end ('end' means 'right) ensures the menu opens to the left */}
          <li>
            <div className='px-3 py-1'>
              <div>{_lang('Logged in as ', 'ログイン中のユーザー名：')}<span className='userName'>{userDetails.displayNameEn}</span></div>
              <div>{userDetails.email}</div>
              <div></div>
            </div>
          </li>
          <li><hr className='dropdown-divider' /></li>
          <li><Link className='dropdown-item' to='/userprofile'>{_lang('User settings', 'ユーザー設定')}</Link></li>
          <li><hr className='dropdown-divider' /></li>
          <li>
            <div className='languageSelector'>
              <div className={!isJa ? 'selected' : undefined} onClick={(e) => setLang('en')}>EN</div>
              <div className={isJa ? 'selected' : undefined} onClick={(e) => setLang('ja')}>JA</div>
            </div>
          </li>
          {userDetails.trueUid && (
            <>
              <li><hr className='dropdown-divider' /></li>
              <li><button className='dropdown-item' onClick={() => {
                // updateDoc(doc(db, 'permissions', userDetails.trueUid), {
                //   _aliasUid: null,
                // })
                setIsClearingAliasUid(1);
                const func = httpsCallable(cloudFunctions, 'clearAliasUid');
                func().then(() => {
                  // can take around 4 seconds if cold start
                  setIsClearingAliasUid(2);
                  location.reload();
                });
              }}>Stop viewing as {userDetails.displayNameEn}</button></li>
            </>
          )}
          <li><hr className='dropdown-divider' /></li>
          <li><button className='dropdown-item' onClick={() => auth.signOut()}>{_lang('Sign out', 'サインアウト')}</button></li>
        </ul>
      </div>
    </>
  );
}
