import { UIEventHandler, useMemo } from 'react';
import { GuideCalendarType, TourRequestType } from 'src/types/types_tourrequest';
import { UserDetailsType } from 'src/types/types_user';
import { dateutcFormatJpShort } from 'src/util/dateformattools';
import { addDays, utc0_from_iso } from 'src/util/datetools';
import { compare } from 'src/util/util_misc';
import { GuideDetailedHoursDataType } from '../Popups/GuideDetailedHoursBreakdown';
import { HoursListItemType, calculateDetailedMonthlyGuideHours } from '../Popups/util_guidinghours_calculatedetails';
import { UserSimpleCalendarGuideType } from '../useGuideList';
import { getGuideYearDates } from '../util_tourcalendar';



interface L3_GuideListProps {
  isReadOnly: boolean;
  block3height: string;
  CELL_HEIGHT_EM: number;
  GUIDECALENDAR_BOTTOM_BUFFER: number;
  ref_L3_GuideListGrid: React.RefObject<HTMLDivElement>;
  onScrollL3: UIEventHandler<HTMLDivElement>;
  tourListtotalWidth: number;
  usedGuides: UserSimpleCalendarGuideType[];
  userDetailsList: UserDetailsType[];
  tourrequestsAll: TourRequestType[];
  guideCalendars: GuideCalendarType[];
  setYearlyCalendarGuide: (iGuide: number) => void;
  setGuideDetailedHours: (guideData: GuideDetailedHoursDataType) => void;
  showWorkdays: boolean;
}

export function L3_GuideList({
  isReadOnly,
  block3height,
  CELL_HEIGHT_EM,
  GUIDECALENDAR_BOTTOM_BUFFER,
  ref_L3_GuideListGrid,
  onScrollL3,
  tourListtotalWidth,
  usedGuides,
  userDetailsList,
  tourrequestsAll,
  guideCalendars,
  setYearlyCalendarGuide,
  setGuideDetailedHours,
  showWorkdays,
}: L3_GuideListProps) {

  const guideRows = useMemo(() => {

    return usedGuides.map((guide, iGuide) => {
      const className =
        guide.teamName === 'Eighty Days Tour Leaders' ? 'guideListRow_1'
          : guide.teamName === 'Eighty Days Other' ? 'guideListRow_2'
            : guide.teamName === 'Freelance Guides' ? 'guideListRow_3'
              : '';

      let rowContents: any;

      let jsxHours = <></>;

      if (guide.teamName === 'Freelance Guides') {
        rowContents = guide.name;
      } else if (guide.teamName === 'Eighty Days Other') {
        const details = userDetailsList.find((user) => user.id === guide.id);
        let longName = details?.displayName || guide.name;
        if (!longName.toLowerCase().includes(guide.name.toLowerCase()))
          longName += ` (${guide.name})`;

        rowContents = longName;

      } else if (guide.teamName === 'Eighty Days Tour Leaders') {
        const details = userDetailsList.find((user) => user.id === guide.id);
        let longName = details?.displayName || guide.name;
        if (!longName.toLowerCase().includes(guide.name.toLowerCase()))
          longName += ` (${guide.name})`;

        if (details?.guideYearStartsOn) {
          const [dateutcStart, dateutcEnd] = getGuideYearDates(details.guideYearStartsOn).map(utc0_from_iso);

          const hoursList: HoursListItemType[] = [];

          for (const tourrequest of tourrequestsAll) {
            if (!tourrequest.calendarDays) continue;
            for (const [dateiso, calendarDay] of Object.entries(tourrequest.calendarDays)) {
              if (!calendarDay.guides) continue;
              const dateutc = new Date(dateiso);
              if (!(dateutc >= dateutcStart && dateutc < dateutcEnd)) continue;
              const guidingSessions = calendarDay.guides.filter((g) => g.id === guide.id);
              if (guidingSessions.length === 0) continue;
              if (guidingSessions.length > 1)
                console.error(`More than one guiding session for guide on same tour ${tourrequest.id} same day ${dateiso}`);
              for (const session of guidingSessions) {
                if (session.hours) {
                  let h = session.hours;
                  if (typeof h === 'string') {
                    if (!(h as string).match(/^\d{1,2}$/)) {
                      console.error(`session.hours is not a number: ${session.hours}`);
                    }
                    h = Number(h);
                  }
                  hoursList.push({ workType: 'guiding', dateiso, hours: h });

                }
              }
            }
          }

          const guideCalendar = guideCalendars.find((gc) => gc.id === guide.id);
          if (guideCalendar && guideCalendar.officeWork) {
            for (const [dateiso, office] of Object.entries(guideCalendar.officeWork)) {
              if (office._isDeleted) continue;
              const dateutc = new Date(dateiso);
              if (!(dateutc >= dateutcStart && dateutc < dateutcEnd)) continue;
              const h = office.workHours || 0;
              hoursList.push({ workType: 'office', dateiso, hours: h });
            }
          }

          hoursList.sort((a, b) => compare(a.dateiso, b.dateiso));


          const calculationResults = calculateDetailedMonthlyGuideHours(dateutcStart, dateutcEnd, hoursList);

          const guidePtoDays = details.guidePaidTimeOff || 10;

          const remainingDays = calculationResults.totalWorkdays - guidePtoDays - calculationResults.totalWorkedHours / 8;

          // jsxHours = <div style={{ width: '100%' }} className='tw-cursor-pointer tw-group' onClick={() => {
          //   setGuideDetailedHours({
          //     guideName: guide.name,
          //     guidePtoDays,
          //     dateutcStart,
          //     dateutcEnd,
          //     calculationResults,
          //   })
          // }}>
          //   <div className='tw-whitespace-nowrap tw-border tw-border-solid tw-border-slate-400 tw-bg-slate-200 tw-flex tw-gap-3 group-hover:tw-bg-slate-300'>
          //     <div>{dateutcFormatJpShort(dateutcStart)}~{dateutcFormatJpShort(addDays(dateutcEnd, -1))}</div>
          //     <div className='tw-text-blue-700 tw-underline'>Details <i className='bi bi-arrow-up-right-square'></i></div>
          //   </div>
          //   <div className=' tw-border tw-border-solid tw-border-slate-400 tw-bg-white group-hover:tw-bg-slate-200'>
          //     <div className='tw-whitespace-nowrap'>
          //       {(calculationResults.totalGuidingHours / 8).toFixed(1)} (guiding)
          //       + {(calculationResults.totalOfficeHours / 8).toFixed(1)} (office)
          //       = {(calculationResults.totalWorkedHours / 8).toFixed(1)} (days worked)
          //     </div>
          //     <div className='tw-whitespace-nowrap'>
          //       {calculationResults.totalWorkdays} (workdays)
          //       - {guidePtoDays} (PTO)
          //       - {(calculationResults.totalWorkedHours / 8).toFixed(1)}{' '}
          //       = <b>{remainingDays.toFixed(1)}</b> (remaining)
          //     </div>
          //   </div>
          // </div>


          const jsxHoursClickable = !isReadOnly; // guides are readonly, so can't click to open the popup

          jsxHours = (
            <div onClick={() => {
              if (!jsxHoursClickable) return;
              setGuideDetailedHours({
                guideName: guide.name,
                guidePtoDays,
                dateutcStart,
                dateutcEnd,
                calculationResults,
              });
            }}
              className={'tw-grid tw-grid-cols-4 tw-gap-[1px] tw-bg-slate-200 tw-group tw-w-[22em] '
                + '[&>div]:tw-outline [&>div]:tw-outline-1 [&>div]:tw-outline-slate-500 [&>div]:tw-text-center [&>div]:tw-px-[0.25em] '
                + (jsxHoursClickable ? 'tw-cursor-pointer ' : '')}
            >

              <div style={{ gridRow: 1, gridColumn: '1/-1' }} className={`tw-flex tw-gap-3 ${jsxHoursClickable ? 'group-hover:tw-bg-slate-300' : ''}`}>
                <div>{dateutcFormatJpShort(dateutcStart)}~{dateutcFormatJpShort(addDays(dateutcEnd, -1))}</div>
                {jsxHoursClickable && (
                  <div className='tw-text-blue-700 tw-underline'>Details <i className='bi bi-arrow-up-right-square'></i></div>
                )}
              </div>

              {/* <div style={{ gridRow: 2, gridColumn: 1 }} className='tw-bg-white'>
                {(calculationResults.totalGuidingHours / 8).toFixed(1)}
              </div>
              <div style={{ gridRow: 3, gridColumn: 1 }}>
                Guiding
              </div>
              <div style={{ gridRow: 2, gridColumn: 2 }} className='tw-bg-white'>
                {(calculationResults.totalOfficeHours / 8).toFixed(1)}
              </div>
              <div style={{ gridRow: 3, gridColumn: 2 }}>
                Office
              </div> */}
              <div style={{ gridRow: 2 }} className={`tw-bg-white ${jsxHoursClickable ? 'group-hover:tw-bg-slate-200' : ''}`}>
                {(calculationResults.totalWorkedHours / 8).toFixed(1)}
              </div>
              <div style={{ gridRow: 3, lineHeight: '1em' }} className={jsxHoursClickable ? 'group-hover:tw-bg-slate-300' : ''}>
                Days worked
              </div>
              <div style={{ gridRow: 2 }} className={`tw-bg-white ${jsxHoursClickable ? 'group-hover:tw-bg-slate-200' : ''}`}>
                {calculationResults.totalWorkdays}
              </div>
              <div style={{ gridRow: 3, lineHeight: '1em' }} className={jsxHoursClickable ? 'group-hover:tw-bg-slate-300' : ''}>
                Workdays in year
              </div>
              <div style={{ gridRow: 2 }} className={`tw-bg-white ${jsxHoursClickable ? 'group-hover:tw-bg-slate-200' : ''}`}>
                {guidePtoDays}
              </div>
              <div style={{ gridRow: 3, lineHeight: '1em' }} className={jsxHoursClickable ? 'group-hover:tw-bg-slate-300' : ''}>
                PTO
              </div>
              <div style={{ gridRow: 2 }} className={`tw-bg-white ${jsxHoursClickable ? 'group-hover:tw-bg-slate-200' : ''}`}>
                {remainingDays.toFixed(1)}
              </div>
              <div style={{ gridRow: 3, lineHeight: '1em' }} className={jsxHoursClickable ? 'group-hover:tw-bg-slate-300' : ''}>
                Workdays remaining
              </div>
            </div>
          );

        }

        rowContents = (
          <>
            <div style={{
              position: 'absolute',
              left: '0em',
              top: '0em',
              height: `${CELL_HEIGHT_EM}em`,
              paddingLeft: '0.5em',
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5em',
            }}>
              {longName}
              {!isReadOnly && (
                <i className='bi bi-calendar3' style={{ cursor: 'pointer' }} onClick={() => setYearlyCalendarGuide(iGuide)}></i>
              )}
            </div>
            {/* <div style={{
            position: 'absolute',
            top: '0em',
            left: '10em',
            height: `${CELL_HEIGHT_EM}em`,
            display: 'flex',
            alignItems: 'center',
          }}>
            {yearStartsOn}
          </div> */}
            {showWorkdays && (
              <div style={{
                position: 'absolute',
                top: '0em',
                left: '8em',
                height: `${CELL_HEIGHT_EM}em`,
                display: 'flex',
                alignItems: 'center',
                width: '24em',
                justifyContent: 'stretch',
              }}>
                {jsxHours}
              </div>
            )}
          </>
        );
      } else {
        const _: never = guide.teamName;
      }

      return (
        <div
          key={guide.id}
          className={className}
          style={{
            position: 'absolute',
            left: '0em',
            top: `${iGuide * CELL_HEIGHT_EM}em`,
            height: `${CELL_HEIGHT_EM}em`,
            width: `${tourListtotalWidth}em`,
            borderLeft: '1px solid #aaa',
            borderRight: '1px solid #aaa',
            borderBottom: '1px solid #aaa',
            paddingLeft: '0.5em',
            display: 'flex',
            alignItems: 'center',
          }}>
          {rowContents}
        </div>
      );
    });

  }, [CELL_HEIGHT_EM, guideCalendars, tourListtotalWidth, tourrequestsAll, usedGuides, userDetailsList, setYearlyCalendarGuide, isReadOnly, setGuideDetailedHours, showWorkdays]);

  return (
    <div
      ref={ref_L3_GuideListGrid}
      className='L3_guideList'
      style={{
        height: block3height,
        position: 'relative',
        // left: '0em',
        // top: block3top,
        width: `${tourListtotalWidth}em`,
        overflowY: 'scroll',
        borderBottom: '1px solid #666',
      }}
      onScroll={onScrollL3}
    >
      <div className='vertical-spacer' style={{
        position: 'absolute',
        height: `${(usedGuides.length + GUIDECALENDAR_BOTTOM_BUFFER) * CELL_HEIGHT_EM}em`,
        width: '1px',
      }}></div>


      {guideRows}

    </div>
  );
}
