import React, { ReactNode } from 'react';

export function ArrayJoin(array: ReactNode[], separator: string) {

  // similar to string[].join(', ') but for arrays of ReactNodes

  const result = [];
  for (let i = 0; i < array.length; i++) {
    if (i > 0)
      result.push(<React.Fragment key={`${i}_sep`}>{separator}</React.Fragment>);
    result.push(<React.Fragment key={`${i}_item`}>{array[i]}</React.Fragment>);
  }
  return result;
}
