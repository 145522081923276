import { EditableFieldWithBorder } from 'src/components/EditableField/EditableFieldWithBorder';
import { TripQuotationType, TripQuotationUpdateObjType } from 'src/types/types_tripquotations';
import { formatNum } from 'src/util/util_formatnum';



interface QuotationPricingBlockProps {
  quotation: TripQuotationType;
  enableEditing: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: (
    userAction: string,
    updateObj: Partial<TripQuotationType> | TripQuotationUpdateObjType,
    sUndoWall: 'u' | 'UNDOWALL', // u = undoable
  ) => Promise<void>;
  tabNavigation: (tabKey: -1 | 1 | undefined, prevId: string, nextId: string) => void;
  servicesTotal: number;
  hotelsTotal: number;
  isAudley: boolean;
};

export function QuotationPricingBlock({
  quotation,
  enableEditing,
  editedCell,
  setEditedCell,
  autosaveNewStep,
  tabNavigation,
  servicesTotal,
  hotelsTotal,
  isAudley,
}: QuotationPricingBlockProps) {


  const lastRow =
    quotation.pricingInfo?.agentCommissionActive ? '2_AGENT_COM'
      : (quotation.pricingInfo?.eightyDaysMarkupActive || quotation.pricingInfo?.fixedFeeActive) ? '1_EIGHTYDAYS_FEES'
        : '0_TOTAL';


  const totalBeforeFees = hotelsTotal + servicesTotal;

  const eightyDaysMarkup = (quotation.pricingInfo?.eightyDaysMarkupActive && quotation.pricingInfo?.eightyDaysMarkup) || 0;
  const fixedFee = (quotation.pricingInfo?.fixedFeeActive && quotation.pricingInfo?.fixedFee) || 0;
  const totalAfter80daysFees =
    totalBeforeFees
    * (1 + eightyDaysMarkup / 100)
    + fixedFee;

  const agentCommission = (quotation.pricingInfo?.agentCommissionActive && quotation.pricingInfo?.agentCommission) || 0;
  const totalAfterAgentCommission =
    Math.round(
      totalAfter80daysFees
      / (1 - agentCommission / 100)
    );

  return (
    <div className='tw-border tw-border-solid tw-border-blue-900/30 tw-rounded
             tw-my-4 '>

      <h3 className='tw-text-lg tw-font-bold tw-m-0 tw-px-2 tw-bg-slate-600/20'>Quotation Pricing</h3>

      <div className='tw-px-4 tw-py-2'>

        {!isAudley && (
          <div className='tw-mb-4'>
            <h5 className='tw-font-bold tw-text-base tw-pl-2'>Fees & commissions</h5>

            <div className='tw-grid tw-grid-cols-[auto_auto_5em_auto] tw-w-fit tw-gap-4 tw-items-center'>
              <div>Eighty Days markup</div>
              <div>
                <input type='checkbox' className='tw-scale-125'
                  checked={quotation.pricingInfo?.eightyDaysMarkupActive ?? false}
                  onChange={(e) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.eightyDaysMarkupActive']: e.target.checked,
                    };
                    autosaveNewStep(`Change eightyDaysMarkupActive place to ‘${e.target.checked}’`, updateObj, 'u');
                  }}
                />
              </div>
              <div>
                <EditableFieldWithBorder
                  fieldname='eightyDaysMarkup'
                  validationType='number'
                  currentValue={quotation.pricingInfo?.eightyDaysMarkup}
                  isClickableToEdit={enableEditing}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.eightyDaysMarkup']: dbvalue,
                    };
                    autosaveNewStep(`Change eightyDaysMarkup place to ‘${dbvalue}’`, updateObj, 'u');
                    tabNavigation(tabKey, '', '');
                  }}
                />
              </div>
              <div>% of cost exc. markup</div>

              <div>Fixed fee (e.g. to cover<br /> bank transfer cost)</div>
              <div>
                <input type='checkbox' className='tw-scale-125'
                  checked={quotation.pricingInfo?.fixedFeeActive ?? false}
                  onChange={(e) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.fixedFeeActive']: e.target.checked,
                    };
                    autosaveNewStep(`Change fixedFeeActive place to ‘${e.target.checked}’`, updateObj, 'u');
                  }}
                />
              </div>
              <div>
                <EditableFieldWithBorder
                  fieldname='fixedFee'
                  validationType='number'
                  currentValue={quotation.pricingInfo?.fixedFee}
                  isClickableToEdit={enableEditing}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.fixedFee']: dbvalue,
                    };
                    autosaveNewStep(`Change fixedFee place to ‘${dbvalue}’`, updateObj, 'u');
                    tabNavigation(tabKey, '', '');
                  }}
                />
              </div>
              <div>JPY</div>

              <div>Agent commission</div>
              <div>
                <input type='checkbox' className='tw-scale-125'
                  checked={quotation.pricingInfo?.agentCommissionActive ?? false}
                  onChange={(e) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.agentCommissionActive']: e.target.checked,
                    };
                    autosaveNewStep(`Change agentCommissionActive place to ‘${e.target.checked}’`, updateObj, 'u');
                  }}
                />
              </div>
              <div>
                <EditableFieldWithBorder
                  fieldname='agentCommission'
                  validationType='number'
                  currentValue={quotation.pricingInfo?.agentCommission}
                  isClickableToEdit={enableEditing}
                  editedCell={editedCell}
                  setEditedCell={setEditedCell}
                  callbackCommitChange={(dbvalue: any, tabKey?: -1 | 1) => {
                    const updateObj: TripQuotationUpdateObjType = {
                      ['pricingInfo.agentCommission']: dbvalue,
                    };
                    autosaveNewStep(`Change agentCommission place to ‘${dbvalue}’`, updateObj, 'u');
                    tabNavigation(tabKey, '', '');
                  }}
                />
              </div>
              <div>% of price inc. commission</div>

            </div>
          </div>
        )}

        <div className=''>
          <h5 className='tw-font-bold tw-text-base tw-pl-2 tw-mt-4'>Total price</h5>
          <table className='
                    [&>*>tr>*]:tw-border-b
                    [&>*>tr>*]:tw-border-solid
                    [&>*>tr>*]:tw-border-slate-200
                    [&>*>tr>*]:tw-py-2
                    [&>*>tr>*]:tw-px-2
                    [&>*>tr>*]:tw-bg-white
                    [&>*>tr>td]:tw-align-top
                    [&>*>tr>th]:tw-align-bottom'>
            <thead>
              <tr>
                <th></th>
                <th>Per pax {quotation.generalInfo.numOfPax && <>({quotation.generalInfo.numOfPax} pax)</>}</th>
                <th>Whole group</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='tw-font-bold'>Accommodation</td>
                <td className='tw-text-right !tw-pl-8'>
                  {quotation.generalInfo.numOfPax && formatNum(Math.round(hotelsTotal / quotation.generalInfo.numOfPax))}
                </td>
                <td className='tw-text-right !tw-pl-8'>
                  {formatNum(hotelsTotal)}
                </td>
              </tr>
              <tr>
                <td className='tw-font-bold'>Services</td>
                <td className='tw-text-right !tw-pl-8'>
                  {quotation.generalInfo.numOfPax && formatNum(Math.round(servicesTotal / quotation.generalInfo.numOfPax))}
                </td>
                <td className='tw-text-right !tw-pl-8'>
                  {formatNum(servicesTotal)}
                </td>
              </tr>
              <tr>
                <td className='tw-font-bold'>{lastRow === '0_TOTAL' ? 'Grand total' : 'Total before fees'}</td>
                <td className='tw-text-right !tw-pl-8'>
                  {quotation.generalInfo.numOfPax && formatNum(Math.round(totalBeforeFees / quotation.generalInfo.numOfPax))}
                </td>
                <td className='tw-text-right !tw-pl-8'>
                  {formatNum(totalBeforeFees)}
                </td>
              </tr>
              {lastRow >= '1_EIGHTYDAYS_FEES' && (
                <>
                  <tr>
                    <td className='tw-font-bold'>Total after Eighty Days fees</td>
                    <td className='tw-text-right !tw-pl-8'>
                      {quotation.generalInfo.numOfPax && formatNum(Math.round(totalAfter80daysFees / quotation.generalInfo.numOfPax))}
                    </td>
                    <td className='tw-text-right !tw-pl-8'>
                      {formatNum(totalAfter80daysFees)}
                    </td>
                  </tr>
                  {lastRow >= '2_AGENT_COM' && (
                    <>
                      <tr>
                        <td className='tw-font-bold'>Total after Agent commission</td>
                        <td className='tw-text-right !tw-pl-8'>
                          {quotation.generalInfo.numOfPax && formatNum(Math.round(totalAfterAgentCommission / quotation.generalInfo.numOfPax))}
                        </td>
                        <td className='tw-text-right !tw-pl-8'>
                          {formatNum(totalAfterAgentCommission)}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
