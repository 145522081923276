import { highlighterColors } from './util_highlighters';



interface RowHighlightersProps {
  onClick: (color: string) => void;
  disabled: boolean;
}

export function RowHighlighters({
  onClick,
  disabled,
}: RowHighlightersProps) {

  return (
    <div className={`tw-mt-2 tw-flex tw-gap-1 ${disabled ? 'tw-opacity-50' : ''}`}>
      {highlighterColors.map(colorObj => {
        const color = colorObj.name;
        return (
          <button
            key={color}
            className={`tw-w-6 tw-h-6 tw-border tw-border-solid ${colorObj.buttonBorder} ${colorObj.buttonBackground} tw-rounded-full ${disabled ? '!tw-cursor-default' : ''}`}
            title={color ? `Highlight row in ${color}` : 'Clear highlight'}
            onClick={() => {
              if (!disabled)
                onClick(color);
            }}></button>
        );
      })}
    </div>
  );

}
