import { memo } from 'react';
import { getSpanDaysFloat } from 'src/util/datetools';
import { isBankHoliday, isWeekend } from 'src/util/util_holidays';


interface WeekendBarsProps {
  listDays: Date[];
  numRows: number;
  currentHourUTC: number; // red bar indicating current time will be placed based on current hour
  dateutcCalendarStart: Date;
  dateutcCalendarEnd: Date;
  CELL_WIDTH_EM: number;
  CELL_HEIGHT_EM: number;
}

function WeekendBars0({
  listDays,
  numRows,
  currentHourUTC,
  dateutcCalendarStart,
  dateutcCalendarEnd,
  CELL_WIDTH_EM,
  CELL_HEIGHT_EM,
}: WeekendBarsProps) {


  const numDays = listDays.length;

  const weekendBars = [];

  for (let i = 0; i < listDays.length; i++) {
    const dateutc = listDays[i];

    if (isWeekend(dateutc)) {
      const nextDayAlsoWeekend = (i < numDays - 1) && isWeekend(listDays[i + 1]);
      const barWidth = nextDayAlsoWeekend ? 2 : 1;
      weekendBars.push(
        <div key={`weekend_${i}`} className='weekendColumn' style={{
          left: `${i * CELL_WIDTH_EM}em`,
          width: `${barWidth * CELL_WIDTH_EM}em`,
          height: `${numRows * CELL_HEIGHT_EM}em`,
        }}></div>
      );
      if (nextDayAlsoWeekend) i++;
      continue;
    }

    if (isBankHoliday(dateutc)) {
      weekendBars.push(
        <div key={`bankholiday_${i}`} className='bankHolidayColumn' style={{
          left: `${i * CELL_WIDTH_EM}em`,
          width: `${CELL_WIDTH_EM}em`,
          height: `${numRows * CELL_HEIGHT_EM}em`,
        }}></div>
      );
      continue;
    }
  }

  const dateutcCurrentHour = new Date(currentHourUTC);
  if (dateutcCurrentHour >= dateutcCalendarStart && dateutcCurrentHour <= dateutcCalendarEnd) {
    const i = getSpanDaysFloat(dateutcCalendarStart, dateutcCurrentHour);
    weekendBars.push(
      <div key='today' className='todayColumn' style={{
        left: `${i * CELL_WIDTH_EM - 0.1}em`,
        width: '0.2em',
        height: `${numRows * CELL_HEIGHT_EM}em`,
      }}></div>
    );
  }

  return weekendBars;

}

export const WeekendBars = memo(WeekendBars0);
