


interface ButtonUpDownProps {
  disableUp: boolean;
  disableDown: boolean;
  onClickUp: () => void;
  onClickDown: () => void;
}

export function ButtonUpDown({
  disableUp,
  disableDown,
  onClickUp,
  onClickDown,
}: ButtonUpDownProps) {

  const tw_enabled =
    'tw-bg-white tw-text-blue-500 '
    + 'tw-border tw-border-solid tw-border-blue-500 tw-rounded '
    + 'hover:tw-bg-blue-500 hover:tw-text-white';
  const tw_disabled =
    'tw-bg-tranparent tw-text-slate-400 '
    + 'tw-border tw-border-solid tw-border-slate-400 tw-rounded';

  return (
    <div key='0' className='tw-flex tw-gap-1 tw-flex-col'>
      <button
        disabled={disableUp}
        className={`${!disableUp ? tw_enabled : tw_disabled} tw-h-5 tw-py-0`}
        onClick={onClickUp}>
        <i className='bi bi-chevron-up tw-block tw-mt-[-0.2em]'></i>
      </button>
      <button
        disabled={disableDown}
        className={`${!disableDown ? tw_enabled : tw_disabled} tw-h-5 tw-pt-[-1em]`}
        onClick={onClickDown}>
        <i className='bi bi-chevron-down tw-block tw-mt-[-0.2em]'></i>
      </button>
    </div>
  );
}
