import { collection, onSnapshot, query, QuerySnapshot, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { HotelPricesType, ServicePriceCatalogType } from 'src/types/types_supplierprices';
import { coll_tripquotations, TripQuotationType } from 'src/types/types_tripquotations';
import { dateFormatJpWithTime, dateisoFormatJpShort } from 'src/util/dateformattools';
import { getSpanDaysExactIso } from 'src/util/datetools';
import { convertHotelPriceDates, convertQuotationDates, convertServicePriceDates } from 'src/util/util_firestoredates';
import { DeleteQuotationButton } from './DeleteQuotationButton';

export function PageQuotationList() {

  const [quotations, setQuotations] = useState<TripQuotationType[]>();
  const [showIdColumn, setShowIdColumn] = useState(false);
  const [shownPopup, setShownPopup] = useState<string | null>(null);

  const { db, setDbError, userDetails } = useAppContext();

  useEffect(() => {
    const processSnapshot = function (snapshot: QuerySnapshot) {
      console.log(`Retrieved ${snapshot.docs.length} docs from firestore collection 'tripquotations'`);

      const list: TripQuotationType[] = [];
      for (const docu of snapshot.docs) {
        const quotation = { ...docu.data(), id: docu.id } as TripQuotationType;
        convertQuotationDates(quotation);
        list.push(quotation);
      }
      const now = new Date().getTime();
      list.sort((a, b) => ((a.dateCreated?.getTime() || now) - (b.dateCreated?.getTime() || now)) * -1);
      setQuotations(list);
    };
    const q = query(collection(db, coll_tripquotations), where('_isDeleted', '==', false));
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting trip quotation list', err));

    return unsubscribe;
  }, [db, setDbError]);

  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>();
  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {

        const hotelList: HotelPricesType[] = [];
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType;
          convertHotelPriceDates(hotelprice);
          hotelList.push(hotelprice);
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex);
        setHotelPrices(hotelList);
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    );
  }, [db, setDbError]);

  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>();
  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {
        const serviceList: ServicePriceCatalogType[] = [];
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType;
          convertServicePriceDates(serviceprice);
          serviceList.push(serviceprice);
        }
        setServicePrices(serviceList);
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    );
  }, [db, setDbError]);


  // *** all hooks above this line ***

  usePageTitle('Quotation list');
  const loadingSpinner = getLoadingSpinnerOrNull([
    ['quotations', quotations],
  ]);
  if (!quotations || !hotelPrices || !servicePrices)
    return loadingSpinner;


  return (
    <div>

      <div>
        <div className='tw-m-10'>
          <h5>List of quotations</h5>
          <table className='[&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-p-1'>
            <thead>
              <tr className='[&>*]:tw-bg-slate-300'>
                <th>Actions</th>
                {showIdColumn && <th>ID</th>}
                <th>Request code</th>
                <th>Source CSV</th>
                <th>Pax name</th>
                <th>Client agency data</th>
                <th>Travel designer</th>
                <th>Trip Dates</th>
                <th>Modified</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className='[&>tr>*]:tw-bg-white [&>tr>td.idcol]:tw-bg-blue-100'>
              {quotations.map(quotation => {
                return (
                  <tr key={quotation.id}>
                    <td className='tw-text-center'>
                      <ButtonTW variant='blue_outline' to={`/quotations/edit/${quotation.id}`}>Edit</ButtonTW>
                    </td>
                    {showIdColumn && (
                      <td className='idcol'>{quotation.id}</td>
                    )}
                    <td>
                      <RequestCodeLinkToAggregator
                        requestCode={quotation.requestCode}
                        linkId={quotation.id}
                        shownPopup={shownPopup}
                        setShownPopup={setShownPopup}
                      />
                    </td>
                    <td>
                      {quotation.sourceCsvName}
                    </td>
                    <td>
                      {quotation.generalInfo.paxName}
                    </td>
                    <td>
                      <div>{quotation.generalInfo.agencyBusinessUnit}</div>
                      <div>{quotation.generalInfo.agencyOwner}</div>
                      <div>{quotation.generalInfo.agencyPrimaryContact}</div>
                      <div>{quotation.generalInfo.agencyItineraryRefWithVersion}</div>
                    </td>
                    <td>
                      {quotation.usersDesigners.map(user => user.name).join(', ')}
                    </td>
                    <td>
                      <div className='tw-grid tw-grid-cols-[auto_auto] tw-gap-x-2'>
                        <div>From:</div>
                        <div>{dateisoFormatJpShort(quotation.generalInfo.tripStartDateiso)}</div>
                        <div>To:</div>
                        <div>{dateisoFormatJpShort(quotation.generalInfo.tripEndDateiso)}</div>
                        {quotation.generalInfo.tripStartDateiso && quotation.generalInfo.tripEndDateiso && (
                          <div className='tw-col-start-2'>({getSpanDaysExactIso(quotation.generalInfo.tripStartDateiso, quotation.generalInfo.tripEndDateiso)} days)</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div>{dateFormatJpWithTime(quotation.dateModified)}</div>
                      <div>
                        {quotation.userCreated.name}
                        {quotation.userCreated.uid !== quotation.userModified.uid && ` (${quotation.userModified.name})`}
                      </div>
                    </td>
                    <td>
                      <DeleteQuotationButton quotation={quotation} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div>
            <CheckboxSwitch id='chkShowIdColumn' label='Show ID column'
              checked={showIdColumn} onChange={(e) => {
                setShowIdColumn(e.target.checked);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
