import { ExpensePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet';
import { getSpanDaysExact, utc0_from_iso } from 'src/util/datetools';


function calcTourNumDays(tourStartDate: Date | null, tourEndDate: Date | null) {
  if (!tourStartDate || !tourEndDate)
    return 0;
  if (tourEndDate < tourStartDate) {
    return 0;
  } else {
    const newNumDays = getSpanDaysExact(tourStartDate, tourEndDate) + 1;
    return newNumDays;
  }
}

export function calcTourNumDaysIso(dateisoTourStart: string, dateisoTourEnd: string) {
  if (!dateisoTourStart || !dateisoTourEnd)
    return 0;
  const dateutcTourStart = utc0_from_iso(dateisoTourStart);
  const dateutcTourEnd = utc0_from_iso(dateisoTourEnd);
  return calcTourNumDays(dateutcTourStart, dateutcTourEnd);
}

export function calculateAmounts(sheet: ExpenseSheetType, advancePaymentsPaid: Map<string, ExpensePaymentType>) {

  const tourNumDays = calcTourNumDaysIso(sheet.dateisoTourStart, sheet.dateisoTourEnd);


  // if some days are missing in meal allowance array, add them here
  if (sheet.mealAllowance.length < tourNumDays) {
    for (let i = sheet.mealAllowance.length; i < tourNumDays; i++) {
      sheet.mealAllowance.push(
        { guiding: false, lunch: false, dinner: false, memorandum: '', guidingDayMemorandum: '' },
      );
    }
  }


  let numGuidingDays = 0;
  let numLunches = 0;
  let numDinners = 0;
  for (let i = 0; i < tourNumDays; i++) {
    if (sheet.mealAllowance[i].guiding) {
      numGuidingDays++;
      if (sheet.mealAllowance[i].lunch) numLunches++;
      if (sheet.mealAllowance[i].dinner) numDinners++;
    }
  }


  let totalAdvancePaid = 0;
  let totalAdvanceUnpaid = 0;
  sheet.advancePayments.forEach((ap) => {
    if (ap.isDeleted || !ap.amount)
      return;

    const isPaid = !!advancePaymentsPaid.get(`${sheet.id} ${ap.id}`);
    if (isPaid)
      totalAdvancePaid += ap.amount;
    else
      totalAdvanceUnpaid += ap.amount;
  });


  let totalTLTransportation = 0;
  sheet.guideTransportation.forEach((gt) => { if (!gt.isDeleted && gt.amount) totalTLTransportation += gt.amount; });


  let totalOtherExpenses = 0;
  sheet.otherExpenses.forEach((oe) => { if (!oe.isDeleted && oe.unitPrice && oe.quantity) totalOtherExpenses += oe.unitPrice * oe.quantity; });


  const totalMealAllowanceLunch = numLunches * sheet.mealAllowancePrices.lunchPrice;
  const totalMealAllowanceDinner = numDinners * sheet.mealAllowancePrices.dinnerPrice;
  const totalMealAllowance = totalMealAllowanceLunch + totalMealAllowanceDinner;

  const totalExpenses = totalTLTransportation + totalOtherExpenses + totalMealAllowance;
  const balance = totalExpenses - totalAdvancePaid;

  const calc = {
    tourNumDays,
    numGuidingDays,
    numLunches,
    numDinners,
    totalAdvancePaid,
    totalAdvanceUnpaid,
    totalTLTransportation,
    totalOtherExpenses,
    totalMealAllowanceLunch,
    totalMealAllowanceDinner,
    totalMealAllowance,
    totalExpenses,
    balance,
  };

  return calc;

}

