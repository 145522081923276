import { useAppContext } from 'src/hooks/useAppContext';
import { AutosaveSheetType } from 'src/pages/ExpenseSheet/ExpenseSheet';
import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateFormatJpShort } from 'src/util/dateformattools';
import { EditableFieldDatepicker } from '../EditableFieldDatepicker/EditableFieldDatepicker';


interface EditableFieldExpensesAutosaveDatepickerProps {
  sheet: ExpenseSheetType;
  tableid: keyof ExpenseSheetType;
  fieldname: string;
  currentValue: Date | null;
  isClickableToEdit: boolean;
  autosaveNewStep: AutosaveSheetType;
  sUndoWall: 'u' | 'UNDOWALL';
  customSaveCallback: (dbvalue: Date | null) => (Partial<ExpenseSheetType> | null);
}

export function EditableFieldExpensesAutosaveDatepicker({
  sheet,
  tableid,
  fieldname,
  currentValue,
  isClickableToEdit,
  autosaveNewStep,
  sUndoWall,
  customSaveCallback,
}: EditableFieldExpensesAutosaveDatepickerProps) {

  const { setDbError } = useAppContext();

  const callbackCommitChange = (date_jst0: Date | null) => {

    const rowList = sheet[tableid];
    if (!rowList || !Array.isArray(rowList)) {
      console.error(`EditableFieldExpenses: sheet[${tableid}] is not an array`);
      return;
    }

    const updateObj: Partial<ExpenseSheetType> | null = customSaveCallback(date_jst0);

    if (updateObj) {
      autosaveNewStep(`Input ‘${date_jst0 ? dateFormatJpShort(date_jst0) : ''}’ in ${tableid} ${fieldname}`, updateObj, sUndoWall)
        .catch((err) => setDbError('Autosave expense sheet', err));
    }
  };

  return (
    <EditableFieldDatepicker
      currentValue_jst0={currentValue}
      isClickableToEdit={isClickableToEdit}
      callbackCommitChange={(date_jst0) => callbackCommitChange(date_jst0)}
    />
  );
}
