

export const highlighterColors = [
  {
    name: 'pink',
    buttonBorder: 'tw-border-pink-400',
    buttonBackground: 'tw-bg-pink-300',
    rowBackground: '!tw-bg-pink-100',
  },
  {
    name: 'yellow',
    buttonBorder: 'tw-border-yellow-400',
    buttonBackground: 'tw-bg-yellow-300',
    rowBackground: '!tw-bg-yellow-100',
  },
  {
    name: 'blue',
    buttonBorder: 'tw-border-blue-400',
    buttonBackground: 'tw-bg-blue-300',
    rowBackground: '!tw-bg-blue-100',
  },
  {
    name: 'green',
    buttonBorder: 'tw-border-green-400',
    buttonBackground: 'tw-bg-green-300',
    rowBackground: '!tw-bg-green-100',
  },
  {
    name: '',
    buttonBorder: 'tw-border-slate-400',
    buttonBackground: 'tw-bg-white',
    rowBackground: '',
  },
];

export const highlighterColorMap = new Map(highlighterColors.map(color => [color.name, color]));

