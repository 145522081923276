import { AddButton } from 'src/components/Buttons/AddButton';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { EditableFieldExpensesAutosave } from 'src/components/EditableField/EditableFieldExpensesAutosave';
import { EditableFieldExpensesAutosaveDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldExpensesAutosaveDatepicker';
import { AdvancePaymentType, ExpensePaymentType, ExpenseSheetType } from 'src/types/types_expensesheet';
import { dateFormatJp } from 'src/util/dateformattools';
import { getTodayIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { formatNum } from 'src/util/util_formatnum';
import { compare } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { AutosaveSheetType } from '../ExpenseSheet';


interface G_PaymentsToGuideProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: AutosaveSheetType;
  advancePaymentsPaid: Map<string, ExpensePaymentType>;
}

export function G_PaymentsToGuide({
  sheet,
  isReadOnly,
  editedCell,
  setEditedCell,
  autosaveNewStep,
  advancePaymentsPaid,
}: G_PaymentsToGuideProps) {

  return (
    <>
      <h5 className='mb-4'>Payments to Guide</h5>

      <div>
        <table className='table mb-1 inputtingTable' style={{ width: `${4 + 8 + 7 + 8 + 8 + 24 + 4}em` }}>
          <colgroup>
            <col style={{ width: '4em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '7em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '24em' }} />
            <col style={{ width: '4em' }} />
          </colgroup>
          <thead>
            <tr className='row-header'>
              <th></th>
              <th>Desired date</th>
              <th>Amount</th>
              <th className='text-center'>Is advance?</th>
              <th>Status</th>
              <th>Memo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sheet.advancePayments.filter((row) => !row.isDeleted).map((row, rowIndex) => {

              const paymentInfo = advancePaymentsPaid.get(`${sheet.id} ${row.id}`);

              const isRowReadOnly = isReadOnly || !!paymentInfo;

              const rowInfo = {
                sheet,
                rowid: row.id,
                tableid: 'advancePayments' as keyof ExpenseSheetType,
                isClickableToEdit: !isRowReadOnly,
                editedCell: editedCell,
                setEditedCell: setEditedCell,
                autosaveNewStep,
              };

              return (
                <tr key={row.id}>
                  <td>
                  </td>
                  <td>
                    <EditableFieldExpensesAutosaveDatepicker
                      fieldname='date'
                      currentValue={row.dateiso ? jst0_from_iso(row.dateiso) : null}

                      customSaveCallback={(date_jst0) => {
                        const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';
                        if (row.dateiso === dateiso)
                          return null; // no change

                        const advancePaymentsNew = sheet.advancePayments.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: AdvancePaymentType = {
                              ...item,
                              dateiso,
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        advancePaymentsNew.sort((a, b) => compare(a.dateiso, b.dateiso));
                        const updateObj: Partial<ExpenseSheetType> = {
                          advancePayments: advancePaymentsNew,
                        };

                        return updateObj;
                      }}

                      {...rowInfo}
                      sUndoWall={'UNDOWALL'} // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='amount'
                      currentValue={row.amount}
                      validationType='number'
                      {...rowInfo}
                      sUndoWall={'UNDOWALL'} // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                    />
                  </td>
                  <td className='text-center'>
                    <input type='checkbox' className='form-check-input' checked={row.isAdvance} disabled={isRowReadOnly} onChange={(e) => {
                      if (isRowReadOnly) return;

                      const advancePaymentsNew = sheet.advancePayments.map((item, index) => {
                        if (index === rowIndex) {
                          const newItem: AdvancePaymentType = {
                            ...item,
                            isAdvance: e.target.checked,
                          };
                          return newItem;
                        } else {
                          return item;
                        }
                      });

                      const updateObj: Partial<ExpenseSheetType> = {
                        advancePayments: advancePaymentsNew,
                      };

                      autosaveNewStep(`${e.target.checked ? 'Set' : 'Unset'} payment as advance`, updateObj, 'UNDOWALL'); // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                    }} />
                  </td>
                  <td>
                    {paymentInfo ? (
                      <span>PAID on {dateFormatJp(paymentInfo.paymentDate)}</span>
                    ) : (
                      <span>Not paid</span>
                    )}
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='memorandum'
                      currentValue={row.memorandum}
                      validationType=''
                      {...rowInfo}
                      isClickableToEdit={!isReadOnly} // even if already paid, memo is editable
                      isTextArea={true}
                      sUndoWall={'UNDOWALL'} // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                    />
                  </td>
                  <td>
                    {!isRowReadOnly && (
                      <DeleteButton onClick={() => {
                        const isEmpty = !row.dateiso && !row.amount && !row.memorandum;
                        if (!isEmpty && !window.confirm('Delete row?'))
                          return;

                        const advancePaymentsNew = sheet.advancePayments.map((item) => {
                          if (item.id === row.id) {
                            const newItem: AdvancePaymentType = {
                              ...item,
                              isDeleted: true,
                            };
                            return newItem;
                          } else {
                            return item;
                          }
                        });

                        const updateObj: Partial<ExpenseSheetType> = {
                          advancePayments: advancePaymentsNew,
                        };

                        autosaveNewStep('Delete advance payment', updateObj, 'UNDOWALL'); // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                      }} />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td>Total</td>
              <td>{formatNum((sheet.calc.totalAdvancePaid + sheet.calc.totalAdvanceUnpaid))}</td>
              <td></td>
              <td></td>
              <td></td>
              <td>
                {!isReadOnly && (
                  <AddButton onClick={() => {
                    const newRow: AdvancePaymentType = {
                      id: nano_id(),
                      dateiso: getTodayIso(),
                      amount: null,
                      isAdvance: true,
                      memorandum: '',
                      isDeleted: false,
                    };

                    const advancePaymentsNew: AdvancePaymentType[] = [...sheet.advancePayments, newRow];

                    const updateObj: Partial<ExpenseSheetType> = {
                      advancePayments: advancePaymentsNew,
                    };

                    autosaveNewStep('Add advance payment', updateObj, 'UNDOWALL'); // any action related to payments is not undoable (in case the payment has already taken place, and this part of the form is disabled, i.e. isRowReadOnly=true)
                  }} />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
        {(sheet.calc.totalAdvancePaid !== 0 && sheet.calc.totalAdvanceUnpaid !== 0) && (
          <div>
            (Paid = {formatNum(sheet.calc.totalAdvancePaid)} | Unpaid = {formatNum(sheet.calc.totalAdvanceUnpaid)})
          </div>
        )}
      </div>
    </>
  );
}
