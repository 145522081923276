


interface ButtonSmProps {
  className?: string;
  orange?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}


export function ButtonSm({
  className,
  orange,
  onClick,
  children,
}: ButtonSmProps) {

  const colors =
    orange
      ? 'tw-border-orange-500 tw-bg-orange-500 tw-text-white     hover:tw-bg-orange-400 hover:tw-text-white hover:tw-border-orange-400'
      : 'tw-border-blue-500   tw-bg-white      tw-text-blue-500  hover:tw-bg-blue-500   hover:tw-text-white';

  return (
    <button className={`tw-border tw-border-solid tw-rounded tw-text-sm tw-py-1 ${colors} ${className ?? ''}`} onClick={onClick}>
      {children}
    </button>
  );
}
