import { Link } from 'react-router-dom';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { ExpenseSheetType } from 'src/types/types_expensesheet';



interface LinkToExpenseSheetProps {
  sheet: ExpenseSheetType;
  shownPopup: string | null;
  setShownPopup: (popup: string | null) => void;
}

export function LinkToExpenseSheet({
  sheet,
  shownPopup,
  setShownPopup,
}: LinkToExpenseSheetProps) {


  return (
    <>
      <RequestCodeLinkToAggregator
        requestCode={sheet.tourCode}
        linkId={sheet.id}
        shownPopup={shownPopup}
        setShownPopup={setShownPopup}
      /><br />
      <Link to={`/guide/expenses/sheet/${sheet.id}`} target='_blank'>
        Open expense sheet
        {' '}
        <i className='bi bi-box-arrow-up-right'></i>
      </Link>
    </>
  );
}
