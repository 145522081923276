import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { getSpanDaysExactIso } from 'src/util/datetools';


export function validateSheet(sheet: ExpenseSheetType) {

  const issues = [];

  let tourDatesValid = true;

  if (!sheet.tourCode)
    issues.push('Missing tour code');
  // if (!sheet.paxName)
  //   issues.push('Missing pax name') // pax name should be synced with Tour request, so any requirement to have max name should be handled on tour request input, not here
  // if (!sheet.numberOfPax)
  //   issues.push('Missing number of pax') // some expense sheets, e.g. for Inspections, do not have any pax so this field is left empty
  if (!sheet.dateisoTourStart) {
    tourDatesValid = false;
    issues.push('Missing start date');
  }
  if (!sheet.dateisoTourEnd) {
    tourDatesValid = false;
    issues.push('Missing end date');
  }
  if (sheet.dateisoTourEnd < sheet.dateisoTourStart) {
    tourDatesValid = false;
    issues.push('Invalid start/end date order');
  }
  if (!sheet.usersDesigners || sheet.usersDesigners.length === 0)
    issues.push('Missing Travel Designer');
  if (!sheet.userGuideUid || !sheet.userGuideEmail)
    issues.push('Missing Guide');

  for (let index = 0; index < sheet.guideTransportation.length; index++) {
    const gt = sheet.guideTransportation[index];
    if (gt.isDeleted)
      continue;
    if (!gt.dateiso)
      issues.push(`Missing date on Transportation ${1 + index}`);
    if (!gt.transportationFrom)
      issues.push(`Missing 'from' on Transportation ${1 + index}`);
    if (!gt.transportationTo)
      issues.push(`Missing 'to' on Transportation ${1 + index}`);
    if (!gt.amount && gt.amount !== 0)
      issues.push(`Missing amount on Transportation ${1 + index}`);
  }

  for (let index = 0; index < sheet.otherExpenses.length; index++) {
    const oe = sheet.otherExpenses[index];
    if (oe.isDeleted)
      continue;
    if (!oe.dateiso)
      issues.push(`Missing date on Other Expense ${1 + index}`);
    if (!oe.expenseName)
      issues.push(`Missing name on Other Expense ${1 + index}`);
    if (!oe.unitPrice)
      issues.push(`Missing unit price on Other Expense ${1 + index}`);
    if (!oe.quantity)
      issues.push(`Missing quantity on Other Expense ${1 + index}`);
  }

  for (let index = 0; index < sheet.advancePayments.length; index++) {
    const ap = sheet.advancePayments[index];
    if (ap.isDeleted)
      continue;
    // if (!ap.date)
    //   issues.push(`Missing date on Advance Payment ${1 + index}`)
    if (!ap.amount)
      issues.push(`Missing amount on Advance Payment ${1 + index}`);
  }

  const tourNumDays = tourDatesValid
    ? (getSpanDaysExactIso(sheet.dateisoTourStart, sheet.dateisoTourEnd) + 1)
    : null;

  if (tourDatesValid && tourNumDays && sheet.mealAllowance.length < tourNumDays)
    throw new Error(`Missing some meal allowance days ${sheet.mealAllowance.length} < ${tourNumDays}`);

  return issues;

}

export function cleanUpBeforeSave(sheet: ExpenseSheetType, tourNumDays: number) {

  // this should only be done right before saving.
  // the rest of the time, we want to preserve the hidden future dates meal settings,
  // in case the user decides to make the tour longer again.

  if (sheet.mealAllowance.length > tourNumDays)
    sheet.mealAllowance = sheet.mealAllowance.slice(0, tourNumDays);

}


