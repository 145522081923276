import { useEffect, useRef } from 'react';

/** Change page title dynamically by passing a value to the hook
 *
 * When no value is provided the hook will change the page title to the initial value.
 * When hook is unmounted the page title will be reset to the initial value.
 *
 * Source: https://github.com/witoszekdev/react-simple-page-title/blob/main/src/usePageTitle.ts
 * See also: https://stackoverflow.com/questions/46160461/how-do-you-set-the-document-title-in-react
 */
export const usePageTitle = (title: string | undefined | null) => {
  const initialTitle = useRef(document.title);

  useEffect(() => {
    // make a copy so that is available when component is unmounted
    const initialPageTitle = initialTitle.current;

    if (title) {
      document.title = title;
    } else {
      document.title = initialPageTitle;
    }

    return () => {
      if (initialPageTitle) {
        document.title = initialPageTitle;
      }
    };
  }, [title]);
};
