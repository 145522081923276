import { TourRequestType } from 'src/types/types_tourrequest';
import { dateFormatJpShort } from 'src/util/dateformattools';
import { formatNum2 } from 'src/util/util_formatnum';



interface SalesInfo1tdProps {
  tourrequest: TourRequestType;
}

export function SalesInfo1td({
  tourrequest,
}: SalesInfo1tdProps) {

  const si = tourrequest.salesInformation;


  return (
    si ? (
      <>
        <td className={'salesInfo2' in tourrequest ? 'col-salesinfo1b' : 'col-salesinfo1c'}>{'salesInfo2' in tourrequest ? 'Y' : 'N'}</td>

        <td className='col-salesinfo1'>{si.usersInCharge[0]?.name}</td>
        <td className='col-salesinfo1'>{si.paymentMethod}</td>

        <td className='col-salesinfo2'>{dateFormatJpShort(si.quotedDate)}</td>
        <td className='col-salesinfo2'>{formatNum2(si.quotedAmount)}</td>

        <td className='col-salesinfo3'>{dateFormatJpShort(si.invoiceDate)}</td>
        <td className='col-salesinfo3'>{formatNum2(si.invoicedAmount)}</td>

        <td className='col-salesinfo4'>{dateFormatJpShort(si.depositDueBy)}</td>
        <td className='col-salesinfo4'>{formatNum2(si.depositAmount)}</td>
        <td className='col-salesinfo4'>{si.depositPaymentDone ? <i className='bi bi-check-square'></i> : ''}</td>

        <td className='col-salesinfo5'>{dateFormatJpShort(si.balanceDueBy)}</td>
        <td className='col-salesinfo5'>{formatNum2(si.balanceAmount)}</td>
        <td className='col-salesinfo5'>{si.balancePaymentDone ? <i className='bi bi-check-square'></i> : ''}</td>

        <td className='col-salesinfo6'>{si.note}</td>
      </>
    ) : (
      <>
        <td className='col-salesinfo0' colSpan={14}>
          [No Si1]
        </td>
      </>
    )
  );
}
