


const colors = {
  transparent: {
    border: 'tw-border-transparent',
    bg: 'tw-bg-transparent',
    text: '',
    hoverBorder: 'hover:tw-border-transparent',
    hoverBg: 'hover:tw-bg-transparent',
    hoverText: '',
  },
  blue_outline: {
    border: 'tw-border-blue-500',
    bg: 'tw-bg-transparent',
    text: 'tw-text-blue-500',
    hoverBorder: 'hover:tw-border-blue-500',
    hoverBg: 'hover:tw-bg-blue-500',
    hoverText: 'hover:tw-text-white',
  },
  green_outline: {
    border: 'tw-border-green-600',
    bg: 'tw-bg-transparent',
    text: 'tw-text-green-600',
    hoverBorder: 'hover:tw-border-green-600',
    hoverBg: 'hover:tw-bg-green-600',
    hoverText: 'hover:tw-text-white',
  },
  orange_outline: {
    border: 'tw-border-orange-500',
    bg: 'tw-bg-transparent',
    text: 'tw-text-orange-500',
    hoverBorder: 'hover:tw-border-orange-400',
    hoverBg: 'hover:tw-bg-orange-400',
    hoverText: 'hover:tw-text-white',
  },
  darkgray_outline: {
    border: 'tw-border-[#6c757d]',
    bg: 'tw-bg-transparent',
    text: 'tw-text-[#6c757d]',
    hoverBorder: 'hover:tw-border-[#6c757d]',
    hoverBg: 'hover:tw-bg-[#6c757d]',
    hoverText: 'hover:tw-text-white',
  },
  bsgreen_outline: {
    border: 'tw-border-[#198754]',
    bg: 'tw-bg-transparent',
    text: 'tw-text-[#198754]',
    hoverBorder: 'hover:tw-border-[#198754]',
    hoverBg: 'hover:tw-bg-[#198754]',
    hoverText: 'hover:tw-text-white',
  },
  bsorange_outline: {
    border: 'tw-border-[#ffc107]',
    bg: 'tw-bg-transparent',
    text: 'tw-text-[#ffc107]',
    hoverBorder: 'hover:tw-border-[#ffc107]',
    hoverBg: 'hover:tw-bg-[#ffc107]',
    hoverText: 'hover:tw-text-black',
  },
  blue: {
    border: 'tw-border-blue-600',
    bg: 'tw-bg-blue-600',
    text: 'tw-text-white',
    hoverBorder: 'hover:tw-border-blue-600',
    hoverBg: 'hover:tw-bg-blue-700',
    hoverText: 'hover:tw-text-white',
  },
  bsOrange: {
    border: 'tw-border-[#ffc107]',
    bg: 'tw-bg-[#ffc107]',
    text: 'tw-text-black',
    hoverBorder: 'hover:tw-border-[#ffc720]',
    hoverBg: 'hover:tw-bg-[#ffca2c]',
    hoverText: 'hover:tw-text-black',
  },
  bsGreen: {
    border: 'tw-border-[#198754]',
    bg: 'tw-bg-[#198754]',
    text: 'tw-text-white',
    hoverBorder: 'hover:tw-border-[#13653f]',
    hoverBg: 'hover:tw-bg-[#146c43]',
    hoverText: 'hover:tw-text-white',
  },
  bsRed: {
    border: 'tw-border-[#dc3545]',
    bg: 'tw-bg-[#dc3545]',
    text: 'tw-text-white',
    hoverBorder: 'hover:tw-border-[#b02a37]',
    hoverBg: 'hover:tw-bg-[#bb2d3b]',
    hoverText: 'hover:tw-text-white',
  },
  bsDarkGray: {
    border: 'tw-border-[#6c757d]',
    bg: 'tw-bg-[#6c757d]',
    text: 'tw-text-white',
    hoverBorder: 'hover:tw-border-[#565e64]',
    hoverBg: 'hover:tw-bg-[#5c636a]',
    hoverText: 'hover:tw-text-white',
  },
  lavender: {
    border: 'tw-border-[#debaf0]',
    bg: 'tw-bg-[#debaf0]',
    text: 'tw-text-black',
    hoverBorder: 'hover:tw-border-[#debaf0]',
    hoverBg: 'hover:tw-bg-[#e5c8f4]',
    hoverText: 'hover:tw-text-black',
  },
  disabled: {
    border: 'tw-border-slate-400',
    bg: 'tw-bg-slate-400',
    text: 'tw-text-white',
    hoverBorder: 'hover:tw-border-slate-400',
    hoverBg: 'hover:tw-bg-slate-400',
    hoverText: 'hover:tw-text-white',
  },
  disabled_outline: {
    border: 'tw-border-slate-400',
    bg: 'tw-bg-transparent',
    text: 'tw-text-slate-400',
    hoverBorder: 'hover:tw-border-slate-400',
    hoverBg: 'hover:tw-bg-transparent',
    hoverText: 'hover:tw-text-slate-400',
  },
};

export type VariantNameType = keyof typeof colors;


export function getTailwindButtonClassName(variant: VariantNameType, textSize: 'sm' | 'md') {

  let selectedColors = colors[variant];
  if (!selectedColors) {
    console.error('ButtonTW: variant not found:', variant);
    selectedColors = colors.blue;
  }

  const tailwind =
    // box model (border, padding)
    `  tw-border
   tw-border-solid
   ${selectedColors.border}
   tw-px-2
   tw-py-1
   tw-rounded
   tw-align-middle `
    // bg
    + `${selectedColors.bg} `
    // text
    + `tw-no-underline
   ${textSize === 'md' ? '' : 'tw-text-sm '}
   ${selectedColors.text} `
    // hover
    + `${selectedColors.hoverBg}
   ${selectedColors.hoverBorder}
   ${selectedColors.hoverText} `;

  return tailwind;
}

