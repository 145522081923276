import { FormEvent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ButtonTW } from '../Buttons/ButtonTW';


interface ModalPopupProps {
  title: string;
  okLabel: string;
  show: boolean;
  callbackClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>, callbackClose: () => void) => void;
  body: React.ReactNode;
  className?: string;
  hideOkButton?: boolean;
}

export function ModalPopup({
  title,
  okLabel,
  show,
  callbackClose,
  onSubmit,
  body,
  className,
  hideOkButton,
}: ModalPopupProps) {

  //const [show, setShow] = useState(false);

  //const show = !!modalAction

  //const handleClose = () => setModalAction(null);
  //const handleShow = () => setShow(true);

  return (
    <>
      {/* <ButtonTW variant="blue" onClick={handleShow}>
        {showLabel}
      </ButtonTW> */}

      <Modal show={show} onHide={callbackClose} className={className}>
        <form onSubmit={(e: FormEvent<HTMLFormElement>) => {
          //e.preventDefault()
          onSubmit(e, () => callbackClose());
        }}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {body}
          </Modal.Body>
          <Modal.Footer>
            {!hideOkButton && (
              <ButtonTW variant='blue' type='submit'>
                {okLabel}
              </ButtonTW>
            )}
            <ButtonTW variant='bsDarkGray' onClick={callbackClose}>
              {hideOkButton ? 'Close' : 'Cancel'}
            </ButtonTW>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
