import { autosaveDocument } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { addMetadataModifiedInvoices } from 'src/pages/Invoices/util_invoices';
import { InvoiceType } from 'src/types/types_invoices';
import { log_db_write } from 'src/util/util_log';
import { getUserSimpleUid } from 'src/util/util_users';
import { EditableField, ValidationType } from './EditableField';


interface EditableFieldInvoiceProps {
  rowid: string;
  fieldname: 'dateisoDeadline' | 'tripcode' | 'paxname' | 'servicePurchased' | 'amount' | 'currency' | 'memorandum';
  validationType: ValidationType;
  currentValue: any;
  invoice: InvoiceType;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  setEditedInvoice: (invoiceid: string) => void;
  isTextArea?: boolean;
  useSpan?: boolean;
}

export function EditableFieldInvoice({
  rowid,
  fieldname,
  validationType,
  currentValue,
  invoice,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  setEditedInvoice,
  isTextArea,
  useSpan,
}: EditableFieldInvoiceProps) {

  const { db, userDetails, setDbError } = useAppContext();

  const callbackCommitChange = (dbvalue: any) => {
    const updateObj: Partial<InvoiceType> = {
      [fieldname]: dbvalue,
    };

    const userAction = `Inline: Set ${fieldname} to ${dbvalue}`;

    autosaveDocument(
      updateObj,
      userAction,
      false,
      undefined,
      'u',
      invoice.id,
      invoice.history,
      getUserSimpleUid(userDetails),
      db,
      'invoices',
      (updateObj) => addMetadataModifiedInvoices(updateObj, userDetails),
      null,
    )
      .then(() => {
        setEditedCell('');
        setEditedInvoice('');
      })
      .catch((err) => setDbError(`Editing invoice ${rowid}`, err));

    log_db_write({ db, userDetails, logkey: 'db_write.invoice.inline_set_field', desc: `${userAction} on invoice ${invoice.id}` });
  };

  return (
    <EditableField
      tableid='invoicetable'
      rowid={rowid}
      fieldname={fieldname}
      validationType={validationType}
      currentValue={currentValue}
      isClickableToEdit={isClickableToEdit}
      editedCell={editedCell}
      setEditedCell={setEditedCell}
      callbackCommitChange={callbackCommitChange}
      isTextArea={isTextArea}
      useSpan={useSpan}
    />
  );
}
