import { Route, Routes } from 'react-router-dom';
import { QuotegridCrud } from './QuotegridCrud/QuotegridCrud';
import { QuotegridList } from './QuotegridList';



export function PageQuotegrids() {


  return (
    <Routes>
      {/* <Route path='/add' element={<QuotegridCrud action='create' />} /> */}
      <Route path='/edit/:quotegridId' element={<QuotegridCrud />} />
      <Route path='/' element={<QuotegridList />} />

    </Routes>
  );

}
