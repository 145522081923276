import { Dispatch, SetStateAction } from 'react';
import { AddButton } from 'src/components/Buttons/AddButton';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons';
import { EditableFieldExpenses } from 'src/components/EditableField/EditableFieldExpenses';
import { EditableFieldTypeahead } from 'src/components/EditableFieldTypeahead/EditableFieldTypeahead';
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton';
import { FileUploadButton } from 'src/components/FileDownloadUpload/FileUploadButton';
import { EitherLegType, TransportationLegType } from 'src/types/types_commute';
import { formatNum } from 'src/util/util_formatnum';
import { arraySum } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { HttpsCallableFunction, HttpsCallableResultHandler, SuggestableFieldType, SuggestionListType } from './ModalTripInput';


interface ModalTripInputTransportationTableProps {
  legList: TransportationLegType[];
  setLegList: Dispatch<SetStateAction<EitherLegType[]>>;
  isReadOnly: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  railSearch: HttpsCallableFunction;
  getTrainLineList: HttpsCallableResultHandler;
  stationSuggestion: HttpsCallableFunction;
  getStationList: HttpsCallableResultHandler;
  suggestionList: SuggestionListType | null;
  clearSuggestionList: () => void;
  suggestionField: { rowid: string; fieldname: string } | null;
  callbackOnChangeToGetSuggestions: (
    value: string,
    row: any,
    fieldname: SuggestableFieldType,
    suggestion: HttpsCallableFunction,
    getStationList: HttpsCallableResultHandler
  ) => void;
  isCommute: boolean;
  userDetails: any;
  attachmentDeletionMode: boolean;
  setAttachmentDeletionMode: (isDeletionMode: boolean) => void;
  modalAction: any;
}

export function ModalTripInputTransportationTable({
  legList,
  setLegList,
  isReadOnly,
  editedCell,
  setEditedCell,
  railSearch,
  getTrainLineList,
  stationSuggestion,
  getStationList,
  suggestionList,
  suggestionField,
  clearSuggestionList,
  callbackOnChangeToGetSuggestions,
  isCommute,
  userDetails,
  attachmentDeletionMode,
  setAttachmentDeletionMode,
  modalAction,
}: ModalTripInputTransportationTableProps) {


  return (
    <>
      <table className='table mb-1 inputtingTable'>
        <colgroup>
          <col style={{ width: '4em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '8em' }} />
          <col style={{ width: '5em' }} />
          <col style={{ width: '16em' }} />
          <col style={{ width: '4em' }} />
        </colgroup>
        <thead>
          <tr className='row-header'>
            <th></th>
            <th>№</th>
            <th>Mode</th>
            <th>Line</th>
            <th>From</th>
            <th>To</th>
            <th>Cost</th>
            <th></th>
            <th>Memo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {legList.map((row, index) => {

            const rowInfo = {
              rowid: row.id,
              tableid: 'legList',
              isClickableToEdit: !isReadOnly,
              editedCell: editedCell,
              setEditedCell: setEditedCell,
            };

            return (
              <tr key={index}>
                <td>
                </td>
                <td>
                  {1 + index}
                </td>
                <td>
                  <EditableFieldTypeahead
                    tableid='legList'
                    rowid={row.id}
                    fieldname='modeOfTransportation'
                    currentValue={row.modeOfTransportation ?? ''}
                    isClickableToEdit={true}
                    editedCell={editedCell}
                    setEditedCell={setEditedCell}
                    callbackCommitChange={(dbvalue) => {
                      setLegList((current) => current.map((leg) => {
                        if (leg.id === row.id) {
                          const newLeg: EitherLegType = {
                            ...leg,
                            modeOfTransportation: dbvalue,
                          };
                          return newLeg;
                        } else {
                          return leg;
                        }
                      }));
                      setEditedCell('');
                    }}
                    typeaheadOptionsList={['Train', 'Bus', 'Shinkansen', 'Taxi', 'Bike rental', 'Other']}
                    // menuMinWidth='7.5rem'
                    hasButtonForEditing={false}
                  />
                </td>
                <td>
                  <EditableFieldExpenses
                    fieldname='trainLine'
                    currentValue={row.trainLine}
                    validationType=''
                    previousField='modeOfTransportation'
                    nextField='stationFrom'
                    {...rowInfo}
                    callbackOnChange={(value) => callbackOnChangeToGetSuggestions(value, row, 'trainLine', railSearch, getTrainLineList)}
                    customSaveCallback={(dbvalue) => {
                      if (row.trainLine !== dbvalue) {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              trainLine: dbvalue,
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  <EditableFieldExpenses
                    fieldname='stationFrom'
                    currentValue={row.stationFrom}
                    validationType=''
                    previousField='trainLine'
                    nextField='stationTo'
                    {...rowInfo}
                    callbackOnChange={(value) => callbackOnChangeToGetSuggestions(value, row, 'stationFrom', stationSuggestion, getStationList)}
                    customSaveCallback={(dbvalue) => {
                      if (row.stationFrom !== dbvalue) {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              stationFrom: dbvalue,
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  <EditableFieldExpenses
                    fieldname='stationTo'
                    currentValue={row.stationTo}
                    validationType=''
                    previousField='stationFrom'
                    nextField='legCost'
                    {...rowInfo}
                    callbackOnChange={(value) => callbackOnChangeToGetSuggestions(value, row, 'stationTo', stationSuggestion, getStationList)}
                    customSaveCallback={(dbvalue) => {
                      if (row.stationTo !== dbvalue) {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              stationTo: dbvalue,
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  <EditableFieldExpenses
                    fieldname='legCost'
                    currentValue={row.legCost}
                    validationType='number'
                    previousField='stationTo'
                    nextField='memorandum'
                    {...rowInfo}
                    customSaveCallback={(dbvalue) => {
                      if (row.legCost !== dbvalue) {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              legCost: dbvalue,
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  {row.receipts && row.receipts.map((filenameOrObj) => {
                    const { storagePath: filename, downloadURL } = filenameOrObj;
                    return (
                      <FileDownloadButton
                        key={filename}
                        filename={filename}
                        downloadURL={downloadURL}
                        isDeletionMode={attachmentDeletionMode}
                        callbackAfterDelete={() => {
                          setLegList((current) => current.map((leg) => {
                            if (leg.id === row.id) {
                              const newLeg: EitherLegType = {
                                ...leg,
                                receipts: leg.receipts!.filter((file) => file.storagePath !== filename),
                                receiptsDeleted: [...(leg.receiptsDeleted ?? []), filenameOrObj],
                              };
                              return newLeg;
                            } else {
                              return leg;
                            }
                          }));
                          setAttachmentDeletionMode(false);
                        }}
                        loginfo={`non-commute expenses category=${modalAction.category} date=${modalAction.sIsoDate}`}
                      />
                    );
                  })}

                  {!isCommute && (
                    <FileUploadButton
                      itemId={`${rowInfo.tableid}_${row.id}`}
                      storageFolder={`receipts/${userDetails.id}`}
                      callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              receipts: [...(leg.receipts ?? []), ...uploadedFilePathsAndURLs],
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }}
                    />
                  )}
                </td>
                <td>
                  <EditableFieldExpenses
                    fieldname='memorandum'
                    currentValue={row.memorandum}
                    validationType=''
                    previousField='legCost'
                    {...rowInfo}
                    customSaveCallback={(dbvalue) => {
                      if (row.memorandum !== dbvalue) {
                        setLegList((current) => current.map((leg) => {
                          if (leg.id === row.id) {
                            const newLeg: EitherLegType = {
                              ...leg,
                              memorandum: dbvalue,
                            };
                            return newLeg;
                          } else {
                            return leg;
                          }
                        }));
                      }
                    }}
                  />
                </td>
                <td>
                  {!isReadOnly && (
                    <div className='d-flex'>

                      <DeleteButton onClick={() => {
                        if (!window.confirm('Delete row?'))
                          return;
                        setLegList(legList.filter((_row, _index) => _index !== index));
                      }} />

                      <MoveUpDownButtons index={index} array={legList} setArray={setLegList} />

                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Total</td>
            <td>{formatNum(arraySum(legList.map((leg) => leg.legCost ?? 0)))}</td>
            <td></td>
            <td></td>
            <td>
              {!isReadOnly && (
                <AddButton onClick={() => {
                  const newLeg: TransportationLegType = {
                    id: nano_id(),
                    modeOfTransportation: null,
                    trainLine: '',
                    stationFrom: '',
                    stationTo: '',
                    legCost: null,
                    memorandum: '',
                  };
                  setLegList([...legList, newLeg]);
                }} />
              )}
            </td>
          </tr>
        </tfoot>
      </table>

      <div className='suggestionList'>
        {suggestionList && (
          suggestionList === 'loading' ? (
            <div>loading...</div>
          ) : suggestionList === 'no suggestions' ? (
            <div>no suggestions</div>
          ) : (
            suggestionList.map((item) => {
              return (
                <div key={item.label}
                  onClick={() => {
                    if (!suggestionField) {
                      console.error('suggestionField is null');
                      return;
                    }
                    setLegList((current) => current.map((leg) => {
                      if (leg.id === suggestionField.rowid) {
                        const newLeg: EitherLegType = {
                          ...leg,
                          [suggestionField.fieldname]: item.label,
                        };
                        return newLeg;
                      } else {
                        return leg;
                      }
                    }));

                    setEditedCell('');
                    clearSuggestionList();
                  }}>
                  {item.label}
                </div>
              );
            })
          )
        )}
      </div>
    </>
  );
}
