import { DocumentSnapshot, deleteField, doc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { ReactCrop, type PercentCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { getTailwindButtonClassName } from 'src/components/Buttons/util_buttontw';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { RequestCodeSelector } from 'src/components/FormControls/RequestCodeSelector';
import { LoadingSpinner } from 'src/components/Spinner/LoadingSpinner';
import { useAutosaveDocumentSingle } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { InvoiceContentsType, InvoicePartialAssignmentType, InvoiceRawType, InvoiceType } from 'src/types/types_invoices';
import { UserSimpleUidType } from 'src/types/types_user';
import { getSpanDaysFloat } from 'src/util/datetools';
import { userrole_isAdmin, userrole_isDev } from 'src/util/user_roles';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { formatNum } from 'src/util/util_formatnum';
import { nano_id } from 'src/util/util_nano_id';
import { validateInput } from 'src/util/util_validateinput';
import { convertInvoice } from '../util_convertinvoice';
import { addMetadataModifiedInvoices, isInvoiceMultiple } from '../util_invoices';
import { PAYEE_ID_AIRSERVE, auto_breakdown_airserve, formatLine_airserve } from './CustomInvoiceParsers/util_breakdown_airserve';
import { PAYEE_ID_GREENTOMATO, auto_breakdown_greentomato } from './CustomInvoiceParsers/util_breakdown_greentomato';
import { PAYEE_ID_IWA, auto_breakdown_iwa, formatLine_iwa } from './CustomInvoiceParsers/util_breakdown_iwa';
import { PAYEE_ID_MOBILEPLANNING, auto_breakdown_mobileplanning } from './CustomInvoiceParsers/util_breakdown_mobileplanning';
import './breakdownbyrequest.css';



const listColors = [
  // https://www.pinterest.com/pin/highlighters-color-palette--610871136954541241/
  'B71C1C',
  '880E4F',
  '4A148C',
  // '311B92',
  // '1A237E',
  '0D47A1',
  // '01579B',
  // '006064',
  // '004D40',
  '1B5E20',
  // '33691E',
  '827717',
  // 'F57F17',
  'FFEB3B',
  'FF6F00',
  // 'E65100',
  // 'BF360C',
];


function cropToString(crop: PercentCrop) {
  // return `${Math.round(crop.x)},${Math.round(crop.y)},${Math.round(crop.width)},${Math.round(crop.height)}`
  return `${crop.x},${crop.y},${crop.width},${crop.height}`;
}

function getStorageFileNameLabel(fileStoragePath: string) {
  const filename = fileStoragePath.split('/').pop()!; // split never returns an empty array
  const match1 = filename.match(/^\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\] (.+)$/i);
  if (match1)
    return match1[2];

  const match2 = filename.match(/^(\d{8}_\d{6}) (.+)$/i);
  if (match2)
    return match2[2];

  return filename;
}

export function BreakdownByRequest() {

  const { db, setDbError, storage, userDetails, _lang } = useAppContext();

  const { invoiceId } = useParams();

  if (!invoiceId)
    throw new Error('invoiceId is required'); // unreachable because router only directs to this page if invoiceId is present

  const userSimple: UserSimpleUidType = useMemo(() => {
    return {
      uid: userDetails.id,
      email: userDetails.email,
      name: userDetails.displayNameEn,
    };
  }, [userDetails]);


  const refTableContainer = useRef<HTMLDivElement>(null);

  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0); // 0-based

  const [invoice, setInvoice] = useState<InvoiceType>();
  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      if (snapshot.exists()) {
        const invoiceRaw = { ...snapshot.data(), id: snapshot.id } as InvoiceRawType;
        const invoice = convertInvoice(invoiceRaw);
        setInvoice(invoice);

        // when invoice is loaded, select the first file by default, except if a file is already selected
        setSelectedFile((selectedFile) => {
          if (invoice && invoice.files && invoice.files.length > 0) {
            if (selectedFile && invoice.files.find((file) => file.storagePath === selectedFile)) {
              // keep the selected file
              return selectedFile;
            } else {
              return invoice.files[0].storagePath;
            }
          } else {
            return null;
          }
        });

      } else {
        setDbError('Invoice not found');
      }
    };

    const q = doc(db, 'invoices', invoiceId);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting invoice', err));
    return unsubscribe;
  }, [db, setDbError, invoiceId]);


  const invoiceFileNumLookup = useMemo(() => {
    const map = new Map<string, number>();
    if (invoice && invoice.files) {
      invoice.files.forEach((file, index) => {
        map.set(file.storagePath, index);
      });
    }
    return map;
  }, [invoice]);

  const [invoiceContentsNotFound, setInvoiceContentsNotFound] = useState(false);
  const [invoiceContents, setInvoiceContents] = useState<InvoiceContentsType>();
  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      if (snapshot.exists()) {
        const invoicecontents = { ...snapshot.data(), id: snapshot.id } as InvoiceContentsType;
        setInvoiceContents(invoicecontents);
        setInvoiceContentsNotFound(false);
      } else {
        setInvoiceContents(undefined);
        setInvoiceContentsNotFound(true);
      }
    };

    const q = doc(db, 'invoicecontents', invoiceId);
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting invoice contents', err));
    return unsubscribe;
  }, [db, setDbError, invoiceId]);


  const selectedFileContents = invoiceContents?.files?.find((file) => file.storagePath === selectedFile);
  const selectedFilePages = selectedFileContents?.pages ?? [];
  const selectedPage = selectedFileContents?.pages?.[selectedPageIndex];
  const imageURL = selectedPage?.downloadURL;


  const [currentPageTextContent, setCurrentPageTextContent] = useState<string | null>(null);
  useEffect(() => {
    // reset text every time invoice or file or page changes
    setCurrentPageTextContent(selectedPage?.textContent ?? null);
  }, [selectedPage]);

  const [zoom, setZoom] = useState(60); // 30-100 by steps of 10

  const [activeCrop, setActiveCrop] = useState<PercentCrop>();
  const [currentlyEditingPartialAssignmentId, setCurrentlyEditingPartialAssignmentId] = useState<string | null>(null);

  const [hoveredRow, setHoveredRow] = useState<null | string>(null);

  const [inputAmountStr, setInputAmountStr] = useState('');
  const [inputRequestCode, setInputRequestCode] = useState('');
  const [inputPaxName, setInputPaxName] = useState('');
  const [inputPaxNameInvoice, setInputPaxNameInvoice] = useState('');

  const [showLabels, setShowLabels] = useState(false);
  const [shownPopup, setShownPopup] = useState<string | null>(null);
  const [showText, setShowText] = useState(false);
  const [editTextContent, setEditTextContent] = useState(false);


  const autosaveNewStep = useAutosaveDocumentSingle('invoices', invoice!, addMetadataModifiedInvoices); // invoice might be undefined here... but it's ok because we won't call autosaveNewStep() until invoice is well defined


  // *** all hooks above this line ***

  let pageTitle = 'Invoice Breakdown';
  if (invoice)
    pageTitle += ` [${invoice.payeeNameEn} | ${invoice.tripcode} | ${invoice.paxname}]`;
  usePageTitle(pageTitle);
  if (!invoice)
    return <LoadingSpinner list={['invoice']} />;

  if (invoiceContentsNotFound) {
    if (invoice.tripcode !== 'Multiple') {
      return (
        <div className='container m-4 bg-warning-subtle col-sm-5 p-4 border border-warning-subtle rounded border-2 rounded-3'>
          <h3 className='mb-3'><i className='bi-file-earmark-x'></i> Invoice image data not found</h3>
          <div>Invoice image data is only generated for invoices whose Request Code is set to “Multiple”.</div>
        </div>
      );
    } else {
      const lessThanFiveMinutes = getSpanDaysFloat(invoice.dateModified, new Date()) * 24 * 60 < 5;
      if (!lessThanFiveMinutes) {
        return (
          <div className='container m-4 bg-warning-subtle col-sm-5 p-4 border border-warning-subtle rounded border-2 rounded-3'>
            <h3 className='mb-3'><i className='bi-file-earmark-x'></i> Invoice image data not found</h3>
            <div>Please contact an administrator to regenerate image data.</div>
          </div>
        );
      } else {
        return (
          <div className='container m-4 bg-warning-subtle col-sm-5 p-4 border border-warning-subtle rounded border-2 rounded-3'>
            <h3 className='mb-3'><i className='bi-hourglass-split'></i> Invoice image data not available yet</h3>
            <div>If you only just uploaded the invoice recently, it may take a few minutes for the image data to be generated.</div>
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1em', marginTop: '2em',
            }}>
              <BarLoader />
            </div>
          </div>
        );
      }
    }
  }

  if (!invoiceContents)
    return <LoadingSpinner list={['invoice contents']} />;


  const isMultiple = isInvoiceMultiple(invoice);

  const currentPageHasTextContent = !!(currentPageTextContent ?? '').trim();



  function fileNameSorter(a: string, b: string) {
    const indexA = invoiceFileNumLookup.get(a);
    const indexB = invoiceFileNumLookup.get(b);
    if (indexA !== undefined && indexB !== undefined)
      return indexA - indexB;
    if (indexA !== undefined)
      return -1;
    if (indexB !== undefined)
      return 1;
    return 0;

  }

  const partialAssignmentList = (invoice?.partialAssignmentList || []);
  const usedFileList = [...(new Set(partialAssignmentList.map((partial) => partial.fileStoragePath)))];
  usedFileList.sort(fileNameSorter);



  // file => pagenum => list of partials
  const partialsTree: {
    fileStoragePath: string;
    pages: {
      pageNumber: number;
      partials: InvoicePartialAssignmentType[];
    }[];
  }[] = [];

  const partialAssignmentListSorted: InvoicePartialAssignmentType[] = [];

  const pageNumsByFile = new Map<string, number[]>();
  for (const fileStoragePath of usedFileList) {

    const thisFilePartialAssignmentList = partialAssignmentList.filter((partial) => partial.fileStoragePath === fileStoragePath);
    const pageNums = [...(new Set(thisFilePartialAssignmentList.map((partial) => partial.pageNumber)))];
    pageNums.sort((a, b) => a - b);
    pageNumsByFile.set(fileStoragePath, pageNums);

    const pagesTree: { pageNumber: number; partials: InvoicePartialAssignmentType[] }[] = [];

    for (const pageNumber of pageNums) {
      const thisPagePartialAssignmentList = thisFilePartialAssignmentList.filter((partial) => partial.pageNumber === pageNumber);
      thisPagePartialAssignmentList.sort((a, b) => {
        if (a.crop.y !== b.crop.y)
          return a.crop.y - b.crop.y;
        return a.crop.x - b.crop.x;
      });
      pagesTree.push({
        pageNumber,
        partials: thisPagePartialAssignmentList,
      });

      partialAssignmentListSorted.push(...thisPagePartialAssignmentList);
    }

    partialsTree.push({
      fileStoragePath,
      pages: pagesTree,
    });
  }

  if (partialAssignmentListSorted.length !== partialAssignmentList.length) {
    throw new Error('Inconsistent array length: partialAssignmentListSorted.length !== partialAssignmentList.length');
  }


  const amountPickedUp = partialAssignmentList.map((partial) => partial.amount).reduce((acc, val) => acc + val, 0);
  const amountAssigned = partialAssignmentList.map((partial) => partial.needsManualConfirmation ? 0 : partial.amount).reduce((acc, val) => acc + val, 0);
  const invoiceTotal = invoice.amountAfterConsumptionTax || invoice.amount; // don't use '??' as invoice.amountAfterConsumptionTax can be the empty string

  const numItemsAlreadyAssigned = partialAssignmentList.filter((partial) => !partial.needsManualConfirmation).length;
  const numItemsNeedToBeAssigned = partialAssignmentList.filter((partial) => partial.needsManualConfirmation).length;

  const fileStoragePathsAndLabels = (invoice.files ?? []).map((file, index) => {
    return {
      storagePath: file.storagePath,
      downloadURL: file.downloadURL,
      label: `File ${1 + index}/${invoice.files.length}: ${getStorageFileNameLabel(file.storagePath)}`,
    };
  });

  if (!invoice.files || invoice.files.length === 0) {
    return <div className='alert alert-warning'>This invoice does not have any attached files.</div>;
  }



  return (
    <div className='container-fluid'>

      <div className='twoColumns' style={{
        // right column is at least 50em, but will expand to fill the whole width if window is wider
        gridTemplateColumns: `${zoom}em minmax(50em, auto)`,

      }}>

        <div className='columnDocViewer' style={{
          //if we don't set minimum width, when user zooms in, the div just shrinks and it's impossible to increase the size of the invoice image
          // minWidth: `${zoom}em`,

        }}>
          <div className='fileAndPageSelectorRow'>
            <div className='zoomControls' style={{
              display: 'flex',
              gap: '0.5em',
            }}>
              <div>
                <ButtonTW variant='blue_outline' onClick={() => {
                  setZoom((zoom) => Math.max(zoom - 10, 30));
                }}><i className='bi bi-zoom-out'></i></ButtonTW>
              </div>
              <div>
                <ButtonTW variant='blue_outline' onClick={() => {
                  setZoom((zoom) => Math.min(zoom + 10, 100));
                }}><i className='bi bi-zoom-in'></i></ButtonTW>
              </div>
            </div>
            <div className='attachmentList' style={{
              flex: '1 1 0', // https://stackoverflow.com/a/63879251/
            }}>
              {(fileStoragePathsAndLabels.length > 1) ? (
                <DropdownButton
                  id='dropdown-file'
                  title={fileStoragePathsAndLabels.find((item) => item.storagePath === selectedFile)?.label}
                  variant='success'
                >
                  {fileStoragePathsAndLabels.map(({ storagePath, label }) => {
                    return (
                      <Dropdown.Item key={storagePath} onClick={() => {
                        setSelectedFile(storagePath);
                        setSelectedPageIndex(0);
                      }}>{label}</Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              ) : (
                <div className='ms-2 fw-bold'>
                  File: {getStorageFileNameLabel(fileStoragePathsAndLabels[0].storagePath)}
                </div>
              )}

            </div>
            <div>
              <a
                className={getTailwindButtonClassName('blue_outline', 'md')}
                href={fileStoragePathsAndLabels.find((item) => item.storagePath === selectedFile)?.downloadURL}
                target='_blank' rel='noreferrer'
              >
                PDF <i className='bi bi-box-arrow-up-right'></i>
              </a>

            </div>
            <div className='pageList' style={{
              display: 'flex',
              gap: '0.25em',
            }}>
              {(selectedFilePages.length > 1) && (
                <>
                  <ButtonTW variant={selectedPageIndex === 0 ? 'bsDarkGray' : 'bsGreen'} textSize='md' style={{
                    padding: '0 0.125em',
                    margin: '0.25em 0',
                  }}
                    onClick={() => {
                      if (selectedPageIndex > 0)
                        setSelectedPageIndex(selectedPageIndex - 1);
                    }}><i className='bi bi-chevron-left'></i></ButtonTW>
                  <DropdownButton
                    id='dropdown-page'
                    title={`Page ${1 + selectedPageIndex}/${selectedFilePages.length}`}
                    variant='success'
                  >
                    {selectedFilePages.map((page, index) => {
                      return (
                        <Dropdown.Item key={index} onClick={() => {
                          setSelectedPageIndex(index);
                        }}>Page {page.pageNumber}/{selectedFilePages.length}</Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <ButtonTW variant={selectedPageIndex === selectedFilePages.length - 1 ? 'bsDarkGray' : 'bsGreen'} style={{
                    padding: '0 0.125em',
                    margin: '0.25em 0',
                  }}
                    onClick={() => {
                      if (selectedPageIndex < selectedFilePages.length - 1)
                        setSelectedPageIndex(selectedPageIndex + 1);
                    }}><i className='bi bi-chevron-right'></i></ButtonTW>
                </>
              )}
            </div>
            <div>
              <CheckboxSwitch id='chkShowLabels' label='Show labels' checked={showLabels} onChange={(e) => setShowLabels(e.target.checked)} />
            </div>
          </div>

          <div className='docViewer'>

            <ReactCrop crop={activeCrop} onChange={(crop, percentCrop) => {
              // console.log('crop', crop)
              // console.log('percentCrop', percentCrop)
              setActiveCrop(percentCrop);
            }}>
              <img src={imageURL} />
            </ReactCrop>

            {partialAssignmentListSorted.map((partial, index) => {
              if (partial.fileStoragePath !== selectedFile)
                // not on this file
                return;

              if (partial.pageNumber !== 1 + selectedPageIndex)
                // not on this page
                return;

              let crop = partial.crop;
              if (!crop)
                return null;

              if (currentlyEditingPartialAssignmentId === partial.id)
                // make the highlighting match the live crop while editing
                crop = activeCrop!; // all partial assignments have a non-null crop attached

              const color = listColors[index % listColors.length];

              const labelPosition: React.CSSProperties = {};
              if (crop.x > 50) {
                labelPosition.right = '100%';
                labelPosition.textAlign = 'right';
              } else {
                labelPosition.left = '100%';
              }

              const isHighlighted = hoveredRow === partial.id;

              return (
                <div key={index} style={{
                  position: 'absolute',
                  border: `${!isHighlighted ? '0.2em solid' : '0.2em dashed'} #${!isHighlighted ? `${color}60` : `${'000000'}FF`}`,
                  backgroundColor: `#${color}${!isHighlighted ? '10' : '40'}`,
                  top: `${crop.y}%`,
                  left: `${crop.x}%`,
                  width: `${crop.width}%`,
                  height: `${crop.height}%`,
                  opacity: 0.7,
                }}
                  className='partialAssignmentBox'>
                  <div style={{
                    ...labelPosition,
                    position: 'absolute',
                    top: '0',
                    // border: '1px solid green',
                    width: '10em',
                    paddingLeft: '0.5em',
                    paddingRight: '0.5em',
                    lineHeight: '1.1em',
                  }}
                    className={showLabels ? undefined : 'partialAssignmentLabel'}>
                    <span style={{
                      backgroundColor: '#0000ffcc',
                      color: 'white',
                    }}>
                      {partial.requestCode}<br />
                      {partial.paxName}<br />
                      {formatNum(partial.amount)}
                    </span>
                  </div>
                </div>
              );
            })}

          </div>

          <div className='docText my-4'>
            <div>
              <CheckboxSwitch id='chkShowText' label='Show text content' checked={showText} disabled={!currentPageHasTextContent} onChange={(e) => {
                setShowText(e.target.checked);
              }} />

              {showText && userrole_isAdmin(userDetails.roles) && (
                <CheckboxSwitch id='chkEditTextContent' label='Edit text content' checked={editTextContent} onChange={(e) => {
                  setEditTextContent(e.target.checked);
                }} />
              )}
            </div>
            {showText && (
              <div className='docTextBody'>
                {editTextContent ? (
                  <>
                    <div>
                      <textarea className='tw-w-full tw-h-[100vh]' style={{
                        whiteSpace: 'pre',
                        overflowWrap: 'normal',
                        overflowX: 'scroll',
                      }} value={currentPageTextContent ?? ''} onChange={(e) => setCurrentPageTextContent(e.target.value)}></textarea>
                    </div>
                    <div>
                      <ButtonTW variant='blue_outline' onClick={() => {
                        const updateObj: Partial<InvoiceContentsType> = {
                          files: invoiceContents.files.map((file) => {
                            if (file.storagePath === selectedFile) {
                              return {
                                ...file,
                                pages: file.pages.map((page, index) => {
                                  if (index === selectedPageIndex) {
                                    return {
                                      ...page,
                                      textContent: currentPageTextContent,
                                    };
                                  } else {
                                    return page;
                                  }
                                }),
                              };
                            } else {
                              return file;
                            }
                          }),
                          dateModified: serverTimestampAsDate(),
                          userModified: userSimple,
                        };
                        updateDoc(doc(db, 'invoicecontents', invoiceId), updateObj);
                      }}>
                        Save changes
                      </ButtonTW>
                    </div>
                  </>
                ) : (
                  <>
                    {currentPageTextContent}
                  </>
                )}
              </div>
            )}
          </div>
        </div>


        <div className='columnAssignmentsData'>

          <div className='spacer'>
            <ButtonTW variant='blue_outline' className='my-1' to={`/invoices/edit/${invoiceId}`}>Edit invoice details</ButtonTW>
          </div>

          {isMultiple ? (
            <>
              <table className='table' style={{ width: '35em' }}>
                <tbody>
                  <tr>
                    <td>Invoice total (inc. tax)</td>
                    <td className='number'>{formatNum(invoiceTotal)}</td>
                    <td></td>
                  </tr>
                  {amountPickedUp !== amountAssigned && (
                    <tr>
                      <td>Amount detected automatically</td>
                      <td className='number'>{formatNum(amountPickedUp)}</td>
                      <td className='number'>({formatNum(partialAssignmentList.length)} items)</td>
                    </tr>
                  )}
                  <tr>
                    <td>Amount assigned to Request Codes</td>
                    <td className='number'>{formatNum(amountAssigned)}</td>
                    <td className='number'>({formatNum(numItemsAlreadyAssigned)} items)</td>
                  </tr>
                  <tr>
                    <td className='fw-bold'>Amount remaining to be assigned</td>
                    <td className='fw-bold number'>{formatNum(invoiceTotal - amountAssigned)}</td>
                    <td className='number'>{numItemsNeedToBeAssigned > 0 && `(${formatNum(numItemsNeedToBeAssigned)} items)`}</td>
                  </tr>
                </tbody>
              </table>

              <div className='mt-3 mb-5'>
                {(amountAssigned > invoiceTotal) ? (
                  <div className='statusBox alert alert-warning'>
                    <div><i className='bi bi-exclamation-triangle'></i></div>
                    <div>More cost has been assigned to Request Codes than the invoice total.</div>
                  </div>
                ) : (amountAssigned < invoiceTotal) ? (
                  <div className='statusBox alert alert-warning'>
                    <div><i className='bi bi-exclamation-triangle'></i></div>
                    <div>Please assign the outstanding amount to other Request Codes.</div>
                  </div>
                ) : (amountAssigned !== 0) ? (
                  <div className='statusBox alert alert-success'>
                    <div><i className='bi bi-check-circle'></i></div>
                    <div>The invoice total has been fully assigned to Request Codes.</div>
                  </div>
                ) : (
                  null // don't show a success message if invoice total is zero
                )}
              </div>

              {(/*indexEditing === null &&*/ activeCrop && activeCrop.width > 0 && activeCrop.height > 0) ? (

                <div className='partialAssignmentInputTableContainer mb-3'>
                  <div className='partialAssignmentInputTable'>
                    <div>Amount (inc. tax)</div>
                    <div><Form.Control value={inputAmountStr} onChange={(e) => {
                      let amountStr = e.target.value;
                      if (amountStr && !amountStr.endsWith('.') && !isNaN(Number(amountStr.replaceAll(',', '')))) {
                        amountStr = formatNum(Number(amountStr.replaceAll(',', '')));
                      }
                      setInputAmountStr(amountStr);
                    }} /></div>
                    <RequestCodeSelector
                      tripCode={inputRequestCode}
                      paxName={inputPaxName}
                      setRequestData={({ requestCode, paxName, tourrequestId }) => {
                        setInputRequestCode(requestCode);
                        setInputPaxName(paxName);
                      }}
                    />
                    {inputPaxNameInvoice && (
                      <>
                        <div>Pax name from invoice data</div>
                        <div>
                          <div className='invoiceRawData'>{inputPaxNameInvoice}</div>
                        </div>
                      </>
                    )}
                    {/* <div>Pax name</div>
                    <div><Form.Control type='text' value={inputPaxName} onChange={(e) => setInputPaxName(e.target.value)} /></div> */}
                    <div></div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25em' }}>
                      <ButtonTW textSize='md' onClick={() => {
                        const [amountIsValid, amountDbValue] = validateInput('number', inputAmountStr);
                        if (!amountIsValid) {
                          window.alert('Invalid amount');
                          return;
                        }

                        let newPartial;
                        let userAction: string;

                        if (currentlyEditingPartialAssignmentId !== null) {
                          const partial = partialAssignmentList.find((p) => p.id === currentlyEditingPartialAssignmentId);
                          newPartial = {
                            ...partial,
                            amount: amountDbValue,
                            requestCode: inputRequestCode,
                            paxName: inputPaxName,
                            fileStoragePath: selectedFile,
                            pageNumber: 1 + selectedPageIndex,
                            crop: activeCrop,
                            needsManualConfirmation: false,
                            //metadata
                            _isDeleted: false,
                            userModified: userSimple,
                            dateModified: serverTimestamp(),
                          };
                          userAction = 'Edit partial assignment';
                        } else {
                          const newPartialAssignment: InvoicePartialAssignmentType = { // here we explicitly type, so that if fields are added in the future, they will not be forgotten here
                            id: nano_id(),
                            amount: amountDbValue,
                            requestCode: inputRequestCode,
                            paxName: inputPaxName,
                            paxNameInvoice: '',
                            fileStoragePath: selectedFile!,
                            pageNumber: 1 + selectedPageIndex,
                            crop: activeCrop,
                            isAutoBreakdown: false,
                            autoBreakdownData: null,
                            needsManualConfirmation: false,
                            //metadata
                            userCreated: userSimple,
                            dateCreated: serverTimestampAsDate(),
                            userModified: userSimple,
                            dateModified: serverTimestampAsDate(),
                          };
                          newPartial = newPartialAssignment;
                          userAction = 'Add partial assignment';
                        }

                        const newPartialId = newPartial.id;
                        delete newPartial.id;

                        const updateObj: Partial<InvoiceType> = {
                          [`partialAssignments.${newPartialId}`]: newPartial,
                        };

                        autosaveNewStep(`${userAction}: [${inputRequestCode}] ${formatNum(amountDbValue)}`, updateObj, 'UNDOWALL')
                          .then(() => {
                            setCurrentlyEditingPartialAssignmentId(null);
                            setActiveCrop(undefined);
                            setInputAmountStr('');
                            setInputRequestCode('');
                            setInputPaxName('');
                            setInputPaxNameInvoice('');
                          })
                          .catch((err) => setDbError('Updating invoice with partial assignments', err));

                      }}>{currentlyEditingPartialAssignmentId === null ? 'Add new partial cost assignment' : 'Save'}</ButtonTW>

                      <ButtonTW variant='bsDarkGray' textSize='md' onClick={() => {
                        setCurrentlyEditingPartialAssignmentId(null);
                        setActiveCrop(undefined);
                        setInputAmountStr('');
                        setInputRequestCode('');
                        setInputPaxName('');
                        setInputPaxNameInvoice('');
                      }}>Cancel</ButtonTW>
                    </div>
                  </div>
                </div>

              ) : (

                <div className='tw-mb-6 tw-flex tw-gap-4'>
                  {selectedFile && (partialAssignmentList.length === 0 || userrole_isDev(userDetails.roles)) && (
                    <DropdownButton id='dropdown-basic-button' title='Auto-breakdown invoice'>
                      <Dropdown.Item disabled={invoice.payeeId !== PAYEE_ID_IWA} onClick={() => {
                        auto_breakdown_iwa(
                          invoiceId,
                          selectedFile,
                          selectedFileContents,
                          partialAssignmentList,
                          setDbError,
                          autosaveNewStep,
                        );
                      }}>Auto-breakdown IWA invoice</Dropdown.Item>
                      <Dropdown.Item disabled={invoice.payeeId !== PAYEE_ID_AIRSERVE} onClick={() => {
                        auto_breakdown_airserve(
                          invoiceId,
                          selectedFile,
                          selectedFileContents,
                          partialAssignmentList,
                          setDbError,
                          autosaveNewStep,
                        );
                      }}>Auto-breakdown Airserve invoice</Dropdown.Item>
                      <Dropdown.Item disabled={invoice.payeeId !== PAYEE_ID_GREENTOMATO} onClick={() => {
                        auto_breakdown_greentomato(
                          invoiceId,
                          selectedFile,
                          selectedFileContents,
                          partialAssignmentList,
                          setDbError,
                          autosaveNewStep,
                        );
                      }}>Auto-breakdown Green Tomato invoice</Dropdown.Item>
                      <Dropdown.Item disabled={invoice.payeeId !== PAYEE_ID_MOBILEPLANNING} onClick={() => {
                        auto_breakdown_mobileplanning(
                          invoiceId,
                          selectedFile,
                          selectedFileContents,
                          partialAssignmentList,
                          setDbError,
                          autosaveNewStep,
                        );
                      }}>Auto-breakdown Mobile Planning invoice</Dropdown.Item>
                    </DropdownButton>
                  )}

                  {userrole_isAdmin(userDetails.roles) && (
                    <ButtonTW variant='blue_outline' onClick={() => {
                      if (!window.confirm('Are you sure?'))
                        return;
                      window.alert('TODO');
                    }}>Clear all partial assignments</ButtonTW>
                  )}
                </div>
              )}

              <div className='tableContainer' ref={refTableContainer}>
                <table className='table'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Request code</th>
                      <th>Pax name</th>
                      <th>Amount (inc. tax)</th>
                      <th>Input by</th>
                      <th>Action</th>
                      <th>Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partialsTree.map(({ fileStoragePath, pages }) => {
                      let fileSeparator = null;
                      if (invoice.files.length > 1) {
                        fileSeparator = (
                          <tr className='fileSeparator'>
                            <td colSpan={7}>
                              <ButtonTW variant='link' onClick={() => {
                                setSelectedFile(fileStoragePath);
                                setSelectedPageIndex(0);
                              }}>
                                File {1 + invoiceFileNumLookup.get(fileStoragePath)!}/{invoice.files.length}:
                                {' '}
                                {getStorageFileNameLabel(fileStoragePath)}
                              </ButtonTW>
                            </td>
                          </tr>
                        );
                      }

                      return (
                        <React.Fragment key={fileStoragePath}>
                          {fileSeparator}

                          {pages.map(({ pageNumber, partials }) => {
                            let pageSeparator = null;
                            const thisFileContents = invoiceContents.files.find((file) => file.storagePath === fileStoragePath);
                            if (thisFileContents && thisFileContents.pages.length > 1) {
                              pageSeparator = (
                                <tr className='pageSeparator'>
                                  <td colSpan={7}>
                                    <ButtonTW variant='link' onClick={() => {
                                      setSelectedFile(fileStoragePath);
                                      setSelectedPageIndex(pageNumber - 1);
                                    }}>Page {pageNumber}/{thisFileContents.pages.length}</ButtonTW>
                                  </td>
                                </tr>
                              );
                            }

                            return (
                              <React.Fragment key={`page_${pageNumber}`}>
                                {pageSeparator}

                                {partials.map((partial) => {
                                  const partialOverallIndex = partialAssignmentListSorted.indexOf(partial);
                                  const color = listColors[partialOverallIndex % listColors.length];

                                  return (
                                    <tr key={partial.id}
                                      className={`${currentlyEditingPartialAssignmentId === partial.id ? 'selectedRow' : ''} ${partial.needsManualConfirmation ? 'needsManualConfirmation' : ''}`}
                                      onMouseOver={(e) => {
                                        setHoveredRow(partial.id);
                                      }}
                                      onMouseOut={(e) => {
                                        setHoveredRow(null);
                                      }}
                                    >
                                      <td style={{
                                        backgroundColor: `#${color}FF`,
                                        // outline: `0.25em solid #${color}`,
                                        // outlineOffset: '-0.25em',
                                      }}
                                        onClick={() => {
                                          console.log(`invoice id: ${invoiceId}, partial id: ${partial.id}`);
                                        }}>

                                      </td>
                                      <td>
                                        <RequestCodeLinkToAggregator
                                          requestCode={partial.requestCode}
                                          linkId={partial.id}
                                          shownPopup={shownPopup}
                                          setShownPopup={setShownPopup}
                                        />
                                      </td>
                                      <td>
                                        {partial.paxNameInvoice && (
                                          <div className='invoiceRawData'>{partial.paxNameInvoice}</div>
                                        )}
                                        <div>
                                          {partial.paxName}
                                        </div>
                                        {(partial.needsManualConfirmation && partial.requestCode && partial.paxName) && (
                                          <div>
                                            <ButtonTW variant='bsOrange' onClick={() => {
                                              const updateObj = {
                                                [`partialAssignments.${partial.id}.needsManualConfirmation`]: false,
                                                //metadata
                                                [`partialAssignments.${partial.id}.userModified`]: userSimple,
                                                [`partialAssignments.${partial.id}.dateModified`]: serverTimestamp(),
                                              };
                                              autosaveNewStep(`Validate partial assignment: [${partial.requestCode}] ${formatNum(partial.amount)}`, updateObj, 'UNDOWALL');
                                            }}><i className='bi bi-check-square'></i> Validate</ButtonTW>
                                          </div>
                                        )}
                                      </td>
                                      <td>{formatNum(partial.amount)}</td>
                                      <td>{partial.userModified.name}</td>
                                      <td>
                                        <div style={currentlyEditingPartialAssignmentId === null ? undefined : { visibility: 'hidden' }}>
                                          <ButtonTW variant='blue_outline' onClick={() => {
                                            // display the right file/page:
                                            setSelectedFile(partial.fileStoragePath);
                                            setSelectedPageIndex(partial.pageNumber - 1);
                                            // set currently edited partial assignment:
                                            setCurrentlyEditingPartialAssignmentId(partial.id);
                                            // show the crop overlay:
                                            setActiveCrop(partial.crop);
                                            // fill-in the form:
                                            setInputAmountStr(formatNum(partial.amount));
                                            setInputRequestCode(partial.requestCode);
                                            setInputPaxName(partial.paxName);
                                            setInputPaxNameInvoice(partial.paxNameInvoice);
                                          }}>Edit</ButtonTW>
                                          {' '}
                                          <ButtonTW variant='blue_outline' onClick={() => {
                                            if (!window.confirm('Are you sure?'))
                                              return;

                                            const updateObj: Partial<InvoiceType> = {
                                              [`partialAssignments.${partial.id}`]: deleteField(),
                                            };

                                            autosaveNewStep(`Delete partial assignment: [${partial.requestCode}] ${formatNum(partial.amount)}`, updateObj, 'UNDOWALL')
                                              .catch((err) => setDbError('Deleting partial assignment', err));

                                          }}>Delete</ButtonTW>
                                        </div>
                                      </td>
                                      <td className='autoBreakdownData'>
                                        {partial.autoBreakdownData && partial.autoBreakdownData.map((line, index) => {
                                          let formatted: ReactNode = line;

                                          if (invoice.payeeId === PAYEE_ID_IWA) {
                                            formatted = formatLine_iwa(line, index);
                                          }

                                          if (invoice.payeeId === PAYEE_ID_AIRSERVE) {
                                            formatted = formatLine_airserve(line);
                                          }

                                          return (
                                            <div key={index} className='invoiceRawData'>{formatted}</div>
                                          );
                                        })}
                                      </td>
                                    </tr>
                                  );
                                })}

                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>

            </>
          ) : (
            <div className='statusBox alert alert-primary'>
              <div>
                <i className='bi bi-info-circle'></i>
              </div>
              <div>
                This invoice’s <b>Request Code</b> is not set to “<b>Multiple</b>”.
                If this invoice should be broken down into and the cost assigned to multiple requests,
                please first change the <b>Request Code</b> to “<b>Multiple</b>” in the invoice details.
              </div>
            </div>
          )}
        </div>


      </div>

    </div>
  );
}
