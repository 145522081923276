import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { HotelPricesType, ServicePriceAgeType, ServicePriceCatalogType, ServicePriceItemType, ServicePriceSeasonType } from 'src/types/types_supplierprices';
import { UserSimpleUidType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { nano_id } from 'src/util/util_nano_id';



export function getNewServiceAge() {
  const newAge: ServicePriceAgeType = {
    ageName: '',
    pricePerPerson: null,
    pricePerGroup: null,
    pricePerPerson2pax: null,
    pricePerPerson3pax: null,
    pricePerPerson4pax: null,
    notes: '',
  };
  return newAge;
}

export function getNewServiceSeason() {
  const newSeason: ServicePriceSeasonType = {
    seasonName: '',
    ages: [getNewServiceAge()],
  };
  return newSeason;
}

export function getNewServicePriceItem() {
  const newPriceItem: ServicePriceItemType = {
    id: nano_id(),
    priceItemName: '',
    seasons: [getNewServiceSeason()],
    itemMappings: [],
  };
  return newPriceItem;
}

export function getNewService(userSimple: UserSimpleUidType) {
  const newService: Omit<ServicePriceCatalogType, 'id'> = {
    _isDeleted: false,
    history: getNewHistoryInfoObj(userSimple, 'Newly created'),
    city: '',
    serviceName: '',
    serviceNotes: '',
    supplierName: '',
    description: '',
    priceStructure: 'perPerson',
    priceItems: [getNewServicePriceItem()],
    facilityType: 'Excursion',
    dateCreated: serverTimestampAsDate(),
    dateModified: serverTimestampAsDate(),
    userCreated: userSimple,
    userModified: userSimple,
  };
  return newService;
}

export function addMetadataModifiedServicePricesTable(
  updateObj: Partial<ServicePriceCatalogType>,
  userSimple: UserSimpleUidType,
) {
  updateObj.dateModified = serverTimestampAsDate();
  updateObj.userModified = userSimple;
}

export function addMetadataModifiedHotelPricesTable(
  updateObj: Partial<HotelPricesType>,
  userSimple: UserSimpleUidType
) {
  updateObj.dateModified = serverTimestampAsDate();
  updateObj.userModified = userSimple;
}

export function roundUp10(num: number) {
  return Math.ceil(num / 10) * 10;
}
