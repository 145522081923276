import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import Papa from 'papaparse';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { getLoadingSpinnerOrNull } from 'src/components/Spinner/util_getLoadingSpinnerOrNull';
import { getNewHistoryInfoObj } from 'src/hooks/autosave/util_undoredo';
import { useAppContext } from 'src/hooks/useAppContext';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { HotelPricesType, ServicePriceCatalogType } from 'src/types/types_supplierprices';
import { coll_tripquotations, QuotationPricingType, TripQuotationRawType } from 'src/types/types_tripquotations';
import { UserSimpleUidType } from 'src/types/types_user';
import { convertHotelPriceDates, convertServicePriceDates, serverTimestampAsDate } from 'src/util/util_firestoredates';
import { log_info } from 'src/util/util_log';
import { parsePassengers, parseQuotationRequest } from './tripQuotationParsingUtils';



export function PageUploadQuotationCsv() {

  const navigate = useNavigate();
  const { db, setDbError, userDetails } = useAppContext();

  const fileInput = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<{ services: File; paxlist: File } | null>(null);
  const [uploadError, setUploadError] = useState('');



  const [hotelPrices, setHotelPrices] = useState<HotelPricesType[]>();
  useEffect(() => {
    onSnapshot(
      collection(db, 'hotelprices'),
      (snapshot) => {

        const hotelList: HotelPricesType[] = [];
        for (const docu of snapshot.docs) {
          const hotelprice = { ...docu.data(), id: docu.id } as HotelPricesType;
          convertHotelPriceDates(hotelprice);
          hotelList.push(hotelprice);
        }
        hotelList.sort((a, b) => a.facilityIndex - b.facilityIndex);
        setHotelPrices(hotelList);
      },
      (err) => setDbError(`Error getting hotel prices: ${err}`)
    );
  }, [db, setDbError]);

  const [servicePrices, setServicePrices] = useState<ServicePriceCatalogType[]>();
  useEffect(() => {
    onSnapshot(
      collection(db, 'serviceprices'),
      (snapshot) => {
        const serviceList: ServicePriceCatalogType[] = [];
        for (const docu of snapshot.docs) {
          const serviceprice = { ...docu.data(), id: docu.id } as ServicePriceCatalogType;
          convertServicePriceDates(serviceprice);
          serviceList.push(serviceprice);
        }
        setServicePrices(serviceList);
      },
      (err) => setDbError(`Error getting service prices: ${err}`)
    );
  }, [db, setDbError]);


  // *** all hooks above this line ***

  usePageTitle('Upload Quotation CSV');
  const loadingSpinner = getLoadingSpinnerOrNull([
    ['hotelPrices', hotelPrices],
    ['servicePrices', servicePrices],
  ]);
  if (!hotelPrices || !servicePrices)
    return loadingSpinner;

  const hasParseErrors = (parseResult: Papa.ParseResult<string[]>) => {
    if (parseResult.errors.length > 0) {
      let errorMessage = '';
      parseResult.errors.forEach((err) => {
        errorMessage += err + '\n';
      });
      console.error(errorMessage);
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!files) return;
    log_info({ db, userDetails, logkey: 'tripquotations.upload_csvs.submit', desc: `Submit files ${files.services.name} and ${files.paxlist.name}` });
    const reader = new FileReader();
    reader.onerror = (e) => console.error('error reading passengers file', e);
    reader.onload = function (e) {
      const passengerCsvContent = e.target?.result as string;
      Papa.parse(passengerCsvContent, {
        header: false,
        complete: async (parseResultPaxList: Papa.ParseResult<string[]>) => {
          if (hasParseErrors(parseResultPaxList)) return;
          const passengers = parsePassengers(parseResultPaxList.data);

          const reader = new FileReader();
          reader.onerror = (e) => console.error('error reading services file', e);
          reader.onload = function (e) {

            const quotationCsvContent = e.target?.result as string;
            Papa.parse(quotationCsvContent, {
              header: false,
              complete: async (parseResult: Papa.ParseResult<string[]>) => {

                if (hasParseErrors(parseResult)) return;
                const passengerCount = passengers ? Object.entries(passengers).length : 0;
                const { generalInfo, services, hotels, csvHeaders } = parseQuotationRequest(parseResult.data, passengerCount, hotelPrices, servicePrices);

                const userSimple: UserSimpleUidType = {
                  uid: userDetails.id,
                  email: userDetails.email,
                  name: userDetails.displayNameEn,
                };

                const pricingInfo: QuotationPricingType = {
                  eightyDaysMarkup: 0,
                  eightyDaysMarkupActive: false,
                  fixedFee: 0,
                  fixedFeeActive: false,
                  agentCommission: 0,
                  agentCommissionActive: false,
                };

                const newTripQuotation: Omit<TripQuotationRawType, 'id'> = {
                  _isDeleted: false,
                  history: getNewHistoryInfoObj(userSimple, 'Newly created trip quotation'),
                  sourceCsvName: files.services.name,
                  sourceCsv: quotationCsvContent,
                  sourceCsvHeaders: csvHeaders,
                  generalInfo,
                  pricingInfo,
                  services,
                  hotels,
                  usersDesigners: [userSimple],
                  usersDesignersUids: [userSimple.uid],
                  dateCreated: serverTimestampAsDate(),
                  dateModified: serverTimestampAsDate(),
                  requestCode: '',
                  requestId: '',
                  userCreated: userSimple,
                  userModified: userSimple,
                  passengers,
                  passengerCsvData: parseResultPaxList.data.map(row => ({ cells: row })),
                  snapshots: {},
                };

                const result = await addDoc(collection(db, coll_tripquotations), newTripQuotation);
                navigate(`/quotations/edit/${result.id}`);
              },
            });
          };
          reader.readAsText(files.services);
        },
      });
    };
    reader.readAsText(files.paxlist);
  };

  return (
    <div>

      <div className='tw-p-10'>
        <div className='tw-mb-4'>
          <h5>Upload a quotation request</h5>
          <div>Select two CSV files: Services and Passenger List.</div>
        </div>
        <table className='[&>*>tr>*]:tw-border-solid [&>*>tr>*]:tw-border-slate-400 [&>*>tr>*]:tw-border [&>*>tr>*]:tw-p-2 tw-mb-4'>
          <tbody>
            <tr>
              <th>Services CSV file</th>
              <td className='tw-min-w-64'>{files?.services?.name}</td>
            </tr>
            <tr>
              <th>Pax list CSV file</th>
              <td>{files?.paxlist?.name}</td>
            </tr>
          </tbody>
        </table>
        {!files ? (
          <div>
            {uploadError && (
              <div className='tw-mb-2'>
                <div className='tw-text-red-600 tw-border tw-border-solid tw-border-red-600 tw-bg-red-100 tw-rounded tw-p-2 tw-inline-block'>
                  {uploadError}
                  <i className='bi bi-x-circle tw-ml-4 tw-cursor-pointer' onClick={() => {
                    setUploadError('');
                    fileInput.current!.value = '';// clear the file input so that onChange gets triggered again even if user selects the same set of files again
                  }} />
                </div>
              </div>
            )}
            <ButtonTW onClick={() => {
              fileInput.current?.click();
            }}>Choose Files</ButtonTW>
            <div className='tw-hidden'>
              <input type='file' ref={fileInput} accept='.csv' multiple className='tw-mr-2'
                onChange={(e) => {

                  const elemInputFile = e.target;

                  const filelist = elemInputFile.files; // elemInputFile.value is just the file path and name, without the actual file data

                  if (!filelist) return;
                  if (filelist.length < 2) {
                    setUploadError(`Select 2 files at the same time: Services CSV and Passengers CSV (${filelist.length} files selected)`);
                    return;
                  }
                  if (filelist.length > 2) {
                    setUploadError(`Too many files (${filelist.length} files selected). Select 2 files.`);
                    return;
                  }

                  const files = Array.from(filelist);

                  const servicesfiles = files.filter(f => f.name.toLowerCase().includes('services'));
                  const paxlistfiles = files.filter(f => f.name.toLowerCase().includes('passenger list'));

                  if (servicesfiles.length === 0) {
                    setUploadError('Services file not found');
                    return;
                  }
                  if (paxlistfiles.length === 0) {
                    setUploadError('Passengers file not found');
                    return;
                  }
                  if (servicesfiles.length > 1) {
                    setUploadError('Multiple services files found');
                    return;
                  }
                  if (paxlistfiles.length > 1) {
                    setUploadError('Multiple passengers files found');
                    return;
                  }

                  setFiles({ services: servicesfiles[0], paxlist: paxlistfiles[0] });
                  setUploadError('');
                }} />
            </div>
          </div>
        ) : (
          <div>
            <div className='tw-mt-2 tw-flex tw-gap-2'>
              <ButtonTW onClick={() => {
                handleSubmit();
              }}>Create Quotation</ButtonTW>
              <ButtonTW variant='blue_outline' onClick={() => {
                log_info({ db, userDetails, logkey: 'tripquotations.upload_csvs.cancel', desc: 'Cancel uploading csvs' });
                setFiles(null);
                setUploadError('');
              }}>Cancel</ButtonTW>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
