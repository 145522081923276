import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dateinput.css';


interface DateInputProps {
  value_local0: Date | null; // local time Date object, or null
  onChange: (date_local0: Date | null) => void;
  disabled?: boolean;
  id?: string;
  className?: string;
}

export function DateInput({
  value_local0,
  onChange,
  disabled,
  id,
  className,
}: DateInputProps) {

  // value is Date object at midnight *local time*

  // const [startDate, setStartDate] = useState(new Date());
  return (
    // @t_s-expect-error ReactDatePicker local package cannot be used as a JSX component
    <ReactDatePicker
      className={className}
      dateFormat='yyyy/MM/dd'
      selected={value_local0}
      onChange={(date_local0) => { if (onChange) onChange(date_local0); }}
      peekNextMonth
      //showMonthDropdown
      //showYearDropdown
      dropdownMode='select'
      disabled={disabled}
      id={id}
    />
  );
}
