import { ReactNode } from 'react';
import { CheckboxSwitch } from 'src/components/Buttons/CheckboxSwitch';
import { HistoryInfoType } from 'src/types/types_historized';
import { UndoRedoButtons } from './UndoRedoButtons';



interface TopWhiteBarEditControlsProps {
  whiteBarActive: boolean; // inactive when first creating a new document as autosave and undo/redo is disabled
  enableEditing: boolean;
  setEnableEditing: (value: boolean) => void;
  saveStatus: string | undefined;
  setSaveStatus: (value: string) => void;
  autosaveUndoRedoStep: (action: 'Undo' | 'Redo', targetStep: number) => Promise<void>;
  history: HistoryInfoType;
  divFloatingTotals: JSX.Element | null;
  userIsAllowedToEdit: boolean;
  children?: ReactNode;
  positionRelative?: boolean;
  middleSlot?: ReactNode;
}

export function TopWhiteBarEditControls({
  whiteBarActive,
  enableEditing,
  setEnableEditing,
  saveStatus,
  setSaveStatus,
  autosaveUndoRedoStep,
  history,
  divFloatingTotals,
  userIsAllowedToEdit,
  children,
  positionRelative,
  middleSlot,
}: TopWhiteBarEditControlsProps) {


  return (
    <>
      <div className='topControls' style={{
        // position: sticky;
        // top: 0;
        position: positionRelative ? 'relative' : 'fixed',
        top: positionRelative ? undefined : '4.125rem',

        width: '100%',
        // height: 7.5em;
        padding: '0.5em 2em',
        backgroundColor: '#ffffffe0',
        // border-radius: 0.5em;
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // flex-direction: column;
        // align-items: center;
        height: '3em',
        // gap: 1em;
        // margin-bottom: 1em;
        boxShadow: positionRelative ? undefined : '0.125rem 0.125rem 1rem rgba(0, 0, 0, 0.25)',
        // typeahead (on guiding expense sheet page) uses z-index:1 for its items and X button when multiple selection is enabled, so we need a higher z-index here
        zIndex: positionRelative ? undefined : 10,
      }}>

        <div className='__topControlsLeft [&_div.form-check]:tw-mb-0' style={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          gap: '1em',
        }}>
          {whiteBarActive && !positionRelative && (
            <CheckboxSwitch id='chkEnableEditing' label='Edit mode' disabled={!userIsAllowedToEdit || !whiteBarActive} checked={enableEditing} onChange={(e) => {
              setSaveStatus('');
              setEnableEditing(e.target.checked);
            }} />
          )}

          <UndoRedoButtons
            whiteBarActive={whiteBarActive}
            enableEditing={enableEditing}
            autosaveUndoRedoStep={autosaveUndoRedoStep}
            history={history}
          />

          {divFloatingTotals}
        </div>

        <div className='__topControlsMiddle tw-flex tw-items-center'>{middleSlot}</div>

        <div className='__topControlsRight tw-flex tw-items-center'>
          {saveStatus && (
            <div className='saveStatus'>
              {saveStatus}
            </div>
          )}
          {children}
        </div>
      </div>

      {/* div to take up equivalent height as the position:fixed bar */}
      {!positionRelative && (
        <div style={{ height: '3em' }}></div>
      )}
    </>
  );
}
