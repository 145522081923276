import { useState } from 'react';
import { AddButton } from 'src/components/Buttons/AddButton';
import { DeleteButton } from 'src/components/Buttons/DeleteButton';
import { MoveUpDownButtons } from 'src/components/Buttons/MoveUpDownButtons';
import { EditableFieldExpensesAutosave } from 'src/components/EditableField/EditableFieldExpensesAutosave';
import { EditableFieldExpensesAutosaveDatepicker } from 'src/components/EditableFieldDatepicker/EditableFieldExpensesAutosaveDatepicker';
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton';
import { FileUploadButton } from 'src/components/FileDownloadUpload/FileUploadButton';
import { useAppContext } from 'src/hooks/useAppContext';
import { ExpenseGuideTransportation, ExpenseSheetType } from 'src/types/types_expensesheet';
import { addDaysIso, getSpanDaysExactIso, iso_from_jst0, jst0_from_iso } from 'src/util/datetools';
import { formatNum } from 'src/util/util_formatnum';
import { compare } from 'src/util/util_misc';
import { nano_id } from 'src/util/util_nano_id';
import { AutosaveSheetType } from '../ExpenseSheet';


interface E_GuideTransportationProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  autosaveNewStep: AutosaveSheetType;
}

export function E_GuideTransportation({
  sheet,
  isReadOnly,
  editedCell,
  setEditedCell,
  autosaveNewStep,
}: E_GuideTransportationProps) {

  const { userDetails } = useAppContext();

  const [attachmentDeletionMode, setAttachmentDeletionMode] = useState(false);

  return (
    <>

      <h5 className='mb-4'>Guide’s transportation</h5>

      <div>
        <table className='table mb-1 inputtingTable' style={{ width: `${4 + 8 * 6 + 16 + 4}em` }}>
          <colgroup>
            <col style={{ width: '4em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '8em' }} />
            <col style={{ width: '16em' }} />
            <col style={{ width: '4em' }} />
          </colgroup>
          <thead>
            <tr className='row-header'>
              <th></th>
              <th>Day №</th>
              <th>Date</th>
              <th>From</th>
              <th>To</th>
              <th>Amount</th>
              <th style={{ whiteSpace: 'nowrap' }}>
                Receipt
                {!isReadOnly && (
                  <button style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                    setAttachmentDeletionMode(!attachmentDeletionMode);
                  }}><i className={`bi ${attachmentDeletionMode ? 'bi-x-square-fill' : 'bi-x-square'}`}></i></button>
                )}
              </th>
              <th>Memo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sheet.guideTransportation.filter((row) => !row.isDeleted).map((row, index) => {
              const rowInfo = {
                sheet,
                rowid: row.id,
                tableid: 'guideTransportation' as keyof ExpenseSheetType,
                isClickableToEdit: !isReadOnly,
                editedCell: editedCell,
                setEditedCell: setEditedCell,
                autosaveNewStep,
              };

              return (
                <tr key={row.id}>
                  <td>
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='dayNumber'
                      currentValue={row.dayNumber}
                      validationType='number'
                      nextField='transportationFrom'

                      customSaveCallback={(dbvalue: number) => {
                        if (row.dayNumber === dbvalue)
                          return null; // no change

                        const guideTransportationNew = sheet.guideTransportation.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: ExpenseGuideTransportation = {
                              ...item,
                              dayNumber: dbvalue,
                              dateiso: sheet.dateisoTourStart ? addDaysIso(sheet.dateisoTourStart, dbvalue - 1) : '',
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        guideTransportationNew.sort((a, b) => compare(a.dayNumber, b.dayNumber));
                        const updateObj: Partial<ExpenseSheetType> = {
                          guideTransportation: guideTransportationNew,
                        };

                        return updateObj;
                      }}

                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosaveDatepicker
                      fieldname='date'
                      currentValue={row.dateiso ? jst0_from_iso(row.dateiso) : null}

                      customSaveCallback={(date_jst0) => {
                        const dateiso = date_jst0 ? iso_from_jst0(date_jst0) : '';

                        const guideTransportationNew = sheet.guideTransportation.map((item) => {
                          if (item.id === row.id) {
                            const rowNew: ExpenseGuideTransportation = {
                              ...item,
                              dateiso,
                              dayNumber: (sheet.dateisoTourStart && dateiso)
                                ? (getSpanDaysExactIso(sheet.dateisoTourStart, dateiso) + 1)
                                : null,
                            };
                            return rowNew;
                          } else {
                            return item;
                          }
                        });

                        guideTransportationNew.sort((a, b) => compare(a.dayNumber, b.dayNumber));
                        const updateObj: Partial<ExpenseSheetType> = {
                          guideTransportation: guideTransportationNew,
                        };

                        return updateObj;
                      }}
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='transportationFrom'
                      currentValue={row.transportationFrom}
                      validationType=''
                      previousField='dayNumber'
                      nextField='transportationTo'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='transportationTo'
                      currentValue={row.transportationTo}
                      validationType=''
                      previousField='transportationFrom'
                      nextField='amount'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='amount'
                      currentValue={row.amount}
                      validationType='number'
                      previousField='transportationTo'
                      nextField='memorandum'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    {row.receipts && row.receipts.map((filenameOrObj, index) => {
                      const { storagePath: filename, downloadURL } = filenameOrObj;
                      return (
                        <FileDownloadButton
                          key={filename}
                          filename={filename}
                          downloadURL={downloadURL}
                          isDeletionMode={attachmentDeletionMode}
                          callbackAfterDelete={() => {
                            const receiptsNew = row.receipts.filter((file) => {
                              if (typeof file === 'string')
                                return file !== filename;
                              else
                                return file.storagePath !== filename;
                            });
                            const receiptsDeletedNew = [...(row.receiptsDeleted ?? []), filenameOrObj];
                            setAttachmentDeletionMode(false);

                            const rowNew: ExpenseGuideTransportation = {
                              ...row,
                              receipts: receiptsNew,
                              receiptsDeleted: receiptsDeletedNew,
                            };

                            const guideTransportationNew: ExpenseGuideTransportation[] = sheet.guideTransportation.map((item) => {
                              if (item.id === row.id) {
                                return rowNew;
                              } else {
                                return item;
                              }
                            });

                            const updateObj: Partial<ExpenseSheetType> = {
                              guideTransportation: guideTransportationNew,
                            };

                            autosaveNewStep('Guide transportation: delete receipt', updateObj, 'u');
                          }}
                          loginfo={`guide transportation on sheet ${sheet.id}`}
                        />
                      );
                    })}

                    {!isReadOnly && (
                      <FileUploadButton
                        itemId={`${rowInfo.tableid}_${row.id}`}
                        storageFolder={`receipts/${userDetails.id}`}
                        callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
                          const receiptsNew = [...row.receipts, ...uploadedFilePathsAndURLs];

                          const guideTransportationNew = sheet.guideTransportation.map((item) => {
                            if (item.id === row.id) {
                              const newItem: ExpenseGuideTransportation = {
                                ...item,
                                receipts: receiptsNew,
                              };
                              return newItem;
                            } else {
                              return item;
                            }
                          });

                          const updateObj: Partial<ExpenseSheetType> = {
                            guideTransportation: guideTransportationNew,
                          };

                          autosaveNewStep('Guide transportation: upload receipt', updateObj, 'u');
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <EditableFieldExpensesAutosave
                      fieldname='memorandum'
                      currentValue={row.memorandum}
                      validationType=''
                      previousField='amount'
                      {...rowInfo}
                      sUndoWall={'u'}
                    />
                  </td>
                  <td>
                    {!isReadOnly && (
                      <div className='d-flex'>

                        <DeleteButton onClick={() => {
                          if (!window.confirm('Delete row?'))
                            return;

                          const guideTransportationNew = sheet.guideTransportation.map((item) => {
                            if (item.id === row.id) {
                              const newItem: ExpenseGuideTransportation = {
                                ...item,
                                isDeleted: true,
                              };
                              return newItem;
                            } else {
                              return item;
                            }
                          });

                          const updateObj: Partial<ExpenseSheetType> = {
                            guideTransportation: guideTransportationNew,
                          };

                          autosaveNewStep('Guide transportation: delete row', updateObj, 'u');
                        }} />

                        <MoveUpDownButtons index={index} array={sheet['guideTransportation']} setArray={(array) => {
                          const updateObj: Partial<ExpenseSheetType> = {
                            guideTransportation: array,
                          };
                          autosaveNewStep('Guide transportation: reorder rows', updateObj, 'u');
                        }} />

                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>Total</td>
              <td>{formatNum(sheet.calc.totalTLTransportation)}</td>
              <td></td>
              <td></td>
              <td>
                {!isReadOnly && (
                  <AddButton onClick={() => {
                    const newRow: ExpenseGuideTransportation = {
                      id: nano_id(),
                      dayNumber: null,
                      dateiso: '',
                      transportationFrom: '',
                      transportationTo: '',
                      amount: null,
                      memorandum: '',
                      receipts: [],
                      isDeleted: false,
                    };

                    const guideTransportationNew: ExpenseGuideTransportation[] = [...sheet.guideTransportation, newRow];

                    const updateObj: Partial<ExpenseSheetType> = {
                      guideTransportation: guideTransportationNew,
                    };

                    autosaveNewStep('Guide transportation: add row', updateObj, 'u');
                  }} />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
}
