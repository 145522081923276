import { TypeaheadUserList } from 'src/components/FormControls/TypeaheadUserList';
import { ExpenseSheetType } from 'src/types/types_expensesheet';
import { UserSimpleTeamUidType, UserSimpleUidType } from 'src/types/types_user';
import { AutosaveSheetType } from '../ExpenseSheet';


interface A_GuideNameProps {
  sheet: ExpenseSheetType;
  isReadOnly: boolean;
  autosaveNewStep: AutosaveSheetType;
  userListSimple: UserSimpleTeamUidType[];
}

export function A_GuideName({
  sheet,
  isReadOnly,
  autosaveNewStep,
  userListSimple,
}: A_GuideNameProps) {

  const guide: UserSimpleUidType = {
    uid: sheet.userGuideUid,
    email: sheet.userGuideEmail,
    name: sheet.userGuideName,
  };

  //const userListGuides = userList ?? [{ id: sheet.userGuideUid, email: sheet.userGuideEmail }]

  return (
    <div className='tw-grid tw-grid-cols-[10em_20em] tw-gap-2 tw-items-baseline'>
      <div><label>Guide</label></div>
      <div>
        <TypeaheadUserList
          id='inputGuide'
          multiple={false}
          onChange={(array) => {
            // Implement if needed. Currently always readonly.
          }}
          userList={userListSimple}
          selected={[guide]}
          guidesFirst={true}
          disabled={true} // guide can never be edited after sheet creation, due to payments
        />
      </div>

      <div><label>Travel designer</label></div>
      <div>
        <TypeaheadUserList
          id='inputDesigners'
          multiple={true}
          onChange={(array) => {
            const updateObj: Partial<ExpenseSheetType> = {
              usersDesigners: array,
            };

            autosaveNewStep(`Set travel designers to [${array.map((u) => u.name).join(', ')}]`, updateObj, 'u');
          }}
          userList={userListSimple}
          selected={sheet.usersDesigners}
          guidesFirst={false}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
}
