import React, { useEffect, useState } from 'react';
import { SortCaretProps } from 'src/components/ColumnSorter/SortCaret';
import { useAppContext } from 'src/hooks/useAppContext';
import { ColumnFilterSimpleAmountType } from 'src/types/types_columnfilters';
import { formatNum } from 'src/util/util_formatnum';
import { log_info } from 'src/util/util_log';
import { ButtonTW } from '../Buttons/ButtonTW';
import { TreeListItem } from './TreeListItem';
import './columnfilterpopup.css';
import { PageNamesType, UrlParamNames, useSetOneSearchParam } from './useSetOneSearchParam';



// Simple Amount = Amount filter, but only possible selection is multiple single items. No ranges.
// Used for 'array-contains-any' queries



interface ColumnFilterPopupSimpleAmountProps {
  pageName: PageNamesType;
  urlParameterName: UrlParamNames;
  allAmounts: number[] | undefined; // undefined while loading
  appliedFilter: ColumnFilterSimpleAmountType | null;
  popupIsOpen: boolean;
  closePopup: () => void;
  sortCaretProps: SortCaretProps | null;
}

export function ColumnFilterPopupSimpleAmount({
  pageName,
  urlParameterName,
  allAmounts,
  appliedFilter,
  popupIsOpen,
  closePopup,
  sortCaretProps,
}: ColumnFilterPopupSimpleAmountProps) {

  const { db, userDetails } = useAppContext();

  const { setOneSearchParam } = useSetOneSearchParam(pageName);

  const [searchValue, setSearchValue] = useState<string>('');


  const [localFilterState, setLocalFilterState] = useState<ColumnFilterSimpleAmountType | null>(null);

  // update this component's filter state if it changes in InvoiceTable
  useEffect(() => {
    setLocalFilterState(appliedFilter);
  }, [appliedFilter]);



  // *** all hooks above this line ***

  if (!allAmounts)
    // still loading
    return null;


  const onCheckChange = (checked: boolean, amount: number) => {

    const sAmount = amount.toString();

    const new_set = new Set(localFilterState);
    if (checked) {
      new_set.add(sAmount);
    } else {
      new_set.delete(sAmount);
    }

    if (new_set.size > 0) {
      setLocalFilterState(new_set);
    } else {
      setLocalFilterState(null);
    }
  };


  return (
    <div className={`filterPopup filterPopupAmount ${popupIsOpen ? 'show' : 'hide'}`}>
      {sortCaretProps && (
        <>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, 1]);
            log_info({ db, userDetails, logkey: 'filterpopup.sortasc', desc: 'Filter popup Amount: Sort ascending' });
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === 1) ? 'bi-caret-up-square' : 'bi-caret-up'}`}></i>
            {' '}
            Sort ascending
          </div>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, -1]);
            log_info({ db, userDetails, logkey: 'filterpopup.sortdesc', desc: 'Filter popup Amount: Sort descending' });
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === -1) ? 'bi-caret-down-square' : 'bi-caret-down'}`}></i>
            {' '}
            Sort descending
          </div>
          {/* <hr className='my-2' /> */}
        </>
      )}
      <div className='filterTitle'>Number filters</div>
      <hr className='my-1' />

      <div className={`filterArea ${localFilterState && localFilterState.size > 0 ? 'filterActive' : ''}`}>
        <div>
          <input type='text' placeholder='Search' className='searchBox' value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value);
          }} />
        </div>
        <div className='checkboxList'>
          {allAmounts.map((amount) => {

            if (searchValue) {
              if (searchValue.includes(',')) {
                if (!formatNum(amount).includes(searchValue))
                  return null;
              } else {
                if (!amount.toString().includes(searchValue))
                  return null;
              }
            }

            return (
              <React.Fragment key={amount}>
                <TreeListItem
                  label={formatNum(amount)}
                  indentation={0}
                  childNodes={null}
                  ternarySelected={localFilterState && localFilterState.has(amount.toString()) ? 1 : 0}
                  onCheckChange={(checked) => {
                    onCheckChange(checked, amount);
                  }}
                  onLabelClick={() => {
                    log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${amount}` });
                    setOneSearchParam(urlParameterName, `${amount}`);
                    closePopup();
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className='mt-3'>
        <ButtonTW variant='blue' style={{ width: '100%' }} onClick={() => {

          let queryparam = null;

          if (localFilterState && localFilterState.size > 0) {

            const list: string[] = [...localFilterState.keys()];

            queryparam = `[${list.join(',')}]`;

          }

          log_info({ db, userDetails, logkey: 'filterpopup.apply', desc: `Filter popup ${urlParameterName}: Apply ${queryparam}` });

          setOneSearchParam(urlParameterName, queryparam);

          closePopup();

        }}>Apply</ButtonTW>
      </div>
      <div className='mt-1'>
        <ButtonTW variant='bsDarkGray' style={{ width: '100%' }} onClick={() => {

          log_info({ db, userDetails, logkey: 'filterpopup.clear', desc: `Filter popup ${urlParameterName}: Clear` });

          setSearchValue('');

          setOneSearchParam(urlParameterName, '');

          closePopup();

        }}>Clear</ButtonTW>
      </div>
    </div>
  );
}
