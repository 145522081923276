import { useLocation, useSearchParams } from 'react-router-dom';
import { filterBaseUrls } from './util_filters';



export type PageNamesType = 'invoices' | 'tourrequests' | 'generalexpenses';

export type UrlParamNames =
  // tour requests
  'requestCode' | 'tourStart' | 'tourEnd' | 'paymentDate' | 'paymentAmount' | 'paxName' | 'teamCategory' | 'customerType' | 'agencyId' | 'country' | 'designer'
  // invoices
  | 'payeeId' | 'amount' | 'deadline'
  // general expenses
  | 'month' | 'expenseDate' | 'expenseAmount';

export function useSetOneSearchParam(pageName: PageNamesType, filtersAvailable = true) {

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const baseUrl = location.pathname;


  if (filtersAvailable && !filterBaseUrls.includes(baseUrl)) {
    console.error(`unexpected path for filter: ${baseUrl}`);
  }

  const setOneSearchParam = (key: UrlParamNames, value: string | null) => {

    const newSearchParams = getNewSearchParams(pageName, searchParams, key, value);

    setSearchParams(newSearchParams);
  };

  const getNewUrl = (key: UrlParamNames, value: string) => {
    const newSearchParams = getNewSearchParams(pageName, searchParams, key, value);
    return baseUrl + '?' + newSearchParams.toString();
  };

  return { setOneSearchParam, getNewUrl };
}

export function getNewSearchParams(
  pageName: PageNamesType,
  searchParams: URLSearchParams,
  key: UrlParamNames,
  value: string | null,
): URLSearchParams {

  const newSearchParams = new URLSearchParams(searchParams);

  // always preserve: fulllist ; designer
  // preserve other constraints for tourrequests.
  // only allow 1 constraint at a time for invoices. TODO: allow multiple constraints for invoices

  const allowMultipleConstraints = pageName === 'tourrequests' || pageName === 'generalexpenses';

  if (!allowMultipleConstraints) {
    for (const key of searchParams.keys()) {
      // if (pageName === 'tourrequests') {
      //   if (key === 'fulllist' || key === 'designer')
      //     // always preserve fulllist and designer
      //     continue
      // }
      newSearchParams.delete(key);
    }
  }

  if (pageName === 'tourrequests') {
    // paymentAmount, paymentDate and designer filters cannot be applied simultaneously because Firestore only allows one array-contains filter per query
    if (key === 'paymentAmount') {
      newSearchParams.delete('paymentDate');
      newSearchParams.delete('designer');
    } else if (key === 'paymentDate') {
      newSearchParams.delete('paymentAmount');
      newSearchParams.delete('designer');
    } else if (key === 'designer') {
      newSearchParams.delete('paymentAmount');
      newSearchParams.delete('paymentDate');
    }
  }

  // apply our filter
  if (value) {
    newSearchParams.set(key, value);
  } else {
    newSearchParams.delete(key);
  }

  return newSearchParams;
}

