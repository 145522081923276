


interface CheckboxButtonProps {
  checked: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  colorScheme?: 'green' | 'red';
  disabled?: boolean;
  children?: React.ReactNode;
}

export function CheckboxButton({
  checked,
  onClick,
  colorScheme,
  disabled,
  children,
}: CheckboxButtonProps) {


  return (
    <button className={`tw-border tw-border-solid tw-rounded tw-ps-0.5 tw-py-0.5 tw-whitespace-nowrap ${!disabled ? 'tw-cursor-pointer' : '!tw-cursor-default'}
      ${checked
        ? (colorScheme === 'red'
          ? 'tw-bg-red-200/70 tw-border-red-700 tw-text-red-900'
          : 'tw-bg-green-200/70 tw-border-green-700 tw-text-green-900'
        )
        : 'tw-bg-slate-200 tw-border-slate-400 tw-text-slate-500'} `}
      onClick={!disabled ? onClick : undefined}>
      {checked ? <i className='bi bi-check'></i> : <i className='bi bi-x'></i>}{children}
    </button>
  );
}
