import { Dispatch, SetStateAction } from 'react';
import { EditableFieldInvoice } from 'src/components/EditableField/EditableFieldInvoice';
import { useAutosaveDocumentSingle } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceType } from 'src/types/types_invoices';
import { dateFormatJpWithTimeAndSeconds, dateisoFormatJp } from 'src/util/dateformattools';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { ModalPopupMarkPaidActionType } from './ModalPopupMarkPaid';
import { addMetadataModifiedInvoices, getDefaultPaymentDate } from './util_invoices';



interface InvoiceTableRowExtendedProps {
  invoice: InvoiceType;
  classRowBgColor: string;
  hideExtendedInfo: () => void;

  // state
  editedInvoice: string;
  setEditedInvoice: (invoiceid: string) => void;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  modalActionMarkPaid: ModalPopupMarkPaidActionType | null;
  setModalActionMarkPaid: Dispatch<SetStateAction<ModalPopupMarkPaidActionType | null>>;

  // user permissions
  user_canMarkPaid: boolean;

  // display settings
  showDateCreatedModified: boolean;
}

export function InvoiceTableRowExtended({
  invoice,
  classRowBgColor,
  hideExtendedInfo,

  editedInvoice,
  setEditedInvoice,
  editedCell,
  setEditedCell,
  modalActionMarkPaid,
  setModalActionMarkPaid,

  user_canMarkPaid,

  showDateCreatedModified,

}: InvoiceTableRowExtendedProps) {


  const { db, setDbError, userDetails } = useAppContext();

  const isRowBeingEdited = editedInvoice === invoice.id;

  const rowProps = {
    rowid: invoice.id,
    invoice,
    isClickableToEdit: isRowBeingEdited,
    editedCell: editedCell,
    setEditedCell: setEditedCell,
    setEditedInvoice: setEditedInvoice,
  };

  const autosaveNewStep = useAutosaveDocumentSingle('invoices', invoice, addMetadataModifiedInvoices);

  return (
    <tr className={`extraInfo ${classRowBgColor}`}>
      <td colSpan={3} className='invoiceDetails'>
        <ul>
          <li>ID: {invoice.id}</li>
          <li>Date invoice received: {dateisoFormatJp(invoice.dateisoInvoiceReceived)}</li>
          <li>Db entry created: {dateFormatJpWithTimeAndSeconds(invoice.dateCreated)}</li>
          <li>Db entry modified: {dateFormatJpWithTimeAndSeconds(invoice.dateModified)}</li>
        </ul>
      </td>
      <td colSpan={3}>

        <div>Memo:</div>
        <div>
          <EditableFieldInvoice
            fieldname='memorandum'
            currentValue={invoice.memorandum}
            validationType=''
            isTextArea={true}
            {...rowProps}
          />
        </div>

      </td>
      <td colSpan={showDateCreatedModified ? 9 : 7}>

        <>
          {user_canMarkPaid && (invoice.status === 'SCHEDULED' || invoice.status === 'PAID') && (
            <>
              <div className='mb-2'>
                <div>Invoice <span className='highlight'>{invoice.status === 'PAID' ? 'paid' : invoice.status === 'SCHEDULED' ? 'scheduled' : '[error]'}</span></div>
                <div>on <span className='highlight'>{dateisoFormatJp(invoice.paymentDateiso)}</span></div>
                <div>using <span className='highlight'>{invoice.paymentSourceAccount}</span></div>
              </div>
              <div>
                <button className='btn btn-outline-secondary btn-sm mb-1' onClick={(e) => {
                  if (!window.confirm(`Are you sure you want to UNDO the ${invoice.status === 'SCHEDULED' ? 'scheduling' : 'payment'}?`))
                    return;

                  const updateObj: Partial<InvoiceType> = {
                    paymentDateiso: 'Z',
                    paymentSourceAccount: null,
                    paymentSetDatetime: serverTimestampAsDate(),
                    paymentUserUid: userDetails.id,
                    paymentUserEmail: userDetails.email,
                    status: 'RECEIVED',
                    statusPaidOrScheduled: false,
                  };

                  const userAction = invoice.status === 'SCHEDULED' ? 'Undo scheduling' : 'Undo payment';
                  autosaveNewStep(userAction, updateObj, 'UNDOWALL') // status change -> not undoable
                    .then(() => {
                      hideExtendedInfo();
                    })
                    .catch((err) => setDbError(`Undo payment ${invoice.id}`, err));
                }}>{invoice.status === 'SCHEDULED' ? 'Undo scheduling' : 'Undo payment'}</button>
              </div>
            </>
          )}

          {user_canMarkPaid && invoice.status !== 'SCHEDULED' && invoice.status !== 'PAID' && (
            <div className='mb-1'>
              <button className='btn btn-outline-success btn-sm' onClick={(e) => {
                setModalActionMarkPaid({
                  action: 'mark scheduled/paid',
                  paymentDateiso: getDefaultPaymentDate(true, invoice),
                  paymentSourceAccount: invoice.paymentSourceAccount ?? '',
                  invoice,
                  isScheduling: true,
                });
              }}> Mark SCHEDULED &gt;&gt;</button>
            </div>
          )}

          {user_canMarkPaid && invoice.status !== 'PAID' && (
            <div>
              <button className='btn btn-outline-success btn-sm' onClick={(e) => {
                setModalActionMarkPaid({
                  action: 'mark scheduled/paid',
                  paymentDateiso: getDefaultPaymentDate(false, invoice),
                  paymentSourceAccount: invoice.paymentSourceAccount ?? '',
                  invoice,
                  isScheduling: false,
                });
              }}> Mark PAID &gt;&gt;</button>
            </div>
          )}

        </>

      </td>
    </tr>
  );


}
