import React, { useEffect, useState } from 'react';
import { SortCaretProps } from 'src/components/ColumnSorter/SortCaret';
import { EditableField } from 'src/components/EditableField/EditableField';
import { useAppContext } from 'src/hooks/useAppContext';
import { ColumnFilterDateisoType, ThreeLevelDateTree } from 'src/types/types_columnfilters';
import { dateisoFormatJpShort } from 'src/util/dateformattools';
import { dateIso, monthIso } from 'src/util/datetools';
import { log_info } from 'src/util/util_log';
import { ButtonTW } from '../Buttons/ButtonTW';
import { TreeListItem } from './TreeListItem';
import './columnfilterpopup.css';
import { PageNamesType, UrlParamNames, useSetOneSearchParam } from './useSetOneSearchParam';
import { constructDefaultTreeListState, refreshMonthTernaryState, refreshYearTernaryState } from './util_filter_dates';
import { nodeNotNull } from './util_filters';



interface ColumnFilterPopupDateProps {
  pageName: PageNamesType;
  urlParameterName: UrlParamNames;
  allDates: ThreeLevelDateTree | undefined; // year => month => days[] // undefined while loading
  appliedFilter: ColumnFilterDateisoType | null;
  popupIsOpen: boolean;
  closePopup: () => void;
  sortCaretProps: SortCaretProps | null;
  emptyValueSelectorTitle: string | null;
}

export function ColumnFilterPopupDate({
  pageName,
  urlParameterName,
  allDates,
  appliedFilter,
  popupIsOpen,
  closePopup,
  sortCaretProps,
  emptyValueSelectorTitle,
}: ColumnFilterPopupDateProps) {

  const { db, userDetails } = useAppContext();

  const { setOneSearchParam } = useSetOneSearchParam(pageName);

  const [editedCell, setEditedCell] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState<string>('');


  const [localFilterState, setLocalFilterState] = useState<ColumnFilterDateisoType>({ filterMode: null });

  // update this component's filter state if it changes in InvoiceTable
  useEffect(() => {
    setLocalFilterState(appliedFilter ?? { filterMode: null });
  }, [appliedFilter]);



  // *** all hooks above this line ***

  if (!allDates)
    // still loading
    return null;


  const getDefaultTreeListState = () => {
    return constructDefaultTreeListState(allDates);
  };

  const onCheckChange = (checked: boolean, year: number | null, month: number | null, day: number | null) => {

    const treeListState = localFilterState.treeListState
      ? { ...localFilterState.treeListState } // use spread syntax to duplicate object because React does not allow us to mutate state
      : getDefaultTreeListState();

    if (year !== null) {
      if (month !== null) {
        if (day !== null) {
          treeListState[dateIso(year, month, day)] = checked ? 1 : 0;

          treeListState[monthIso(year, month)] = refreshMonthTernaryState(treeListState, year, month, allDates.get(year)!.get(month)!);

        } else {
          // day === null

          treeListState[monthIso(year, month)] = checked ? 1 : 0;
          for (const day of allDates.get(year)!.get(month)!) {
            treeListState[dateIso(year, month, day)] = checked ? 1 : 0;
          }
        }

        treeListState[year.toString()] = refreshYearTernaryState(treeListState, year, allDates.get(year)!);

      } else {
        // month === null
        treeListState[year.toString()] = checked ? 1 : 0;
        for (const [month, days] of allDates.get(year)!) {
          treeListState[monthIso(year, month)] = checked ? 1 : 0;
          for (const day of days) {
            treeListState[dateIso(year, month, day)] = checked ? 1 : 0;
          }
        }
      }
    } else {
      // year === null
      // Not yet paid checkbox
      treeListState.emptyValues = checked ? 1 : 0;
    }


    let allOne = true, allZero = true;
    for (const year of allDates.keys()) {
      const yearState = treeListState[year.toString()];
      if (yearState !== 1) allOne = false;
      if (yearState !== 0) allZero = false;
    }

    if (allOne) {
      treeListState.ternarySelectedPaid = 1;
    } else if (allZero) {
      treeListState.ternarySelectedPaid = 0;
    } else {
      treeListState.ternarySelectedPaid = 0.5;
    }

    if (treeListState.emptyValues !== 1) allOne = false;
    if (treeListState.emptyValues !== 0) allZero = false;

    if (allOne) {
      treeListState.ternarySelected = 1;
    } else if (allZero) {
      treeListState.ternarySelected = 0;
    } else {
      treeListState.ternarySelected = 0.5;
    }



    if (treeListState.ternarySelected !== 0) {
      setLocalFilterState({
        filterMode: 'checkboxes',
        treeListState,
      });
    } else {
      // nothing at all was ticked
      setLocalFilterState({ filterMode: null });
    }

  };


  return (
    <div className={`filterPopup filterPopupDate ${popupIsOpen ? 'show' : 'hide'}`}>
      {sortCaretProps && (
        <>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, 1]);
            log_info({ db, userDetails, logkey: 'filterpopup.sortasc', desc: `Filter popup ${urlParameterName}: Sort ascending` });
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === 1) ? 'bi-caret-up-square' : 'bi-caret-up'}`}></i>
            {' '}
            Sort ascending
          </div>
          <div className='clickable' onClick={() => {
            sortCaretProps.setSortSetting([sortCaretProps.colName, -1]);
            log_info({ db, userDetails, logkey: 'filterpopup.sortdesc', desc: `Filter popup ${urlParameterName}: Sort descending` });
          }}>
            <i className={`bi ${(sortCaretProps.sortCol === sortCaretProps.colName && sortCaretProps.sortDir === -1) ? 'bi-caret-down-square' : 'bi-caret-down'}`}></i>
            {' '}
            Sort descending
          </div>
          {/* <hr className='my-2' /> */}
        </>
      )}
      <div className='filterTitle'>Date filters</div>
      <hr className='my-1' />
      <div className={`equalityFilter filterArea ${localFilterState.filterMode === 'equals' ? 'filterActive' : ''}`}>
        <div>
          Date =
        </div>
        <EditableField
          tableid='filterPopup'
          rowid='equals'
          fieldname='value'
          validationType='dateiso'
          currentValue={localFilterState.filterEquals ? dateisoFormatJpShort(localFilterState.filterEquals) : ''}
          isClickableToEdit={true}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          useSpan={true}
          callbackCommitChange={(dateiso) => {
            console.log('value', dateiso);
            if (dateiso) {
              setLocalFilterState({
                filterMode: 'equals',
                filterEquals: dateiso,
              });
            } else {
              setLocalFilterState({
                filterMode: null,
              });
            }
            setEditedCell(null);
          }}
        />
      </div>
      <hr className='my-1' />
      <div className={`filterArea ${localFilterState.filterMode === 'range' ? 'filterActive' : ''}`}>
        <div className='equalityFilter'>
          <div>
            Date ≥
          </div>
          <EditableField
            tableid='filterPopup'
            rowid='greaterThan'
            fieldname='value'
            validationType='dateiso'
            currentValue={localFilterState.filterLowerBound ? dateisoFormatJpShort(localFilterState.filterLowerBound) : ''}
            isClickableToEdit={true}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            useSpan={true}
            callbackCommitChange={(dateiso) => {
              console.log('value', dateiso);
              if (dateiso) {
                if (localFilterState.filterMode === 'range') {
                  setLocalFilterState({ ...localFilterState, filterLowerBound: dateiso });
                } else {
                  setLocalFilterState({
                    filterMode: 'range',
                    filterLowerBound: dateiso,
                  });
                }
              } else {
                if (localFilterState.filterUpperBound) {
                  setLocalFilterState({ ...localFilterState, filterLowerBound: undefined });
                } else {
                  setLocalFilterState({ filterMode: null });
                }
              }
              setEditedCell(null);
            }}
          />
        </div>
        <div className='equalityFilter'>
          <div>
            Date ≤
          </div>
          <EditableField
            tableid='filterPopup'
            rowid='smallerThan'
            fieldname='value'
            validationType='dateiso'
            currentValue={localFilterState.filterUpperBound ? dateisoFormatJpShort(localFilterState.filterUpperBound) : ''}
            isClickableToEdit={true}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            useSpan={true}
            callbackCommitChange={(dateiso) => {
              console.log('value', dateiso);
              if (dateiso) {
                if (localFilterState.filterMode === 'range') {
                  setLocalFilterState({ ...localFilterState, filterUpperBound: dateiso });
                } else {
                  setLocalFilterState({
                    filterMode: 'range',
                    filterUpperBound: dateiso,
                  });
                }
              } else {
                if (localFilterState.filterLowerBound) {
                  setLocalFilterState({ ...localFilterState, filterUpperBound: undefined });
                } else {
                  setLocalFilterState({ filterMode: null });
                }
              }
              setEditedCell(null);
            }}
          />
        </div>
      </div>
      <hr className='my-1' />

      <div className={`filterArea ${localFilterState.filterMode === 'checkboxes' ? 'filterActive' : ''}`}>
        <div>
          <input type='text' placeholder='Search' className='searchBox' value={searchValue} onChange={(e) => {
            setSearchValue(e.target.value);
          }} />
        </div>
        <div className='checkboxList'>
          {[...allDates].sort((a, b) => a[0] - b[0]).map(([year, monthsMap]) => {
            const months = [...monthsMap].sort((a, b) => a[0] - b[0]);
            const monthNodes = months.map(([month, days]) => {

              days.sort((a, b) => a - b);

              const dayNodes = days.map((day) => {
                const date = new Date(Date.UTC(year, month - 1, day));
                const sDate = `${year}/${month}/${day}`;
                const dateiso = dateIso(year, month, day);

                if (searchValue) {
                  if (!sDate.includes(searchValue)) {
                    return null;
                  }
                }

                return (
                  <TreeListItem
                    key={day}
                    label={sDate}
                    indentation={2}
                    childNodes={null}
                    ternarySelected={localFilterState.treeListState?.[dateiso] ?? 0}
                    onCheckChange={(checked) => {
                      onCheckChange(checked, year, month, day);
                    }}
                    labelClassName={date.getUTCDay() % 6 === 0 ? 'holiday' : undefined}
                    onLabelClick={() => {
                      const param = `[${dateIso(year, month, day)}]`;
                      log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${param}` });
                      setOneSearchParam(urlParameterName, param);
                      closePopup();
                    }}
                  />
                );
              }).filter(nodeNotNull);

              if (dayNodes.length === 0)
                // all days were filtered out
                return null;

              const monthiso = monthIso(year, month);

              return (
                <TreeListItem
                  key={month}
                  label={`${year}/${month}`}
                  indentation={1}
                  childNodes={dayNodes}
                  ternarySelected={localFilterState.treeListState?.[monthiso] ?? 0}
                  onCheckChange={(checked) => {
                    onCheckChange(checked, year, month, null);
                  }}
                  onLabelClick={() => {
                    const param = `[${monthiso}]`;
                    log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${param}` });
                    setOneSearchParam(urlParameterName, param);
                    closePopup();
                  }}
                />
              );
            }).filter(nodeNotNull);

            if (monthNodes.length === 0)
              // all months were filtered out
              return null;

            return (
              <React.Fragment key={year}>
                <TreeListItem
                  label={`${year}`}
                  indentation={0}
                  childNodes={monthNodes}
                  ternarySelected={localFilterState.treeListState?.[year.toString()] ?? 0}
                  onCheckChange={(checked) => {
                    onCheckChange(checked, year, null, null);
                  }}
                  onLabelClick={() => {
                    const param = `[${year}]`;
                    log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${param}` });
                    setOneSearchParam(urlParameterName, param);
                    closePopup();
                  }}
                />
              </React.Fragment>
            );
          })}
          {emptyValueSelectorTitle && (
            <TreeListItem
              label={emptyValueSelectorTitle}
              indentation={0}
              childNodes={null}
              ternarySelected={localFilterState.treeListState?.emptyValues ?? 0}
              onCheckChange={(checked) => {
                onCheckChange(checked, null, null, null);
              }}
              onLabelClick={() => {
                const param = '[notpaid]';
                log_info({ db, userDetails, logkey: 'filterpopup.directclick', desc: `Filter popup ${urlParameterName}: Direct click ${param}` });
                setOneSearchParam(urlParameterName, param);
                closePopup();
              }}
            />
          )}
        </div>
      </div>
      <div className='mt-3'>
        <ButtonTW variant='blue' style={{ width: '100%' }} onClick={() => {

          let queryparam = null;

          if (localFilterState.filterMode === 'equals') {
            queryparam = localFilterState.filterEquals;
          } else if (localFilterState.filterMode === 'range') {
            queryparam = localFilterState.filterLowerBound ? localFilterState.filterLowerBound : '';
            queryparam += '~';
            queryparam += localFilterState.filterUpperBound ? localFilterState.filterUpperBound : '';
          } else if (localFilterState.filterMode === 'checkboxes') {
            const treeListState = localFilterState.treeListState;
            if (treeListState.ternarySelected === 1) {
              // everything is selected
              queryparam = '[all]';
            } else if (treeListState.ternarySelected === 0) {
              // nothing is selected -> disable this filter
              queryparam = null;
            } else {
              const list: string[] = [];
              if (treeListState.ternarySelectedPaid === 1) {
                list.push('paid');
              } else if (treeListState.ternarySelectedPaid === 0) {
                // nothing (will probably add notpaid below)
              } else {
                for (const [year, months] of allDates) {
                  if (treeListState[`${year}`] === 1) {
                    list.push(year.toString());
                  } else if (treeListState[`${year}`] === 0.5) {
                    for (const [month, days] of months) {
                      const monthiso = monthIso(year, month);
                      if (treeListState[monthiso] === 1) {
                        list.push(monthiso);
                      } else if (treeListState[monthiso] === 0.5) {
                        for (const day of days) {
                          const dateiso = dateIso(year, month, day);
                          if (treeListState[dateiso] === 1) {
                            list.push(dateiso);
                          }
                        }
                      }
                    }
                  }
                }
              }

              if (treeListState.emptyValues === 1) {
                list.push('notpaid');
              }

              queryparam = `[${list.join(',')}]`;
            }
          }

          log_info({ db, userDetails, logkey: 'filterpopup.apply', desc: `Filter popup ${urlParameterName}: Apply ${queryparam}` });

          setOneSearchParam(urlParameterName, queryparam);

          closePopup();

        }}>Apply</ButtonTW>
      </div>
      <div className='mt-1'>
        <ButtonTW variant='bsDarkGray' style={{ width: '100%' }} onClick={() => {

          log_info({ db, userDetails, logkey: 'filterpopup.clear', desc: `Filter popup ${urlParameterName}: Clear` });

          setSearchValue('');

          setOneSearchParam(urlParameterName, '');

          closePopup();

        }}>Clear</ButtonTW>
      </div>
    </div>
  );
}
