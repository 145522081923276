import { Dropdown, SplitButton } from 'react-bootstrap';
import { HistoryInfoType, HistoryStepDropdownType } from 'src/types/types_historized';
import { useAppContext } from '../useAppContext';


interface UndoRedoButtonsProps {
  whiteBarActive: boolean; // inactive when first creating a new document as autosave and undo/redo is disabled
  enableEditing: boolean;
  autosaveUndoRedoStep: (action: 'Undo' | 'Redo', targetStep: number) => Promise<void>;
  history: HistoryInfoType;

}

export function UndoRedoButtons({
  whiteBarActive,
  enableEditing,
  autosaveUndoRedoStep,
  history,
}: UndoRedoButtonsProps) {


  const { db, setDbError, userDetails } = useAppContext();

  const currentStep = history?.currentStep ?? 1;
  const lastStep = history?.lastStep ?? 1;
  const currentStepObj = history?.steps?.[`step_${currentStep}`];

  const historySteps =
    Object.entries(history?.steps ?? {})
      .map(([stepKey, step]) => {
        const stepNew: HistoryStepDropdownType = {
          ...step,
          stepNum: Number(stepKey.match(/^step_(\d+)$/)![1]),
          stepDisabled: false,
        };
        return stepNew;
      })
      .sort((a, b) => a.stepNum - b.stepNum);

  // REDO steps
  const historyStepsRedo = historySteps.filter((step) => step.stepNum > currentStep);

  // UNDO steps
  const historyStepsUndo = historySteps.filter((step) => step.stepNum <= currentStep)
    .slice(1) // need to remove the fist step as when we undo step N, we actually jump to target step N-1 and we need the target step to be in our history list
    .reverse();

  let reachedDisabledStep = false;
  for (const step of historyStepsUndo) {
    reachedDisabledStep ||= step.isUndoWall; // if true once, all the subsequent elements are also disabled
    step.stepDisabled = reachedDisabledStep;
  }

  const canUndo = historyStepsUndo.length > 0;
  const canRedo = historyStepsRedo.length > 0;


  return (
    <>
      <div style={(whiteBarActive && enableEditing) ? undefined : { visibility: 'hidden' }}>
        <SplitButton
          id='splitbutton-undo'
          variant={`${canUndo ? 'outline-primary' : 'outline-secondary'} btn-sm btnUndoRedo`}
          disabled={!canUndo}
          // title={undoLabel}
          title={<i className='bi bi-arrow-counterclockwise'></i>}
          onClick={() => {
            if (currentStepObj?.isUndoWall)
              return;
            autosaveUndoRedoStep('Undo', currentStep - 1)
              .catch((err) => setDbError('Undo failed', err));
          }}>
          {historyStepsUndo.map((step) => {
            return (
              <Dropdown.Item
                key={step.stepNum}
                disabled={step.stepDisabled}
                onClick={() => {
                  if (step.stepDisabled)
                    return;
                  autosaveUndoRedoStep('Undo', step.stepNum - 1)
                    .catch((err) => setDbError('Undo failed', err));
                }}
              >
                {step.stepAction}
              </Dropdown.Item>
            );
          })}
        </SplitButton>
      </div>

      {/* {userrole_isDev(userDetails.roles) && (
            <div>{currentStep}/{lastStep}</div>
          )} */}

      <div style={(whiteBarActive && enableEditing) ? undefined : { visibility: 'hidden' }}>
        <SplitButton
          variant={`${canRedo ? 'outline-primary' : 'outline-secondary'} btn-sm btnUndoRedo`}
          disabled={!canRedo}
          // title={redoLabel}
          title={<i className='bi bi-arrow-clockwise'></i>}
          onClick={() => {
            if (!canRedo)
              return;

            autosaveUndoRedoStep('Redo', currentStep + 1)
              .catch((err) => setDbError('Redo failed', err));

          }}>
          {historyStepsRedo.map((step) => {
            return (
              <Dropdown.Item
                key={step.stepNum}
                onClick={() => {
                  autosaveUndoRedoStep('Redo', step.stepNum)
                    .catch((err) => setDbError('Redo failed', err));
                }}
              >
                {step.stepAction}
              </Dropdown.Item>
            );
          })}
        </SplitButton>
      </div>
    </>
  );
}
