import * as Sentry from '@sentry/react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { DocumentSnapshot, connectFirestoreEmulator, doc, getFirestore, onSnapshot, updateDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { ErrorInfo, ReactNode, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
import { PageContent } from './PageContent';
import { HeaderNavBar } from './components/NavBar/HeaderNavBar';
import { MessageBar } from './components/NavBar/MessageBar';
import { LoadingSpinner } from './components/Spinner/LoadingSpinner';
import { getFireBaseConfig, isTestDb } from './config/config-select';
import { AppContext } from './contexts/AppContext';
import { useDbError } from './hooks/useDbError';
import { useMaintenanceMessage } from './hooks/useMaintenanceMessage';
import { LanguagePreferenceType, UserDetailsType } from './types/types_user';
import { UserPermissionAllEnumType, getPermission_from_roles } from './util/user_roles';
import { convertUserDetailsDates } from './util/util_firestoredates';
import { isDevMode } from './util/util_getbuildtime';
import { log_error } from './util/util_log';

// Initialize Firebase
const firebaseConfig = getFireBaseConfig();
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
//console.log(auth.currentUser?.email)
const storage = getStorage(app);
const analytics = getAnalytics(app);
logEvent(analytics, 'app_loaded');
const cloudFunctions = getFunctions(app, 'asia-northeast1');


if (import.meta.env.VITE_EMULATE_FIREBASE) {
  console.log('******** Using emulators ********');
  // connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  connectFunctionsEmulator(cloudFunctions, '127.0.0.1', 5001);
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_APP_CHECK_KEY),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});


function App() {


  const [user, userLoading, userError] = useAuthState(auth);
  //const user = auth.currentUser

  const [currentError, setDbError] = useDbError(db, user);

  const [userDetails, setUserDetails] = useState<UserDetailsType>();
  const [languagePreference, setLanguagePreference] = useState<string>();

  const getUserDetails = useMemo(() => httpsCallable(cloudFunctions, 'getUserDetails'), []);
  const refreshPhotoIfNeeded = useMemo(() => httpsCallable(cloudFunctions, 'refreshPhotoIfNeeded'), []);

  const [maintenance] = useMaintenanceMessage(db, setDbError);

  // this is to force a re-render of the TourRequestCrud page every time the navbar link is clicked.
  // without this, some values such as the agency name linger even after clicking the navbar link.
  const [navbarClickNewRequestCounter, setNavbarClickNewRequestCounter] = useState(1);


  // on first run after sign in, retrieve user permissions
  useEffect(() => {
    if (!user) {
      setUserDetails(undefined);
      return;
    }

    // console.log('user', user)

    getUserDetails()
      .then((result) => {
        const userDetails = result.data as UserDetailsType;

        convertUserDetailsDates(userDetails);
        setUserDetails(userDetails);
        setLanguagePreference(userDetails.languagePreference);

        if (userDetails.trueUid) {
          // don't refresh photo
          return;
        }

        // async refresh photo
        refreshPhotoIfNeeded()
          .then((result) => {
            if (result.data !== 'Skipped profile photo refresh')
              console.log('refreshPhotoIfNeeded result', result.data);
          });
      })
      .catch((error) => {
        console.error('Error getting user details:', error);
        setDbError('Getting user details', error);
      });

  }, [setDbError, user, getUserDetails, refreshPhotoIfNeeded]);



  const [cacheRequestCodes, setCacheRequestCodes] = useState<Set<string>>();
  useEffect(() => {
    if (!user)
      // not logger in
      return;

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const cache = snapshot.data()?.filterCache;

      // request codes
      const requestCodeSet = new Set(Object.keys(cache.requestCodes));
      setCacheRequestCodes(requestCodeSet);
    };

    // TODO: separate request codes into their own document
    // (currently we are downloading not just request codes but
    // also pax names, payment amountsm, tour dates, etc.)
    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'filterCacheRequests'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/filterCacheRequests', err)
    );

    return unsubscribe;
  }, [setDbError, user]);



  //console.log("user from useAuthState", user);
  //console.log("user from auth.currentUser", auth.currentUser)


  let content: ReactNode = '';

  const isLoading = userLoading || (!!user && !userDetails);

  if (maintenance) {
    // maintenance takes priority over currentError, because during maintenance, Firestore is shut off, which will cause
    // permission errors all over the place. we don't want to show the permission errors, instead we show the maintenance
    // message
    content = (
      <div className='alert alert-danger p-3 d-flex align-items-center'>
        <i className='bi bi-exclamation-triangle-fill display-6 ms-2 me-4'></i>
        <div>
          <div><b>{maintenance.title}</b></div>
          <div>{maintenance.message}</div>
        </div>
      </div>
    );

  } else if (currentError) {
    content = currentError.errorHtml;

  } else if (userLoading) {
    //content = <div className='p-4'>Loading...</div>
    content = <LoadingSpinner list={['user auth']} />;

  } else if (userError) {
    console.error('User error', userError);
    // @ts-expect-error Not sure what type userError is, and whether it can convert to ReactNode
    content = <div className='p-4'>Error: {userError}</div>;

  } else if (!user) {
    Sentry.setUser(null);
    content = <div className='p-4'>Not logged in</div>;

  } else if (!userDetails) {
    //content = <div className='p-4'>Loading user details...</div>
    content = <LoadingSpinner list={['user details']} />;

  } else if (!cacheRequestCodes) {
    content = <LoadingSpinner list={['request codes']} />;

  } else {

    Sentry.setUser({
      id: userDetails.id,
      email: userDetails.email,
      username: userDetails.displayNameEn,
    });

    // user is ok
    // authorize the user in db if needed

    //console.log(auth.currentUser)
    //console.log(user)

    content = <PageContent />;


  }

  const _lang = languagePreference === 'ja' ? ((en: string, ja: string) => ja) : ((en: string, ja: string) => en);
  const setLang = (lang: LanguagePreferenceType) => {
    if (!userDetails) return;
    setLanguagePreference(lang);
    updateDoc(doc(db, 'users', userDetails.id), { languagePreference: lang });
  };

  // redirect to new domain
  const pageURL = window.location.href;
  // console.log('pageURL', pageURL)
  if (pageURL.startsWith('https://eighty-days-invoice-manager.web.app/')) {
    const newURL = pageURL.replace('https://eighty-days-invoice-manager.web.app/', 'https://portal.80days.co.jp/');
    window.location.replace(newURL);
    return;
  }
  if (pageURL.startsWith('https://eighty-days-invoice-manager.firebaseapp.com/')) {
    const newURL = pageURL.replace('https://eighty-days-invoice-manager.firebaseapp.com/', 'https://portal.80days.co.jp/');
    window.location.replace(newURL);
    return;
  }

  const perm = (permission: UserPermissionAllEnumType) => getPermission_from_roles(permission, userDetails?.roles);

  return (
    <BrowserRouter>
      <HelmetProvider>
        <AppContext.Provider value={{
          userDetails: userDetails!, // this can be null during initialization, but no pages can access it during that time
          db,
          storage,
          auth,
          cloudFunctions,
          currentError,
          setDbError,
          analytics,
          _lang,
          setLang,
          cacheRequestCodes: cacheRequestCodes!, // this can be null during initialization, but no pages can access it during that time
          navbarClickNewRequestCounter,
          setNavbarClickNewRequestCounter,
          perm,
        }}>
          <div className='App'>

            {isTestDb() && (
              <>
                {/* Below 2 lines are to have a different background color in test mode */}
                {/* <Helmet bodyAttributes={{ style: 'background-color : #fff4e1' }} /> */}
                {/* <Helmet style={[{ 'cssText': 'div.arrowTriangleTop {background-color : #fff4e1}' }]} /> */}
                <Helmet>
                  <link rel='icon' type='image/png' href='/Favi_yellow.png' />
                </Helmet>
              </>
            )}

            <HeaderNavBar isLoading={isLoading} />

            <MessageBar />

            <ErrorBoundary
              FallbackComponent={ErrorBoundaryFallback}
              onError={(error: Error, info: ErrorInfo) => {
                if (!isDevMode()) {
                  Sentry.captureException(error, { data: info });
                  log_error({
                    db,
                    userDetails,
                    logkey: 'error.rendering',
                    desc: `Rendering error: ${error.message}`,
                    errorObj: error,
                    errorData: { error, info },
                  });
                }
              }}>
              {content}
            </ErrorBoundary>

            <footer className='py-2 border-top px-3 text-body-secondary'>
              &copy; 2023-2025 Eighty Days Japan, Inc.
            </footer>


          </div>
        </AppContext.Provider>
      </HelmetProvider>
    </BrowserRouter>
  );
}



export default App;
