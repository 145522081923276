import { useEffect, useRef } from 'react';
import { ScrollableTable } from 'src/components/Tables/ScrollableTable';
import { HotelPricesType } from '../../types/types_supplierprices';

interface HotelSearchProps {
  hotelSearchQuery: string;
  setHotelSearchQuery: (query: string) => void;
  hotels: HotelPricesType[];
  setSelectedHotel: (hotel: HotelPricesType) => void;

}

export function HotelSearch({
  hotelSearchQuery,
  setHotelSearchQuery,
  hotels,
  setSelectedHotel,
}: HotelSearchProps) {

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  return (
    <>
      <div className='tw-my-2'>
        <input
          type='search'
          ref={inputRef}
          defaultValue={hotelSearchQuery} onChange={(e) => {
            setHotelSearchQuery(e.target.value);
          }}
        />
        <span className='tw-ml-1'>
          {hotels.length} result(s)
        </span>
      </div>
      <div className='tw-h-[38rem]'>
        <ScrollableTable
          headers={['City', 'Hotel name']}
          headerClassNames={['', '']}
          data={hotels.map(hotel => {
            return {
              id: hotel.id,
              cells: [hotel.city, hotel.facilityName],
              onClick: () => setSelectedHotel(hotel),
            };
          })}
          tableHeight='35rem'
          columnWidths={['7rem', '25rem']}
        />
      </div>
    </>
  );
}
