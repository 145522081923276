import { addDays, addMonthsUtc } from 'src/util/datetools';
import { isHoliday } from 'src/util/util_holidays';



export type HoursListItemType = {
  workType: 'guiding' | 'office';
  dateiso: string;
  hours: number;
};

export type GuideDetailedHoursMonthType = {
  // represents just 1 month
  iMonth: number;
  sMonth: string;
  dateutcMonthStart: Date;
  dateutcMonthEnd: Date;
  numWorkdays: number;
  cumuWorkdays: number;
  monthGuidingHours: number;
  cumuGuidingHours: number;
  monthOfficeHours: number;
  cumuOfficeHours: number;
  monthWorkedHours: number;
  cumuWorkedHours: number;
};

export type GuideHoursCalculationResultsType = {
  detailedTableRows: GuideDetailedHoursMonthType[];
  totalWorkdays: number;
  totalGuidingHours: number;
  totalOfficeHours: number;
  totalWorkedHours: number;
};

export function calculateDetailedMonthlyGuideHours(
  dateutcStart: Date,
  dateutcEnd: Date,
  hoursList: HoursListItemType[],
) {

  const detailedTableRows: GuideDetailedHoursMonthType[] = [];

  let cumuOfficeHours = 0;
  let cumuGuidingHours = 0;
  let cumuWorkedHours = 0;
  let cumuWorkdays = 0;
  let iMonth = 0;
  for (let dateutcMonthStart = dateutcStart; dateutcMonthStart < dateutcEnd;) {
    let dateutcMonthEnd = addMonthsUtc(dateutcMonthStart, 1);
    dateutcMonthEnd.setDate(1);
    if (dateutcMonthEnd > dateutcEnd)
      dateutcMonthEnd = dateutcEnd;

    const sMonth = dateutcMonthStart.toISOString().substring(0, 7);

    let monthOfficeHours = 0;
    let monthGuidingHours = 0;
    for (const workday of hoursList) {
      const month = workday.dateiso.substring(0, 7);
      if (month !== sMonth) continue;
      if (workday.workType === 'office') monthOfficeHours += workday.hours;
      if (workday.workType === 'guiding') monthGuidingHours += workday.hours;
    }

    let numWorkdays = 0;
    for (let dateutc = dateutcMonthStart; dateutc < dateutcMonthEnd; dateutc = addDays(dateutc, 1)) {
      if (isHoliday(dateutc)) continue;
      numWorkdays++;
    }

    const monthWorkedHours = monthGuidingHours + monthOfficeHours;

    cumuGuidingHours += monthGuidingHours;
    cumuOfficeHours += monthOfficeHours;
    cumuWorkedHours += monthWorkedHours;
    cumuWorkdays += numWorkdays;

    detailedTableRows.push({
      iMonth, // 0-based
      sMonth, // yyyy-mm
      dateutcMonthStart,
      dateutcMonthEnd, // exclusive
      numWorkdays,
      cumuWorkdays,
      monthGuidingHours,
      cumuGuidingHours,
      monthOfficeHours,
      cumuOfficeHours,
      monthWorkedHours,
      cumuWorkedHours,
    });

    dateutcMonthStart = dateutcMonthEnd;
    iMonth++;

  }

  const result: GuideHoursCalculationResultsType = {
    detailedTableRows,
    totalWorkdays: cumuWorkdays,
    totalGuidingHours: cumuGuidingHours,
    totalOfficeHours: cumuOfficeHours,
    totalWorkedHours: cumuWorkedHours,
  };

  return result;
}
