import { PercentCrop } from 'react-image-crop';
import { setDbErrorType } from 'src/hooks/useDbError';
import { InvoiceContentsFileType, InvoicePartialAssignmentType } from 'src/types/types_invoices';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { nano_id } from 'src/util/util_nano_id';


export const PAYEE_ID_MOBILEPLANNING = 'ooGyHcpYI8nyuWR3L4BM';


function matchLine(line: string) {
  return line.match(/^(?<linenum>\d+) +(?<desc>.+) +(?<ordernum>\d{6}) +(?<devicenum>[A-Z]{2}\d{4,5})? +(?<dates>\d{2}\/\d{2}\/\d{2} +~ +\d{2}\/\d{2}\/\d{2})? +￥(?<price>[\d,]+) +10%対象$/);
}



export function auto_breakdown_mobileplanning(
  invoiceId: string,
  selectedFile: string,
  selectedFileContents: InvoiceContentsFileType | undefined,
  partialAssignmentList: InvoicePartialAssignmentType[],
  setDbError: setDbErrorType,
  autosaveNewStep: (
    userAction: string,
    updateObj: any,
    sUndoWall: 'u' | 'UNDOWALL',
  ) => Promise<void>,
) {
  if (!selectedFileContents) {
    window.alert('No file contents');
    return;
  }
  if (!selectedFileContents.pages || selectedFileContents.pages.length === 0) {
    window.alert('No pages');
    return;
  }
  if (selectedFileContents.conversionTool !== 'xpdf') {
    window.alert(`Please contact Laurent for XPDF conversion. invoiceId=${invoiceId}`);
    return;
  }
  if (partialAssignmentList.length > 0) {
    if (!window.confirm('This invoice already has some rows in the breakdown table. Running auto-breakdown may result in duplicate rows. Continue?'))
      return;
  }


  const updateObj: Record<string, any> = {};
  updateObj.partialAssignments = {};

  const userSimpleAuto = {
    uid: '',
    name: 'Auto',
    email: '',
  };


  const ordernumRequestCodeMap = new Map<string, { requestCode: string; paxNameInvoice: string }>();


  for (let iPage = 0; iPage < selectedFileContents.pages.length; iPage++) {

    const page = selectedFileContents.pages[iPage];
    if (!page.textContent)
      continue;
    const pageLines = page.textContent.split('\n');

    const tableRows = [];

    let itemsStarted = false;

    for (let iLine = 0; iLine < pageLines.length; iLine++) {
      const line = pageLines[iLine].trim();
      if (!line)
        continue;

      if (!itemsStarted) {
        if (line.startsWith('No ')) {
          if (line.match(/^No +品⽬ +注⽂番号 +端末番号 +レンタル期間 +価格 +区分$/)) {
            itemsStarted = true;
          } else {
            // regex failed? in the past, this happened when the cost of the following line was moved up to this line by the pdf parser for no reason
            console.log('ERROR: Regex failed to match line: ', line);
          }
        }
        continue;
      }

      if (line.startsWith('備考')) {
        break;
      }

      if (line.match(/^\d+$/)) {
        // empty item row
        continue;
      }

      if (line.match(/^\d+\/\d+$/)) {
        // page number
        break;
      }


      const match = matchLine(line);
      if (!match) {
        console.log("didn't match");
        throw new Error(`Line did not match regex: [${line}]`);
      }

      // (?<linenum>\d+) +(?<desc>.+) +(?<ordernum>\d{6}) +(?<devicenum>(SV|LG)\d{4})? +(?<dates>\d{2}\/\d{2}\/\d{2} ~ \d{2}\/\d{2}\/\d{2}) +￥(?<price>\d+) +10%対象
      const linenum = match.groups!.linenum;
      const desc = match.groups!.desc;
      const ordernum = match.groups!.ordernum;
      const devicenum = match.groups!.devicenum;
      const dates = match.groups!.dates;
      const sPrice = match.groups!.price;

      const price = Number(sPrice.replaceAll(',', ''));

      if (isNaN(price))
        throw new Error(`invalid price on line [${line}]`);

      let requestCode = '';
      let paxNameInvoice = '';

      const match_brackets = desc.match(/「(?<brackets>[^」]+)」/);
      if (match_brackets) {
        const match_requestcode = match_brackets.groups!.brackets.match(/^(?<code>[^ ]+) - (?<paxname>.+)$/);
        if (match_requestcode) {
          requestCode = match_requestcode.groups!.code;
          paxNameInvoice = match_requestcode.groups!.paxname;
          ordernumRequestCodeMap.set(ordernum, { requestCode, paxNameInvoice });
        } else {
          const obj = ordernumRequestCodeMap.get(ordernum);
          if (obj) {
            requestCode = obj.requestCode;
            paxNameInvoice = obj.paxNameInvoice;
          }
        }
      }

      // const prevRequestCode = carteRequestCodeMap.get(carte)
      // if (requestCode) {
      //   if (prevRequestCode) {
      //     if (prevRequestCode !== requestCode) {
      //       console.error(`carte ${carte} has multiple request codes: ${prevRequestCode} vs ${requestCode}`)
      //     }
      //   }
      //   carteRequestCodeMap.set(carte, requestCode)
      // } else {
      //   if (prevRequestCode) {
      //     requestCode = prevRequestCode
      //   }
      // }


      const tableRowData = {
        iLine,
        linenum,
        desc,
        ordernum,
        devicenum,
        dates,
        price,
        requestCode,
        paxNameInvoice,
        line,
      };

      tableRows.push(tableRowData);

    } // each line of text

    const partialAssignments = [];

    for (let i = 0; i < tableRows.length; i++) {
      const thisRow = tableRows[i];
      if (i > 0 && thisRow.ordernum === tableRows[i - 1].ordernum) {
        const currentItem = partialAssignments[partialAssignments.length - 1];
        currentItem.numRows += 1;
        currentItem.price += thisRow.price;
        currentItem.lines.push(thisRow.line);
        if (!currentItem.requestCode && thisRow.requestCode) {
          currentItem.requestCode = thisRow.requestCode;
          currentItem.paxNameInvoice = thisRow.paxNameInvoice;
        }
      } else {
        partialAssignments.push({
          ...thisRow,
          numRows: 1,
          lines: [thisRow.line],
        });
      }
    }

    for (let i = 0; i < partialAssignments.length; i++) {

      const thisItem = partialAssignments[i];

      const {
        iLine,
        price,
        requestCode,
        paxNameInvoice,
        lines,
        numRows,
      } = thisItem;

      const crop: PercentCrop = {
        x: 5.2,
        width: 90,
        y: (iPage === 0 ? 3.5 : 6.6) + iLine * 0.99,
        height: 0.99 * 2 * numRows,
        unit: '%',
      };

      const newPartialAssignment: InvoicePartialAssignmentType = { // here we explicitly type, so that if fields are added in the future, they will not be forgotten here
        id: nano_id(),
        amount: price,
        requestCode,
        paxName: '',
        paxNameInvoice,
        fileStoragePath: selectedFile,
        pageNumber: 1 + iPage,
        crop: crop,
        isAutoBreakdown: true,
        autoBreakdownData: lines,
        needsManualConfirmation: true,
        //metadata
        userCreated: userSimpleAuto,
        dateCreated: serverTimestampAsDate(),
        userModified: userSimpleAuto,
        dateModified: serverTimestampAsDate(),
      };

      const { id: partialId, ...dbObj } = newPartialAssignment;

      // updateObj[`partialAssignments.${partialId}`] = dbObj
      updateObj.partialAssignments[partialId] = dbObj;

    }

  } // each page


  autosaveNewStep('Auto-assigning breakdown: Mobile Planning', updateObj, 'UNDOWALL')
    .catch((err) => setDbError('Auto-assigning breakdown', err));

}
