


export function formatNum(num: number | null | undefined): string {
  if (num === null || num === undefined) {
    return '';
  }
  return num.toLocaleString('en-US');
}

export function formatNumCcy(num: number, ccy: string) {
  if (num === null || num === undefined) {
    return '';
  }
  return num.toLocaleString('en-US', {
    // style: 'currency',  // produces A$100  ¥100
    // currency: ccy,
    minimumFractionDigits: ccy === 'JPY' || ccy === 'KRW' ? 0 : 2,
    maximumFractionDigits: ccy === 'JPY' || ccy === 'KRW' ? 0 : 2,
  });

}

export function formatNum2(num: number | string): string {
  // same as formatNum but can also accept strings
  if (num === null || num === undefined) {
    return '';
  }
  if (typeof num === 'string') {
    return num;
  }
  return num.toLocaleString('en-US');
}

export function formatNumRound(num: number, decimalPlaces: number): string {
  return num.toLocaleString('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}
