import { serverTimestamp } from 'firebase/firestore';


export function serverTimestampAsDate() {
  // a bit of a hack, but I can't think of a better way for now
  return serverTimestamp() as unknown as Date;
}


// because we replace Firestore dates with JavaScript dates in place
// (changing the property type)
// this does not lend itself well to TypeScript static typing
// so we type all the parameters as any

export function convertInvoiceDates(invoice: any) {
  convertFirestoreDates(invoice);
}

export function convertQuotationDates(quotation: any) {
  convertFirestoreDates(quotation);
}

export function convertHotelPriceDates(hotelprice: any) {
  convertFirestoreDates(hotelprice);
}

export function convertServicePriceDates(serviceprice: any) {
  convertFirestoreDates(serviceprice);
}


export function convertExpenseSheetDates(sheet: any) {

  // convert
  //   sheet.tourStartDate
  //   sheet.tourEndDate
  //   sheet.accountingDate
  convertFirestoreDates(sheet);

  // deep member conversion:

  sheet.advancePayments.forEach((item: any) => {
    if (item.date) item.date = item.date.toDate();
  });

  sheet.guideTransportation.forEach((item: any) => {
    if (item.date) item.date = item.date.toDate();
  });

  sheet.otherExpenses.forEach((item: any) => {
    if (item.date) item.date = item.date.toDate();
  });

  for (const key of Object.keys(sheet.workflowHistory)) {
    if (sheet.workflowHistory[key].date) sheet.workflowHistory[key].date = sheet.workflowHistory[key].date.toDate();
  }

  // fix id->uid
  if (sheet.usersDesigners) {
    sheet.usersDesigners.forEach((user: any) => {
      if (!user.uid && user.id) {
        user.uid = user.id;
        delete user.id;
      }
    });
  }

}


export function convertTourRequestDates(tourrequest: any) {
  convertFirestoreDates(tourrequest);

  if (tourrequest.salesInformation) {
    convertFirestoreDates(tourrequest.salesInformation);
  }

  if (tourrequest.salesInfo2) {
    convertFirestoreDates(tourrequest.salesInfo2);
    tourrequest.salesInfo2.quotations.forEach((quotation: any) => convertFirestoreDates(quotation));
    tourrequest.salesInfo2.payments.forEach((payment: any) => {
      convertFirestoreDates(payment);
      if (payment.freeeStatus) {
        convertFirestoreDates(payment.freeeStatus); // dateModified
      }
    });
  }
}


export function convertDatesAgency(agency: any) {
  convertFirestoreDates(agency);
  if (agency.requestList) {
    agency.requestList.forEach((reqSimple: any) => {
      // reqSimple.dateCreated needs to be converted
      convertFirestoreDates(reqSimple);
    });
  }
}

export function convertPayeeDates(payee: any) {
  if (payee.dateCreated)
    payee.dateCreated = payee.dateCreated.toDate();
  if (payee.dateModified)
    payee.dateModified = payee.dateModified.toDate();
}

export function convertExpensePaymentDates(expensepayment: any) {
  convertFirestoreDates(expensepayment);
}

export function convertCommuteMonthlyDates(commuteMonthly: any) {
  convertFirestoreDates(commuteMonthly);
}

export function convertGeneralExpenseDates(generalExpense: any) {
  convertFirestoreDates(generalExpense);
}

export function convertWebppMinVersionDates(webappMinVersion: any) {
  convertFirestoreDates(webappMinVersion);
}

export function convertQuotegridSheetDates(quotegridsheet: any) {
  convertFirestoreDates(quotegridsheet);
  convertFirestoreDates(quotegridsheet.metadataCreated);
  convertFirestoreDates(quotegridsheet.metadataModified);
}

export function convertUserDetailsDates(userDetails: any) {
  convertFirestoreDates(userDetails);
}

export function convertExploreBookingsDates(exploreBooking: any) {
  convertFirestoreDates(exploreBooking);
}

export function convertLogMessageDates(logmessage: any) {
  convertFirestoreDates(logmessage);
}

export function convertCalendarLogDates(logmessage: any) {
  convertFirestoreDates(logmessage);
}


function convertFirestoreDates(obj: any) {

  for (const field of Object.keys(obj)) {
    const value = obj[field];
    if (value && value.toDate) {
      obj[field] = value.toDate();
      continue;
    }

    // handle algolia search results which have _seconds and _nanoseconds but no toDate() method
    if (value && (typeof value === 'object') && ('_seconds' in value) && ('_nanoseconds' in value)) {
      obj[field] = new Date(value._seconds * 1000 + Math.floor(value._nanoseconds / 1000000));
      continue;
    }

    // only shallow conversion for now

    // if (typeof value === 'object') {
    //   console.log(`${field}: ${Object.keys(value)}`)
    // }
  }
}


function convertFirestoreDates_Deep(obj: any) {

  for (const field of Object.keys(obj)) {
    const value = obj[field];

    if (!value)
      continue;

    if (value && value.toDate) {
      obj[field] = value.toDate();
      continue;
    }

    // deep conversion:

    if (Array.isArray(value)) {
      const array = value;
      for (let i = 0; i < array.length; i++) {
        // TODO
      }
    }

    if (typeof value === 'object') {
      //console.log(`${field}: ${Object.keys(value)}`)

      if (value instanceof Date)
        continue;

      convertFirestoreDates(value);
      continue;
    }
  }
}
