


export function getFieldValue(obj: Record<string, any>, field: string) {
  if (!obj)
    // trigger error just as obj[field] would normally trigger an error when obj is not defined
    throw new Error(`Tried to get value of field [${field}] from null or undefined object`);

  const fieldParts = field.split('.');
  let value: any = obj;
  for (let i = 0; i < fieldParts.length; i++) {
    const fieldPart = fieldParts[i];
    value = value[fieldPart];
    if (value === undefined)
      // field is missing, we don't try to go further as that would trigger an error
      return undefined;
  }
  return value;
}
