import { StatusKeyType } from '../../PageTourRequests';
import { OneRequestTableType } from '../../types_tourrequest_state';
import { getTwBackground, NUM_ROW_CHOICES } from '../../util_tourrequests';



interface StatusHeaderRowProps {
  disabledPagination: boolean;
  // pageNumber: number; // 1-based
  // isLoading: 'start' | 'previous' | 'next' | true | null;
  requestTable: OneRequestTableType;
  statusKey: StatusKeyType;
  isFooter: boolean;
}

export function StatusHeaderRow({
  disabledPagination,
  // pageNumber,
  // isLoading,
  requestTable,
  statusKey,
  isFooter,
}: StatusHeaderRowProps) {

  const {
    isLoading,
    setIsLoading,
    previousPagesLastDocs,
    setPreviousPagesLastDocs,
    currentPageLastDoc,
    numberPerPage,
    setNumberPerPage,
    isExpanded,
    setIsExpanded,
  } = requestTable;


  // const choices = NUM_ROW_CHOICES.filter((n) => n < requestTable.rowCount)
  // add 1 more
  // const lastChoice = Math.max(50, NUM_ROW_CHOICES[choices.length])

  // above list of choices with the last choice being the number of rows was buggy, caused issues when switching between designers.
  // now we just list all choices as is. much more straightforward.
  const choices = NUM_ROW_CHOICES;

  // const moreToLoad = requestTable.rowCount > numberPerPage // always false if `disabledPagination` is true
  const moreToLoad = numberPerPage > 0 && requestTable.rowCount && requestTable.rowCount > numberPerPage;


  let title = '?';
  if (statusKey === 'confirmed_active') {
    title = 'CONFIRMED - PRE/ON/POST-TOUR';
  } else if (statusKey === 'ongoing') {
    title = 'ONGOING';
  } else if (statusKey === 'confirmed_archived') {
    title = 'CONFIRMED - ARCHIVED';
  } else if (statusKey === 'lost_and_cancelled') {
    title = 'LOST AND CANCELLED';
  } else if (statusKey === 'all') {
    title = 'ALL STATUSES';
  }

  let ordering = '';
  if (statusKey === 'confirmed_active' || statusKey === 'confirmed_archived' || statusKey === 'ongoing') {
    ordering = 'tour start date, ascending';
  } else {
    ordering = 'date created, descending';
  }

  return (
    <tr key={'header_0'}>
      <td colSpan={100} className={`tw-text-sm  tw-text-white !tw-py-1 !tw-pl-12 ${getTwBackground(statusKey)}`}>
        <div className='tw-flex tw-gap-5 tw-items-center'>

          <div className='tw-cursor-pointer' onClick={() => {
            setIsExpanded(!isExpanded);
          }}>
            {isExpanded ? (
              isFooter ? (
                <i className='bi bi-chevron-up'></i>
              ) : (
                <i className='bi bi-chevron-down'></i>
              )
            ) : (
              <i className='bi bi-chevron-right'></i>
            )}
          </div>

          <div className='tw-font-bold'>
            {title}
          </div>



          {isExpanded ? (
            <div>
              {requestTable.rowCount === 0 ? (
                <>
                  Showing 0 of 0
                </>
              ) : (
                <>
                  Showing 1 ~
                  {' '}
                  {disabledPagination ? (
                    <>{requestTable.rowCount}</>
                  ) : (
                    <select className='tw-bg-transparent tw-border-white tw-text-white'
                      value={numberPerPage}
                      disabled={disabledPagination}
                      onChange={(e) => {
                        const n = Number(e.target.value);
                        setNumberPerPage(n);

                        // reset to first page
                        setPreviousPagesLastDocs([]);
                      }}>
                      {choices.map((n) => <option key={n} value={n}>{n}</option>)}
                      {/* {lastChoice && <option value={lastChoice}>{requestTable.rowCount}</option>} */}
                    </select>
                  )}
                  {' '}
                  of {requestTable.rowCount} (ordered by {ordering})
                </>
              )}
            </div>
          ) : requestTable.rowCount !== undefined ? (
            <div>
              {requestTable.rowCount} {requestTable.rowCount === 1 ? 'request' : 'requests'}
            </div>
          ) : null}

          {isExpanded ? (
            moreToLoad && (

              <>

                <button className='tw-border tw-border-solid tw-border-white tw-rounded tw-px-2 tw-bg-transparent tw-p-0 tw-m-0 tw-inline-block tw-text-white'
                  onClick={() => {
                    const i = choices.indexOf(numberPerPage);
                    if (i === -1)
                      return;
                    const newNum = choices[i + 1] || choices.at(-1)!;

                    if (newNum !== numberPerPage) {
                      setIsLoading(true);
                      setPreviousPagesLastDocs([]);
                      setNumberPerPage(newNum);
                    }
                  }}>
                  Show more
                </button>


                <button className='tw-border tw-border-solid tw-border-white tw-rounded tw-px-2 tw-bg-transparent tw-p-0 tw-m-0 tw-inline-block tw-text-white'
                  onClick={() => {
                    if (numberPerPage !== 0) {
                      setIsLoading(true);
                      setPreviousPagesLastDocs([]);
                      setNumberPerPage(choices.at(-1)!);
                    }
                  }}>
                  Show all
                </button>

              </>
            )
          ) : (
            !!requestTable.rowCount && (
              <button className='tw-border tw-border-solid tw-border-white tw-rounded tw-px-2 tw-bg-transparent tw-p-0 tw-m-0 tw-inline-block tw-text-white'
                onClick={() => {
                  if (numberPerPage !== 0) {
                    setIsLoading(true);
                    setIsExpanded(true);
                    setPreviousPagesLastDocs([]);
                  }
                }}>
                Show
              </button>
            )
          )}



        </div>
      </td>
    </tr>
  );
}
