import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAppContext } from 'src/hooks/useAppContext';
import { userrole_canListInvoices } from 'src/util/user_roles';
import { usePayeeList } from '../Payees/utildb_usePayeeList';
import { Unauthorized } from '../Unauthorized';
import { AddInvoice } from './AddInvoice';
import { BreakdownByRequest } from './Breakdown/BreakdownByRequest';
import { InvoiceTable } from './InvoiceTable';


export function PageInvoices() {

  const { userDetails, _lang } = useAppContext();

  const [recentlyInsertedId, setRecentlyInsertedId] = useState('');//YVDz6495DYAWULI47mJ7

  const payeeList = usePayeeList();

  return (
    <Routes>
      <Route path='/' element={
        userrole_canListInvoices(userDetails.roles)
          ? (
            <InvoiceTable recentlyInsertedId={recentlyInsertedId} setRecentlyInsertedId={setRecentlyInsertedId} payeeList={payeeList} />
          ) : (
            <Unauthorized />
          )
      } />
      <Route path='/add' element={<AddInvoice setRecentlyInsertedId={setRecentlyInsertedId} payeeList={payeeList} action='add' />} />
      <Route path='/edit/:invoiceId' element={<AddInvoice setRecentlyInsertedId={setRecentlyInsertedId} payeeList={payeeList} action='edit' />} />
      <Route path='/breakdown/:invoiceId' element={<BreakdownByRequest />} />
    </Routes>
  );
}
