import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeCategoryType } from 'src/types/types_payee';


function eqIC(a: string, b: string) {
  // equals Ignore Case (also ignore accents, punctuation)
  return a.localeCompare(b, 'en', { sensitivity: 'base', ignorePunctuation: true }) === 0;
}


function categoriesToString(payeeCategories: PayeeCategoryType[]) {
  let categoriesString = '';
  payeeCategories.forEach(({ name, subcategories }) => {
    categoriesString += `${name}\n`;
    subcategories.forEach((subitem) => {
      categoriesString += `- ${subitem}\n`;
    });
  });

  return categoriesString.trim();
}


interface PayeeCategoryEditorProps {
  payeeCategories: PayeeCategoryType[];
  setPayeeCategories: (cats: PayeeCategoryType[]) => void;
  finishEditing: () => void;
}

export function PayeeCategoryEditor({ payeeCategories, setPayeeCategories, finishEditing }: PayeeCategoryEditorProps) {

  const { db, setDbError, userDetails } = useAppContext();

  const [categoriesText, setCategoriesText] = useState<string>('');

  // initialize the text field from the categories object,
  // and refresh the text value every time the categories object changes.
  useEffect(() => {
    setCategoriesText(categoriesToString(payeeCategories));
  }, [payeeCategories]);

  return (
    <>
      <textarea className='form-control' rows={20} value={categoriesText} onChange={(e) => setCategoriesText(e.target.value)}></textarea>

      <ButtonTW variant='blue_outline' onClick={(e) => {
        const lines = categoriesText.trim().split('\n');
        const cats: PayeeCategoryType[] = [];
        let currentGroup: PayeeCategoryType | null = null;
        for (let line of lines) {
          line = line.trim();
          if (!line)
            continue;
          if (!line.startsWith('-')) {
            const groupName = line.trim();

            // check duplicates
            if (cats.find((c) => eqIC(c.name, groupName))) {
              window.alert('Duplicate category group');
              return;
            }

            currentGroup = {
              name: groupName,
              subcategories: [],
            };
            cats.push(currentGroup);
          } else {
            if (!currentGroup) {
              // ignore subcat bullet points that appear before main category heading
              continue;
            }

            const subcatname = line.substring(1).trim();

            // check duplicates
            for (const group of cats) {
              for (const subcat of group.subcategories) {
                if (eqIC(subcat, subcatname)) {
                  window.alert('Duplicate category name');
                  return;
                }
              }
            }

            if (subcatname)
              currentGroup.subcategories.push(subcatname);
          }
        }

        setDoc(doc(db, 'settings', 'payeecategories'), {
          categories: cats,
          userModifiedUid: userDetails.id,
          userModifiedName: userDetails.displayNameEn,
          userModifiedEmail: userDetails.email,
          dateModified: serverTimestamp(),
        })
          .then(() => {
            //window.alert('Saved categories')
            setPayeeCategories(cats);
            setCategoriesText(categoriesToString(cats));
            finishEditing();
          })
          .catch((err) => setDbError('Saving categories', err));

      }}>Save categories</ButtonTW>
      {' '}
      <ButtonTW variant='blue_outline' onClick={(e) => {
        // reset text field
        setCategoriesText(categoriesToString(payeeCategories));
        finishEditing();
      }}>Cancel</ButtonTW>
    </>
  );
}
