import { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonTW } from 'src/components/Buttons/ButtonTW';
import { FilterButton } from 'src/components/Buttons/FilterButton';
import { RequestCodeLinkToAggregator } from 'src/components/ContextMenus/RequestCodeLinkToAggregator';
import { EditableFieldInvoice } from 'src/components/EditableField/EditableFieldInvoice';
import { EditableFieldInvoiceStatus } from 'src/components/EditableFieldInvoiceStatus/EditableFieldInvoiceStatus';
import { EditableFieldPayee } from 'src/components/EditableFieldPayee/EditableFieldPayee';
import { FileDownloadButton } from 'src/components/FileDownloadUpload/FileDownloadButton';
import { FileUploadButton } from 'src/components/FileDownloadUpload/FileUploadButton';
import { GenericPill } from 'src/components/StatusPill/StatusPill';
import { useAutosaveDocumentSingle } from 'src/hooks/autosave/util_autosave';
import { useAppContext } from 'src/hooks/useAppContext';
import { InvoiceType } from 'src/types/types_invoices';
import { PayeeType } from 'src/types/types_payee';
import { UserDetailsType } from 'src/types/types_user';
import { dateisoFormatJp } from 'src/util/dateformattools';
import { dateFormatUserFriendly } from 'src/util/datelayouttools';
import { getTodayIso } from 'src/util/datetools';
import { userrole_isAdmin } from 'src/util/user_roles';
import { formatNum } from 'src/util/util_formatnum';
import { log_db_write } from 'src/util/util_log';
import { arraySum, compare } from 'src/util/util_misc';
import { getUserSimpleUid } from 'src/util/util_users';
import { getShortDisplayNameEn, getShortDisplayNameJa } from '../Payees/util_payees';
import { FreeeInputCell } from './FreeeInputCell';
import { InvoiceFieldType } from './InvoiceTable';
import { addMetadataModifiedInvoices, getIsOld, getUpdateObjAfterInvoiceFileUpload, getUpdateObjToDeleteFile, isInvoiceMultiple } from './util_invoices';



interface InvoiceTableRowProps {
  invoice: InvoiceType;
  index: number;
  indexNonOld: number;
  classRowBgColor: string;
  invoiceListDisplayed: InvoiceType[];
  showExtended: boolean;
  showExtendedInfo: () => void;
  hideExtendedInfo: () => void;
  paramRequestCode: string | null;
  paramPayeeId: string | null;
  freeeUnlocked: boolean;

  // state
  editedInvoice: string;
  setEditedInvoice: (invoiceid: string) => void;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  shownPopup: string | null;
  setShownPopup: (popupid: string | null) => void;

  // user permissions
  user_canEditOwnInvoices: boolean;
  user_canEditAnyInvoice: boolean;
  user_canMarkPaid: boolean;
  user_canDeleteInvoices: boolean;
  user_canEditFreee: boolean;

  // display settings
  sortCol: InvoiceFieldType;
  recentlyInsertedId: string;
  payeeInEnglish: boolean;
  showDateCreatedModified: boolean;

  // lists for dropdowns
  userList: Record<string, UserDetailsType>;
  payeeList: PayeeType[] | undefined;
}


export function InvoiceTableRow({
  invoice,
  index,
  indexNonOld,
  classRowBgColor,
  invoiceListDisplayed,
  showExtended,
  showExtendedInfo,
  hideExtendedInfo,
  paramRequestCode,
  paramPayeeId,
  editedInvoice,
  freeeUnlocked,
  setEditedInvoice,
  editedCell,
  setEditedCell,
  shownPopup,
  setShownPopup,
  user_canEditOwnInvoices,
  user_canEditAnyInvoice,
  user_canMarkPaid,
  user_canDeleteInvoices,
  user_canEditFreee,
  sortCol,
  recentlyInsertedId,
  payeeInEnglish,
  showDateCreatedModified,
  userList,
  payeeList,
}: InvoiceTableRowProps) {


  const { db, setDbError, userDetails } = useAppContext();

  const navigate = useNavigate();

  const userSimple = getUserSimpleUid(userDetails);

  const displayStatus = invoice.status; // invoice.status in db is EXPECTED, RECEIVED, NEEDS CHECKING, DONE CHECKING, SCHEDULED, PAID;  'RECEIVED' will not be shown explicitly

  const isPaid = invoice.status === 'PAID';
  const isPaidOrScheduled = isPaid || invoice.status === 'SCHEDULED';

  const isOld = getIsOld(invoice);

  let deadlineColor = '';

  if (!isPaidOrScheduled) {
    if (invoice.dateisoDeadline < getTodayIso()) {
      // invoice is OVERDUE
      deadlineColor = 'danger';
    }
    if (invoice.dateisoDeadline === getTodayIso()) {
      // invoice is DUE TODAY
      deadlineColor = 'warning';
    }
  }

  const isTrash = invoice.status === 'TRASH';

  const isEditableAsOwnDoc = user_canEditOwnInvoices && userDetails.id === invoice.uid;
  const isEditable = user_canEditAnyInvoice || isEditableAsOwnDoc;
  const isAdmin = userrole_isAdmin(userDetails.roles);

  const isRowBeingEdited = editedInvoice === invoice.id;


  const classOddEvenFlag = isOld ? '' : indexNonOld % 2 === 0 ? 'evenRowFlag' : 'oddRowFlag'; // these are just flags, do not have styling attached, just used for JS to know if even or odd row


  const groupRowsByIdenticalSortValues = sortCol !== 'amount'; // we assume there won't be any need for grouping by indentical amount...

  let classRepeatedValues = '';
  let strongBorderBottom = false;
  if (groupRowsByIdenticalSortValues) {
    const isLastInTable = index === invoiceListDisplayed.length - 1;

    let isGroupable = invoice[sortCol] ? true : false;
    if (isGroupable && (sortCol === 'tripcode' || sortCol === 'paxname') && invoice[sortCol].toLowerCase() === 'multiple')
      isGroupable = false;

    // /!\ here the normal comparison !== or === will NOT work with DATE objects
    // instead we use our compare(a,b) function which DOES return 0 if dates are the same date
    const sameAsAbove = index > 0 && isGroupable && compare(invoiceListDisplayed[index - 1][sortCol], invoice[sortCol]) === 0;
    const sameAsBelow = !isLastInTable && isGroupable && compare(invoiceListDisplayed[index + 1][sortCol], invoice[sortCol]) === 0;

    if (sameAsAbove && sameAsBelow)
      classRepeatedValues = 'repeat-values-middle';
    else if (sameAsBelow)
      classRepeatedValues = 'repeat-values-top';
    else if (sameAsAbove)
      classRepeatedValues = 'repeat-values-bottom';
    else
      classRepeatedValues = '';

    strongBorderBottom = !sameAsBelow;
  }

  const rowProps = {
    rowid: invoice.id,
    invoice,
    isClickableToEdit: isRowBeingEdited,
    editedCell: editedCell,
    setEditedCell: setEditedCell,
    setEditedInvoice: setEditedInvoice,
  };

  const deadlineEditableField = (
    <EditableFieldInvoice
      fieldname='dateisoDeadline'
      validationType='dateiso'
      currentValue={dateisoFormatJp(invoice.dateisoDeadline)}
      {...rowProps}
    />
  );

  const partials = invoice.partialAssignmentList || [];
  const partialAmounts = partials.map((partial) => partial.amount);
  const assigned = arraySum(partialAmounts);
  const assignedPerfectly = assigned === invoice.amount;
  let assignIcon: ReactNode;
  if (assignedPerfectly) {
    assignIcon = <i className='bi bi-patch-check tw-text-lg' title='Fully assigned'></i>;
  } else {
    assignIcon = <i className='bi bi-exclamation-triangle tw-text-lg' title='Partially assigned'></i>;
  }
  let assignedPercent = Math.round(assigned / invoice.amount * 100);
  if (assignedPercent === 0 && assigned > 0)
    assignedPercent = 1;
  if (assignedPercent === 100 && assigned < invoice.amount)
    assignedPercent = 99;
  if (assignedPercent === 100 && assigned > invoice.amount)
    assignedPercent = 101;


  const autosaveNewStep = useAutosaveDocumentSingle('invoices', invoice, addMetadataModifiedInvoices);

  return (
    <tr className={`${classRowBgColor} ${classOddEvenFlag} ${isTrash ? 'invoice-in-trash' : ''} ${strongBorderBottom ? 'strongBorderBottom' : ''} ${classRepeatedValues}`}>
      <td><div onClick={(e) => {
        if (showExtended) {
          hideExtendedInfo();
        } else {
          showExtendedInfo();
        }
      }}><i className={`bi ${showExtended ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i></div></td>
      <td className={(sortCol === 'dateisoDeadline' && false) ? 'col-sorted' : ''}>
        {deadlineColor ? (
          <GenericPill color={deadlineColor} content={deadlineEditableField} />
        ) : (
          deadlineEditableField
        )}
      </td>
      <td className={(sortCol === 'tripcode') ? 'col-sorted' : ''}>
        {isRowBeingEdited ? (
          <EditableFieldInvoice
            fieldname='tripcode'
            currentValue={invoice.tripcode}
            validationType=''
            {...rowProps}
          />
        ) : (
          <>
            {isInvoiceMultiple(invoice) ? (
              <>
                <ButtonTW variant={assignedPerfectly ? 'green_outline' : 'orange_outline'} to={`/invoices/breakdown/${invoice.id}`}>{assignIcon} Multiple</ButtonTW>
                <div className='tw-pt-1 tw-text-sm' title={`${formatNum(assigned)} of ${formatNum(invoice.amount)}`}
                  onClick={() => {
                    console.log('partialAmounts', partialAmounts);
                  }}>
                  {assignedPercent}% assigned
                </div>
              </>
            ) : (
              <RequestCodeLinkToAggregator
                requestCode={invoice.tripcode}
                linkId={invoice.id}
                shownPopup={shownPopup}
                setShownPopup={setShownPopup}
              />
            )}
            {' '}
            <FilterButton
              filterActive={paramRequestCode === invoice.tripcode}
              setFilterValue={(requestCode) => {
                if (requestCode) {
                  navigate(`/invoices/?requestCode=${requestCode}`);
                } else {
                  navigate('/invoices/');
                }
              }}
              currentValue={invoice.tripcode}
              title='Filter by request code'
            />
          </>
        )}
      </td>
      <td className={(sortCol === 'paxname') ? 'col-sorted' : ''}>
        <EditableFieldInvoice
          fieldname='paxname'
          currentValue={invoice.paxname}
          validationType=''
          {...rowProps}
        />
      </td>
      <td className={(sortCol === 'servicePurchased') ? 'col-sorted' : ''}>
        <EditableFieldInvoice
          fieldname='servicePurchased'
          currentValue={invoice.servicePurchased}
          validationType=''
          {...rowProps}
        />
      </td>
      <td className={(sortCol === 'payeeNameEn' || sortCol === 'payeeNameJa') ? 'col-sorted' : ''}>
        <EditableFieldPayee
          tableid='invoicetable'
          rowid={invoice.id}
          currentPayeeName={payeeInEnglish ? invoice.payeeNameEn : invoice.payeeNameJa}
          currentPayeeId={invoice.payeeId}
          isClickableToEdit={isRowBeingEdited}
          editedCell={editedCell}
          setEditedCell={setEditedCell}
          callbackCommitChangePayee={(payeeObj: PayeeType) => {
            const payeeNameEn = getShortDisplayNameEn(payeeObj);
            const payeeNameJa = getShortDisplayNameJa(payeeObj);

            const updateObj: Partial<InvoiceType> = {
              payeeId: payeeObj.id,
              payeeNameJa,
              payeeNameEn,
              payeeCategories: payeeObj.categories,
            };

            autosaveNewStep(`Inline: Set supplier to ${payeeNameEn}`, updateObj, 'u')
              .then(() => {
                setEditedCell('');
                setEditedInvoice('');
              })
              .catch((err) => setDbError(`Set payee to ${payeeNameEn} ${payeeObj.id} on invoice ${invoice.id}`, err));

            log_db_write({ db, userDetails, logkey: 'db_write.invoice.inline_set_payee', desc: `Set payee to ${payeeNameEn} ${payeeObj.id} on invoice ${invoice.id}` });
          }}
          callbackNewPayee={(payeeName: string) => {
            navigate(`/suppliers/add?name=${payeeName}&invoiceIds=${invoice.id}&returnTo=invoicelist`);
          }}
          payeeList={payeeList}
        />
        {' '}
        <FilterButton
          filterActive={paramPayeeId === invoice.payeeId}
          setFilterValue={(payeeId) => {
            if (payeeId) {
              navigate(`/invoices/?payeeId=${payeeId}`);
            } else {
              navigate('/invoices/');
            }
          }}
          currentValue={invoice.payeeId}
          title='Filter by supplier'
        />
      </td>
      <td>
        {invoice.payeeCategories ? invoice.payeeCategories.join(', ') : ''}
      </td>
      <td className={`${(sortCol === 'amount') ? 'col-sorted' : ''} numeric`}>
        <EditableFieldInvoice
          fieldname='amount'
          validationType='number'
          currentValue={formatNum(invoice.amount)}
          useSpan={true}
          {...rowProps}
        />
        &nbsp;
        <EditableFieldInvoice
          fieldname='currency'
          validationType='currency'
          currentValue={invoice.currency}
          useSpan={true}
          {...rowProps}
        />
      </td>
      <td className={(sortCol === 'userEmail') ? 'col-sorted' : ''}>
        {userList[invoice.uid]?.displayNameEn || invoice.userCreatedName}
      </td>
      <td>
        {invoice.paymentDateiso && invoice.paymentDateiso !== 'Z' && dateisoFormatJp(invoice.paymentDateiso)}
      </td>
      <td>
        {/* <InvoiceStatusPill status={status} /> */}
        <EditableFieldInvoiceStatus
          tableid='invoicetable'
          currentValue={invoice.status}
          displayStatus={displayStatus}
          {...rowProps}
        />
      </td>
      {user_canEditFreee && (
        <td style={{ color: freeeUnlocked ? '#4488ff' : undefined }}>
          <FreeeInputCell
            freeeUnlocked={freeeUnlocked}
            checked={invoice.freeeStatus?.checked ?? false}
            memorandum={invoice.freeeStatus?.memorandum ?? ''}
            callbackUpdateDoc={async (freeeStatus, field) => {
              const updateObj: Partial<InvoiceType> = {
                freeeStatus,
              };

              const userAction =
                field === 'checkbox' ? `Set Freee status to ${freeeStatus.checked ? 'checked' : 'unchecked'}`
                  : field === 'memo' ? `Set Freee memo to ${freeeStatus.memorandum}`
                    : 'Update Freee status';

              autosaveNewStep(userAction, updateObj, 'UNDOWALL') // NOT undoable
                .catch((err) => setDbError(`Setting freee status ${invoice.id}`, err));
            }}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            cellid={invoice.id}
          />
        </td>
      )}
      <td style={{ 'fontSize': '1.2em' }}>
        {invoice.files && invoice.files.map((obj, index) => {

          const canDeleteFiles = isEditable; // now we allow anybody to delete attachments on their own invoices. previously, we only let admins delete files
          const isDeletionMode = canDeleteFiles && isRowBeingEdited;

          const filename = obj.storagePath;
          const downloadURL = obj.downloadURL;

          return (
            <FileDownloadButton
              key={filename}
              filename={filename}
              downloadURL={downloadURL}
              isDeletionMode={isDeletionMode}
              callbackAfterDelete={() => {
                const { updateObj, userAction } = getUpdateObjToDeleteFile(invoice, obj);

                autosaveNewStep(userAction, updateObj, 'u')
                  .then(() => {
                    setEditedCell('');
                    setEditedInvoice('');
                  })
                  .catch((err) => setDbError(`Deleting invoice file attachment [${filename}]`, err));
              }}
              loginfo={`invoice ${invoice.id} ${invoice.payeeNameJa} ${invoice.payeeNameEn}`}
            />
          );
        })}

        {/* Only admin can see deleted files: */}
        {isAdmin && invoice.filesDeleted && invoice.filesDeleted.length > 0 && (
          isRowBeingEdited ? (
            invoice.filesDeleted.map((obj, index) => {
              const filename = obj.storagePath;
              const downloadURL = obj.downloadURL;
              return (
                <FileDownloadButton
                  key={`file_${index}_${filename}`} // filename alone is not sufficient, as the user could delete the same file multiple times (by using undo/redo) which would result the exact same storagePath being present multiple times in filesDeleted
                  filename={filename}
                  downloadURL={downloadURL}
                  isDeletionMode={false}
                  iconBase='bi-file-earmark-x'
                  loginfo={`invoice ${invoice.id} ${invoice.payeeNameJa} ${invoice.payeeNameEn} deleted file`}
                />
              );
            })
          ) : (
            <i className={`bi-${Math.min(invoice.filesDeleted.length, 9)}-circle`} title={`${invoice.filesDeleted.length} deleted files`}></i>
          )
        )}

        {/* <button title='Add new file' className='d-inline-flex px-1' style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {

        }}>
          <i className='bi-cloud-arrow-up'></i>
        </button> */}

        {(!isTrash && isEditable) && (
          <FileUploadButton
            itemId={invoice.id}
            storageFolder={`invoices/${userDetails.id}`}
            callbackUploadSuccess={(uploadedFilePathsAndURLs) => {
              const { updateObj, userAction } = getUpdateObjAfterInvoiceFileUpload(invoice, uploadedFilePathsAndURLs);

              autosaveNewStep(userAction, updateObj, 'u')
                .catch((err) => setDbError(`${userAction} on invoice ${invoice.id}`, err));

              log_db_write({ db, userDetails, logkey: 'db_write.invoice.inline_upload_file', desc: `${userAction} on invoice ${invoice.id}` });
            }}
          />
        )}
      </td>
      {showDateCreatedModified && (
        <>
          <td className='text-center p-1 date-col'>
            {dateFormatUserFriendly(invoice.dateCreated)}
          </td>
          <td className='text-center p-1 date-col'>
            {dateFormatUserFriendly(invoice.dateModified)}
          </td>
        </>
      )}
      <td style={{ fontSize: '1.2em', whiteSpace: 'nowrap' }}>
        {!isTrash ? (
          <>
            {isEditable && (
              <button title='Quick Edit' className='d-inline-flex px-1' style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                if (!isRowBeingEdited) {
                  setEditedCell('');
                  setEditedInvoice(invoice.id);
                } else {
                  setEditedCell('');
                  setEditedInvoice('');
                }
              }}>
                <i className={isRowBeingEdited ? 'bi-pencil-fill' : 'bi-pencil'}></i>
              </button>
            )}

            {isEditable && (
              <Link title='Edit on separate page' to={`/invoices/edit/${invoice.id}`} className='d-inline-flex px-1' style={{ color: 'black' }}>
                <i className='bi-layout-text-sidebar-reverse'></i>
              </Link>
            )}

            {isEditable && (
              <button title='Send to trash' className='d-inline-flex px-1' style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                if (!window.confirm('Are you sure you want to send this invoice to trash?'))
                  return;

                const updateObj: Partial<InvoiceType> = {
                  status: 'TRASH',
                  statusPaidOrScheduled: false,
                };

                autosaveNewStep('Send invoice to TRASH', updateObj, 'UNDOWALL') // NOT undoable
                  .catch((err) => setDbError(`Sending invoice to trash ${invoice.id}`, err));
              }}>
                <i className={'bi-trash'}></i>
              </button>
            )}
          </>
        ) : (
          <>
            {isEditable && (
              <button title='Restore' className='d-inline-flex px-1' style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                if (!window.confirm('Are you sure you want to restore this invoice from trash?'))
                  return;

                const updateObj: Partial<InvoiceType> = {
                  status: 'RECEIVED',
                  statusPaidOrScheduled: false,
                };

                autosaveNewStep('Restore invoice from TRASH', updateObj, 'UNDOWALL') // NOT undoable
                  .catch((err) => setDbError(`Restoring invoice from trash ${invoice.id}`, err));
              }}>
                <i className={'bi-recycle'}></i>
              </button>
            )}

            {user_canDeleteInvoices && (
              <button title='Delete' className='d-inline-flex px-1' style={{ 'border': 'none', 'background': 'transparent' }} onClick={(e) => {
                if (!window.confirm('Are you sure you want to delete this invoice permanently?'))
                  return;

                const updateObj: Partial<InvoiceType> = {
                  _isDeleted: true,
                  status: 'DELETED',
                  statusPaidOrScheduled: false,
                };

                autosaveNewStep('Permanently DELETE invoice', updateObj, 'UNDOWALL') // NOT undoable
                  .catch((err) => setDbError(`Permanently deleting invoice ${invoice.id}`, err));
              }}>
                <i className={'bi-x-circle'}></i>
              </button>
            )}

            {/* <i className='bi-file-check'></i> */}
          </>
        )}
      </td>
    </tr>
  );



}
