import { useRef } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { PayeeTypeahead } from 'src/pages/Invoices/PayeeTypeahead';
import { PayeeType } from 'src/types/types_payee';
import { useOutsideAlerter } from '../EditableField/util_useOutsideAlerter';


interface EditableFieldPayeeProps {
  tableid: string;
  rowid: string;
  currentPayeeName: string;
  currentPayeeId: string;
  isClickableToEdit: boolean;
  editedCell: string | null;
  setEditedCell: (cellid: string | null) => void;
  callbackCommitChangePayee: (payeeObj: any) => void;
  callbackNewPayee: (payeeName: string) => void;
  payeeList: PayeeType[] | undefined;
}

export function EditableFieldPayee({
  tableid,
  rowid,
  currentPayeeName,
  currentPayeeId,
  isClickableToEdit,
  editedCell,
  setEditedCell,
  callbackCommitChangePayee,
  callbackNewPayee,
  payeeList,
}: EditableFieldPayeeProps) {


  const { db, setDbError } = useAppContext();

  const cellid = `${tableid}_${rowid}_${'payee'}`;


  // the below allows a user to click anywhere on the page, to make the current edited cell to stop being edited
  const inputRef = useRef(null);
  // _eslint-disable-next-line react-compiler/react-compiler -- https://github.com/facebook/react/issues/30745
  useOutsideAlerter(inputRef, () => {

    // no need to commit here as Typeahead always updates the underlying db/object on every change, so we just stop editing
    setEditedCell('');

  });


  const currentlyEditing = editedCell === cellid;


  if (!currentlyEditing) {

    // to make the space clickable for editing:
    const whitespace = isClickableToEdit ? '\xa0'.repeat(20) : ''; // \xa0 is &nbsp;

    const onclickHandler = (() => {
      if (isClickableToEdit)
        setEditedCell(cellid);
    });

    const displayValue = currentPayeeName;

    // here we use a <span> instead of a <div> so that the filter icon gets placed at the end of the text instead of underneath (on invoice table)
    return <span onClick={onclickHandler}>{displayValue || whitespace}</span>;


  } else {

    if (!payeeList)
      return <span>Loading...</span>;

    const currentPayeeObj = payeeList.find((p) => p.id === currentPayeeId);

    return (

      <div
        style={{ minWidth: '31rem' }}
        ref={inputRef}
      >
        <PayeeTypeahead
          id='payee'
          payeeList={payeeList}
          payeeId={null} // easier for user if the textbox is just blank rather than containing the current payee name
          setPayeeObj={(payeeObj) => {
            if (payeeObj)
              callbackCommitChangePayee(payeeObj);
          }}
          callbackNewPayee={callbackNewPayee}
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === 'Escape')
              setEditedCell('');
          }}
        />
      </div>

    );
  }
}


