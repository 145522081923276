import { Tooltip } from 'react-tooltip';
import './workflowwidget.css';


interface WorkflowItemProps {
  status: string;
  selected: string;
  label: string | JSX.Element;
  sublabel?: string;
  isFirst?: boolean;
  isLast?: boolean;
  hasArrowDown?: boolean;
  hasArrowTop?: boolean;
  setSelected: (status: string) => void;
}

export function WorkflowItem({ status, selected, label, sublabel, isFirst, isLast, hasArrowDown, hasArrowTop, setSelected }: WorkflowItemProps) {
  const isSelected = status === selected;
  return (
    <div className={`workflowItem ${isSelected ? 'selected' : ''} ${isFirst ? 'firstItem' : ''} ${isLast ? 'lastItem' : ''}`} onClick={(e) => setSelected(status)}>
      <div>{label}</div>
      {/* {sublabel && (
        <small>{sublabel}</small>
      )} */}
      {!isLast && (
        <div className='arrowTriangle'></div>
      )}
      {hasArrowDown && (
        <div className='arrowTriangleDown'></div>
      )}
      {hasArrowTop && (
        <div className='arrowTriangleTop'></div>
      )}

    </div>
  );
}


interface WorkflowWidgetGenericProps {
  statusList: { status: string; label: string; sublabel?: string }[];
  selected: string;
  setSelected: (status: string) => void;
  showDepositCheckbox?: boolean;
}

export function WorkflowWidgetGeneric({ statusList, selected, setSelected, showDepositCheckbox }: WorkflowWidgetGenericProps) {

  return (
    <div className='workflowWidget'>

      <div className='workflowWidgetRow'>

        {statusList.map(({ status, label, sublabel }, index) => {
          return (
            <WorkflowItem
              key={status}
              status={status}
              selected={selected}
              label={label}
              sublabel={sublabel}
              isFirst={index === 0}
              isLast={index === statusList.length - 1}
              hasArrowDown={index === statusList.length - 1}
              setSelected={setSelected}
            />
          );
        })}

      </div>

      {/* {showDepositCheckbox && (
        <div className='workflowWidgetExtraRow'>
          <div className='workflowWidgetExtraItem'>
            <input type='checkbox' checked={true} id='chkRequiresDeposit' /> <label htmlFor='chkRequiresDeposit'>Trip requires deposit</label>
          </div>
        </div>
      )} */}

    </div>
  );
}


interface WorkflowWidgetProps {
  selected: string;
  setSelected: (status: string) => void;
}

export function WorkflowWidgetSales({ selected, setSelected }: WorkflowWidgetProps) {

  // const statusList1 = [
  //   { status: 'NEW_REQUEST', label: 'New request' },
  //   { status: 'FIRST_ITINERARY_SENT', label: 'First itinerary sent' },
  //   { status: 'TUNING', label: 'Tuning' },
  //   { status: 'CONFIRMED', label: 'Confirmed' },
  // ]


  return (
    <div className='workflowWidget'>

      <div className='workflowWidgetRow'>

        <WorkflowItem
          status={'NEW_REQUEST'}
          selected={selected}
          label={<>New<br />request</>}
          isFirst={true}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'FIRST_ITINERARY_SENT'}
          selected={selected}
          label={<>First<br />itinerary sent</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'TUNING'}
          selected={selected}
          label={<>Tuning</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'CONFIRMED'}
          selected={selected}
          label={<>Confirmed</>}
          setSelected={setSelected}
          isLast={true}
          hasArrowDown={true}
        />

      </div>

    </div>
  );
}


export function WorkflowWidgetFinance({ selected, setSelected }: WorkflowWidgetProps) {

  // const statusList2 = [
  //   { status: 'DEPOSIT_INVOICE_SENT', label: <>Deposit<br /> invoice sent</> },
  //   { status: 'DEPOSIT_PAID', label: <>Deposit<br /> received</> },
  //   { status: 'BALANCE_INVOICE_SENT', label: <>Balance/full amount<br /> invoice sent</> },
  //   { status: 'INITIAL_QUOTE_FULLY_PAID', label: 'Main trip fee received', sublabel: '(open to add-ons)' },
  //   { status: 'ACCOUNT_CLOSED', label: 'Account closed' },
  // ]


  return (
    <div className='workflowWidget'>

      <div className='workflowWidgetRow'>

        <WorkflowItem
          status={'DEPOSIT_INVOICE_SENT'}
          selected={selected}
          label={<>Deposit<br />invoiced</>}
          isFirst={true}
          setSelected={setSelected}
          hasArrowTop={true}
        />

        <WorkflowItem
          status={'DEPOSIT_PAID'}
          selected={selected}
          label={<>Deposit<br />received</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'BALANCE_INVOICE_SENT'}
          selected={selected}
          label={<>Full amount<br />invoiced</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'INITIAL_QUOTE_FULLY_PAID'}
          selected={selected}
          label={<>Full amount<br />received &nbsp;<a data-tooltip-id='workflow-widget-tooltip' data-tooltip-html='Some additional payments for<br />add-ons may still be outstanding' data-tooltip-place='bottom'><i className='bi bi-info-circle'></i></a></>}
          sublabel={'(open to add-ons)'}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'ACCOUNT_CLOSED'}
          selected={selected}
          label={<>Account<br />closed</>}
          isLast={true}
          setSelected={setSelected}
        />

      </div>

      <Tooltip id='workflow-widget-tooltip' place='top' variant='dark' />
    </div>
  );
}


export function WorkflowWidgetAll({ selected, setSelected }: WorkflowWidgetProps) {

  return (
    <div className='workflowWidget'>

      <div className='workflowWidgetRow'>

        <WorkflowItem
          status={'NEW_REQUEST'}
          selected={selected}
          label={<>New<br />request</>}
          isFirst={true}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'FIRST_ITINERARY_SENT'}
          selected={selected}
          label={<>Itinerary<br />sent</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'TUNING'}
          selected={selected}
          label={<>Tuning</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'CONFIRMED'}
          selected={selected}
          label={<>Confirmed</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'DEPOSIT_INVOICE_SENT'}
          selected={selected}
          label={<>Deposit<br />invoiced</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'DEPOSIT_PAID'}
          selected={selected}
          label={<>Deposit<br />received</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'BALANCE_INVOICE_SENT'}
          selected={selected}
          label={<>Full amount<br />invoiced</>}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'INITIAL_QUOTE_FULLY_PAID'}
          selected={selected}
          label={<>Full amount<br />received &nbsp;<a data-tooltip-id='workflow-widget-tooltip' data-tooltip-html='Some additional payments for<br />add-ons may still be outstanding' data-tooltip-place='bottom'><i className='bi bi-info-circle'></i></a></>}
          sublabel={'(open to add-ons)'}
          setSelected={setSelected}
        />

        <WorkflowItem
          status={'ACCOUNT_CLOSED'}
          selected={selected}
          label={<>Account<br />closed</>}
          isLast={true}
          setSelected={setSelected}
        />

      </div>

      <Tooltip id='workflow-widget-tooltip' place='top' variant='dark' />
    </div>
  );
}
