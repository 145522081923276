import { deleteObject, getDownloadURL, ref } from 'firebase/storage';
import { useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { log_db_read } from 'src/util/util_log';
import { openInNewTab } from 'src/util/util_misc';


interface FileDownloadButtonProps {
  filename: string;
  downloadURL: string;
  isDeletionMode?: boolean;
  callbackAfterDelete?: () => void;
  iconBase?: string;
  loginfo: string;
}

export function FileDownloadButton({
  filename,
  downloadURL,
  isDeletionMode,
  callbackAfterDelete,
  iconBase,
  loginfo,
}: FileDownloadButtonProps) {

  const { db, userDetails, storage } = useAppContext();

  const [isLinkActive, setIsLinkActive] = useState(false);

  const lcFilename = filename.toLowerCase();
  if (!iconBase) {
    iconBase =
      lcFilename.endsWith('.pdf') ? 'bi-file-earmark-pdf'
        : lcFilename.endsWith('.txt') ? 'bi-file-earmark-text'
          : (lcFilename.endsWith('.doc') || lcFilename.endsWith('.docx')) ? 'bi-file-earmark-word'
            : (lcFilename.match(/\.xls[xmb]?$/)) ? 'bi-file-earmark-spreadsheet'
              : (lcFilename.endsWith('.jpg') || lcFilename.endsWith('.png') || lcFilename.endsWith('.bmp') || lcFilename.endsWith('.jpeg') || lcFilename.endsWith('.webp')) ? 'bi-file-earmark-image'
                : 'bi-file-earmark';
  }
  const icon = `${iconBase}${isLinkActive ? '-fill' : ''}`;

  const handlerOpenInNewTab = async (filename: string, downloadURL: string) => {
    setIsLinkActive(true);
    log_db_read({ db, userDetails, logkey: 'db_read.download_file', desc: `Downloading file [${filename}] [${loginfo}]` });
    try {
      if (!downloadURL) {
        console.log('retrieving download URL');
        downloadURL = await getDownloadURL(ref(storage, filename));
        if (!downloadURL) {
          setIsLinkActive(false);
          window.alert("couldn't retrieve download URL");
          return;
        }
      } else {
        console.log('download URL was found in firestore');
      }
      openInNewTab(downloadURL, () => setIsLinkActive(false));
    } catch (err) {
      console.error(err);
      setIsLinkActive(false);
      window.alert("couldn't retrieve download URL");
    }
  };

  const handlerDelete = () => {
    if (!callbackAfterDelete)
      // should never be null in deletion mode
      throw new Error('callbackAfterDelete is null');

    if (!window.confirm(`Are you sure you want to delete the file:\r\n\r\n${filename}`))
      return;
    const fileRef = ref(storage, filename);
    // for now we don't actually delete the file from storage...
    // TODO: instead of deleting file from invoice row, just mark file as deleted,
    // so that we can track files that have been deleted by users to actually delete them from storage later
    const deleteFromStorage = false;
    if (deleteFromStorage)
      deleteObject(fileRef).then(callbackAfterDelete);
    else
      callbackAfterDelete();
  };

  return (
    <button title={filename} className='d-inline-flex px-1' style={{ border: 'none', background: 'transparent' }}
      onClick={(e) => {
        e.preventDefault();
        if (isDeletionMode) {
          handlerDelete();
        } else {
          handlerOpenInNewTab(filename, downloadURL);
        }
      }}>
      <i className={isDeletionMode ? 'bi-trash' : icon}></i>
    </button>
  );
}
