import { ColumnFilterPayeeType } from 'src/types/types_columnfilters';



export function getPayeeFilterFromParam(payeeIdFilter: string | null): ColumnFilterPayeeType | null {
  if (!payeeIdFilter) return null;

  let list: string[];
  if (payeeIdFilter.match(/^\[.+\]$/)) {
    // enclosed in brackets
    list = payeeIdFilter.slice(1, -1).split(',');
  } else {
    list = [payeeIdFilter];
  }

  return new Set(list);
}

