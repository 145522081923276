import { ReactNode } from 'react';
import { formatNum } from 'src/util/util_formatnum';
import './NumberBoxWithLabel.css';


interface NumberBoxWithLabelProps {
  label: string;
  value: number | string | ReactNode;
  className?: string;
}

export function NumberBoxWithLabel({ label, value, className }: NumberBoxWithLabelProps) {
  // this mimics react-bootstrap's code, but replaces the input with a div, using the same react-boostrap classes

  // const bootstrapInput = (
  //   <FloatingLabel label={label}>
  //     <Form.Control type='text' readOnly={true} value={formatNum(value)} />
  //   </FloatingLabel>
  // )

  const displayValue = typeof value === 'number' ? formatNum(value) : value;

  return (
    <div className='number-box'>
      <div className={className}>{displayValue}</div>
      <label>{label}</label>
    </div>
  );
}
