import { useState } from 'react';



interface TreeListItemProps {
  label: string;
  indentation: number;
  childNodes: JSX.Element[] | null;
  ternarySelected: 0 | 0.5 | 1;
  onCheckChange: ((checked: boolean) => void) | null; // null if we don't want to show the checkbox
  labelClassName?: string;
  onLabelClick: (() => void) | null; // null if we don't want label to be clickable
}

export function TreeListItem({
  label,
  indentation,
  childNodes,
  ternarySelected,
  onCheckChange,
  labelClassName,
  onLabelClick,
}: TreeListItemProps) {


  const [isExpanded, setIsExpanded] = useState(false);

  const showCheckbox = onCheckChange !== null;
  const labelClickable = onLabelClick !== null;

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div style={indentation ? { marginLeft: `${indentation * 1}em` } : undefined}>
        <i className={`bi ${isExpanded ? 'bi-dash-square' : 'bi-plus-square'}`} style={{ fontSize: '0.8em', visibility: childNodes ? 'visible' : 'hidden' }} onClick={onClick}></i>
        {' '}
        {showCheckbox && (
          <i className={`bi ${ternarySelected === 1 ? 'bi-check-square' : ternarySelected === 0.5 ? 'bi-dash-square' : 'bi-square'}`} onClick={() => {
            if (ternarySelected !== 1) {
              onCheckChange(true);
            } else {
              onCheckChange(false);
            }
          }}></i>
        )}
        {' '}
        <span style={labelClickable ? { cursor: 'pointer' } : undefined} className={`treeListItemLabel ${labelClassName}`} onClick={onLabelClick ?? undefined}>{label}</span>
      </div>
      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {childNodes}
      </div>
    </>
  );

}
