import { QuotegridType } from 'src/types/types_quotegrid';
import { UserDetailsType } from 'src/types/types_user';
import { serverTimestampAsDate } from 'src/util/util_firestoredates';
import { getUserSimpleUid } from 'src/util/util_users';



export function addMetadataModifiedQuotegrids(updateObj: Partial<QuotegridType>, userDetails: UserDetailsType) {

  updateObj.metadataModified = {
    dateModified: serverTimestampAsDate(),
    userModified: getUserSimpleUid(userDetails),
  };

}

