import { EnumLineItemType, QuotegridLineItemsByTypeType, QuotegridLineItemTypeAccommodation, QuotegridLineItemTypeGuide, QuotegridLineItemTypeOther, QuotegridLineItemTypeTicket, QuotegridLineItemTypeTransportation } from 'src/types/types_quotegrid';



export function addBlankItems(
  lineItemsByType: QuotegridLineItemsByTypeType,
  dayNum: number,
  dateiso: string,
  NEW_ROW_ID: string,
  itemType: EnumLineItemType,
) {

  if (itemType === 'accommodation') {
    const blankAccommodation: QuotegridLineItemTypeAccommodation = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['accommodation'].length,
      itemType: 'accommodation',
      name: '',
      room_type: '',
      room_size: '',
      room_otherFeatures: '',
      room_numberOfPaxPerRoom: null,
      room_pricePerPerson: null,
      totalPrice: null,
      memorandum: '',
    };

    lineItemsByType['accommodation'].push(blankAccommodation);
  }

  if (itemType === 'transportation') {
    const blankTransportation: QuotegridLineItemTypeTransportation = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['transportation'].length,
      itemType: 'transportation',
      name: '',
      transportation_from: '',
      transportation_to: '',
      transportation_carType: '',
      transportation_unitPrice: null,
      transportation_unitCount: null,
      totalPrice: null,
      memorandum: '',
    };

    lineItemsByType['transportation'].push(blankTransportation);
  }

  if (itemType === 'tickets') {
    const blankTicket: QuotegridLineItemTypeTicket = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['tickets'].length,
      itemType: 'tickets',
      name: '',
      ticket_numberOfAdults: null,
      ticket_pricePerAdult: null,
      ticket_numberOfChildren: null,
      ticket_pricePerChild: null,
      totalPrice: null,
      memorandum: '',
    };

    lineItemsByType['tickets'].push(blankTicket);
  }

  if (itemType === 'guide') {
    const blankGuide: QuotegridLineItemTypeGuide = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['guide'].length,
      itemType: 'guide',
      name: '',
      guide_accommodation: null,
      guide_transportation: null,
      guide_meals: null,
      guide_numberOfHours: null,
      guide_pricePerHour: null,
      totalPrice: null,
      memorandum: '',
    };

    lineItemsByType['guide'].push(blankGuide);
  }

  if (itemType === 'other') {
    const blankOther: QuotegridLineItemTypeOther = {
      id: NEW_ROW_ID,
      dayNum,
      date: dateiso,
      index: lineItemsByType['other'].length,
      itemType: 'other',
      name: '',
      totalPrice: null,
      memorandum: '',
    };

    lineItemsByType['other'].push(blankOther);
  }
}
