import { Paths } from './nestedfields.js';
import { HistoryInfoType } from './types_historized.js';
import { UserSimpleUidType } from './types_user.js';

export const coll_tripquotations = 'tripquotations';
export const coll_tripquotationshistory = 'tripquotationshistory';

export const bookingStatuses = ['RQ', 'RB', 'CF'] as const;
export type BookingStatusType = typeof bookingStatuses[number];

export type TripQuotationUpdateObjType = Partial<{
  [key in Paths<TripQuotationType>]: any;
}>;

export type TripQuotationType = {
  _isDeleted: boolean;
  id: string;
  history: HistoryInfoType;
  generalInfo: GeneralInformationType;
  pricingInfo?: QuotationPricingType;
  sourceCsvName: string;
  sourceCsv: string;
  sourceCsvHeaders: string[];
  passengerCsvData: { cells: string[] }[];
  hotelList: HotelTripQuotationRowType[];
  serviceList: ServiceTripQuotationRowType[];
  passengerList: PassengerTripQuotationRowType[];
  snapshotList: QuotationSnapshotType[];
  usersDesigners: UserSimpleUidType[]; // we use an array rather than a map to preserve order of designers
  usersDesignersUids: string[];
  requestCode: string;
  requestId: string;
  // metadata
  dateCreated: Date;
  userCreated: UserSimpleUidType;
  dateModified: Date;
  userModified: UserSimpleUidType;
};

export type TripQuotationRawType = Omit<TripQuotationType, 'serviceList' | 'hotelList' | 'passengerList' | 'snapshotList'> & {
  services: Record<string, ServiceTripQuotationRowRawType>;
  hotels: Record<string, HotelTripQuotationRowRawType>;
  passengers: Record<string, PassengerTripQuotationRowRawType>;
  snapshots?: Record<string, QuotationSnapshotRawType>;
};

export type ServiceTripQuotationRowRawType = Omit<ServiceTripQuotationRowType, 'id'> & { index: number };

export type PassengerTripQuotationRowType = {
  id: string;
  salutation: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  passengerType: string;
  gender: string;
  dateOfBirth: string;
  bookingFormReceived: boolean;
  mobilityMedical: string;
  dietary: string;
  heightCm: number | null;
  weightKg: number | null;
  identityType: string;
  identityNumber: string;
  expiryDate: string;
  nationality: string;
  issueDate: string;
  stayPreferences: string;
  clientClassification: string;
};
export type PassengerTripQuotationRowRawType = Omit<PassengerTripQuotationRowType, 'id'> & { index: number };

export type GeneralInformationType = {
  agencyItineraryRef: string;
  agencyItineraryRefWithVersion: string;
  agencyOwner: string;
  agencyBusinessUnit: string;
  agencyPrimaryContact: string; // field name is incorrect, this refers to the Pax that is primary contact for Audley

  paxName: string; // Audley field is 'Lead Passenger'
  numOfPax: number | null;
  tripStartDateiso: string;
  tripEndDateiso: string;
  tripDurationDays: number | null;

  generalCsvData: Record<string, string[]>;
};

export type QuotationPricingType = {
  eightyDaysMarkup: number | null;
  eightyDaysMarkupActive: boolean;
  fixedFee: number | null;
  fixedFeeActive: boolean;
  agentCommission: number | null;
  agentCommissionActive: boolean;
};

export type ServiceType = 'Activity' | 'Transfer' | 'Package_Item' | 'Rail';

export type ServiceTripQuotationRowType = {
  id: string;
  csvdata: string[] | null;
  dateiso: string;
  sourceServicePlace: string;
  serviceType: ServiceType;
  serviceName: string;
  sourceServiceName: string;
  serviceDetails: string;
  sourceServiceDetails: string;
  supplierNote: string;
  itemNote: string;
  pickUpTime: string;
  dropOffTime: string;
  passengers: string;
  bookingStatus: BookingStatusType;
  bookingRemarks: string;
  internalMemo: string;
  numOfPax: number | null;
  pricePerPax: number | null;
  totalPrice: number | null;
  selectedServicePrice: SelectedServicePriceType | null;
  highlighterColor?: 'pink' | 'yellow' | 'blue' | 'green' | null;
};

export type SelectedServicePriceType = {
  serviceId: string;
  servicePlace: string;
  serviceName: string;
  serviceItemId: string;
  serviceItemName: string;
  seasonName: string | null;
  ageName: string | null;
  numOfPax: number | null;
  pricePerPax: number | null;
};

export type MealPlan = 'B' | 'L' | 'D';
export type Season = 'low' | 'mid' | 'high' | 'special';

export type HotelTripQuotationRowRawType = Omit<HotelTripQuotationRowType, 'id' | 'hotelRoomList'> & {
  index: number;
  hotelRooms: Record<string, HotelRoomRawType>;
};
export type HotelTripQuotationRowType = {
  id: string;

  // readonly data
  serviceType: 'Accommodation';
  csvdata: string[] | null; // null for rows manually added in Portal
  supplierNote: string;
  itemNote: string;
  passengers: string;

  // csv fields original source values (readonly)
  sourceCheckinDateiso: string;
  sourceCheckoutDateiso: string;
  sourceHotelPlace: string;
  sourceHotelName: string;
  sourceRoomType: string;
  sourceMeals: string;
  sourceMealCode: string;

  // csv fields editable values
  checkinDateiso: string;
  checkoutDateiso: string;
  hotelPlace: string;
  hotelName: string;
  roomType: string;
  breakfastIncluded: boolean;
  dinnerIncluded: boolean;
  numOfNights: number | null;
  numOfRooms: number | null;
  numOfPax: number | null;

  // data not found in csv
  bookingStatus: BookingStatusType;
  bookingRemarks: string;
  internalMemo: string;
  isBarRate: boolean;
  pricePerPaxPerNight: number | null;
  totalPrice: number | null;
  selectedHotelPrice: SelectedHotelPriceType | null;
  hotelRoomList: HotelRoomType[];
  highlighterColor?: 'pink' | 'yellow' | 'blue' | 'green' | null;
};

export type SelectedHotelPriceType = {
  id: string;
  hotelPlace: string;
  hotelName: string;
  roomType: string;
  numOfPaxPerRoom: string | number;
  season: string | null;
  pricePerPax: number | null;
  // pricePerPaxBySeason: { season: string, pricePerPax: number | null }[]; // <- precise day by day pricing
};

export type HotelRoomRawType = Omit<HotelRoomType, 'id' | 'hotelNightList'> & {
  hotelNights: Record<string, HotelNightRawType>;
  index: number;
};
export type HotelRoomType = {
  id: string;
  hotelNightList: HotelNightType[];
};

export type HotelNightRawType = Omit<HotelNightType, 'id'> & {
  index: number;
};
export type HotelNightType = {
  id: string;
  dateiso: string;
  pricePerPax: number | null;
  numOfPax: number | null;
  totalPrice: number | null;
  season: Season | null;
};

export type QuotationSnapshotRawType = Omit<QuotationSnapshotType, 'id'>;
export type QuotationSnapshotType = {
  id: string;
  dateCreated: Date;
  userCreated: UserSimpleUidType;
  historyDocumentId: string;
  snapshotName: string;
};
