import dateFormat from 'dateformat';
import { getSpanDaysFloat, getTodayJST } from './datetools';


export function dateFormatUserFriendly(date: Date, singleLine: boolean = false) {
  if (!date) return '';
  const span = Math.floor(getSpanDaysFloat(getTodayJST(), date));
  const dayPart =
    span === 0 ? 'today'
      : span === -1 ? 'yest.'
        : span < 0 ? `${-span} ${span < -1 ? 'days ago' : 'day ago'}`
          : `${span} days from now`;
  const timePart = dateFormat(date, 'H:MM');
  return Math.abs(span) > 1 ? (
    singleLine
      ? dayPart
      : <div className='pt-2'>{dayPart}</div>/* add padding due to their being only 1 line */
  ) : (
    <div>
      {singleLine ? (
        <>
          {dayPart}  {timePart}
        </>
      ) : (
        <>
          {dayPart}<br />{timePart}
        </>
      )}
    </div>
  );
}
