import { addDays } from 'src/util/datetools';
import { GuideHoursCalculationResultsType } from './util_guidinghours_calculatedetails';



export type GuideDetailedHoursDataType = {
  guideName: string;
  guidePtoDays: number;
  dateutcStart: Date;
  dateutcEnd: Date;
  calculationResults: GuideHoursCalculationResultsType;
};



interface GuideDetailedHoursBreakdownProps {
  guideData: GuideDetailedHoursDataType;
  closePopup: () => void;
}

export function GuideDetailedHoursBreakdown({
  guideData,
  closePopup,
}: GuideDetailedHoursBreakdownProps) {


  return (

    <div className='tw-bg-white tw-p-4 tw-rounded tw-border tw-border-solid tw-border-slate-300 tw-shadow-lg' style={{
      position: 'absolute',
      top: '2em',
      left: '2em',
    }}>
      <div className='tw-flex tw-justify-between'>
        <h5>Detailed hours breakdown - {guideData.guideName}</h5>
        <div className='tw-text-right'>
          <i className='bi bi-x-square tw-cursor-pointer' onClick={() => {
            closePopup();
          }}></i>
        </div>
      </div>


      <table className='tw-border-collapse tw-border tw-border-slate-400 tw-border-solid tableGuideDetailedHours'>
        <thead className='[&>tr>th]:tw-bg-slate-200'>
          <tr>
            <th rowSpan={2}></th>
            <th rowSpan={2}>Month</th>
            <th rowSpan={2}>From</th>
            <th rowSpan={2}>To</th>
            <th colSpan={2}>Workdays</th>
            <th colSpan={3}>Guiding</th>
            <th colSpan={3}>Office</th>
            <th colSpan={3}>Total worked</th>
          </tr>
          <tr>
            <th>days</th>
            <th>cumu</th>
            <th>hrs</th>
            <th>days</th>
            <th>cumu</th>
            <th>hrs</th>
            <th>days</th>
            <th>cumu</th>
            <th>hrs</th>
            <th>days</th>
            <th>cumu</th>
          </tr>
        </thead>
        <tbody>
          {guideData.calculationResults.detailedTableRows.map((row) => {
            return (
              <tr key={row.sMonth}>
                <td>{1 + row.iMonth}</td>
                <td>{row.sMonth}</td>
                <td>{row.dateutcMonthStart.toISOString().substring(0, 10).replaceAll('-', '/')}</td>
                <td>{addDays(row.dateutcMonthEnd, -1).toISOString().substring(0, 10).replaceAll('-', '/')}</td>
                <td className='tw-text-right'>{row.numWorkdays} days</td>
                <td className='tw-text-right'>{row.cumuWorkdays} days</td>
                <td className='tw-text-right'>{row.monthGuidingHours} hrs</td>
                <td className='tw-text-right'>{row.monthGuidingHours / 8} days</td>
                <td className='tw-text-right'>{row.cumuGuidingHours / 8} days</td>
                <td className='tw-text-right'>{row.monthOfficeHours} hrs</td>
                <td className='tw-text-right'>{row.monthOfficeHours / 8} days</td>
                <td className='tw-text-right'>{row.cumuOfficeHours / 8} days</td>
                <td className='tw-text-right'>{row.monthWorkedHours} hrs</td>
                <td className='tw-text-right'>{row.monthWorkedHours / 8} days</td>
                <td className='tw-text-right'>{row.cumuWorkedHours / 8} days</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className='mt-3 tw-border-collapse [&_th]:tw-bg-slate-200 [&_tr>*]:tw-border [&_tr>*]:tw-border-solid [&_tr>*]:tw-border-slate-400 [&_tr>*]:tw-p-1 [&_tr>td]:tw-text-right'>
        <tbody>
          <tr>
            <th>Workdays in year</th>
            <td>{guideData.calculationResults.totalWorkdays} days</td>
          </tr>
          <tr>
            <th>Days worked</th>
            <td>{guideData.calculationResults.totalWorkedHours / 8} days</td>
          </tr>
          <tr>
            <th>PTO</th>
            <td>{guideData.guidePtoDays} days</td>
          </tr>
          <tr>
            <th>Remaining</th>
            <td>{guideData.calculationResults.totalWorkdays - guideData.calculationResults.totalWorkedHours / 8 - guideData.guidePtoDays} days</td>
          </tr>
        </tbody>
      </table>

    </div>

  );

}
