import { DocumentSnapshot, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAppContext } from 'src/hooks/useAppContext';
import { log_db_read } from 'src/util/util_log';



export function useEmergencyPhoneList() {

  const { db, userDetails, setDbError } = useAppContext();

  const [phoneList, setPhoneList] = useState<string[]>();
  useEffect(() => {
    log_db_read({ db, userDetails, logkey: 'db_read.settings.emergencyPhoneList', desc: 'Phone users' });

    const processSnapshot = function (snap: DocumentSnapshot) {
      const list = snap.data()!.list as string[];
      setPhoneList(list);
    };

    const q = doc(db, 'settings', 'emergencyPhoneList');
    const unsubscribe = onSnapshot(q, processSnapshot, (err) => setDbError('Getting emergency phone list', err));

    return unsubscribe;
  }, [db, setDbError, userDetails]);

  return phoneList;
}
