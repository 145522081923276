import { SearchResponse } from '@algolia/client-search';
import { DocumentSnapshot, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { getDateFilterFromParam, getQueryConstraintsBasedOnDateFilter } from 'src/components/ColumnFilters/util_filter_dates';
import { convertDateListToThreeLevelCache, getNonEmptyKeys, getSimpleAmountFilterFromParam, getStringFilterFromParam } from 'src/components/ColumnFilters/util_filters';
import { useAppContext } from 'src/hooks/useAppContext';
import { EightyDaysTeamsType, FilterCacheTourRequestsType } from 'src/types/objectTypes';
import { AgencySimpleType } from 'src/types/types_agencies';
import { ColumnFilterDateisoType, ColumnFilterPaxNameType, ColumnFilterRequestCodeType, ColumnFilterSimpleAmountType, ColumnFilterSimpleDateType, ColumnFilterStringType, ThreeLevelDateTree } from 'src/types/types_columnfilters';
import { TourRequestHitType } from 'src/types/types_tourrequest';
import { UserSimpleUidType, UserTeamType } from 'src/types/types_user';
import { userrole_canViewRequests } from 'src/util/user_roles';
import { stringCompare } from 'src/util/util_misc';
import { useUserDetailsList } from '../ExpenseSheet/util_getuserlist';
import { Unauthorized } from '../Unauthorized';
import { TourRequestCrud } from './RequestsCrud/TourRequestCrud';
import { TourRequestList } from './RequestsList/TourRequestList';
import { TourRequestSearch } from './RequestsList/TourRequestSearch';
import { useQueryRequestList } from './RequestsList/useQueryRequestList';
import { PageTourRequestStateType, RequestListColumnFilterAppliedData, RequestListColumnFilterCacheData } from './types_tourrequest_state';
import { sortRequestCodes } from './util_tourrequests';


const DEFAULT_ROWS_PER_PAGE = 50;

export type StatusKeyType = 'all' | 'confirmed_active' | 'ongoing' | 'confirmed_archived' | 'lost_and_cancelled';

export type FilterFieldType = {
  fieldname: FilterFieldNameType;
  filterValue: string;
};

export type FilterFieldNameType = 'eightyDaysDepartment' | 'agencyOrPlatformId' | 'country';

export function PageTourRequests() {

  const { db, setDbError, userDetails, navbarClickNewRequestCounter } = useAppContext();

  const location = useLocation();

  // false = we are on CRUD page, do not load any request lists
  // true  = we are on Requests List page, load request lists
  const isRequestListPage =
    location.pathname === '/requests/worklog/'
    || location.pathname === '/requests/list/';
  // || location.pathname === '/requests/'

  const sortByStatus = location.pathname === '/requests/worklog/';



  // below state is defined here and not in TourRequestList so that
  // state is not lost when editing a request
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [showExploreSeries, setShowExploreSeries] = useState(true);
  const [showSalesInfo1, setShowSalesInfo1] = useState(false);
  const [showExpensesMiniTable, setShowExpensesMiniTable] = useState(false);
  const [expandPaymentsTable, setExpandPaymentsTable] = useState(false);



  const [travellersShownReqIdList, setTravellersShownReqIdList] = useState<string[]>([]);



  // -------------------------------------------------
  // <column filters>
  const [searchParams, setSearchParams] = useSearchParams();
  const paramRequestCode = searchParams.get('requestCode');
  const paramTourStart = searchParams.get('tourStart');
  const paramTourEnd = searchParams.get('tourEnd');
  const paramPaymentDate = searchParams.get('paymentDate');
  const paramPaymentAmount = searchParams.get('paymentAmount');
  const paramPaxName = searchParams.get('paxName');
  const paramTeamCategory = searchParams.get('teamCategory');
  const paramCustomerType = searchParams.get('customerType');
  const paramAgency = searchParams.get('agencyId');
  const paramCountry = searchParams.get('country');
  const paramDesigner = searchParams.get('designer');

  // !paramRequestCode
  // && !paramTourStart
  // && !paramTourEnd
  // && !paramPaymentDate
  // && !paramPaymentAmount
  // && !paramPaxName
  // && !paramTeamCategory
  // && !paramCustomerType
  // && !paramAgency
  // && !paramCountry
  // // && !paramDesigner



  const [filterCacheRequestCodes, setFilterCacheRequestCodes] = useState<string[]>();
  const [filterCacheTourStartDates, setFilterCacheTourStartDates] = useState<ThreeLevelDateTree>();
  const [filterCacheTourEndDates, setFilterCacheTourEndDates] = useState<ThreeLevelDateTree>();
  const [filterCachePaymentDates, setFilterCachePaymentDates] = useState<ThreeLevelDateTree>();
  const [filterCachePaymentAmounts, setFilterCachePaymentAmounts] = useState<number[]>();
  const [filterCachePaxNames, setFilterCachePaxNames] = useState<string[]>();
  const [filterCacheTeamCategories, setFilterCacheTeamCategories] = useState<EightyDaysTeamsType>();
  const [filterCacheCustomerTypes, setFilterCacheCustomerTypes] = useState<string[]>();
  const [filterCacheAgencies, setFilterCacheAgencies] = useState<AgencySimpleType[]>();
  const [filterCacheCountries, setFilterCacheCountries] = useState<string[]>();
  useEffect(() => {

    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const cache = snapshot.data()?.filterCache as FilterCacheTourRequestsType;

      // request codes
      const requestCodeList = getNonEmptyKeys(cache.requestCodes);
      requestCodeList.sort(sortRequestCodes);
      setFilterCacheRequestCodes(requestCodeList);

      // tour start dates
      const tourStartDates = convertDateListToThreeLevelCache(getNonEmptyKeys(cache.tourStartDates));
      setFilterCacheTourStartDates(tourStartDates);

      // tour end dates
      const tourEndDates = convertDateListToThreeLevelCache(getNonEmptyKeys(cache.tourEndDates));
      setFilterCacheTourEndDates(tourEndDates);

      // payment dates
      const paymentDates = convertDateListToThreeLevelCache(getNonEmptyKeys(cache.paymentDates));
      setFilterCachePaymentDates(paymentDates);

      // amounts
      const paymentAmounts = getNonEmptyKeys(cache.paymentAmounts).map((a) => Number(a));
      paymentAmounts.sort((a, b) => a - b);
      setFilterCachePaymentAmounts(paymentAmounts);

      // pax names
      const paxNames = getNonEmptyKeys(cache.paxNames);
      paxNames.sort(stringCompare);
      setFilterCachePaxNames(paxNames);

    };

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'filterCacheRequests'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/filterCacheRequests', err)
    );

    return unsubscribe;
  }, [db, setDbError]);


  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const docu = snapshot.data()!;
      const objlist = docu.objlist as AgencySimpleType[];
      setFilterCacheAgencies(objlist);
    };

    const unsubscribe = onSnapshot(doc(db, '_cachedlists', 'cachedlistAgencies'), processSnapshot,
      (err) => setDbError('Getting _cachedlists/cachedlistAgencies', err)
    );

    return unsubscribe;
  }, [db, setDbError]);

  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const docu = snapshot.data()!;
      const list = docu.list as string[];
      setFilterCacheCountries(list);
    };

    const unsubscribe = onSnapshot(doc(db, 'settings', 'countryList'), processSnapshot,
      (err) => setDbError('Getting settings/countryList', err)
    );

    return unsubscribe;
  }, [db, setDbError]);

  useEffect(() => {
    const processSnapshot = function (snapshot: DocumentSnapshot) {
      const docu = snapshot.data()!;
      const customerTypes = docu.customerTypes as string[];
      const eightyDaysTeams = docu.eightyDaysTeams as EightyDaysTeamsType;
      setFilterCacheCustomerTypes(customerTypes);
      setFilterCacheTeamCategories(eightyDaysTeams);
    };

    const unsubscribe = onSnapshot(doc(db, 'settings', 'requestClassificationLists'), processSnapshot,
      (err) => setDbError('Getting settings/requestClassificationLists', err)
    );

    return unsubscribe;
  }, [db, setDbError]);



  // convert the 'requestCode' search parameter to a ColumnFilterRequestCodeType object
  const appliedFilterRequestCode = useMemo<ColumnFilterRequestCodeType | null>(() => {
    return getStringFilterFromParam(paramRequestCode);
  }, [paramRequestCode]);

  const appliedFilterTourStart = useMemo<ColumnFilterDateisoType | null | undefined>(() => {
    return getDateFilterFromParam(paramTourStart, filterCacheTourStartDates);
  }, [paramTourStart, filterCacheTourStartDates]);

  const appliedFilterTourEnd = useMemo<ColumnFilterDateisoType | null | undefined>(() => {
    return getDateFilterFromParam(paramTourEnd, filterCacheTourEndDates);
  }, [paramTourEnd, filterCacheTourEndDates]);

  const appliedFilterPaymentDate = useMemo<ColumnFilterSimpleDateType | null>(() => {
    return getStringFilterFromParam(paramPaymentDate);
  }, [paramPaymentDate]);

  const appliedFilterPaymentAmount = useMemo<ColumnFilterSimpleAmountType | null>(() => {
    return getSimpleAmountFilterFromParam(paramPaymentAmount);
  }, [paramPaymentAmount]);

  const appliedFilterPaxName = useMemo<ColumnFilterPaxNameType | null>(() => {
    return getStringFilterFromParam(paramPaxName);
  }, [paramPaxName]);

  const appliedFilterTeamCategory = useMemo<ColumnFilterStringType | null>(() => { // ROUND BUTTON
    return getStringFilterFromParam(paramTeamCategory);
  }, [paramTeamCategory]);

  const appliedFilterCustomerType = useMemo<ColumnFilterStringType | null>(() => {
    return getStringFilterFromParam(paramCustomerType);
  }, [paramCustomerType]);

  const appliedFilterAgency = useMemo<ColumnFilterStringType | null>(() => { // ROUND BUTTON
    return getStringFilterFromParam(paramAgency);
  }, [paramAgency]);

  const appliedFilterCountry = useMemo<ColumnFilterStringType | null>(() => { // ROUND BUTTON
    return getStringFilterFromParam(paramCountry);
  }, [paramCountry]);



  const columnFilterCacheData: RequestListColumnFilterCacheData = {
    filterCacheRequestCodes,
    filterCacheTourStartDates,
    filterCacheTourEndDates,
    filterCachePaymentDates,
    filterCachePaymentAmounts,
    filterCachePaxNames,
    filterCacheTeamCategories,
    filterCacheCustomerTypes,
    filterCacheAgencies,
    filterCacheCountries,
  };

  const columnFilterAppliedData: RequestListColumnFilterAppliedData = {
    appliedFilterRequestCode,
    appliedFilterTourStart: appliedFilterTourStart ?? null, // appliedFilterTourStart is undefined while loading
    appliedFilterTourEnd: appliedFilterTourEnd ?? null, // appliedFilterTourEnd is undefined while loading
    appliedFilterPaymentDate,
    appliedFilterPaymentAmount,
    appliedFilterPaxName,
    appliedFilterTeamCategory,
    appliedFilterCustomerType,
    appliedFilterAgency,
    appliedFilterCountry,
    paramDesigner,
  };

  // </column filters>
  // -------------------------------------------------


  // below are used only on search page
  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResponse<TourRequestHitType> | null>(null);



  // because date filters potentially use client side filtering, we can't use pagination
  const clientSideFiltering_tourStart = appliedFilterTourStart && getQueryConstraintsBasedOnDateFilter(appliedFilterTourStart, 'dateisoTourStart').clientSideFiltering;
  const clientSideFiltering_tourEnd = appliedFilterTourEnd && getQueryConstraintsBasedOnDateFilter(appliedFilterTourEnd, 'dateisoTourEnd').clientSideFiltering;
  // || appliedFilterPaymentDate // payment date uses a basic filter dropdown that doesn't allow selecting ranges, so client side filtering is not needed
  const disabledPagination = useMemo(() => {
    return {
      tourStart: clientSideFiltering_tourStart ?? false,
      tourEnd: clientSideFiltering_tourEnd ?? false,
      anyField: (clientSideFiltering_tourStart || clientSideFiltering_tourEnd) ?? false,
    };
  }, [clientSideFiltering_tourStart, clientSideFiltering_tourEnd]);



  const requestTable_all = useQueryRequestList({
    isRequestListPage,
    statusKey: 'all',
    sortByStatus,
    columnFilterAppliedData,
    showExploreSeries,
    disabledPagination,
  });

  const requestTable_confirmedActive = useQueryRequestList({
    isRequestListPage,
    statusKey: 'confirmed_active',
    sortByStatus,
    columnFilterAppliedData,
    showExploreSeries,
    disabledPagination,
  });

  const requestTable_ongoing = useQueryRequestList({
    isRequestListPage,
    statusKey: 'ongoing',
    sortByStatus,
    columnFilterAppliedData,
    showExploreSeries,
    disabledPagination,
  });

  const requestTable_confirmedArchived = useQueryRequestList({
    isRequestListPage,
    statusKey: 'confirmed_archived',
    sortByStatus,
    columnFilterAppliedData,
    showExploreSeries,
    disabledPagination,
  });

  const requestTable_lostAndCancelled = useQueryRequestList({
    isRequestListPage,
    statusKey: 'lost_and_cancelled',
    sortByStatus,
    columnFilterAppliedData,
    showExploreSeries,
    disabledPagination,
  });

  const requestTables =
    !sortByStatus ? [
      requestTable_all,
    ] : [
      requestTable_confirmedActive,
      requestTable_ongoing,
      requestTable_confirmedArchived,
      requestTable_lostAndCancelled,
    ];



  const userList = useUserDetailsList(false);
  const travelDesignerList = useMemo(() => {
    if (!userList) return undefined;
    const teamsToShow: UserTeamType[] = [
      'CEO',
      // 'Administration',
      'Travel Designer',
      // 'Operations',
    ];
    const list: UserSimpleUidType[] = [];
    for (const user of userList) {
      if (teamsToShow.includes(user.teamName)) {
        const newUser: UserSimpleUidType = {
          uid: user.id,
          email: user.email,
          name: user.displayNameEn,
        };
        list.push(newUser);
      }
    }
    list.sort((a, b) => a.name.localeCompare(b.name));
    return list;
  }, [userList]);


  const state: PageTourRequestStateType = {

    // props that are not specific to a query/table
    showAllColumns, setShowAllColumns,
    showExploreSeries, setShowExploreSeries,
    showSalesInfo1, setShowSalesInfo1,
    showExpensesMiniTable, setShowExpensesMiniTable,
    expandPaymentsTable, setExpandPaymentsTable,
    disabledPagination: disabledPagination.anyField,
    travellersShownReqIdList, setTravellersShownReqIdList,
    columnFilterCacheData,
    columnFilterAppliedData,
    travelDesignerList,

    // props specific to query/table
    sortByStatus,
    requestTables: requestTables,

    // below are for search page
    searchTerms, setSearchTerms,
    searchResults, setSearchResults,
  };


  if (!userrole_canViewRequests(userDetails.roles))
    return <Unauthorized />;

  return (
    <Routes>
      <Route path='/' element={<Navigate to='worklog/' />} />
      <Route path='/worklog/' element={<TourRequestList state={state} />} />
      <Route path='/list/' element={<TourRequestList state={state} />} />
      <Route path='/add' element={
        <TourRequestCrud action='create' simpleAgencyList={filterCacheAgencies} key={navbarClickNewRequestCounter} />
      } />
      <Route path='/edit/:tourrequestId' element={
        <TourRequestCrud action='edit' simpleAgencyList={filterCacheAgencies} />
      } />
      <Route path='/search' element={<TourRequestSearch state={state} />} />
    </Routes>
  );
}
