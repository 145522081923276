

interface FilterButtonProps {
  filterActive: boolean;
  setFilterValue: (value: string | null) => void;
  currentValue: string;
  title: string;
}

export function FilterButton({
  filterActive,
  setFilterValue,
  currentValue,
  title,
}: FilterButtonProps) {

  if (!setFilterValue)
    // filtering not available
    return;

  return (
    <i className={`bi ${filterActive ? 'bi-filter-circle-fill' : 'bi-filter-circle'}`} style={{ cursor: 'pointer' }} title={title} onClick={() => {
      if (filterActive)
        setFilterValue(null);
      else
        setFilterValue(currentValue);
    }}></i>
  );
}
