import { CommuteMonthlyType } from 'src/types/types_commute';
import { addDays, iso_from_utc0, parseIsoDateToUTC } from 'src/util/datetools';
import { arraySum } from 'src/util/util_misc';


export const phoneCostNight = 3000;
export const phoneCostHoliday = 5000;
export const commuteMonthlyCap = 30000;


export function getDateutcList(selectedMonth: string) {

  const firstDateUTC = parseIsoDateToUTC(`${selectedMonth}-01`);

  const list: Date[] = [];

  for (let dateutc = firstDateUTC;
    dateutc.getUTCMonth() === firstDateUTC.getUTCMonth();
    dateutc = addDays(dateutc, 1)) {
    list.push(dateutc);
  }

  return list;
}


export type CommuteMonthlyTotalsType = {
  monthlyTotal_commute: number;
  monthlyTotal_commute_capped: number;
  monthlyTotal_nonCommuteTransportation: number;
  monthlyTotal_nonCommuteOther: number;
  monthlyTotal_emergencyPhone: number;
  monthlyTotalNum_emergencyPhone_night: number;
  monthlyTotalNum_emergencyPhone_holiday: number;
  monthlyTotal_nonCommute: number;
  monthlyTotal_all: number;
};

export function calculateTotals(dateutcList: Date[], commuteMonthly: CommuteMonthlyType | undefined): CommuteMonthlyTotalsType {

  let monthlyTotal_commute = 0;
  let monthlyTotal_nonCommuteTransportation = 0;
  let monthlyTotal_nonCommuteOther = 0;
  let monthlyTotal_emergencyPhone = 0;
  let monthlyTotalNum_emergencyPhone_night = 0;
  let monthlyTotalNum_emergencyPhone_holiday = 0;


  for (const dateutc of dateutcList) {

    const sIsoDate = iso_from_utc0(dateutc);

    const legListOtherTransportation = commuteMonthly?.otherTransportationExpenses?.[sIsoDate];
    const listOtherNonTransportation = commuteMonthly?.otherNonTransportationExpenses?.[sIsoDate];
    const listEmergencyPhone = commuteMonthly?.emergencyPhone?.[sIsoDate];

    const legListToWork = commuteMonthly?.commuteToWork?.[sIsoDate];
    const legListFromWork = commuteMonthly?.commuteFromWork?.[sIsoDate];

    const costToWork = legListToWork ? arraySum(legListToWork.map((leg) => leg.legCost ?? 0)) : 0;
    const costFromWork = legListFromWork ? arraySum(legListFromWork.map((leg) => leg.legCost ?? 0)) : 0;
    const costCommute = costToWork + costFromWork;
    const costNonCommuteTransportation = legListOtherTransportation ? arraySum(legListOtherTransportation.map((leg) => leg.legCost ?? 0)) : 0;
    const costNonCommuteOther = listOtherNonTransportation ? arraySum(listOtherNonTransportation.map((leg) => leg.cost ?? 0)) : 0;
    const costEmergencyPhone = listEmergencyPhone ? listEmergencyPhone.map((item) => (item.shift === 'holiday' ? phoneCostHoliday : item.shift === 'night' ? phoneCostNight : NaN)).reduce((a, b) => a + b, 0) : 0;
    const numEmergencyPhone_night = listEmergencyPhone ? listEmergencyPhone.filter((item) => item.shift === 'night').length : 0;
    const numEmergencyPhone_holiday = listEmergencyPhone ? listEmergencyPhone.filter((item) => item.shift === 'holiday').length : 0;
    monthlyTotal_commute += costCommute;
    monthlyTotal_nonCommuteTransportation += costNonCommuteTransportation;
    monthlyTotal_nonCommuteOther += costNonCommuteOther;
    monthlyTotal_emergencyPhone += costEmergencyPhone;
    monthlyTotalNum_emergencyPhone_night += numEmergencyPhone_night;
    monthlyTotalNum_emergencyPhone_holiday += numEmergencyPhone_holiday;


  }

  const monthlyTotal_commute_capped = Math.min(monthlyTotal_commute, commuteMonthlyCap);
  const monthlyTotal_nonCommute = monthlyTotal_nonCommuteTransportation + monthlyTotal_nonCommuteOther + monthlyTotal_emergencyPhone;
  const monthlyTotal_all = monthlyTotal_commute_capped + monthlyTotal_nonCommute;


  return {
    monthlyTotal_commute,
    monthlyTotal_commute_capped,
    monthlyTotal_nonCommuteTransportation,
    monthlyTotal_nonCommuteOther,
    monthlyTotal_emergencyPhone,
    monthlyTotalNum_emergencyPhone_night,
    monthlyTotalNum_emergencyPhone_holiday,
    monthlyTotal_nonCommute,
    monthlyTotal_all,
  };

}
