


interface FilteringCriteriaPillProps {
  name: string;
  list: string[] | { id: string; name: string }[];
  setSearchParam: (newParam: string) => void;
}

export function FilteringCriteriaPill({
  name,
  list,
  setSearchParam,
}: FilteringCriteriaPillProps) {

  let listObj: { id: string; name: string }[];
  if (typeof list[0] === 'string') {
    listObj = list.map((x) => ({ id: x as string, name: x as string }));
  } else {
    listObj = list as { id: string; name: string }[];
  }

  return (
    <div className='tw-bg-slate-300 tw-border tw-border-solid tw-border-slate-400 tw-rounded tw-p-1'>
      {name}
      {listObj.length > 0 && ': '}
      {listObj.map((filterValue) => {
        const key = filterValue.id;
        const label = filterValue.name;
        const otherKeys = `[${listObj.filter((x) => x.id !== filterValue.id).map((x) => x.id).join(',')}]`;

        return (
          <span key={key} className='tw-px-1 '>
            <span className='tw-bg-slate-200 tw-px-1 tw-py-0.5'>
              {label}
              {listObj.length > 1 && (
                <i className='bi bi-x-circle tw-cursor-pointer tw-text-slate-700 tw-ml-1' onClick={() => setSearchParam(otherKeys)}></i>
              )}
            </span>
          </span>
        );
      })}
      <i className='bi bi-x-circle tw-cursor-pointer tw-text-slate-700 tw-ml-1' onClick={() => setSearchParam('')}></i>
    </div>
  );

}
