import { KeyboardEvent } from 'react';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useNavigate } from 'react-router-dom';
import { PayeeType } from 'src/types/types_payee';
import { getNameJaWithPrefixSuffix, stripCommas } from '../Payees/util_payees';
import './payeetypeahead.css';


type PayeeOptionType = PayeeType & { customOption: boolean };

interface PayeeTypeaheadProps {
  id: string;
  payeeList: PayeeType[];
  payeeId: string | null; // null if no payee selected
  setPayeeObj: (payee: PayeeType | null) => void;
  callbackNewPayee: (payeeName: string) => void;
  // -- props passed down as-is:
  autoFocus?: boolean;
  onKeyDown?: (e: KeyboardEvent) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export function PayeeTypeahead({
  id,
  payeeList,
  payeeId,
  setPayeeObj,
  callbackNewPayee,
  // -- props passed down as-is:
  autoFocus,
  onKeyDown,
  onBlur,
  disabled,
}: PayeeTypeaheadProps) {

  const navigate = useNavigate();

  const payeeObj = payeeId ? payeeList.find((p) => p.id === payeeId) : null;
  const singleSelections = payeeObj ? [payeeObj] : [];

  return (
    <Typeahead
      className='typeahead-suppress-popper-warning payee-typeahead'
      id={id}
      disabled={disabled}
      labelKey='displayName'
      // @ts-expect-error Typeahead
      onChange={(list: PayeeOptionType[]) => {
        if (list.length > 0 && list[0].customOption) {
          callbackNewPayee(list[0].displayName);
          return;
        }

        if (list.length > 0)
          setPayeeObj(list[0]);
        else
          setPayeeObj(null);
      }}
      options={payeeList}
      //placeholder="Choose a supplier..."
      selected={singleSelections}
      allowNew={true}
      newSelectionPrefix='Create new supplier: '
      filterBy={['nameJaFull', 'nameKanaFull', 'nameEnFull', 'nameHiraganaFull']}
      autoFocus={autoFocus}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      // @ts-expect-error Typeahead
      renderMenuItemChildren={(payee: PayeeType, { text }) => (
        <>
          <div className='typeaheadPayeeJa'>
            <div>
              <Highlighter search={text}>{getNameJaWithPrefixSuffix(payee)}</Highlighter>
            </div>
            {payee.nameBrandJa && (
              <div className='brand'>
                <Highlighter search={text}>{stripCommas(payee.nameBrandJa)}</Highlighter>
              </div>
            )}
            {payee.namePersonJa && (
              <div className='person'>
                <Highlighter search={text}>{stripCommas(payee.namePersonJa)}</Highlighter>
              </div>
            )}
          </div>
          <div className='typeaheadPayeeEn'>
            <div>
              <Highlighter search={text}>{payee.nameEnMain}</Highlighter>
            </div>
            {payee.nameBrandEn && (
              <div className='brand'>
                <Highlighter search={text}>{payee.nameBrandEn}</Highlighter>
              </div>
            )}
            {payee.namePersonEn && (
              <div className='person'>
                <Highlighter search={text}>{payee.namePersonEn}</Highlighter>
              </div>
            )}
          </div>
        </>
      )}
    />
  );
}
